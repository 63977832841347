import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tab } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt, faDroplet, faFireFlame, faWind, faHeat, faGear } from '@fortawesome/pro-regular-svg-icons';
import { Button, DropdownList } from '@cipa-gmbh/o6k-component-library';
import EnterEnPIBenchmarkModal from './EnterEnPIBenchmarkModal';
import { faCircleArrowDownRight } from '@fortawesome/pro-solid-svg-icons';
import EnPILineChart from './EnPILineChart';
import { ResourceTableData } from '../../api/resourceManager';
import { Plant } from '../../api/plants';

const resourceTableData: ResourceTableData[] = [
    {
        assetName: 'WPA',
        assetId: '12345',
        assetType: 'corrugator',
        dailyAverage: 1000,
        totalConsumption: 1000,
        enPI: 10,
        benchmarkEnPI: 10,
        deviation: 0.1,
    },
    {
        assetName: 'WPA2',
        assetId: '123456',
        assetType: 'corrugator',
        dailyAverage: 1000,
        totalConsumption: 1000,
        enPI: 10,
        benchmarkEnPI: 10,
        deviation: 0.1,
    },
    {
        assetName: 'WPA3',
        assetId: '123457',
        assetType: 'conversion',
        dailyAverage: 1000,
        totalConsumption: 1000,
        enPI: 10,
        benchmarkEnPI: 10,
        deviation: 0.1,
    },
    {
        assetName: 'Conversion',
        assetId: '123458',
        assetType: 'conversion',
        dailyAverage: 1000,
        totalConsumption: 1000,
        enPI: 10,
        benchmarkEnPI: 10,
        deviation: 0.1,
    },
    {
        assetName: 'Baling Press',
        assetId: '21113',
        assetType: 'baling_press',
        dailyAverage: 1000,
        totalConsumption: 904,
        enPI: null,
        benchmarkEnPI: null,
        deviation: null,
    },
    {
        assetName: 'Total Plant',
        assetId: '213131',
        assetType: 'total plant',
        dailyAverage: 5000,
        totalConsumption: 5000,
        enPI: 50,
        benchmarkEnPI: 10,
        deviation: 0.1,
    },
];

const resourceList = ['electricity', 'water', 'gas', 'steam'];

//const { data: resourceList, isInitialLoading: isLoadingResourceList } = useFetchResourceListForPlant(plant?.id ?? '');

const ResourceTableBody = ({
    resourceType,
    startDate,
    endDate,
    plant,
}: {
    resourceType: string;
    startDate: string;
    endDate: string;
    plant: Plant;
}) => {
    const { t } = useTranslation();
    /*
    const { data: resourceTableData, refetch: refetchResourceTableData } = useFetchResourceTableData(
      plant?.id ?? '',
      plant?.timezoneId ?? '',
      resourceType,
      startTime,
      endTime,
    );
    */

    return (
        <>
            {resourceTableData.map((item, i) => {
                return (
                    <tr key={i} className="table-row h-9">
                        <td
                            className={`opensans-regular w-1/2 whitespace-nowrap border-b border-r border-neutral-200 align-middle text-black`}
                        >
                            <span className="pl-3 text-xs">{item.assetName}</span>
                        </td>
                        <td
                            className={`opensans-regular w-3/25 whitespace-nowrap border-b border-r border-neutral-200 px-3 align-middle text-xs text-black`}
                        >
                            <div className="flex items-center justify-end gap-2">
                                <span className="pb-0.5 text-xs">{item.dailyAverage}</span>
                                {/*<FontAwesomeIcon icon={faCircleArrowUpRight} size="sm" className="text-red-400" />*/}
                            </div>
                        </td>
                        <td
                            className={`opensans-regular w-3/25 whitespace-nowrap border-b border-r border-neutral-200 px-3 align-middle text-xs text-black`}
                        >
                            <div className="flex items-center justify-end gap-1">
                                <span className="pb-0.5 text-xs">{item.totalConsumption} </span>
                                <span className="pb-0.5 text-xs text-neutral-500">
                                    (
                                    {parseFloat(
                                        (
                                            (item.totalConsumption /
                                                resourceTableData[resourceTableData.length - 1].totalConsumption) *
                                            100
                                        ).toFixed(2),
                                    )}
                                    %)
                                </span>
                            </div>
                        </td>
                        <td
                            className={`opensans-regular w-4/25 whitespace-nowrap border-b border-r border-neutral-200 px-3 align-middle text-xs text-black`}
                        >
                            {item.enPI !== null ? (
                                <div className="flex items-center justify-end gap-1">
                                    <span className="pb-0.5 text-xs">{item.enPI} </span>
                                    <span className="pb-0.5 text-xs text-neutral-500">
                                        {resourceType === 'electricity'
                                            ? `kWh`
                                            : resourceType === 'water'
                                            ? `l`
                                            : resourceType === 'gas'
                                            ? `m³`
                                            : resourceType === 'compressedAir'
                                            ? `m³`
                                            : resourceType === 'steam' && `m³`}
                                        /1000{' '}
                                        {item.assetType === 'corrugator'
                                            ? 'm²'
                                            : item.assetType === 'conversion' && t('LIVE.SHEETS')}
                                    </span>
                                </div>
                            ) : (
                                <span className="flex items-center justify-end">-</span>
                            )}
                        </td>
                        <td
                            className={`opensans-regular w-4/25 whitespace-nowrap border-b border-r border-neutral-200 px-3 align-middle text-xs text-black`}
                        >
                            {item.benchmarkEnPI !== null ? (
                                <div className="flex items-center justify-end gap-1">
                                    <span className="pb-0.5 text-xs">{item.benchmarkEnPI} </span>
                                    <span className="pb-0.5 text-xs text-neutral-500">
                                        {resourceType === 'electricity'
                                            ? `kWh`
                                            : resourceType === 'water'
                                            ? `l`
                                            : resourceType === 'gas'
                                            ? `m³`
                                            : resourceType === 'compressedAir'
                                            ? `m³`
                                            : resourceType === 'steam' && `m³`}
                                        /1000{' '}
                                        {item.assetType === 'corrugator'
                                            ? 'm²'
                                            : item.assetType === 'conversion' && t('LIVE.SHEETS')}
                                    </span>
                                </div>
                            ) : (
                                <span className="flex items-center justify-end">-</span>
                            )}
                        </td>
                        <td
                            className={`opensans-regular w-2/25 items-center whitespace-nowrap border-b border-neutral-200 px-3 text-center align-middle text-xs text-white
                                ${
                                    item.deviation !== null &&
                                    (item.deviation > 0 ? 'bg-green-400' : item.deviation < 0 && 'bg-red-400')
                                }`}
                        >
                            {item.deviation !== null ? (
                                <span className="pb-0.5 text-xs">
                                    {item.deviation > 0 ? '+' + item.deviation * 100 : '-' + item.deviation * 100}%
                                </span>
                            ) : (
                                ''
                            )}
                        </td>
                    </tr>
                );
            })}
            <EnPILineChart
                resourceTableData={resourceTableData}
                startDate={startDate}
                endDate={endDate}
                resourceType={resourceType}
                plant={plant ?? ({} as Plant)}
            />
        </>
    );
};

const ResourceTableHeader = ({ resourceType }: { resourceType: string }) => {
    const { t } = useTranslation();

    return (
        <thead className="table-header-group border-l">
            <tr className="h-14">
                <th
                    align="center"
                    scope="col"
                    className={`opensans-regular sticky top-0 z-10 w-1/2 border-b border-r border-t border-neutral-200 bg-neutral-100 text-black`}
                ></th>
                <th
                    align="right"
                    scope="col"
                    className={`opensans-regular w-3/25 whitespace-nowrap border-b border-r border-t border-neutral-200 bg-neutral-100 px-3 text-xs text-black`}
                >
                    <div className={'flex flex-col'}>
                        <span className="opensans-semibold text-xs">
                            {t('RESOURCE_MANAGER.RESOURCE_TABLE.DAILY_AVERAGE')}
                        </span>
                        <span className="text-neutral-500">
                            {resourceType === 'electricity'
                                ? `[kWh]`
                                : resourceType === 'water'
                                ? `[l]`
                                : resourceType === 'gas'
                                ? `[m³]`
                                : resourceType === 'compressedAir'
                                ? `[m³]`
                                : resourceType === 'steam' && `[m³]`}
                        </span>
                    </div>
                </th>
                <th
                    align="right"
                    scope="col"
                    className={`opensans-regular sticky top-0 z-10 w-3/25 whitespace-nowrap border-b border-r border-t border-neutral-200 bg-neutral-100 px-3 text-xs text-black`}
                >
                    <div className="flex flex-col">
                        <span className="opensans-semibold">
                            {t('RESOURCE_MANAGER.RESOURCE_TABLE.TOTAL_CONSUMPTION')}
                        </span>
                        <span className="text-neutral-500">
                            {resourceType === 'electricity'
                                ? `[kWh]`
                                : resourceType === 'water'
                                ? `[l]`
                                : resourceType === 'gas'
                                ? `[m³]`
                                : resourceType === 'compressedAir'
                                ? `[m³]`
                                : resourceType === 'steam' && `[m³]`}
                        </span>
                    </div>
                </th>
                <th
                    align="right"
                    scope="col"
                    className={`opensans-regular sticky top-0 z-10 w-4/25 whitespace-nowrap border-b border-r border-t border-neutral-200 bg-neutral-100 px-3 text-xs text-black`}
                >
                    <div className="flex flex-col">
                        <span className="opensans-semibold">EnPI</span>
                        <span className="text-neutral-500">
                            {resourceType === 'electricity'
                                ? `[kWh/1000 ${t('RESOURCE_MANAGER.RESOURCE_TABLE.UNITS')}]`
                                : resourceType === 'water'
                                ? `[l/1000 ${t('RESOURCE_MANAGER.RESOURCE_TABLE.UNITS')}]`
                                : resourceType === 'gas'
                                ? `[m³/1000 ${t('RESOURCE_MANAGER.RESOURCE_TABLE.UNITS')}]`
                                : resourceType === 'compressedAir'
                                ? `[m³/1000 ${t('RESOURCE_MANAGER.RESOURCE_TABLE.UNITS')}]`
                                : resourceType === 'steam' && `[m³/1000 ${t('RESOURCE_MANAGER.RESOURCE_TABLE.UNITS')}]`}
                        </span>
                    </div>
                </th>
                <th
                    align="right"
                    scope="col"
                    className={`opensans-regular sticky top-0 z-10 w-4/25 truncate border-b border-r border-t border-neutral-200 bg-neutral-100 px-3 text-xs text-black`}
                >
                    <div className="flex flex-col">
                        <span className="opensans-semibold">Benchmark EnPI</span>
                        <span className="text-neutral-500">
                            {resourceType === 'electricity'
                                ? `[kWh/1000 ${t('RESOURCE_MANAGER.RESOURCE_TABLE.UNITS')}]`
                                : resourceType === 'water'
                                ? `[l/1000 ${t('RESOURCE_MANAGER.RESOURCE_TABLE.UNITS')}]`
                                : resourceType === 'gas'
                                ? `[m³/1000 ${t('RESOURCE_MANAGER.RESOURCE_TABLE.UNITS')}]`
                                : resourceType === 'compressedAir'
                                ? `[m³/1000 ${t('RESOURCE_MANAGER.RESOURCE_TABLE.UNITS')}]`
                                : resourceType === 'steam' && `[m³/1000 ${t('RESOURCE_MANAGER.RESOURCE_TABLE.UNITS')}]`}
                        </span>
                    </div>
                </th>
                <th
                    align="right"
                    scope="col"
                    className={`opensans-regular sticky top-0 z-10 w-2/25 items-start border-b border-t border-neutral-200 bg-neutral-100 px-3 pt-[11px] align-top text-xs text-black `}
                >
                    <span className="opensans-semibold">{t('RESOURCE_MANAGER.RESOURCE_TABLE.DEVIATION')}</span>
                </th>
            </tr>
        </thead>
    );
};

const ResourceTableMobile = ({
    resourceType,
    filterType,
    startDate,
    endDate,
    plant,
}: {
    resourceType: string;
    filterType: string;
    startDate: string;
    endDate: string;
    plant: Plant;
}) => {
    return (
        <>
            <div className="flex flex-row items-center justify-between p-4">
                <div className="flex flex-col gap-1">
                    <span className="text-sm">{'Wellpappenanlage'}</span>
                    <span className="text-xs text-neutral-400">{'444 kWh/1.000 Borgen (Benchmark: 500)'}</span>
                </div>
                <div className="flex flex-row items-center gap-1">
                    <FontAwesomeIcon icon={faCircleArrowDownRight} className="h-4 w-4 text-green-400" />
                    <span className="text-sm text-green-400">{'12%'}</span>
                </div>
            </div>
            <hr />
            <div className="flex flex-row items-center justify-between p-4">
                <div className="flex flex-col gap-1">
                    <span className="text-sm">{'Wellpappenanlage'}</span>
                    <span className="text-xs text-neutral-400">{'444 kWh/1.000 Borgen (Benchmark: 500)'}</span>
                </div>
                <div className="flex flex-row items-center gap-1">
                    <FontAwesomeIcon icon={faCircleArrowDownRight} className="h-4 w-4 text-green-400" />
                    <span className="text-sm text-green-400">{'12%'}</span>
                </div>
            </div>
            {filterType === 'enpi' && (
                <EnPILineChart
                    resourceTableData={resourceTableData}
                    startDate={startDate}
                    endDate={endDate}
                    resourceType={resourceType}
                    plant={plant ?? ({} as Plant)}
                />
            )}
        </>
    );
};

export default function ResourceTable({
    startDate,
    endDate,
    plant,
}: {
    startDate: string;
    endDate: string;
    plant: Plant;
}): JSX.Element {
    const { t } = useTranslation();

    const [filterType, setFilterType] = useState<{ value: string; option: string }>({
        value: 'enpi',
        option: t('RESOURCE_MANAGER.SWITCH_ENPI'),
    });

    const filterArray: { value: string; option: string }[] = [
        { value: 'enpi', option: t('RESOURCE_MANAGER.SWITCH_ENPI') },
        { value: 'total', option: t('RESOURCE_MANAGER.SWITCH_TOTAL') },
        { value: 'average', option: t('RESOURCE_MANAGER.RESOURCE_TABLE.DAILY_AVERAGE') },
    ];
    //const { assetId } = useParams();

    /*  const { data: resourceAverageDailyConsumptionData, refetch: refetchTotalElectricityConsumptionData } =
      useFetchResourceAverageDailyConsumptionData(
          plant?.id ?? '',
          assetId ?? '',
          plant?.timezoneId ?? '',
          startTime,
          endTime,
          false,
      );*/
    const [showEnterEnPIBenchmarkModal, setShowEnterEnPIBenchmarkModal] = useState<boolean>(false);

    return (
        <div className="w-full bg-white lg:rounded lg:border lg:border-neutral-100">
            <div className="opensans-semibold flex flex-row items-center justify-between bg-mainBackground px-4 pb-2 pt-6 lg:hidden lg:h-10 lg:bg-white lg:px-6 lg:py-3 lg:pt-2">
                <span className="lg:text-xs lg:text-neutral-500">{t('RESOURCE_MANAGER.CONSUMPTIONS')}</span>
                <div className="w-46 lg:hidden">
                    <DropdownList
                        className="flex flex-row"
                        item_type={'default'}
                        value={{
                            label: filterType.option,
                            value: filterType.value,
                        }}
                        options={filterArray.map((filter) => ({
                            label: filter.option,
                            value: filter.value,
                            action: () => {
                                setFilterType(filter);
                            },
                        }))}
                        no_option_text={t('DROPDOWN.NO_OPTIONS')}
                        placeholder={t('DASHBOARDS.ERROR.NO_KPI')}
                        large
                    />
                </div>
            </div>
            <Tab.Group defaultIndex={0}>
                <div className="flex h-14 items-center">
                    <Tab.List className="opensans-semibold flex h-14 w-full justify-center gap-4 border-b px-6 text-xs text-neutral-500 lg:w-auto lg:items-center">
                        {resourceList?.find((item) => item === 'electricity') && (
                            <Tab className="h-full w-full focus:outline-none lg:w-auto">
                                {({ selected }) => (
                                    <div
                                        className={`inline-flex h-full w-full items-center justify-center gap-2 border-b-2 px-3 ${
                                            selected
                                                ? 'border-blue-400 text-blue-400'
                                                : 'border-transparent text-neutral-500 hover:border-b-2 hover:border-neutral-600 hover:text-neutral-600'
                                        }`}
                                    >
                                        <FontAwesomeIcon icon={faBolt} size="lg" className="hidden lg:block" />
                                        <span>{t('RESOURCE_MANAGER.ELECTRICITY')}</span>
                                    </div>
                                )}
                            </Tab>
                        )}
                        {resourceList?.find((item) => item === 'water') && (
                            <Tab className="h-full w-full focus:outline-none lg:w-auto">
                                {({ selected }) => (
                                    <div
                                        className={`inline-flex h-full w-full items-center justify-center gap-2 border-b-2 px-3 ${
                                            selected
                                                ? 'border-blue-400 text-blue-400'
                                                : 'border-transparent text-neutral-500 hover:border-b-2 hover:border-neutral-600 hover:text-neutral-600'
                                        }`}
                                    >
                                        <FontAwesomeIcon icon={faDroplet} size="lg" className="hidden lg:block" />
                                        <span>{t('RESOURCE_MANAGER.WATER')}</span>
                                    </div>
                                )}
                            </Tab>
                        )}
                        {resourceList?.find((item) => item === 'gas') && (
                            <Tab className="h-full w-full focus:outline-none lg:w-auto">
                                {({ selected }) => (
                                    <div
                                        className={`inline-flex h-full w-full items-center justify-center gap-2 border-b-2 px-3 ${
                                            selected
                                                ? 'border-blue-400 text-blue-400'
                                                : 'border-transparent text-neutral-500 hover:border-b-2 hover:border-neutral-600 hover:text-neutral-600'
                                        }`}
                                    >
                                        <FontAwesomeIcon icon={faFireFlame} size="lg" className="hidden lg:block" />
                                        <span>{t('RESOURCE_MANAGER.GAS')}</span>
                                    </div>
                                )}
                            </Tab>
                        )}
                        {resourceList?.find((item) => item === 'compressed_air') && (
                            <Tab className="h-full w-full focus:outline-none lg:w-auto">
                                {({ selected }) => (
                                    <div
                                        className={`inline-flex h-full w-full items-center justify-center gap-2 border-b-2 px-3 ${
                                            selected
                                                ? 'border-blue-400 text-blue-400'
                                                : 'border-transparent text-neutral-500 hover:border-b-2 hover:border-neutral-600 hover:text-neutral-600'
                                        }`}
                                    >
                                        <FontAwesomeIcon icon={faWind} size="lg" className="hidden lg:block" />
                                        <span>{t('RESOURCE_MANAGER.COMPRESSED_AIR')}</span>
                                    </div>
                                )}
                            </Tab>
                        )}
                        {resourceList?.find((item) => item === 'steam') && (
                            <Tab className="h-full w-full focus:outline-none lg:w-auto">
                                {({ selected }) => (
                                    <div
                                        className={`inline-flex h-full w-full items-center justify-center gap-2 border-b-2 px-3 ${
                                            selected
                                                ? 'border-blue-400 text-blue-400'
                                                : 'border-transparent text-neutral-500 hover:border-b-2 hover:border-neutral-600 hover:text-neutral-600'
                                        }`}
                                    >
                                        <FontAwesomeIcon icon={faHeat} size="lg" className="hidden lg:block" />
                                        <span>{t('RESOURCE_MANAGER.STEAM')}</span>
                                    </div>
                                )}
                            </Tab>
                        )}
                    </Tab.List>
                    <div className="ml-auto mr-6 hidden lg:block">
                        <Button
                            button_type={'secondary'}
                            color={'default'}
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                setShowEnterEnPIBenchmarkModal(true);
                            }}
                            className="whitespace-none flex items-center justify-center gap-2"
                        >
                            <FontAwesomeIcon icon={faGear} />
                            <span>{t('RESOURCE_MANAGER.RESOURCE_TABLE.ENTER_BENCHMARK')}</span>
                        </Button>
                    </div>
                </div>
                <Tab.Panels className="w-full overflow-x-auto">
                    <div
                        className="w-full border-collapse border-separate text-sm lg:table"
                        style={{ borderSpacing: 0 }}
                    >
                        {resourceList?.find((item) => item === 'electricity') && (
                            <Tab.Panel>
                                <div className="hidden lg:block">
                                    <ResourceTableHeader resourceType="electricity" />
                                    <ResourceTableBody
                                        resourceType="electricity"
                                        startDate={startDate}
                                        endDate={endDate}
                                        plant={plant}
                                    />
                                </div>
                                <div className="lg:hidden">
                                    <ResourceTableMobile
                                        resourceType="electricity"
                                        filterType={filterType.value}
                                        startDate={startDate}
                                        endDate={endDate}
                                        plant={plant}
                                    />
                                </div>
                            </Tab.Panel>
                        )}
                        {resourceList?.find((item) => item === 'water') && (
                            <Tab.Panel>
                                <div className="hidden lg:block">
                                    <ResourceTableHeader resourceType="water" />
                                    <ResourceTableBody
                                        resourceType="water"
                                        startDate={startDate}
                                        endDate={endDate}
                                        plant={plant}
                                    />
                                </div>
                                <div className="lg:hidden">
                                    <ResourceTableMobile
                                        resourceType="water"
                                        filterType={filterType.value}
                                        startDate={startDate}
                                        endDate={endDate}
                                        plant={plant}
                                    />
                                </div>
                            </Tab.Panel>
                        )}
                        {resourceList?.find((item) => item === 'gas') && (
                            <Tab.Panel>
                                <div className="hidden lg:block">
                                    <ResourceTableHeader resourceType="gas" />
                                    <ResourceTableBody
                                        resourceType="gas"
                                        startDate={startDate}
                                        endDate={endDate}
                                        plant={plant}
                                    />
                                </div>
                                <div className="lg:hidden">
                                    <ResourceTableMobile
                                        resourceType="gas"
                                        filterType={filterType.value}
                                        startDate={startDate}
                                        endDate={endDate}
                                        plant={plant}
                                    />
                                </div>
                            </Tab.Panel>
                        )}
                        {resourceList?.find((item) => item === 'compressed_air') && (
                            <Tab.Panel>
                                <div className="hidden lg:block">
                                    <ResourceTableHeader resourceType="compressedAir" />
                                    <ResourceTableBody
                                        resourceType="compressedAir"
                                        startDate={startDate}
                                        endDate={endDate}
                                        plant={plant}
                                    />
                                </div>
                                <div className="lg:hidden">
                                    <ResourceTableMobile
                                        resourceType="compressedAir"
                                        filterType={filterType.value}
                                        startDate={startDate}
                                        endDate={endDate}
                                        plant={plant}
                                    />
                                </div>
                            </Tab.Panel>
                        )}
                        {resourceList?.find((item) => item === 'steam') && (
                            <Tab.Panel>
                                <div className="hidden lg:block">
                                    <ResourceTableHeader resourceType="steam" />
                                    <ResourceTableBody
                                        resourceType="steam"
                                        startDate={startDate}
                                        endDate={endDate}
                                        plant={plant}
                                    />
                                </div>
                                <div className="lg:hidden">
                                    <ResourceTableMobile
                                        resourceType="steam"
                                        filterType={filterType.value}
                                        startDate={startDate}
                                        endDate={endDate}
                                        plant={plant}
                                    />
                                </div>
                            </Tab.Panel>
                        )}
                    </div>
                </Tab.Panels>
            </Tab.Group>
            {showEnterEnPIBenchmarkModal && (
                <EnterEnPIBenchmarkModal
                    setShowEnterEnPIBenchmarkModal={setShowEnterEnPIBenchmarkModal}
                    resourceList={resourceList}
                />
            )}
        </div>
    );
}
