import React from 'react';

export default function NotificationDropdownItemLoader(): JSX.Element {
    return (
        <div className="flex h-full flex-col items-center overflow-y-hidden">
            <div className="flex h-full w-[566px] flex-col">
                <div className="h-full w-[566px] overflow-y-auto bg-white">
                    <div className="skeleton-box h-20 w-full rounded" />
                    <div className="skeleton-box mt-2 h-20 w-full rounded" />
                    <div className="skeleton-box mt-2 h-20 w-full rounded" />
                    <div className="skeleton-box mb-2 mt-2 h-20 w-full rounded" />
                </div>
            </div>
        </div>
    );
}
