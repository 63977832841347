import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowUpRight,
    faChevronDown,
    faChevronsLeft,
    faChevronsRight,
    faChevronUp,
    faIndustry,
    faQuestionCircle,
    faShoppingCart,
    faHome,
    faUser,
    faArrowRightFromBracket,
    faChevronLeft,
    faChevronRight,
    faUserGraduate,
    faMagnifyingGlass,
    faAlarmExclamation,
    faChartMixed,
    faFile,
    faGear,
    faLeaf,
} from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { useFetchPlantByIdIsAdmin } from '../../api/plants';
import { Slide } from '@mui/material';
import { LanguageContext } from '../../pages/App';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { useAuth0 } from '@auth0/auth0-react';
import { useFetchUser } from '../../api/user';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { ReactComponent as HeaderLogo } from '../../assets/openpack_logo_header.svg';
import { ReactComponent as HistorianLogo } from '../../assets/historian_app_icon_sidebar.svg';
import useBreakpoint from '../../utils/customBreakpointHook';
import { Button } from '@cipa-gmbh/o6k-component-library';
import { useCookies } from 'react-cookie';
import { useFetchSolutionList } from '../../api/cockpit';

export default function Sidebar({
    isMobileMenuOpen,
    setMobileMenuOpen,
    isSidebarOpen,
    setSidebarOpen,
    setShowDataWindow,
    isAdmin,
    setIsAdmin,
    setIsPlantMember,
    isPlantMember,
}: {
    isMobileMenuOpen: boolean;
    setMobileMenuOpen: (arg: boolean) => void;
    isSidebarOpen?: boolean;
    setSidebarOpen?: (arg: boolean) => void;
    setShowDataWindow: (arg: boolean) => void;
    isAdmin: boolean;
    setIsAdmin: (arg: boolean) => void;
    setIsPlantMember: (arg: boolean) => void;
    isPlantMember: boolean;
}): JSX.Element {
    const { t } = useTranslation();
    //const match = useMatch('/plant/:plantId/*');
    const matchCockpit = useMatch('/cockpit');
    const matchHome = useMatch('/');
    //const matchServiceStore = useMatch('/service-store');
    const matchPlant = useMatch('/plant_overview');
    const matchProfile = useMatch('/profile');
    const matchLive = useMatch('/plant/:plantId/live/*');
    const matchResourceManager = useMatch('/plant/:plantId/resource_manager/*');
    const matchDashboards = useMatch('/plant/:plantId/dashboards/*');
    const matchDocumentation = useMatch('/plant/:plantId/documentation/*');
    const matchConfiguration = useMatch('/plant/:plantId/configuration/*');
    const matchConditionMonitoring = useMatch('/plant/:plantId/condition_monitoring/*');
    const matchHistorian = useMatch('/historian');
    const matchEducation = useMatch('/education');
    const matchEducationDetails = useMatch('/education/:educationId');
    const [, setIsSearch] = useState<boolean>(false);
    const [isHelpMenuOpen, setHelpMenuOpen] = useState<boolean>(false);
    const [uuid, setUuid] = useState<string>('');
    const chosenLanguage = useContext(LanguageContext);
    const navigate = useNavigate();
    const { user: auth0user } = useAuth0();
    const userId = auth0user?.sub;
    const { data: user, isInitialLoading: isInitialLoadingUser } = useFetchUser(userId ?? '');
    const { pathname } = useLocation();
    const { data: selectedPlant } = useFetchPlantByIdIsAdmin(uuid);
    const { data: defaultPlant } = useFetchPlantByIdIsAdmin(user?.defaultOrganization ?? '');
    const { logout: auth0Logout } = useAuth0();
    const { data: orgSolutionList } = useFetchSolutionList(user?.defaultOrganization ?? '');
    const breakpoint = useBreakpoint();
    const TooltipWithStyles = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#38465E',
            color: '#ffffff',
            fontFamily: 'opensans-regular',
            fontSize: '12px',
            borderRadius: '4px',
        },
    });
    const [cookies] = useCookies(['showResourceManager']);

    useEffect(() => {
        if (breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md') {
            setSidebarOpen && setSidebarOpen(false);
        } else {
            setSidebarOpen && setSidebarOpen(true);
        }
    }, [breakpoint]);
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();

    useEffect(() => {
        if (!!user?.defaultOrganization && defaultPlant) {
            setIsAdmin(defaultPlant?.isAdmin ?? false);
        }
    }, [user, defaultPlant]);

    useEffect(() => {
        if (pathname.includes('/plant/')) {
            setUuid(getUUID(pathname));
            if (getUUID(pathname) !== '') {
                if (selectedPlant?.isAdmin) {
                    setIsAdmin(true);
                } else {
                    setIsAdmin(false);
                }
            }
        }
    }, [pathname, selectedPlant]);

    useEffect(() => {
        setIsSearch(true);
    }, [query.get('search')]);

    function getUUID(url: string) {
        const substring = url.substr(url.lastIndexOf('/plant/') + 7, 36);
        const uuid = substring.match(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);
        if (uuid && uuid?.length > 0) {
            return uuid[0];
        } else {
            return '';
        }
    }

    const openCloseHandler = () => {
        setTimeout(() => setSidebarOpen && setSidebarOpen(!isSidebarOpen), 200);
    };

    return (
        <>
            {/* DESKTOP MENU VIEW */}
            <Slide direction="right" in={true}>
                <div
                    className={`z-20 hidden h-full flex-col justify-between overflow-y-auto border-r bg-white text-sm text-gray-900 lg:flex ${
                        isSidebarOpen ? `w-sidebarOpen` : `w-sidebarClose`
                    }`}
                >
                    <ul className="px-2 pt-2">
                        <li>
                            <TooltipWithStyles
                                title={!isSidebarOpen ? '' + t('SIDEBAR.COCKPIT') : ''}
                                placement="right"
                            >
                                <NavLink
                                    to="/cockpit"
                                    className={`${
                                        matchCockpit || matchHome
                                            ? 'bg-neutral-200 text-primary shadow'
                                            : 'hover:bg-neutral-100'
                                    } ${
                                        isSidebarOpen ? 'pl-4' : 'justify-center'
                                    } group inline-flex h-12 w-full items-center rounded focus:outline-none`}
                                    onClick={() => {
                                        if (!isPlantMember) {
                                            setIsPlantMember(true);
                                            window.location.href = '/cockpit';
                                        }
                                        setSidebarOpen && setSidebarOpen(true);
                                    }}
                                >
                                    <div className="inline-flex">
                                        <div className={` ${isSidebarOpen ? 'w-10' : ''} text-left`}>
                                            <FontAwesomeIcon
                                                icon={faHome}
                                                size="lg"
                                                className={` ${isSidebarOpen ? 'ml-0.5' : ''}`}
                                            />
                                        </div>
                                        {isSidebarOpen && (
                                            <span className="opensans-semibold">{t('SIDEBAR.COCKPIT')}</span>
                                        )}
                                    </div>
                                </NavLink>
                            </TooltipWithStyles>
                        </li>
                        <li>
                            <TooltipWithStyles title={!isSidebarOpen ? '' + t('SIDEBAR.LIVE') : ''} placement="right">
                                <NavLink
                                    to={`/plant/${user?.defaultOrganization}/live/overview`}
                                    className={`${
                                        matchLive ? 'bg-neutral-200 text-primary shadow' : 'hover:bg-neutral-100'
                                    } ${
                                        isSidebarOpen ? 'pl-4' : 'justify-center'
                                    } group inline-flex h-12 w-full items-center rounded focus:outline-none`}
                                    onClick={() => {
                                        setSidebarOpen && setSidebarOpen(true);
                                        setShowDataWindow(false);
                                    }}
                                >
                                    <div className="inline-flex">
                                        <div className={` ${isSidebarOpen ? 'w-10' : ''} text-left`}>
                                            <FontAwesomeIcon
                                                icon={faIndustry}
                                                size="lg"
                                                className={` ${isSidebarOpen ? 'ml-0.5' : ''}`}
                                            />
                                        </div>
                                        {isSidebarOpen && (
                                            <span className="opensans-semibold">{t('SIDEBAR.LIVE')}</span>
                                        )}
                                    </div>
                                </NavLink>
                            </TooltipWithStyles>
                        </li>
                        <li>
                            <TooltipWithStyles
                                title={!isSidebarOpen ? '' + 'Condition Monitoring' : ''}
                                placement="right"
                            >
                                <NavLink
                                    to={`/plant/${user?.defaultOrganization}/condition_monitoring`}
                                    className={`${
                                        matchConditionMonitoring
                                            ? 'bg-neutral-200 text-primary shadow'
                                            : 'hover:bg-neutral-100'
                                    } ${
                                        isSidebarOpen ? 'pl-4' : 'justify-center'
                                    } group inline-flex h-12 w-full items-center rounded focus:outline-none`}
                                    onClick={() => {
                                        setSidebarOpen && setSidebarOpen(true);
                                    }}
                                >
                                    <div className="inline-flex">
                                        <div className={` ${isSidebarOpen ? 'w-10' : ''} text-left`}>
                                            <FontAwesomeIcon
                                                icon={faAlarmExclamation}
                                                size="lg"
                                                className={` ${isSidebarOpen ? 'ml-0.5' : ''}`}
                                            />
                                        </div>
                                        {isSidebarOpen && (
                                            <span className="opensans-semibold">{'Condition Monitoring'}</span>
                                        )}
                                    </div>
                                </NavLink>
                            </TooltipWithStyles>
                        </li>
                        {cookies.hasOwnProperty('showResourceManager') &&
                            cookies.showResourceManager &&
                            orgSolutionList?.packages.findIndex(
                                (solutionItem) => solutionItem.name === 'Resource Control',
                            ) != -1 &&
                            orgSolutionList?.packages[
                                orgSolutionList?.packages.findIndex(
                                    (solutionItem) => solutionItem.name === 'Resource Control',
                                )
                            ].bookedByInputOrganization === true && (
                                <li>
                                    <TooltipWithStyles
                                        title={!isSidebarOpen ? '' + t('SIDEBAR.RESOURCE_MANAGER') : ''}
                                        placement="right"
                                    >
                                        <NavLink
                                            to={`/plant/${user?.defaultOrganization}/resource_manager`}
                                            className={`${
                                                matchResourceManager
                                                    ? 'bg-neutral-200 text-primary shadow'
                                                    : 'hover:bg-neutral-100'
                                            } ${
                                                isSidebarOpen ? 'pl-4' : 'justify-center'
                                            } group inline-flex h-12 w-full items-center rounded focus:outline-none`}
                                            onClick={() => {
                                                setSidebarOpen && setSidebarOpen(true);
                                            }}
                                        >
                                            <div className="inline-flex">
                                                <div className={` ${isSidebarOpen ? 'w-10' : ''} text-left`}>
                                                    <FontAwesomeIcon
                                                        icon={faLeaf}
                                                        size="lg"
                                                        className={` ${isSidebarOpen ? 'ml-0.5' : ''}`}
                                                    />
                                                </div>
                                                {isSidebarOpen && (
                                                    <span className="opensans-semibold">
                                                        {t('SIDEBAR.RESOURCE_MANAGER')}
                                                    </span>
                                                )}
                                            </div>
                                        </NavLink>
                                    </TooltipWithStyles>
                                </li>
                            )}
                        <li>
                            <TooltipWithStyles
                                title={!isSidebarOpen ? '' + t('SIDEBAR.DASHBOARDS') : ''}
                                placement="right"
                            >
                                <NavLink
                                    to={`/plant/${user?.defaultOrganization}/dashboards`}
                                    className={`${
                                        matchDashboards ? 'bg-neutral-200 text-primary shadow' : 'hover:bg-neutral-100'
                                    } ${
                                        isSidebarOpen ? 'pl-4' : 'justify-center'
                                    } group inline-flex h-12 w-full items-center rounded focus:outline-none`}
                                    onClick={() => {
                                        setSidebarOpen && setSidebarOpen(true);
                                    }}
                                >
                                    <div className="inline-flex">
                                        <div className={` ${isSidebarOpen ? 'w-10' : ''} text-left`}>
                                            <FontAwesomeIcon
                                                icon={faChartMixed}
                                                size="lg"
                                                className={` ${isSidebarOpen ? 'ml-0.5' : ''}`}
                                            />
                                        </div>
                                        {isSidebarOpen && (
                                            <span className="opensans-semibold">{t('SIDEBAR.DASHBOARDS')}</span>
                                        )}
                                    </div>
                                </NavLink>
                            </TooltipWithStyles>
                        </li>
                        <li>
                            <TooltipWithStyles
                                title={!isSidebarOpen ? '' + t('SIDEBAR.DOCUMENTATION') : ''}
                                placement="right"
                            >
                                <NavLink
                                    to={`/plant/${user?.defaultOrganization}/documentation`}
                                    className={`${
                                        matchDocumentation
                                            ? 'bg-neutral-200 text-primary shadow'
                                            : 'hover:bg-neutral-100'
                                    } ${
                                        isSidebarOpen ? 'pl-4' : 'justify-center'
                                    } group inline-flex h-12 w-full items-center rounded focus:outline-none`}
                                    onClick={() => {
                                        setSidebarOpen && setSidebarOpen(true);
                                    }}
                                >
                                    <div className="inline-flex">
                                        <div className={` ${isSidebarOpen ? 'w-10' : ''} text-left`}>
                                            <FontAwesomeIcon
                                                icon={faFile}
                                                size="lg"
                                                className={` ${isSidebarOpen ? 'ml-0.5' : ''}`}
                                            />
                                        </div>
                                        {isSidebarOpen && (
                                            <span className="opensans-semibold">{t('SIDEBAR.DOCUMENTATION')}</span>
                                        )}
                                    </div>
                                </NavLink>
                            </TooltipWithStyles>
                        </li>
                        {isAdmin && !isInitialLoadingUser && (
                            <li>
                                <TooltipWithStyles
                                    title={!isSidebarOpen ? '' + t('SIDEBAR.CONFIGURATION') : ''}
                                    placement="right"
                                >
                                    <NavLink
                                        to={`/plant/${user?.defaultOrganization}/configuration`}
                                        className={`${
                                            matchConfiguration
                                                ? 'bg-neutral-200 text-primary shadow'
                                                : 'hover:bg-neutral-100'
                                        } ${
                                            isSidebarOpen ? 'pl-4' : 'justify-center'
                                        } group inline-flex h-12 w-full items-center rounded focus:outline-none`}
                                        onClick={() => {
                                            setSidebarOpen && setSidebarOpen(true);
                                        }}
                                    >
                                        <div className="inline-flex">
                                            <div className={` ${isSidebarOpen ? 'w-10' : ''} text-left`}>
                                                <FontAwesomeIcon
                                                    icon={faGear}
                                                    size="lg"
                                                    className={` ${isSidebarOpen ? 'ml-0.5' : ''}`}
                                                />
                                            </div>
                                            {isSidebarOpen && (
                                                <span className="opensans-semibold">{t('SIDEBAR.CONFIGURATION')}</span>
                                            )}
                                        </div>
                                    </NavLink>
                                </TooltipWithStyles>
                            </li>
                        )}
                        <li>
                            <TooltipWithStyles
                                title={!isSidebarOpen ? '' + t('SIDEBAR.MARKETPLACE') : ''}
                                placement="right"
                            >
                                <a
                                    href={process.env.REACT_APP_MARKETPLACE_URL}
                                    target="_blank"
                                    rel="noreferrer"
                                    className={` ${
                                        isSidebarOpen ? 'justify-between pl-4' : 'justify-center'
                                    } mt-1 inline-flex h-12 w-full items-center rounded hover:bg-neutral-100`}
                                >
                                    <div className="inline-flex">
                                        <div className={` ${isSidebarOpen ? 'w-10' : ''} text-left`}>
                                            <FontAwesomeIcon icon={faShoppingCart} size="lg" />
                                        </div>
                                        {isSidebarOpen && (
                                            <span className="opensans-semibold">{t('SIDEBAR.MARKETPLACE')}</span>
                                        )}
                                    </div>
                                    {isSidebarOpen && (
                                        <FontAwesomeIcon icon={faArrowUpRight} className="mr-3" size="sm" />
                                    )}
                                </a>
                            </TooltipWithStyles>
                        </li>
                        <ul className={`${isHelpMenuOpen && isSidebarOpen ? 'bg-neutral-50 shadow' : ''} mt-1 rounded`}>
                            <TooltipWithStyles title={!isSidebarOpen ? '' + t('SIDEBAR.HELP') : ''} placement="right">
                                <li>
                                    <button
                                        className={`${
                                            isHelpMenuOpen && isSidebarOpen ? 'bg-neutral-50' : 'bg-transparent'
                                        } ${
                                            isSidebarOpen ? 'justify-between pl-4' : 'justify-center'
                                        } group inline-flex h-12 w-full items-center rounded hover:bg-neutral-100 focus:outline-none`}
                                        onClick={() => {
                                            setHelpMenuOpen(!isHelpMenuOpen);
                                            setSidebarOpen && setSidebarOpen(true);
                                        }}
                                    >
                                        <div className="inline-flex">
                                            <div className={` ${isSidebarOpen ? 'w-10' : ''} text-left`}>
                                                <FontAwesomeIcon
                                                    icon={faQuestionCircle}
                                                    size="lg"
                                                    className={` ${isSidebarOpen ? 'ml-0.5' : ''} `}
                                                />
                                            </div>
                                            {isSidebarOpen && (
                                                <span className="opensans-semibold">{t('SIDEBAR.HELP')}</span>
                                            )}
                                        </div>
                                        {isSidebarOpen && (
                                            <div className="w-6">
                                                <FontAwesomeIcon
                                                    icon={!isHelpMenuOpen ? faChevronDown : faChevronUp}
                                                    size="sm"
                                                    className="mr-2"
                                                />
                                            </div>
                                        )}
                                    </button>
                                    {isHelpMenuOpen && isSidebarOpen && (
                                        <div className="pl-24">
                                            <li>
                                                <a
                                                    className="-ml-24 flex h-12 items-center justify-between rounded pl-sideBarElements hover:bg-neutral-100"
                                                    href={`${
                                                        chosenLanguage === 'de'
                                                            ? 'https://openpack.com/contact'
                                                            : 'https://openpack.com/en/contact'
                                                    }`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <span>{t('SIDEBAR.HELP_MENU.CONTACT')}</span>
                                                    <FontAwesomeIcon icon={faArrowUpRight} className="mr-3" size="sm" />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    className="-ml-24 flex h-12 items-center justify-between rounded pl-sideBarElements hover:bg-neutral-100"
                                                    href={`${
                                                        chosenLanguage === 'de'
                                                            ? 'https://openpack.com/impressum'
                                                            : 'https://openpack.com/en/impressum'
                                                    }`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <span>{t('SIDEBAR.HELP_MENU.IMPRINT')}</span>
                                                    <FontAwesomeIcon icon={faArrowUpRight} className="mr-3" size="sm" />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    className="-ml-24 flex h-12 items-center justify-between rounded pl-sideBarElements hover:bg-neutral-100"
                                                    href={`${
                                                        chosenLanguage === 'de'
                                                            ? 'https://openpack.com/datenschutz'
                                                            : 'https://openpack.com/en/datenschutz'
                                                    }`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <span>{t('SIDEBAR.HELP_MENU.PRIVACY')}</span>
                                                    <FontAwesomeIcon icon={faArrowUpRight} className="mr-3" size="sm" />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    className="-ml-24 flex h-12 items-center justify-between rounded pl-sideBarElements hover:bg-neutral-100"
                                                    href={`${
                                                        chosenLanguage === 'de'
                                                            ? 'https://openpack.com/agb'
                                                            : 'https://openpack.com/en/agb'
                                                    }`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <span className="w-full group-hover:text-clip">
                                                        {t('SIDEBAR.HELP_MENU.TERMS_OF_USE')}
                                                    </span>
                                                    <FontAwesomeIcon icon={faArrowUpRight} className="mr-3" size="sm" />
                                                </a>
                                            </li>
                                        </div>
                                    )}
                                </li>
                            </TooltipWithStyles>
                        </ul>
                        {(matchHistorian || matchEducation || matchEducationDetails) && (
                            <div className="mb-5 mt-6 px-5">
                                <hr className="text-gray-50" />
                            </div>
                        )}
                        {matchHistorian && (
                            <li>
                                <TooltipWithStyles title={!isSidebarOpen ? '' + 'Historian' : ''} placement="right">
                                    <NavLink
                                        to="/historian"
                                        className={` ${
                                            isSidebarOpen ? 'pl-4' : 'justify-center'
                                        } group inline-flex h-12 w-full items-center rounded bg-neutral-200 text-primary shadow focus:outline-none`}
                                        onClick={() => {
                                            setSidebarOpen && setSidebarOpen(true);
                                        }}
                                    >
                                        <div className="inline-flex">
                                            <div className={` ${isSidebarOpen ? 'w-10' : ''} text-left`}>
                                                <HistorianLogo
                                                    className={` ${isSidebarOpen ? 'ml-0.5' : ''} h-5 text-blue-500`}
                                                />
                                            </div>
                                            {isSidebarOpen && <span className="opensans-semibold">{'Historian'}</span>}
                                        </div>
                                    </NavLink>
                                </TooltipWithStyles>
                            </li>
                        )}
                        {(matchEducation || matchEducationDetails) && (
                            <li>
                                <TooltipWithStyles
                                    title={!isSidebarOpen ? '' + t('SIDEBAR.EDUCATION') + ' (BETA)' : ''}
                                    placement="right"
                                >
                                    <NavLink
                                        to="/education"
                                        className={`${
                                            matchEducation || matchEducationDetails
                                                ? 'bg-neutral-200 text-primary shadow'
                                                : 'hover:bg-neutral-100'
                                        } ${
                                            isSidebarOpen ? 'pl-4' : 'justify-center'
                                        } group inline-flex h-12 w-full items-center rounded focus:outline-none`}
                                        onClick={() => {
                                            setSidebarOpen && setSidebarOpen(true);
                                        }}
                                    >
                                        <div className="inline-flex">
                                            <div className={` ${isSidebarOpen ? 'w-10' : ''} text-left`}>
                                                <FontAwesomeIcon
                                                    icon={faUserGraduate}
                                                    size="lg"
                                                    className={` ${isSidebarOpen ? 'ml-0.5' : ''}`}
                                                />
                                            </div>
                                            {isSidebarOpen && (
                                                <span className="opensans-semibold">
                                                    {t('SIDEBAR.EDUCATION') + ' (BETA)'}
                                                </span>
                                            )}
                                        </div>
                                    </NavLink>
                                </TooltipWithStyles>
                            </li>
                        )}
                    </ul>
                    <button
                        className="mb-2 ml-2 mr-2 flex h-8 flex-shrink-0 flex-row items-center justify-center rounded border border-neutral-200 hover:bg-neutral-50"
                        onClick={openCloseHandler}
                    >
                        <FontAwesomeIcon icon={isSidebarOpen ? faChevronsLeft : faChevronsRight} />
                    </button>
                </div>
            </Slide>
            {/* MOBILE MENU VIEW */}
            <Slide direction="right" in={isMobileMenuOpen}>
                <div
                    className={`${
                        isMobileMenuOpen ? 'block' : 'hidden'
                    } absolute left-0 top-0 z-50 grid h-full w-sidebarOpen grid-rows-custom-sidenav-layout bg-white xl:hidden`}
                >
                    <div className="flex h-18 items-center justify-between border px-6">
                        {!isHelpMenuOpen ? (
                            <HeaderLogo className="-ml-4 w-24 hover:cursor-pointer" onClick={() => navigate('/')} />
                        ) : (
                            <button
                                onClick={() => {
                                    setHelpMenuOpen(false);
                                }}
                            >
                                <FontAwesomeIcon icon={faChevronLeft} className="text-xs" />
                            </button>
                        )}
                        {isHelpMenuOpen && <span className="opensans-semibold text-xs">{t('SIDEBAR.HELP')}</span>}
                        <button onClick={() => setMobileMenuOpen(false)}>
                            <FontAwesomeIcon icon={faTimes} className="text-lg text-neutral-900" />
                        </button>
                    </div>
                    <div className="z-20 flex h-full flex-col justify-between overflow-y-auto border-r bg-white text-sm text-gray-900">
                        {!isHelpMenuOpen && (
                            <ul className="px-2 pt-2">
                                <li>
                                    <NavLink
                                        to="/cockpit"
                                        className={`${
                                            matchCockpit || matchHome
                                                ? 'bg-neutral-200 text-primary shadow'
                                                : 'hover:bg-neutral-100'
                                        } group inline-flex h-12 w-full items-center rounded pl-4 focus:outline-none`}
                                        onClick={() => {
                                            if (!isPlantMember) {
                                                setIsPlantMember(true);
                                                window.location.href = '/cockpit';
                                            }
                                            setMobileMenuOpen(false);
                                        }}
                                    >
                                        <div className="inline-flex">
                                            <div className="text-left">
                                                <FontAwesomeIcon icon={faHome} size="lg" />
                                            </div>
                                            <span className="opensans-semibold ml-5">{t('SIDEBAR.COCKPIT')}</span>
                                        </div>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to={`/plant/${user?.defaultOrganization}/live/overview`}
                                        className={`${
                                            matchLive ? 'bg-neutral-200 text-primary shadow' : 'hover:bg-neutral-100'
                                        } group inline-flex h-12 w-full items-center rounded pl-4 focus:outline-none`}
                                        onClick={() => setMobileMenuOpen(false)}
                                    >
                                        <div className="inline-flex">
                                            <div className="text-left">
                                                <FontAwesomeIcon icon={faIndustry} size="lg" />
                                            </div>
                                            <span className="opensans-semibold ml-5">{t('SIDEBAR.LIVE')}</span>
                                        </div>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to={`/plant/${user?.defaultOrganization}/condition_monitoring`}
                                        className={`${
                                            matchConditionMonitoring
                                                ? 'bg-neutral-200 text-primary shadow'
                                                : 'hover:bg-neutral-100'
                                        } group inline-flex h-12 w-full items-center rounded pl-4 focus:outline-none`}
                                        onClick={() => setMobileMenuOpen(false)}
                                    >
                                        <div className="inline-flex">
                                            <div className="text-left">
                                                <FontAwesomeIcon
                                                    icon={faAlarmExclamation}
                                                    size="lg"
                                                    className="ml-0.5"
                                                />
                                            </div>
                                            <span className="opensans-semibold ml-5">{'Condition Monitoring'}</span>
                                        </div>
                                    </NavLink>
                                </li>
                                {cookies.hasOwnProperty('showResourceManager') &&
                                    cookies.showResourceManager &&
                                    orgSolutionList?.packages.findIndex(
                                        (solutionItem) => solutionItem.name === 'Resource Control',
                                    ) != -1 &&
                                    orgSolutionList?.packages[
                                        orgSolutionList?.packages.findIndex(
                                            (solutionItem) => solutionItem.name === 'Resource Control',
                                        )
                                    ].bookedByInputOrganization === true && (
                                        <li>
                                            <NavLink
                                                to={`/plant/${user?.defaultOrganization}/resource_manager`}
                                                className={`${
                                                    matchResourceManager
                                                        ? 'bg-neutral-200 text-primary shadow'
                                                        : 'hover:bg-neutral-100'
                                                } group inline-flex h-12 w-full items-center rounded pl-4 focus:outline-none`}
                                                onClick={() => setMobileMenuOpen(false)}
                                            >
                                                <div className="inline-flex">
                                                    <div className="text-left">
                                                        <FontAwesomeIcon icon={faLeaf} size="lg" className="ml-0.5" />
                                                    </div>
                                                    <span className="opensans-semibold ml-5">
                                                        {t('SIDEBAR.RESOURCE_MANAGER')}
                                                    </span>
                                                </div>
                                            </NavLink>
                                        </li>
                                    )}
                                <li>
                                    <NavLink
                                        to={`/plant/${user?.defaultOrganization}/dashboards`}
                                        className={`${
                                            matchDashboards
                                                ? 'bg-neutral-200 text-primary shadow'
                                                : 'hover:bg-neutral-100'
                                        } group inline-flex h-12 w-full items-center rounded pl-4 focus:outline-none`}
                                        onClick={() => setMobileMenuOpen(false)}
                                    >
                                        <div className="inline-flex">
                                            <div className="text-left">
                                                <FontAwesomeIcon icon={faChartMixed} size="lg" className="ml-0.5" />
                                            </div>
                                            <span className="opensans-semibold ml-5">{t('SIDEBAR.DASHBOARDS')}</span>
                                        </div>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to={`/plant/${user?.defaultOrganization}/documentation`}
                                        className={`${
                                            matchDocumentation
                                                ? 'bg-neutral-200 text-primary shadow'
                                                : 'hover:bg-neutral-100'
                                        } group inline-flex h-12 w-full items-center rounded pl-4 focus:outline-none`}
                                        onClick={() => setMobileMenuOpen(false)}
                                    >
                                        <div className="inline-flex">
                                            <div className="text-left">
                                                <FontAwesomeIcon icon={faFile} size="lg" className="ml-1.5" />
                                            </div>
                                            <span className="opensans-semibold ml-5">{t('SIDEBAR.DOCUMENTATION')}</span>
                                        </div>
                                    </NavLink>
                                </li>
                                {isAdmin && !isInitialLoadingUser && (
                                    <li>
                                        <NavLink
                                            to={`/plant/${user?.defaultOrganization}/configuration`}
                                            className={`${
                                                matchConfiguration
                                                    ? 'bg-neutral-200 text-primary shadow'
                                                    : 'hover:bg-neutral-100'
                                            } group inline-flex h-12 w-full items-center rounded pl-4 focus:outline-none`}
                                            onClick={() => setMobileMenuOpen(false)}
                                        >
                                            <div className="inline-flex">
                                                <div className="text-left">
                                                    <FontAwesomeIcon icon={faGear} size="lg" className="ml-0.5" />
                                                </div>
                                                <span className="opensans-semibold ml-5">
                                                    {t('SIDEBAR.CONFIGURATION')}
                                                </span>
                                            </div>
                                        </NavLink>
                                    </li>
                                )}
                                <li>
                                    <a
                                        href={process.env.REACT_APP_MARKETPLACE_URL}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="mt-1 inline-flex h-12 w-full items-center justify-between rounded pl-4 hover:bg-neutral-100"
                                        onClick={() => setMobileMenuOpen(false)}
                                    >
                                        <div className="inline-flex">
                                            <div className="text-left">
                                                <FontAwesomeIcon icon={faShoppingCart} size="lg" />
                                            </div>
                                            <span className="opensans-semibold ml-5">{t('SIDEBAR.MARKETPLACE')}</span>
                                        </div>
                                        <FontAwesomeIcon icon={faArrowUpRight} className="mr-3" size="sm" />
                                    </a>
                                </li>
                                <ul className={`${isHelpMenuOpen ? 'bg-neutral-50 shadow' : ''} mt-1 rounded`}>
                                    <li>
                                        <button
                                            className={`${
                                                isHelpMenuOpen ? 'bg-neutral-50' : 'bg-transparent'
                                            } group inline-flex h-12 w-full items-center justify-between rounded pl-4 hover:bg-neutral-100 focus:outline-none`}
                                            onClick={() => setHelpMenuOpen(true)}
                                        >
                                            <div className="inline-flex">
                                                <div className="w-10 text-left">
                                                    <FontAwesomeIcon
                                                        icon={faQuestionCircle}
                                                        size="lg"
                                                        className="ml-0.5"
                                                    />
                                                </div>
                                                <span className="opensans-semibold">{t('SIDEBAR.HELP')}</span>
                                            </div>
                                            <div className="w-6">
                                                <FontAwesomeIcon icon={faChevronRight} size="sm" className="mr-2" />
                                            </div>
                                        </button>
                                    </li>
                                </ul>
                                <hr className="mt-1 text-neutral-100" />
                                <li>
                                    <Button
                                        button_type={'tertiary'}
                                        color={'default'}
                                        large
                                        className="group mt-1 inline-flex h-12 w-full items-center rounded pl-4 hover:bg-neutral-100 focus:outline-none"
                                        onClick={() => {
                                            setMobileMenuOpen(false);
                                        }}
                                    >
                                        <div className="inline-flex">
                                            <div className="w-10 text-left">
                                                <FontAwesomeIcon
                                                    icon={faMagnifyingGlass}
                                                    size="lg"
                                                    className="ml-0.5"
                                                />
                                            </div>
                                            <span className="opensans-semibold">{t('SEARCH.SEARCH')}</span>
                                        </div>
                                    </Button>
                                </li>
                            </ul>
                        )}
                        {isHelpMenuOpen && (
                            <ul className="px-2 pt-2">
                                <li>
                                    <a
                                        className="flex h-12 items-center justify-between rounded pl-4 hover:bg-neutral-100"
                                        href={`${
                                            chosenLanguage === 'de'
                                                ? 'https://openpack.com/contact-9'
                                                : 'https://openpack.com/en/contact-9'
                                        }`}
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={() => {
                                            setHelpMenuOpen(false);
                                            setMobileMenuOpen(false);
                                        }}
                                    >
                                        <span className="opensans-semibold">{t('SIDEBAR.HELP_MENU.CONTACT')}</span>
                                        <FontAwesomeIcon icon={faArrowUpRight} className="mr-3" size="sm" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="flex h-12 items-center justify-between rounded pl-4 hover:bg-neutral-100"
                                        href={`${
                                            chosenLanguage === 'de'
                                                ? 'https://openpack.com/impressum'
                                                : 'https://openpack.com/en/impressum'
                                        }`}
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={() => {
                                            setHelpMenuOpen(false);
                                            setMobileMenuOpen(false);
                                        }}
                                    >
                                        <span className="opensans-semibold">{t('SIDEBAR.HELP_MENU.IMPRINT')}</span>
                                        <FontAwesomeIcon icon={faArrowUpRight} className="mr-3" size="sm" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="flex h-12 items-center justify-between rounded pl-4 hover:bg-neutral-100"
                                        href={`${
                                            chosenLanguage === 'de'
                                                ? 'https://openpack.com/datenschutz'
                                                : 'https://openpack.com/en/datenschutz'
                                        }`}
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={() => {
                                            setHelpMenuOpen(false);
                                            setMobileMenuOpen(false);
                                        }}
                                    >
                                        <span className="opensans-semibold">{t('SIDEBAR.HELP_MENU.PRIVACY')}</span>
                                        <FontAwesomeIcon icon={faArrowUpRight} className="mr-3" size="sm" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="flex h-12 items-center justify-between rounded pl-4 hover:bg-neutral-100"
                                        href={`${
                                            chosenLanguage === 'de'
                                                ? 'https://openpack.com/agb'
                                                : 'https://openpack.com/en/agb'
                                        }`}
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={() => {
                                            setHelpMenuOpen(false);
                                            setMobileMenuOpen(false);
                                        }}
                                    >
                                        <span className="opensans-semibold w-full group-hover:text-clip">
                                            {t('SIDEBAR.HELP_MENU.TERMS_OF_USE')}
                                        </span>
                                        <FontAwesomeIcon icon={faArrowUpRight} className="mr-3" size="sm" />
                                    </a>
                                </li>
                            </ul>
                        )}
                        <div>
                            <div className="mt-10 border-b border-t border-neutral-200 p-2">
                                <ul>
                                    <li>
                                        <NavLink
                                            to="/profile"
                                            className={`${
                                                matchProfile
                                                    ? 'bg-neutral-200 text-primary shadow'
                                                    : 'hover:bg-neutral-100'
                                            } inline-flex h-12 w-full items-center rounded pl-3 focus:outline-none`}
                                            onClick={() => setMobileMenuOpen(false)}
                                        >
                                            <div className="inline-flex">
                                                <div className="w-10">
                                                    <FontAwesomeIcon icon={faUser} size="lg" className="ml-0.5" />
                                                </div>
                                                <span className="opensans-semibold">{t('HEADER.PROFILE')}</span>
                                            </div>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to="/plant_overview"
                                            className={`${
                                                matchPlant
                                                    ? 'bg-neutral-200 text-primary shadow'
                                                    : 'hover:bg-neutral-100'
                                            } inline-flex h-12 w-full items-center rounded pl-3 focus:outline-none`}
                                            onClick={() => setMobileMenuOpen(false)}
                                        >
                                            <div className="inline-flex">
                                                <div className="w-10">
                                                    <FontAwesomeIcon icon={faIndustry} size="lg" className="ml-0.5" />
                                                </div>
                                                <span className="opensans-semibold">{t('HEADER.PLANTS')}</span>
                                            </div>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className="py-2">
                                <ul>
                                    <li className="px-1">
                                        <button
                                            className={`${
                                                isHelpMenuOpen ? 'bg-neutral-50' : 'bg-transparent'
                                            } inline-flex h-12 w-full items-center justify-between rounded pl-4 hover:bg-neutral-100 focus:outline-none`}
                                            onClick={() =>
                                                auth0Logout({
                                                    logoutParams: {
                                                        returnTo:
                                                            process.env.REACT_APP_REDIRECT_URI +
                                                                '?version=login&lang=' +
                                                                user?.defaultLanguage ?? 'en',
                                                    },
                                                })
                                            }
                                        >
                                            <div className="inline-flex">
                                                <div className="w-10 text-left">
                                                    <FontAwesomeIcon
                                                        icon={faArrowRightFromBracket}
                                                        size="lg"
                                                        className="ml-0.5"
                                                    />
                                                </div>
                                                <span
                                                    className={`opensans-semibold ${isSidebarOpen ? '' : 'lg:hidden'}`}
                                                >
                                                    {t('HEADER.LOGOUT')}
                                                </span>
                                            </div>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Slide>
        </>
    );
}
