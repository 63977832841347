import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faSearch } from '@fortawesome/pro-solid-svg-icons';
import { faClock } from '@fortawesome/pro-regular-svg-icons';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import localeDate from 'dayjs/plugin/localeData';
import { TruncatedText } from '@cipa-gmbh/o6k-component-library';
import locale_de from 'dayjs/locale/de';
import locale_en from 'dayjs/locale/en-au';
import locale_pt from 'dayjs/locale/pt';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFetchLastNSearches } from '../../api/search';
import { LanguageContext } from '../../pages/App';
import { ReactComponent as SpinnerLoader } from '../../assets/Spinner_Circle_White_BG.svg';
import useBreakpoint from '../../utils/customBreakpointHook';

dayjs.extend(LocalizedFormat);
dayjs.extend(localeDate);

export function SearchTermDropdown({
    setShowSearchDropdown,
    setSearchText,
    setEffect,
}: {
    setShowSearchDropdown: (arg: boolean) => void;
    setSearchText: (arg: string) => void;
    setEffect: (arg: boolean) => void;
}): JSX.Element {
    function getQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const navigate = useNavigate();
    const language = useContext(LanguageContext);
    const { pathname } = useLocation();
    const query = getQuery();
    const { data: lastSearches, isFetching } = useFetchLastNSearches('5');

    return (
        <div className="absolute left-0 top-0 z-20 mx-auto mt-13 max-h-68 w-full overflow-y-auto rounded border border-neutral-50 bg-white text-black shadow-2xl lg:top-full lg:mt-1">
            <ul className="my-1 overflow-y-auto text-sm">
                {isFetching && (
                    <div className="my-4 flex flex-col items-center justify-center md:my-2.5">
                        <SpinnerLoader className="h-5 w-5 animate-spin" />
                    </div>
                )}
                {!isFetching &&
                    lastSearches &&
                    lastSearches?.length > 0 &&
                    lastSearches.map((item, i) => {
                        return (
                            <div key={i}>
                                <li
                                    className="h-12 cursor-pointer text-neutral-900 hover:bg-neutral-50 lg:h-10"
                                    onClick={() => {
                                        //set timeout, because the useEffect for resetting the searchValue is faster than the setSearchUrl
                                        setTimeout(() => {
                                            setSearchText(item.searchTerm);
                                        }, 100);
                                        if (!pathname.includes('/search') && !pathname.includes('/mail')) {
                                            navigate('/search?search=' + item.searchTerm + '&returnURL=' + pathname);
                                        } else {
                                            navigate(
                                                '/search?search=' +
                                                    item.searchTerm +
                                                    '&returnURL=' +
                                                    query.get('returnURL'),
                                            );
                                        }
                                        setShowSearchDropdown(false);
                                        setEffect(false);
                                    }}
                                >
                                    <div className={`flex h-full flex-row items-center justify-between pl-3 pr-4`}>
                                        <FontAwesomeIcon icon={faClock} className="text-neutral-500" />
                                        <div className="ml-3 mr-auto hidden xl:block">
                                            <TruncatedText maxLength={25} text={item.searchTerm} />
                                        </div>
                                        <div className="ml-3 mr-auto hidden lg:block xl:hidden">
                                            <TruncatedText maxLength={20} text={item.searchTerm} />
                                        </div>
                                        <div className="ml-3 mr-auto hidden lg:block lg:hidden">
                                            <TruncatedText maxLength={20} text={item.searchTerm} />
                                        </div>
                                        <div className="ml-3 mr-auto text-xs lg:hidden ">
                                            <TruncatedText maxLength={15} text={item.searchTerm} />
                                        </div>
                                        <div className="text-sm text-neutral-500">
                                            {dayjs(item.timestamp)
                                                .locale(
                                                    language.toLowerCase() === 'de'
                                                        ? locale_de
                                                        : language.toLowerCase() === 'pt'
                                                        ? locale_pt
                                                        : locale_en,
                                                )
                                                .format(
                                                    language.toLowerCase() === 'de'
                                                        ? 'DD.MM.YY - HH:mm'
                                                        : language.toLowerCase() === 'pt'
                                                        ? 'DD.MM.YY - HH:mm'
                                                        : 'DD.MM.YY - hh:mm A',
                                                )}
                                        </div>
                                    </div>
                                </li>
                                <div
                                    className={`${i !== lastSearches?.length - 1 ? 'border-b border-neutral-200' : ''}`}
                                />
                            </div>
                        );
                    })}
            </ul>
        </div>
    );
}

function SearchBar(): JSX.Element {
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const navigate = useNavigate();
    const query = useQuery();
    const [searchText, setSearchText] = useState(query.get('search') ?? '');
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const [showSearchDropdown, setShowSearchDropdown] = useState(false);
    const [effect, setEffect] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const breakpoint = useBreakpoint();

    const handleKeyPress = useCallback(
        (e) => {
            if (e.key === 'Esc' || e.key === 'Escape') {
                if (showSearchDropdown) {
                    setEffect(false);
                    setShowSearchDropdown(false);
                }
            } else if (e.key === 'Enter' && searchText !== '') {
                if (!pathname.includes('/search') && !pathname.includes('/mail')) {
                    navigate('/search?search=' + searchText + '&returnURL=' + pathname);
                    setEffect(false);
                    document.getElementById('searchField')?.blur();
                } else {
                    navigate('/search?search=' + searchText + '&returnURL=' + query.get('returnURL'));
                    setEffect(false);
                    document.getElementById('searchField')?.blur();
                }
            }
            setSearchText(searchText);
        },
        [setSearchText, searchText, pathname, query, showSearchDropdown],
    );

    useEffect(() => {
        if (breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md') {
            if (inputRef.current) {
                inputRef.current?.focus();
                inputRef.current?.click();
            }
        }
    }, []);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);

    useEffect(() => {
        if (pathname !== '/search') {
            setSearchText('');
        } else {
            setSearchText(query.get('search') ?? '');
        }
    }, [pathname, query.get('search')]);

    return (
        <div className="w-full py-2">
            <div
                id="loadingDropdownSearchBar"
                className={`relative hidden items-center justify-between rounded border-neutral-200 bg-neutral-50 lg:flex ${
                    effect
                        ? 'transition-slowest ease w-100 border-2 border-primary transition-width focus:border-primary lg:w-140'
                        : 'transition-slowest ease w-100 border border-neutral-200 transition-width hover:border-neutral-600'
                }`}
            >
                <input
                    value={searchText}
                    className="z-30 h-9 w-full appearance-none border-none bg-transparent px-3 py-1 text-sm leading-tight placeholder-neutral-500 focus:outline-none"
                    type="text"
                    id="searchField"
                    autoComplete="off"
                    placeholder={t('SEARCH.PLACEHOLDER')}
                    onClick={() => {
                        setEffect(true);
                        setShowSearchDropdown(true);
                    }}
                    onChange={(e) => {
                        setSearchText(e.target.value);
                        if (showSearchDropdown) {
                            setShowSearchDropdown(false);
                        }
                        if (e.target.value === '') {
                            setShowSearchDropdown(true);
                        }
                    }}
                />
                {effect && (
                    <button
                        onClick={() => {
                            setEffect(false);
                        }}
                        tabIndex={-1}
                        className="fixed inset-0 h-full w-full cursor-default focus:outline-none"
                    />
                )}
                <div className="flex flex-row items-center justify-between">
                    {searchText !== '' && (
                        <div className="mr-2 flex h-6 w-6 flex-row">
                            <button
                                className="z-10 flex h-full w-full flex-row items-center justify-center"
                                onClick={() => {
                                    setSearchText('');
                                    setEffect(true);
                                    document.getElementById('searchField')?.focus();
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faCircleXmark}
                                    className="h-3 w-3 cursor-pointer text-neutral-500"
                                />
                            </button>
                        </div>
                    )}
                    <button
                        onMouseDown={(e) => e.preventDefault()}
                        className={`z-30 flex h-9 items-center justify-center ${effect ? 'w-12 bg-primary' : ''}`}
                        onClick={() => {
                            navigate(
                                '/search?search=' +
                                    searchText +
                                    '&returnURL=' +
                                    (!pathname.includes('/search') ? pathname : query.get('returnURL')),
                            );
                        }}
                        onKeyPress={(e) => handleKeyPress(e)}
                    >
                        {effect && <FontAwesomeIcon icon={faSearch} className="text-white" />}
                        {!searchText && !effect && <FontAwesomeIcon icon={faSearch} className="mr-3 text-primary" />}
                    </button>
                </div>
                {showSearchDropdown && (
                    <button
                        onClick={() => {
                            setShowSearchDropdown(false);
                            setEffect(false);
                        }}
                        tabIndex={-1}
                        className="fixed inset-0 z-20 h-full w-full cursor-default focus:outline-none"
                    />
                )}
                {showSearchDropdown && (
                    <SearchTermDropdown
                        setShowSearchDropdown={setShowSearchDropdown}
                        setSearchText={setSearchText}
                        setEffect={setEffect}
                    />
                )}
            </div>
            {
                <div
                    className={`relative flex w-full items-center justify-between rounded border-neutral-200 bg-neutral-50 lg:hidden ${
                        effect
                            ? 'transition-slowest ease border-2 border-primary transition-width focus:border-primary'
                            : 'transition-slowest ease border border-neutral-200 transition-width hover:border-neutral-600'
                    }`}
                >
                    <input
                        ref={inputRef}
                        autoFocus
                        value={searchText}
                        className="z-30 flex h-12 w-full bg-transparent px-3 py-1 text-sm leading-tight placeholder-neutral-500 focus:outline-none"
                        type="text"
                        id="searchFieldMobile"
                        autoComplete="off"
                        placeholder={t('SEARCH.PLACEHOLDER')}
                        onClick={() => {
                            setEffect(true);
                            setShowSearchDropdown(true);
                        }}
                        onChange={(e) => {
                            setSearchText(e.target.value);
                            if (showSearchDropdown) {
                                setShowSearchDropdown(false);
                            }
                            if (e.target.value === '') {
                                setShowSearchDropdown(true);
                            }
                        }}
                    />
                    {effect && (
                        <button
                            onClick={() => {
                                setEffect(false);
                            }}
                            tabIndex={-1}
                            className="fixed inset-0 h-full w-full cursor-default focus:outline-none"
                        />
                    )}
                    <div className="flex flex-row">
                        {searchText !== '' && (
                            <div className="mr-2 flex h-12 w-12 flex-row">
                                <button
                                    className="z-10 flex h-full w-full flex-row items-center justify-center"
                                    onClick={() => {
                                        setSearchText('');
                                        setEffect(true);
                                        document.getElementById('searchField')?.focus();
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faCircleXmark}
                                        className="h-4 w-4 cursor-pointer text-neutral-500"
                                    />
                                </button>
                            </div>
                        )}
                        <button
                            onMouseDown={(e) => e.preventDefault()}
                            className={`${
                                effect ? 'w-12 bg-primary' : ''
                            } z-30 flex h-12 flex-col items-center justify-center text-white`}
                            onClick={() => {
                                navigate(
                                    '/search?search=' +
                                        searchText +
                                        '&returnURL=' +
                                        (!pathname.includes('/search') ? pathname : query.get('returnURL')),
                                );
                            }}
                            onKeyPress={(e) => handleKeyPress(e)}
                        >
                            {effect && <FontAwesomeIcon icon={faSearch} className="text-white" />}
                            {!searchText && !effect && (
                                <FontAwesomeIcon icon={faSearch} className="mr-3 text-primary" />
                            )}
                        </button>
                    </div>
                    {showSearchDropdown && (
                        <button
                            onClick={() => {
                                setShowSearchDropdown(false);
                                setEffect(false);
                            }}
                            tabIndex={-1}
                            className="fixed inset-0 z-20 h-full w-full cursor-default focus:outline-none"
                        />
                    )}
                    {showSearchDropdown && (
                        <SearchTermDropdown
                            setShowSearchDropdown={setShowSearchDropdown}
                            setSearchText={setSearchText}
                            setEffect={setEffect}
                        />
                    )}
                </div>
            }
        </div>
    );
}

export default SearchBar;
