import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt, faDroplet, faFireFlame, faHeat, faWind } from '@fortawesome/pro-regular-svg-icons';
import { useParams } from 'react-router-dom';
import { Tab } from '@headlessui/react';
import { Button, SubtleButton, OpenpackDataInput } from '@cipa-gmbh/o6k-component-library';
import { useTranslation } from 'react-i18next';
import { AssetEnPIBenchmarkData, EnPIBenchmarkData, updateEnPIBenchmark } from '../../api/resourceManager';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ReactComponent as SpinnerLoader_Button } from '../../assets/Spinner_Circle_Button.svg';

const enPIBenchmarkData = {
    electricity: [
        { assetName: 'Gesamtes Werk', assetType: 'corrugator', value: 150 },
        { assetName: 'WPA', assetType: 'corrugator', value: 50 },
        { assetName: 'Rotationsstanze', assetType: 'conversion', value: 50 },
        { assetName: 'Inliner', assetType: 'conversion', value: 50 },
    ],
    water: [
        { assetName: 'Gesamtes Werk', assetType: 'corrugator', value: 75 },
        { assetName: 'WPA', assetType: 'corrugator', value: 25 },
        { assetName: 'Rotationsstanze', assetType: 'conversion', value: 25 },
        { assetName: 'Inliner', assetType: 'conversion', value: 25 },
    ],
    gas: [
        { assetName: 'Gesamtes Werk', assetType: 'corrugator', value: 250 },
        { assetName: 'WPA', assetType: 'corrugator', value: 50 },
        { assetName: 'Rotationsstanze', assetType: 'conversion', value: 100 },
        { assetName: 'Inliner', assetType: 'conversion', value: 100 },
    ],
    compressedAir: null,
    steam: [
        { assetName: 'Gesamtes Werk', assetType: 'corrugator', value: 100 },
        { assetName: 'WPA', assetType: 'corrugator', value: 50 },
        { assetName: 'Rotationsstanze', assetType: 'conversion', value: 50 },
    ],
};

const BenchmarkDataEntryMenu = ({ resourceType, data, setData }) => {
    const handleData = (index, event) => {
        const newData = [...data];
        newData[index].value = event.target.value;
        setData(newData);
    };
    const { t } = useTranslation();
    return (
        <div className="max-h-[700px] overflow-y-auto bg-neutral-50 px-6 text-sm">
            {enPIBenchmarkData?.[resourceType] !== undefined && (
                <div className="box-content flex h-9 items-center border-b border-neutral-200 py-4">
                    <span className="w-1/2">{enPIBenchmarkData?.[resourceType]?.[0]?.assetName}</span>
                    <div className={`group relative mt-1 inline-flex w-1/2`}>
                        <OpenpackDataInput
                            key={0}
                            value={data[0]?.value ?? ''}
                            onChange={(e) => {
                                handleData(0, e);
                            }}
                            single_unit={'kWh/1000 m²'}
                        />
                    </div>
                </div>
            )}
            <div className="flex flex-col justify-center gap-4 py-4 pl-6">
                {enPIBenchmarkData?.[resourceType]?.map((asset, i) => {
                    if (i === 0) return null;
                    return (
                        <div key={i} className="flex h-9 items-center">
                            <span className="w-1/2">{asset.assetName}</span>
                            <div className={`group relative mt-1 inline-flex w-1/2`}>
                                <OpenpackDataInput
                                    key={i}
                                    value={data[i]?.value ?? ''}
                                    onChange={(e) => {
                                        handleData(i, e);
                                    }}
                                    single_unit={
                                        (resourceType === 'electricity'
                                            ? `kWh`
                                            : resourceType === 'water'
                                            ? `l`
                                            : resourceType === 'gas'
                                            ? `m³`
                                            : resourceType === 'compressedAir'
                                            ? `m³`
                                            : resourceType === 'steam' && `m³`) +
                                        '/1000' +
                                        (asset?.assetType === 'corrugator'
                                            ? 'm²'
                                            : asset?.assetType === 'conversion' && t('LIVE.SHEETS'))
                                    }
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
export default function EnterEnPIBenchmarkModal({
    setShowEnterEnPIBenchmarkModal,
    resourceList,
}: {
    setShowEnterEnPIBenchmarkModal: (arg: boolean) => void;
    resourceList: string[];
}): JSX.Element {
    const { plantId } = useParams();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const [isMakingAPICall, setIsMakingAPICall] = useState<boolean>(false);
    const { mutateAsync: updateEnPIBenchmarkMutation } = useMutation<
        EnPIBenchmarkData,
        Error,
        { enPIBenchmarkData: EnPIBenchmarkData; plantId: string }
    >({
        mutationFn: updateEnPIBenchmark,
        onSuccess: (data, parameters) => {
            queryClient.invalidateQueries({
                queryKey: ['enpi-benchmark', plantId],
            });
            queryClient.setQueryData(['enpi-benchmark', parameters.plantId], {
                enPIBenchmarkData: data,
            });
            setIsMakingAPICall(false);
        },
        onError: (error) => {
            console.log(error);
            setIsMakingAPICall(false);
        },
    });
    const [electricityData, setElectricityData] = useState<AssetEnPIBenchmarkData[]>([]);
    const [waterData, setWaterData] = useState<AssetEnPIBenchmarkData[]>([]);
    const [gasData, setGasData] = useState<AssetEnPIBenchmarkData[]>([]);
    const [compressedAirData, setCompressedAirData] = useState<AssetEnPIBenchmarkData[]>([]);
    const [steamData, setSteamData] = useState<AssetEnPIBenchmarkData[]>([]);

    /*const { data: enPIBenchmarkData, isFetching: isLoadingEnPIBenchmarkData } = useFetchEnPIBenchmarkData(
        plantId ?? '',
    );*/

    function handleUpdateData() {
        setIsMakingAPICall(true);
        updateEnPIBenchmarkMutation({
            //cast empty strings to number
            enPIBenchmarkData: {
                electricity: electricityData?.map((item) => {
                    return {
                        assetName: item.assetName,
                        assetType: item.assetType,
                        value: Number(item.value),
                    };
                }),
                water: waterData?.map((item) => {
                    return {
                        assetName: item.assetName,
                        assetType: item.assetType,
                        value: Number(item.value),
                    };
                }),
                gas: gasData?.map((item) => {
                    return {
                        assetName: item.assetName,
                        assetType: item.assetType,
                        value: Number(item.value),
                    };
                }),
                compressedAir: compressedAirData?.map((item) => {
                    return {
                        assetName: item.assetName,
                        assetType: item.assetType,
                        value: Number(item.value),
                    };
                }),
                steam: steamData?.map((item) => {
                    return {
                        assetName: item.assetName,
                        assetType: item.assetType,
                        value: Number(item.value),
                    };
                }),
            },
            plantId: plantId ?? '',
        });
    }

    useEffect(() => {
        //set data
        setElectricityData(enPIBenchmarkData?.electricity ?? []);
        setWaterData(enPIBenchmarkData?.water ?? []);
        setGasData(enPIBenchmarkData?.gas ?? []);
        setCompressedAirData(enPIBenchmarkData?.compressedAir ?? []);
        setSteamData(enPIBenchmarkData?.steam ?? []);
    }, [enPIBenchmarkData]);

    return (
        <div className="fixed inset-0 z-50 flex w-full cursor-default items-center justify-center px-2">
            <div className="relative z-50 w-full shadow-lg md:w-[560px]">
                <div className="relative m-auto flex w-full flex-col rounded bg-white">
                    {/*Header Bar*/}
                    <div className="rounded-t px-6 py-4">
                        <div className="flex items-start justify-between">
                            <span className="opensans-bold text-sm">EnPI Benchmarks</span>
                            <SubtleButton
                                className="justify-center"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setShowEnterEnPIBenchmarkModal(false);
                                }}
                            />
                        </div>
                    </div>
                    {/*Main Content*/}
                    <Tab.Group defaultIndex={0}>
                        <div className="flex h-9 items-center">
                            <Tab.List className="opensans-semibold flex h-9 items-center gap-4 px-6 text-xs text-neutral-500">
                                {resourceList?.find((item) => item === 'electricity') && (
                                    <Tab className="h-full focus:outline-none">
                                        {({ selected }) => (
                                            <div
                                                className={`inline-flex h-full w-full items-center justify-center gap-2 border-b-2 px-3 ${
                                                    selected
                                                        ? 'border-blue-400 text-blue-400'
                                                        : 'border-transparent text-neutral-500 hover:border-b-2 hover:border-neutral-600 hover:text-neutral-600'
                                                }`}
                                            >
                                                <FontAwesomeIcon icon={faBolt} size="lg" />
                                                <span>{t('RESOURCE_MANAGER.ELECTRICITY')}</span>
                                            </div>
                                        )}
                                    </Tab>
                                )}
                                {resourceList?.find((item) => item === 'water') && (
                                    <Tab className="h-full focus:outline-none">
                                        {({ selected }) => (
                                            <div
                                                className={`inline-flex h-full w-full items-center justify-center gap-2 border-b-2 px-3 ${
                                                    selected
                                                        ? 'border-blue-400 text-blue-400'
                                                        : 'border-transparent text-neutral-500 hover:border-b-2 hover:border-neutral-600 hover:text-neutral-600'
                                                }`}
                                            >
                                                <FontAwesomeIcon icon={faDroplet} size="lg" />
                                                <span>{t('RESOURCE_MANAGER.WATER')}</span>
                                            </div>
                                        )}
                                    </Tab>
                                )}
                                {resourceList?.find((item) => item === 'gas') && (
                                    <Tab className="h-full focus:outline-none">
                                        {({ selected }) => (
                                            <div
                                                className={`inline-flex h-full w-full items-center justify-center gap-2 border-b-2 px-3 ${
                                                    selected
                                                        ? 'border-blue-400 text-blue-400'
                                                        : 'border-transparent text-neutral-500 hover:border-b-2 hover:border-neutral-600 hover:text-neutral-600'
                                                }`}
                                            >
                                                <FontAwesomeIcon icon={faFireFlame} size="lg" />
                                                <span>{t('RESOURCE_MANAGER.GAS')}</span>
                                            </div>
                                        )}
                                    </Tab>
                                )}
                                {resourceList?.find((item) => item === 'compressed_air') && (
                                    <Tab className="h-full focus:outline-none">
                                        {({ selected }) => (
                                            <div
                                                className={`inline-flex h-full w-full items-center justify-center gap-2 border-b-2 px-3 ${
                                                    selected
                                                        ? 'border-blue-400 text-blue-400'
                                                        : 'border-transparent text-neutral-500 hover:border-b-2 hover:border-neutral-600 hover:text-neutral-600'
                                                }`}
                                            >
                                                <FontAwesomeIcon icon={faWind} size="lg" />
                                                <span>{t('RESOURCE_MANAGER.COMPRESSED_AIR')}</span>
                                            </div>
                                        )}
                                    </Tab>
                                )}
                                {resourceList?.find((item) => item === 'steam') && (
                                    <Tab className="h-full focus:outline-none">
                                        {({ selected }) => (
                                            <div
                                                className={`inline-flex h-full w-full items-center justify-center gap-2 border-b-2 px-3 ${
                                                    selected
                                                        ? 'border-blue-400 text-blue-400'
                                                        : 'border-transparent text-neutral-500 hover:border-b-2 hover:border-neutral-600 hover:text-neutral-600'
                                                }`}
                                            >
                                                <FontAwesomeIcon icon={faHeat} size="lg" />
                                                <span>{t('RESOURCE_MANAGER.STEAM')}</span>
                                            </div>
                                        )}
                                    </Tab>
                                )}
                            </Tab.List>
                        </div>
                        <Tab.Panels className="w-full">
                            {resourceList?.find((item) => item === 'electricity') && (
                                <Tab.Panel>
                                    <BenchmarkDataEntryMenu
                                        resourceType={'electricity'}
                                        data={electricityData}
                                        setData={setElectricityData}
                                    />
                                </Tab.Panel>
                            )}
                            {resourceList?.find((item) => item === 'water') && (
                                <Tab.Panel>
                                    <BenchmarkDataEntryMenu
                                        resourceType="water"
                                        data={waterData}
                                        setData={setWaterData}
                                    />
                                </Tab.Panel>
                            )}
                            {resourceList?.find((item) => item === 'gas') && (
                                <Tab.Panel>
                                    <BenchmarkDataEntryMenu resourceType="gas" data={gasData} setData={setGasData} />
                                </Tab.Panel>
                            )}
                            {resourceList?.find((item) => item === 'compressed_air') && (
                                <Tab.Panel>
                                    <BenchmarkDataEntryMenu
                                        resourceType="compressed_air"
                                        data={compressedAirData}
                                        setData={setCompressedAirData}
                                    />
                                </Tab.Panel>
                            )}
                            {resourceList?.find((item) => item === 'steam') && (
                                <Tab.Panel>
                                    <BenchmarkDataEntryMenu
                                        resourceType="steam"
                                        data={steamData}
                                        setData={setSteamData}
                                    />
                                </Tab.Panel>
                            )}
                        </Tab.Panels>
                    </Tab.Group>
                </div>
                <div className="flex w-full flex-row items-center justify-end rounded-b border-t border-neutral-200 bg-neutral-100 px-6 py-2.5">
                    <Button
                        button_type={'secondary'}
                        color={'default'}
                        className="mr-4"
                        onClick={() => {
                            setShowEnterEnPIBenchmarkModal(false);
                        }}
                    >
                        {t('DIALOG_MESSAGES.CANCEL_BUTTON')}
                    </Button>
                    <Button
                        button_type={'primary'}
                        color={'default'}
                        /*disabled={isLoadingEnPIBenchmarkData}*/
                        onClick={handleUpdateData}
                        /*isDisabled={isLoadingEnPIBenchmarkData}*/
                    >
                        <div className="flex h-full w-full items-center justify-center">
                            {isMakingAPICall && <SpinnerLoader_Button className="mr-2 h-5 w-5 animate-spin" />}
                            {t('DASHBOARDS.SAVE')}
                        </div>
                    </Button>
                </div>
            </div>
            {/*Gray Background*/}
            <button
                type="button"
                onClick={() => {
                    setShowEnterEnPIBenchmarkModal(false);
                }}
                tabIndex={-1}
                className="fixed inset-0 z-40 h-full w-full cursor-default bg-neutral-900 opacity-60 outline-none focus:outline-none"
            />
        </div>
    );
}
