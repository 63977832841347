import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { DropdownList } from '@cipa-gmbh/o6k-component-library';
import { EnPIHistory, ResourceTableData } from '../../api/resourceManager';
import { Root } from '@amcharts/amcharts5';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import am5themes_Material from '@amcharts/amcharts5/themes/Material';
import * as am5xy from '@amcharts/amcharts5/xy';
import { Plant } from '../../api/plants';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { v4 as uuidv4 } from 'uuid';
import { LineSeries } from '@amcharts/amcharts5/xy';

dayjs.extend(utc);
dayjs.extend(timezone);

const enPIHistoryData: EnPIHistory = {
    assetId: '123456',
    assetName: 'Total Plant',
    organizationId: '131313',
    enPI: [
        {
            time: '2024-05-07T12:11:45+00:00',
            consumption: 470.65,
        },
        {
            time: '2024-05-07T12:11:50+00:00',
            consumption: 295.07,
        },
        {
            time: '2024-05-07T12:11:55+00:00',
            consumption: 255.83,
        },
        {
            time: '2024-05-07T12:12:00+00:00',
            consumption: 593.7,
        },
        {
            time: '2024-05-07T12:12:05+00:00',
            consumption: 316.18,
        },
        {
            time: '2024-05-07T12:12:10+00:00',
            consumption: 291.35,
        },
        {
            time: '2024-05-07T12:12:15+00:00',
            consumption: 481.39,
        },
        {
            time: '2024-05-07T12:12:20+00:00',
            consumption: 221.33,
        },
        {
            time: '2024-05-07T12:12:25+00:00',
            consumption: 219.08,
        },
        {
            time: '2024-05-07T12:12:30+00:00',
            consumption: 245.11,
        },
    ],
    enPIBenchmark: 500,
};
export default function EnPILineChart({
    resourceTableData,
    startDate,
    endDate,
    resourceType,
    plant,
}: {
    resourceTableData: ResourceTableData[];
    startDate: string;
    endDate: string;
    resourceType: string;
    plant: Plant;
}): JSX.Element {
    const [dropdownOptions, setDropdownOptions] = useState<{ label: string; value: string; action: () => void }[]>([]);
    const [, setSelectedResource] = useState<ResourceTableData | null>(
        resourceTableData[resourceTableData.length - 1] ?? null,
    );
    const [selectedDropdownItem, setSelectedDropdownItem] = useState<{
        label: string;
        value: string;
        action: () => void;
    }>();
    const id = uuidv4();
    const { t } = useTranslation();
    const rootRef = useRef<Root | null>(null);

    //TODO remove when implement apis
    if (resourceType) {
    }

    const [, setStartOfDayEpoch] = useState<number | null>(null);
    const [, setEndOfDayEpoch] = useState<number | null>(null);

    const convertDatesToEpoch = (date: string, type: 'start' | 'end') => {
        const [day, month, year] = date.split('.').map(Number);
        const dateConverted = dayjs(new Date(year, month - 1, day));

        if (type === 'start') {
            return dateConverted.startOf('day').unix();
        } else {
            return dateConverted.endOf('day').unix();
        }
    };

    useEffect(() => {
        if (startDate) {
            setStartOfDayEpoch(convertDatesToEpoch(startDate, 'start'));
        }
        if (endDate) {
            setEndOfDayEpoch(convertDatesToEpoch(endDate, 'end'));
        }
    }, [startDate, endDate, plant]);

    /*const { data: enPIHistoryData } = useFetchEnPIHistory(
        plant?.id ?? '',
        plant?.timezoneId ?? '',
        startDate,
        endDate,
        resourceType,
        selected?.assetId,
    );*/

    // useEffect(() => {
    //     if (resourceTableData.length > 0) {
    //         setSelectedResource(resourceTableData[resourceTableData.length - 1] ?? null);
    //         // setSelectedDropdownItem({
    //         //     label: resourceTableData[resourceTableData.length - 1].assetName,
    //         //     value: resourceTableData[resourceTableData.length - 1].assetId,
    //         //     action: () => {
    //         //         changeSelectedItem(resourceTableData[resourceTableData.length - 1]);
    //         //     },
    //         // });
    //     }
    // }, [resourceTableData]);

    const changeSelectedItem = (option: ResourceTableData) => {
        setSelectedResource(option);
        setSelectedDropdownItem({
            label: option.assetName,
            value: option.assetId,
            action: () => {
                changeSelectedItem(option);
            },
        });
    };

    useEffect(() => {
        const newOptions = resourceTableData.map((data) => ({
            label: data.assetName,
            value: data.assetId,
            action: () => {
                changeSelectedItem(data);
            },
        }));
        setDropdownOptions(newOptions);

        if (!selectedDropdownItem) {
            setSelectedDropdownItem(newOptions[newOptions.length - 1]);
        }
    }, [resourceTableData]);

    useLayoutEffect(() => {
        let root: Root | null = null;
        am5.addLicense('AM5C446337179');
        if (rootRef.current === null) {
            root = am5.Root.new('enPIHistory' + id);
        } else {
            root = rootRef.current;
            root.dispose();
            root = am5.Root.new('enPIHistory' + id);
        }

        if (root) {
            root.setThemes([am5themes_Animated.new(root), am5themes_Material.new(root)]);

            // Create chart
            const chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    panX: false,
                    panY: false,
                    maxTooltipDistance: 0,
                    layout: root.verticalLayout,
                }),
            );
            chart.set('height', am5.percent(100));
            chart.set('width', am5.percent(100));

            const xAxisTooltip = am5.Tooltip.new(root, {
                paddingTop: 4,
                paddingBottom: 4,
                paddingLeft: 8,
                paddingRight: 8,
            });
            xAxisTooltip?.get('background')?.set('fill', am5.color('#051126'));
            xAxisTooltip?.get('background')?.set('fillOpacity', 100);

            // Create axes
            const xAxis = chart.xAxes.push(
                am5xy.DateAxis.new(root, {
                    groupData: true,
                    groupCount: 200,
                    baseInterval: {
                        timeUnit: 'second',
                        count: 1,
                    },
                    renderer: am5xy.AxisRendererX.new(root, {
                        minGridDistance: 50,
                    }),
                    tooltip: xAxisTooltip,
                    tooltipDateFormat: '[fontSize: 12px]dd. MMM - HH:mm',
                }),
            );
            if (xAxis) {
                const dateFormats = xAxis.get('dateFormats');
                if (dateFormats) {
                    dateFormats['minute'] = 'HH:mm';
                    dateFormats['hour'] = 'HH:mm';
                    dateFormats['second'] = 'HH:mm:ss';
                }
            }
            xAxis.get('renderer').grid.template.set('forceHidden', true);

            const xRenderer = xAxis.get('renderer');
            xRenderer.labels.template.setAll({
                fill: am5.color('#000000'),
                fontSize: '10px',
                paddingTop: 8,
            });

            const yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    renderer: am5xy.AxisRendererY.new(root, {}),
                    min: 0,
                }),
            );
            const yRenderer = yAxis.get('renderer');
            yRenderer.labels.template.setAll({
                fill: am5.color('#000000'),
                fontSize: '10px',
                paddingRight: 8,
            });
            yAxis.children.moveValue(
                am5.Label.new(root, {
                    text: 'kWh/1000 ' + t('LIVE.SHEETS'),
                    rotation: -90,
                    y: am5.p50,
                    centerX: am5.p50,
                    fontSize: 12,
                    fill: am5.color('#000000'),
                }),
                0,
            );

            if (enPIHistoryData && enPIHistoryData.enPI) {
                const result: { time: Date; consumption: number }[] = [];
                enPIHistoryData.enPI.forEach((item) => {
                    result.push({
                        time: dayjs(item?.time)?.tz(plant?.timezoneId)?.toDate(),
                        consumption: item.consumption,
                    });
                });
                const sortedData = result.sort((a, b) => a.time.getTime() - b.time.getTime());
                //enPI series
                let series1 = am5.registry.entitiesById['series-enpi-history' + enPIHistoryData.assetId + id];
                if (!series1) {
                    series1 = chart.series.push(
                        am5xy.SmoothedXLineSeries.new(root, {
                            name: t('RESOURCE_MANAGER.CONSUMPTION'),
                            id: 'series-enpi-history' + enPIHistoryData.assetId + id,
                            xAxis: xAxis,
                            yAxis: yAxis,
                            valueYField: 'consumption',
                            valueXField: 'time',
                            legendValueText: '{valueY}',
                            tooltip: am5.Tooltip.new(root, {
                                paddingTop: 4,
                                paddingBottom: 4,
                                paddingLeft: 8,
                                paddingRight: 8,
                                pointerOrientation: 'horizontal',
                                labelText: '{valueY}',
                            }),
                            fill: am5.color('#0064FA'),
                            stroke: am5.color('#0064FA'),
                        }),
                    );
                    series1.data.processor = am5.DataProcessor.new(root, {
                        numericFields: ['consumption'],
                        dateFields: ['time'],
                        dateFormat: 'yyyy-MM-dd',
                    });
                    series1.data.setAll(sortedData);
                }
            }

            if (enPIHistoryData && enPIHistoryData.enPI && enPIHistoryData.enPIBenchmark) {
                const result2: { time: Date; benchmark: number }[] = [];
                enPIHistoryData.enPI.forEach((item) => {
                    result2.push({
                        time: dayjs(item?.time)?.tz(plant?.timezoneId)?.toDate(),
                        benchmark: enPIHistoryData.enPIBenchmark,
                    });
                });
                const sortedData = result2.sort((a, b) => a.time.getTime() - b.time.getTime());
                //enPI benchmark series
                let series2 = am5.registry.entitiesById['series2-enpi-history' + enPIHistoryData.assetId + id];
                if (!series2) {
                    series2 = chart.series.push(
                        am5xy.SmoothedXLineSeries.new(root, {
                            name: t('RESOURCE_MANAGER.BENCHMARK'),
                            id: 'series2-enpi-history' + enPIHistoryData.assetId + id,
                            xAxis: xAxis,
                            yAxis: yAxis,
                            valueYField: 'benchmark',
                            valueXField: 'time',
                            legendValueText: '{valueY}',
                            tooltip: am5.Tooltip.new(root, {
                                paddingTop: 4,
                                paddingBottom: 4,
                                paddingLeft: 8,
                                paddingRight: 8,
                                pointerOrientation: 'horizontal',
                                labelText: '{valueY}',
                            }),
                            fill: am5.color('#000000'),
                            stroke: am5.color('#000000'),
                        }),
                    );
                    series2.strokes.template.setAll({
                        strokeWidth: 1,
                        strokeDasharray: [3, 3, 3],
                    });
                    series2.data.processor = am5.DataProcessor.new(root, {
                        numericFields: ['consumption'],
                        dateFields: ['time'],
                        dateFormat: 'yyyy-MM-dd',
                    });
                    series2.data.setAll(sortedData);
                }
            }

            /*   const rangeDataItem = yAxis.makeDataItem({
                value: enPIHistoryData.enPIBenchmark,
            });

            const range = yAxis.createAxisRange(rangeDataItem);

            range?.get('grid')?.setAll({
                stroke: am5.color('#000000'),
                strokeOpacity: 1,
                strokeWidth: 1,
                strokeDasharray: [3, 3, 3],
            });*/

            // Add cursor
            const cursor = chart.set(
                'cursor',
                am5xy.XYCursor.new(root, {
                    behavior: 'zoomX',
                }),
            );
            cursor.lineY.set('visible', false);

            const legend = chart.bottomAxesContainer.children.push(
                am5.Legend.new(root, {
                    x: am5.percent(50),
                    centerX: am5.percent(50),
                    layout: root.gridLayout,
                    marginTop: 14,
                    useDefaultMarker: true,
                }),
            );

            legend.valueLabels.template.set('forceHidden', true);

            // When legend item container is hovered, dim all the series except the hovered one
            legend.itemContainers.template.events.on('pointerover', function (e) {
                const itemContainer = e.target;

                // As series list is data of a legend, dataContext is series
                const series = itemContainer?.dataItem?.dataContext;

                chart.series.each(function (chartSeries) {
                    //cast type
                    const lineSeries = chartSeries as LineSeries;
                    if (lineSeries != series) {
                        lineSeries?.strokes?.template.setAll({
                            strokeOpacity: 0.15,
                        });
                    } else {
                        lineSeries?.strokes?.template.setAll({
                            strokeWidth: 3,
                        });
                    }
                });
            });

            // When legend item container is unhovered, make all series as they are
            legend.itemContainers.template.events.on('pointerout', function () {
                chart.series.each(function (chartSeries) {
                    const lineSeries = chartSeries as LineSeries;
                    lineSeries.strokes.template.setAll({
                        strokeOpacity: 1,
                        strokeWidth: 1,
                    });
                });
            });

            legend.labels.template.setAll({
                fill: am5.color('#000000'),
            });

            // It's important to set legend data after all the events are set on template, otherwise events won't be copied
            legend.data.setAll(chart.series.values);

            legend.markerRectangles.template.setAll({
                dy: 2.5,
                width: 12,
                height: 12,
                cornerRadiusTL: 4,
                cornerRadiusTR: 4,
                cornerRadiusBL: 4,
                cornerRadiusBR: 4,
            });
            legend.labels.template.setAll({
                fontSize: 12,
                fill: am5.color('#000000'),
            });
            legend.valueLabels.template.set('forceHidden', true);

            // It's important to set legend data after all the events are set on template, otherwise events won't be copied
            legend.data.setAll(chart.series.values);

            rootRef.current = root;
        }

        return () => {
            if (root) {
                root?.dispose();
            }
        };
    }, [enPIHistoryData]);

    return (
        <div className="mt-6 flex w-full flex-col gap-2">
            <div className="flex items-center gap-2 px-4 lg:px-6">
                <span className="opensans-semibold text-xs text-neutral-500">{t('RESOURCE_MANAGER.ENPI_HISTORY')}</span>
                <div id="standardDropdownPrefilled" className="w-full flex-row justify-end lg:w-64">
                    <DropdownList
                        item_type={'default'}
                        value={selectedDropdownItem}
                        setValue={setSelectedDropdownItem}
                        options={dropdownOptions}
                        no_option_text={t('DROPDOWN.NO_OPTIONS')}
                    />
                </div>
            </div>
            <div className="mt-8 h-62">
                <div className="h-full w-full" id={'enPIHistory' + id} />
            </div>
        </div>
    );
}
