import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faDroplet, faFireFlame, faHeat, faWind } from '@fortawesome/pro-regular-svg-icons';
import { faCircleInfo as faCircleInfoSolid } from '@fortawesome/pro-solid-svg-icons';
import { faCircleArrowDownRight, faCircleArrowUpRight } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { OpenpackToolTip } from '@cipa-gmbh/o6k-component-library';
import { Plant } from '../../api/plants';
import { useFetchResourceAverageDailyConsumptionData } from '../../api/resourceManager';
import dayjs from 'dayjs';

const HoverableTooltipIcon = () => {
    const [isHovered, setIsHovered] = useState(false);
    const { t } = useTranslation();

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <OpenpackToolTip label={t('RESOURCE_MANAGER.TOOLTIP')} placement={'top'}>
            <div className="relative inline-block" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                {isHovered ? (
                    <FontAwesomeIcon icon={faCircleInfoSolid} size="xs" className="text-neutral-500" />
                ) : (
                    <FontAwesomeIcon icon={faCircleInfo} size="xs" className="text-neutral-500" />
                )}
            </div>
        </OpenpackToolTip>
    );
};

export default function ConsumptionPerResourceDailyAverage({
    startTime,
    endTime,
    plant,
}: {
    startTime: number;
    endTime: number;
    plant: Plant;
}): JSX.Element {
    const { t } = useTranslation();

    const { data: resourceAverageDailyConsumptionData } = useFetchResourceAverageDailyConsumptionData(
        plant?.id ?? '',
        plant?.timezoneId ?? '',
        isNaN(startTime) ? dayjs().startOf('day').unix() : startTime,
        isNaN(endTime) ? dayjs().endOf('day').unix() : endTime,
    );

    return (
        <div className="w-full bg-white lg:rounded lg:border lg:border-neutral-100">
            <div className="opensans-semibold flex flex-row items-center bg-mainBackground px-4 pb-2 pt-6 lg:h-10 lg:bg-white lg:px-6 lg:py-3 lg:pt-2">
                <span className="lg:text-xs lg:text-neutral-500">
                    {t('RESOURCE_MANAGER.CONSUMPTION_DAILY_AVERAGE')}
                </span>
            </div>
            <div className="flex flex-col items-center justify-between gap-x-2 overflow-y-hidden px-6 pb-4 pt-2 md:grid md:md:grid-cols-3 2xl:flex 2xl:h-32 2xl:grid-cols-4 2xl:flex-row 2xl:gap-x-1 2xl:gap-y-2 2xl:overflow-x-auto">
                <div className="flex w-fit flex-1 flex-col gap-1 p-4">
                    <div className="opensans-semibold text-xs text-neutral-500">
                        {t('RESOURCE_MANAGER.ELECTRICITY')}
                    </div>
                    <div className="flex items-center gap-1">
                        <FontAwesomeIcon icon={faDroplet} size={'lg'} className="text-neutral-500" />
                        {resourceAverageDailyConsumptionData?.currentElectricityAvg?.toFixed(2) ? (
                            <>
                                <span className="opensans-semibold text-2xl">
                                    {resourceAverageDailyConsumptionData?.currentElectricityAvg?.toFixed(2)}
                                </span>
                                <span className="pt-2 text-sm">kWh</span>
                            </>
                        ) : (
                            <div className="opensans-semibold text-2xl">{t('WORKFLOW_AREA.NOT_AVAILABLE')}</div>
                        )}
                    </div>
                    {resourceAverageDailyConsumptionData?.benchmarkElectricityAvg !== null ? (
                        <div className="flex items-center gap-1 whitespace-nowrap">
                            {resourceAverageDailyConsumptionData?.percentElectricity !== undefined &&
                            resourceAverageDailyConsumptionData?.percentElectricity !== null ? (
                                resourceAverageDailyConsumptionData?.percentElectricity > 0 ? (
                                    <FontAwesomeIcon icon={faCircleArrowUpRight} size="xs" className="text-red-400" />
                                ) : (
                                    <FontAwesomeIcon
                                        icon={faCircleArrowDownRight}
                                        size="xs"
                                        className="text-green-400"
                                    />
                                )
                            ) : (
                                <div></div>
                            )}
                            <div className="text-xs text-neutral-500">
                                {resourceAverageDailyConsumptionData?.percentElectricity?.toFixed(2)}% (
                                {t('RESOURCE_MANAGER.COMPARISON')}:{' '}
                                {resourceAverageDailyConsumptionData?.benchmarkElectricityAvg?.toFixed(2)} kWh)
                            </div>
                            <HoverableTooltipIcon />
                        </div>
                    ) : (
                        <div className="text-xs text-neutral-500">-</div>
                    )}
                </div>
                <div className="flex w-fit flex-1 flex-col gap-1 p-4">
                    <div className="opensans-semibold text-xs text-neutral-500">{t('RESOURCE_MANAGER.WATER')}</div>
                    <div className="flex items-center gap-1">
                        <FontAwesomeIcon icon={faDroplet} size={'lg'} className="text-neutral-500" />
                        {resourceAverageDailyConsumptionData?.currentWaterAvg?.toFixed(2) ? (
                            <>
                                <span className="opensans-semibold text-2xl">
                                    {resourceAverageDailyConsumptionData?.currentWaterAvg?.toFixed(2)}
                                </span>
                                <span className="pt-2 text-sm">l</span>
                            </>
                        ) : (
                            <div className="opensans-semibold text-2xl">{t('WORKFLOW_AREA.NOT_AVAILABLE')}</div>
                        )}
                    </div>
                    {resourceAverageDailyConsumptionData?.benchmarkWaterAvg !== null ? (
                        <div className="flex items-center gap-1 whitespace-nowrap">
                            {resourceAverageDailyConsumptionData?.percentWater !== undefined &&
                            resourceAverageDailyConsumptionData?.percentWater !== null ? (
                                resourceAverageDailyConsumptionData?.percentWater > 0 ? (
                                    <FontAwesomeIcon icon={faCircleArrowUpRight} size="xs" className="text-red-400" />
                                ) : (
                                    <FontAwesomeIcon
                                        icon={faCircleArrowDownRight}
                                        size="xs"
                                        className="text-green-400"
                                    />
                                )
                            ) : (
                                <div></div>
                            )}
                            <div className="text-xs text-neutral-500">
                                {resourceAverageDailyConsumptionData?.percentWater?.toFixed(2)}% (
                                {t('RESOURCE_MANAGER.COMPARISON')}:{' '}
                                {resourceAverageDailyConsumptionData?.benchmarkWaterAvg?.toFixed(2)} l)
                            </div>
                            <HoverableTooltipIcon />
                        </div>
                    ) : (
                        <div className="text-xs text-neutral-500">-</div>
                    )}
                </div>
                <div className="flex w-fit flex-1 flex-col gap-1 p-4">
                    <div className="opensans-semibold text-xs text-neutral-500">{t('RESOURCE_MANAGER.GAS')}</div>
                    <div className="flex items-center gap-1">
                        <FontAwesomeIcon icon={faFireFlame} size={'lg'} className="text-neutral-500" />
                        {resourceAverageDailyConsumptionData?.currentGasAvg?.toFixed(2) ? (
                            <>
                                <span className="opensans-semibold text-2xl">
                                    {resourceAverageDailyConsumptionData?.currentGasAvg?.toFixed(2)}
                                </span>
                                <span className="pt-2 text-sm">m³</span>
                            </>
                        ) : (
                            <div className="opensans-semibold text-2xl">{t('WORKFLOW_AREA.NOT_AVAILABLE')}</div>
                        )}
                    </div>
                    {resourceAverageDailyConsumptionData?.benchmarkGasAvg !== null ? (
                        <div className="flex items-center gap-1 whitespace-nowrap">
                            {resourceAverageDailyConsumptionData?.percentGas !== undefined &&
                            resourceAverageDailyConsumptionData?.percentGas !== null ? (
                                resourceAverageDailyConsumptionData?.percentGas > 0 ? (
                                    <FontAwesomeIcon icon={faCircleArrowUpRight} size="xs" className="text-red-400" />
                                ) : (
                                    <FontAwesomeIcon
                                        icon={faCircleArrowDownRight}
                                        size="xs"
                                        className="text-green-400"
                                    />
                                )
                            ) : (
                                <div></div>
                            )}
                            <div className="text-xs text-neutral-500">
                                {resourceAverageDailyConsumptionData?.percentGas?.toFixed(2)}% (
                                {t('RESOURCE_MANAGER.COMPARISON')}:{' '}
                                {resourceAverageDailyConsumptionData?.benchmarkGasAvg?.toFixed(2)} m³)
                            </div>
                            <HoverableTooltipIcon />
                        </div>
                    ) : (
                        <div className="text-xs text-neutral-500">-</div>
                    )}
                </div>
                <div className="flex w-fit flex-1 flex-col gap-1 p-4">
                    <div className="opensans-semibold text-xs text-neutral-500">
                        {t('RESOURCE_MANAGER.COMPRESSED_AIR')}
                    </div>
                    <div className="flex items-center gap-1">
                        <FontAwesomeIcon icon={faWind} size={'lg'} className="text-neutral-500" />
                        {resourceAverageDailyConsumptionData?.currentCompressedAirAvg?.toFixed(2) ? (
                            <>
                                <span className="opensans-semibold text-2xl">
                                    {resourceAverageDailyConsumptionData?.currentCompressedAirAvg?.toFixed(2)}
                                </span>
                                <span className="pt-2 text-sm">m³</span>
                            </>
                        ) : (
                            <div className="opensans-semibold text-2xl">{t('WORKFLOW_AREA.NOT_AVAILABLE')}</div>
                        )}
                    </div>
                    {resourceAverageDailyConsumptionData?.benchmarkCompressedAirAvg !== null ? (
                        <div className="flex items-center gap-1 whitespace-nowrap">
                            {resourceAverageDailyConsumptionData?.percentCompressedAir !== undefined &&
                            resourceAverageDailyConsumptionData?.percentCompressedAir !== null ? (
                                resourceAverageDailyConsumptionData?.percentCompressedAir > 0 ? (
                                    <FontAwesomeIcon icon={faCircleArrowUpRight} size="xs" className="text-red-400" />
                                ) : (
                                    <FontAwesomeIcon
                                        icon={faCircleArrowDownRight}
                                        size="xs"
                                        className="text-green-400"
                                    />
                                )
                            ) : (
                                <div></div>
                            )}
                            <div className="text-xs text-neutral-500">
                                {resourceAverageDailyConsumptionData?.percentCompressedAir?.toFixed(2)}% (
                                {t('RESOURCE_MANAGER.COMPARISON')}:{' '}
                                {resourceAverageDailyConsumptionData?.benchmarkCompressedAirAvg?.toFixed(2)} m³)
                            </div>
                            <HoverableTooltipIcon />
                        </div>
                    ) : (
                        <div className="text-xs text-neutral-500">-</div>
                    )}
                </div>
                <div className="flex w-fit flex-1 flex-col gap-1 p-4">
                    <div className="opensans-semibold text-xs text-neutral-500">{t('RESOURCE_MANAGER.STEAM')}</div>
                    <div className="flex items-center gap-1">
                        <FontAwesomeIcon icon={faHeat} size={'lg'} className="text-neutral-500" />
                        {resourceAverageDailyConsumptionData?.currentSteamAvg?.toFixed(2) ? (
                            <>
                                <span className="opensans-semibold text-2xl">
                                    {resourceAverageDailyConsumptionData?.currentSteamAvg?.toFixed(2)}
                                </span>
                                <span className="pt-2 text-sm">m³</span>
                            </>
                        ) : (
                            <div className="opensans-semibold text-2xl">{t('WORKFLOW_AREA.NOT_AVAILABLE')}</div>
                        )}
                    </div>
                    {resourceAverageDailyConsumptionData?.benchmarkSteamAvg !== null ? (
                        <div className="flex items-center gap-1 whitespace-nowrap">
                            {resourceAverageDailyConsumptionData?.percentSteam !== undefined &&
                            resourceAverageDailyConsumptionData?.percentSteam !== null ? (
                                resourceAverageDailyConsumptionData?.percentSteam > 0 ? (
                                    <FontAwesomeIcon icon={faCircleArrowUpRight} size="xs" className="text-red-400" />
                                ) : (
                                    <FontAwesomeIcon
                                        icon={faCircleArrowDownRight}
                                        size="xs"
                                        className="text-green-400"
                                    />
                                )
                            ) : (
                                <div></div>
                            )}
                            <div className="text-xs text-neutral-500">
                                {resourceAverageDailyConsumptionData?.percentSteam?.toFixed(2)}% (
                                {t('RESOURCE_MANAGER.COMPARISON')}:{' '}
                                {resourceAverageDailyConsumptionData?.benchmarkSteamAvg?.toFixed(2)} m³)
                            </div>
                            <HoverableTooltipIcon />
                        </div>
                    ) : (
                        <div className="text-xs text-neutral-500">-</div>
                    )}
                </div>
            </div>
        </div>
    );
}
