import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { UserInitials } from '@cipa-gmbh/o6k-component-library';
import { useFetchUser } from '../../api/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { faUser, faIndustry, faArrowRightFromBracket } from '@fortawesome/pro-regular-svg-icons';

function ProfileSelection({
    closeMenu,
    menuIsOpen,
    requestedMenu,
    setRequestedMenu,
}: {
    closeMenu: () => void;
    menuIsOpen: boolean;
    requestedMenu: string;
    setRequestedMenu: (arg: string) => void;
}): JSX.Element {
    const [profileSelectionIsOpen, setProfileSelectionIsOpen] = useState(false);
    const closeProfileSection = () => setProfileSelectionIsOpen(false);
    const navigate = useNavigate();
    const { user: auth0user } = useAuth0();
    const userId = auth0user?.sub;
    const { data: user } = useFetchUser(userId ?? '');
    const { t } = useTranslation();

    const { logout: auth0Logout, isAuthenticated } = useAuth0();

    const toggleProfileSelectionIsOpen = () => {
        setRequestedMenu(profileSelectionIsOpen ? '' : 'profile');
        setProfileSelectionIsOpen(!profileSelectionIsOpen);
    };

    useEffect(() => {
        if (!menuIsOpen) {
            closeProfileSection();
        }
    }, [menuIsOpen]);
    useEffect(() => {
        if (requestedMenu !== 'profile') {
            closeProfileSection();
        }
    }, [requestedMenu]);

    const handleEscape = useCallback(
        (e) => {
            if ((e.key === 'Esc' || e.key === 'Escape') && profileSelectionIsOpen) {
                closeProfileSection();
            }
        },
        [profileSelectionIsOpen],
    );
    useEffect(() => {
        window.addEventListener('keydown', handleEscape);
        return () => {
            window.removeEventListener('keydown', handleEscape);
        };
    }, [handleEscape]);

    const routeToProfile = () => {
        closeMenu();
        setRequestedMenu('');
        navigate('/profile');
    };

    const routeToPlants = () => {
        closeMenu();
        setRequestedMenu('');
        navigate('/plant_overview');
    };

    const logout = () => {
        auth0Logout({
            logoutParams: {
                returnTo: process.env.REACT_APP_REDIRECT_URI + '?version=login&lang=' + user?.defaultLanguage ?? 'en',
            },
        });
        closeMenu();
        setRequestedMenu('');
    };

    return (
        <>
            <div className="relative z-[70] hidden px-2 py-1 pb-2 pt-1 md:flex md:p-0">
                {isAuthenticated && user && (
                    <>
                        <button
                            className="flex items-center rounded-l-3xl rounded-r p-1.5 hover:bg-neutral-100"
                            onClick={toggleProfileSelectionIsOpen}
                        >
                            <div className="group relative z-10 inline-flex focus:outline-none md:items-center">
                                <div className="spartan-bold flex h-10 w-10 items-center justify-center overflow-hidden rounded-full bg-neutral-900 text-xs text-white">
                                    {user.hasPic && user.picUrlMedium ? (
                                        <img src={user.picUrlMedium} alt="Profile" />
                                    ) : (
                                        <span>
                                            <UserInitials user={user} />
                                        </span>
                                    )}
                                </div>
                            </div>
                            <FontAwesomeIcon icon={faCaretDown} className="px-1.5 text-gray-500" />
                        </button>
                        {/* if dropdown is open, background button for closing with click outside the dropdown */}
                        {profileSelectionIsOpen && (
                            <button
                                onClick={closeProfileSection}
                                tabIndex={-1}
                                className="fixed inset-0 h-full w-full cursor-default focus:outline-none"
                            />
                        )}
                        <div
                            className={`${
                                profileSelectionIsOpen ? `inline-block` : `hidden`
                            } absolute right-3 top-12 z-[70] mt-1.5 w-40 rounded border border-neutral-100 bg-white py-1 text-sm text-gray-900 shadow-lg`}
                        >
                            <div className="border-b">
                                <button
                                    className="flex h-9 w-full cursor-pointer flex-nowrap items-center px-3.5 py-2 text-left hover:bg-neutral-100"
                                    onClick={routeToProfile}
                                >
                                    <FontAwesomeIcon icon={faUser} className="h-4 w-4 pr-3.5 text-neutral-500" />
                                    <div>{t('HEADER.PROFILE')}</div>
                                </button>
                                <button
                                    className="mb-1 flex h-9 w-full cursor-pointer flex-nowrap items-center px-3.5 py-2 text-left hover:bg-neutral-100"
                                    onClick={routeToPlants}
                                >
                                    <FontAwesomeIcon icon={faIndustry} className="h-4 w-4 pr-3.5 text-neutral-500" />
                                    <div>{t('PLANTS.OVERVIEW.MY_PLANTS')}</div>
                                </button>
                            </div>
                            <div>
                                <button
                                    className="mt-1 flex h-9 w-full cursor-pointer flex-nowrap items-center px-3.5 py-2 text-left hover:bg-neutral-100"
                                    onClick={logout}
                                >
                                    <FontAwesomeIcon
                                        icon={faArrowRightFromBracket}
                                        className="h-4 w-4 pr-3.5 text-neutral-500"
                                    />
                                    <div>{t('HEADER.LOGOUT')}</div>
                                </button>
                            </div>
                        </div>
                    </>
                )}
            </div>
            {/* MOBILE MENU VIEW
            <div className="px-2 pt-2 pb-2 md:hidden">
                {isAuthenticated && (
                    <>
                        <button
                            className="group relative z-10 inline-flex focus:outline-none"
                            onClick={toggleProfileSelectionIsOpen}
                        >
                            <div className="flex items-center justify-center overflow-hidden rounded-full border border-white group-hover:border-opblue">
                                {user?.hasPic ? (
                                    <img src={user.picUrlMedium} alt="Profile" className="h-8 w-8 rounded-full" />
                                ) : (
                                    <span className="rounded-full border p-2">
                                        <UserInitials user={user ?? ({} as User)} />
                                    </span>
                                )}
                            </div>
                            <span className="mt-2 ml-3 group-hover:text-opblue">{user?.firstName ?? ''}</span>
                        </button>
                         if dropdown is open, background button for closing with click outside the dropdown
                        <div
                            className={`${profileSelectionIsOpen ? `flex` : `hidden`} flex-col text-black`}
                            onClick={toggleProfileSelectionIsOpen}
                        >
                            {[
                                { label: t('HEADER.PROFILE'), clickHandler: routeToProfile },
                                { label: t('HEADER.PLANTS'), clickHandler: routeToPlants },
                                { label: t('HEADER.LOGOUT'), clickHandler: logout },
                            ].map((item, index) => {
                                return (
                                    <button
                                        key={index}
                                        className="cursor-pointer px-4 py-1 pl-16 text-left hover:text-opblue-hover"
                                        onClick={item.clickHandler}
                                    >
                                        {item.label}
                                    </button>
                                );
                            })}
                        </div>
                    </>
                )}
            </div>*/}
        </>
    );
}

export default ProfileSelection;
