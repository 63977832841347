import React, { useContext, useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFetchPlantById } from '../../api/plants';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { LanguageContext } from '../../pages/App';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {
    DeleteNotificationItem,
    deleteNotifications,
    NotificationActionItem,
    NotificationItemData,
    NotificationReadUnread,
    setNotificationAsReadUnread,
    triggerNotificationAction,
    UpdateActionItem,
    updateActionOutcome,
} from '../../api/notifications';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { faCheckCircle, faIndustry, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropdownMenu } from '@cipa-gmbh/o6k-component-library';
import { faDownload } from '@fortawesome/pro-regular-svg-icons';

export function NotificationListItem({
    notification,
    lastNotification,
    setNotificationOpen,
    setNotificationRead,
}: {
    notification: NotificationItemData;
    lastNotification: boolean;
    setNotificationOpen: (arg: boolean) => void;
    setNotificationRead: (notificationId: string, setRead: boolean) => Promise<string>;
}): JSX.Element {
    const queryClient = useQueryClient();
    const [hintTooltipHelper, setHintTooltipHelper] = useState(false);
    const [hintTooltipUpdater, setHintTooltipUpdater] = useState(false);
    const radioButtonRef = useRef<HTMLButtonElement>(null);
    const { data: plant } = useFetchPlantById(notification?.organizationId ?? '');
    //get user (browser) timezone
    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const chosenLanguage = useContext(LanguageContext);

    dayjs.extend(timezone);
    dayjs.extend(utc);
    const format24 = 'DD.MM.YYYY • HH:mm';
    const format12 = 'DD/MM/YYYY • hh:mm A';

    const { t } = useTranslation();

    const [isValidImage, setIsValidImage] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        const img = new Image();
        img.onload = () => setIsValidImage(true);
        img.onerror = () => setIsValidImage(false);
        img.src = notification.illustration;
    }, [notification]);

    // const isMount = (() => {
    //     const isMountRef = useRef(true);
    //     useEffect(() => {
    //         isMountRef.current = false;
    //     }, []);
    //     return isMountRef.current;
    // })();

    const setTriggerNotificationAction = async (action: NotificationActionItem) => {
        await setTriggerNotificationActionMutation(action);
    };

    useEffect(() => {
        /* hintToolHelper is used to show the tooltip text correctly and hintTooltipUpdater
 is used to run the .show function after state is finished changing */
        //skip initial render
        // if (!isMount && radioButtonRef.current) {
        //     //ReactTooltip.show(radioButtonRef.current);
        // }
        setHintTooltipHelper(!hintTooltipHelper);
    }, [hintTooltipUpdater]);

    function getNotificationFeatureName(type: string) {
        if (type === 'REPORTING') {
            return 'Reporting';
        }
        if (type === 'HISTORIAN') {
            return 'Historian';
        }
        if (type === 'CONDITION_MONITORING') {
            return 'Condition Monitoring';
        }
        if (type === 'PLANT_INVITATION') {
            return 'Plant Invitation';
        }
    }

    const menuItems: { label: string; function: (arg: boolean) => void }[] = [];
    menuItems.push({
        label: !notification.read ? t('NOTIFICATIONS.SET_AS_READ') : t('NOTIFICATIONS.SET_AS_UNREAD'),
        function: () =>
            !notification.read
                ? setNotificationRead(notification.notificationId, true)
                : setNotificationRead(notification.notificationId, false),
    });
    menuItems.push({
        label: t('NOTIFICATIONS.DELETE_NOTIFICATION'),
        function: () => deleteMultipleNotifications(),
    });

    function getNotificationSeverity(severity: number) {
        if (severity === 10) {
            return (
                <div className="flex h-5 items-center justify-center rounded bg-red-50 px-2">
                    <span className="text-10 text-red-500">
                        {t('CONDITION_MONITORING.SEVERITY.ERROR').toUpperCase()}
                    </span>
                </div>
            );
        }
        if (severity === 20) {
            return (
                <div className="flex h-5 items-center justify-center rounded bg-blue-50 px-2">
                    <span className="text-10 text-blue-500">
                        {t('CONDITION_MONITORING.SEVERITY.INFORMATION').toUpperCase()}
                    </span>
                </div>
            );
        } else {
            return (
                <div className="flex h-5 items-center justify-center rounded bg-orange-50 px-2">
                    <span className="text-10 text-orange-500">
                        {t('CONDITION_MONITORING.SEVERITY.WARNING').toUpperCase()}
                    </span>
                </div>
            );
        }
    }
    function getNotificationActions(
        action: NotificationActionItem,
        notificationId: string,
        feature: string,
        key: number,
    ) {
        return (
            <button
                key={key}
                className={`${action.type === 'POSITIVE' && 'text-green-400'} ${
                    action.type === 'NEGATIVE' && 'text-red-400'
                } ${
                    action.type === 'NEUTRAL' && 'text-blue-400'
                } font-opensans mr-4 inline-flex items-center text-sm hover:underline focus:underline focus:outline-none`}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    //only call the api when it is no reporting feature
                    if (feature !== 'REPORTING') {
                        setTriggerNotificationAction(action);
                    } else {
                        //download pdf, pptx, xlsx files directly
                        window.open(action.apiUrl, '_blank');
                    }
                }}
            >
                <div className="flex h-full w-full items-center justify-center">
                    {action.type === 'POSITIVE' && <FontAwesomeIcon icon={faCheckCircle} className="text-sm" />}
                    {action.type === 'NEGATIVE' && (
                        <FontAwesomeIcon icon={faTimesCircle} className="text-sm text-red-400" />
                    )}
                    {feature === 'REPORTING' && <FontAwesomeIcon icon={faDownload} className="md:hidden" />}
                    <span
                        className={`
                        ${action.type === 'POSITIVE' && 'ml-2 text-green-400'}
                        ${action.type === 'NEGATIVE' && 'ml-2 text-red-400'}
                        ${action.type === 'NEUTRAL' && 'text-blue-400'} text-sm`}
                    >
                        {feature === 'REPORTING' && <span className="hidden md:block">{action.name}</span>}
                        {feature !== 'REPORTING' && action.name}
                        {feature === 'REPORTING' && (
                            <span className="ml-2 md:hidden">{action.name.replace('Download ', '')}</span>
                        )}
                    </span>
                </div>
            </button>
        );
    }
    function getNotificationOutcome(notification: NotificationItemData) {
        if (notification.action.find((act) => act.type === notification.actionExecuted)?.type === 'POSITIVE') {
            return (
                <span>
                    <FontAwesomeIcon icon={faCheckCircle} className="text-sm text-green-400" />
                    <span className="ml-1 text-xs text-neutral-500">
                        {notification.action.find((act) => act.type === notification.actionExecuted)?.outcome}
                    </span>
                </span>
            );
        }
        if (notification.action.find((act) => act.type === notification.actionExecuted)?.type === 'NEGATIVE') {
            return (
                <span>
                    <FontAwesomeIcon icon={faTimesCircle} className="text-sm text-red-400" />
                    <span className="ml-1 text-xs text-neutral-500">
                        {notification.action.find((act) => act.type === notification.actionExecuted)?.outcome}
                    </span>
                </span>
            );
        }
        if (notification.action.find((act) => act.type === notification.actionExecuted)?.type === 'NEUTRAL') {
            return (
                <span>
                    <FontAwesomeIcon icon={faTimesCircle} className="text-sm text-red-400" />
                    <span className="ml-1 text-xs text-neutral-500">
                        {notification.action.find((act) => act.type === notification.actionExecuted)?.outcome}
                    </span>
                </span>
            );
        }
    }

    const { mutateAsync: deleteNotificationsMutation } = useMutation<string, Error, DeleteNotificationItem[]>({
        mutationFn: deleteNotifications,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['notifications'],
            });
        },
        onError: (error) => {
            console.log(error.message);
        },
    });

    const deleteMultipleNotifications = async () => {
        const notificationDeleteArray = [] as DeleteNotificationItem[];

        notificationDeleteArray.push({
            notificationId: notification.notificationId,
            createdOn: notification.createdOn,
        });

        await deleteNotificationsMutation(notificationDeleteArray);
    };

    const { mutateAsync: setTriggerNotificationActionMutation } = useMutation<void, Error, NotificationActionItem>({
        mutationFn: async (action) => triggerNotificationAction({ action }),
        onSuccess: (data, action) => {
            //call API to change executed parameter to show the outcome on the notification item
            updateActionOutcomeMutation({
                notificationId: notification.notificationId,
                actionExecuted: action.name,
            });
            queryClient.invalidateQueries({
                queryKey: ['plant'],
            });
            queryClient.invalidateQueries({
                queryKey: ['invitation'],
            });
            setNotificationRead(notification.notificationId, true);
        },
        onError: (error) => {
            console.log(error.message);
        },
    });

    const { mutateAsync: updateActionOutcomeMutation } = useMutation<string, Error, UpdateActionItem>({
        mutationFn: async ({ notificationId, actionExecuted }) =>
            updateActionOutcome({ notificationId: notificationId, actionExecuted: actionExecuted }),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['notifications'],
            });
            queryClient.invalidateQueries({
                queryKey: ['notificationsLast7'],
            });
            queryClient.invalidateQueries({
                queryKey: ['notificationCount'],
            });
        },
        onError: (error) => {
            console.log(error.message);
        },
    });

    return (
        <NavLink
            to={notification?.link}
            id="mainHover"
            className={`${
                lastNotification ? '' : 'border-b'
            } py-auto relative flex w-full cursor-pointer flex-row items-center py-2 pl-3 pr-4 text-left text-neutral-900 md:hover:bg-neutral-100`}
            onClick={() => {
                setNotificationOpen(false);
                setNotificationRead(notification.notificationId, true);
            }}
        >
            <ReactTooltip className="tooltip-custom" id="read-tooltip" place="left" />
            <div className="flex w-full flex-row">
                <div className="flex flex-row items-center justify-center">
                    <div className="group-notifications mr-3 flex h-4 w-4 items-center">
                        <div
                            className={`flex h-full w-full items-center justify-center rounded-full ${
                                !notification.read
                                    ? 'group-notifications-hover:bg-blue-100'
                                    : 'group-notifications-hover:bg-neutral-200'
                            }`}
                        >
                            <button
                                ref={radioButtonRef}
                                data-tooltip-content={
                                    notification.read
                                        ? t('NOTIFICATIONS.SET_AS_UNREAD')
                                        : t('NOTIFICATIONS.SET_AS_READ')
                                }
                                data-tooltip-id="read-tooltip"
                                className={`flex h-full w-full items-center justify-center rounded-full ${
                                    !notification.read
                                        ? 'z-10 group-notifications-hover:border group-notifications-hover:border-blue-400'
                                        : 'text-primary group-notifications-hover:border group-notifications-hover:border-neutral-300'
                                }`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setNotificationRead(notification.notificationId, !notification.read);
                                    setHintTooltipHelper(!hintTooltipHelper);
                                    setHintTooltipUpdater(!hintTooltipUpdater);
                                }}
                            >
                                <div
                                    className={`h-2 w-2 rounded-full ${
                                        notification.read
                                            ? 'bg-neutral-200 group-notifications-hover:bg-neutral-300'
                                            : 'bg-blue-400'
                                    }`}
                                />
                            </button>
                        </div>
                    </div>
                    <div className="flex h-8 w-8 flex-row rounded-lg">
                        {isValidImage ? (
                            <div className="h-8 w-8">
                                <img alt="plant" className="mr-4 h-8 w-8 rounded-md" src={notification?.illustration} />
                            </div>
                        ) : (
                            notification.feature === 'PLANT_INVITATION' && (
                                <div className="flex h-8 w-8 items-center justify-center rounded border border-borderPlantImage border-opacity-30 bg-neutral-200">
                                    <FontAwesomeIcon icon={faIndustry} className="text-sm text-neutral-300" />
                                </div>
                            )
                        )}
                    </div>
                </div>
                <div className="ml-4 flex w-full flex-col text-sm">
                    <div className="flex w-full flex-row items-center justify-between">
                        <div className="flex flex-row">
                            <span className="whitespace-nowrap text-xs text-blue-400">
                                {getNotificationFeatureName(notification.feature)}
                            </span>
                            <span className="ml-5 hidden whitespace-nowrap text-xs text-neutral-400 md:block">
                                {dayjs
                                    .unix(notification?.createdOn ?? 0)
                                    .tz(browserTimeZone)
                                    .format(chosenLanguage === 'de' ? format24 : format12)}
                            </span>
                        </div>
                        <div className="flex flex-row items-center">
                            <div className="-mr-5">
                                {notification.severity !== 0 && getNotificationSeverity(notification.severity)}
                            </div>
                            <div
                                className="mr-2"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                            >
                                <DropdownMenu menuItems={menuItems} />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <span className="text-sm text-neutral-900">
                            <span className="mr-2">{notification.content.long}</span>
                            <span className="whitespace-nowrap">
                                {notification.actionExecuted && getNotificationOutcome(notification)}
                            </span>
                        </span>
                    </div>
                    {!notification.actionExecuted && (
                        <div className="mt-1">
                            {notification.action.map((act, i) =>
                                getNotificationActions(act, notification.notificationId, notification.feature, i),
                            )}
                        </div>
                    )}
                    {plant?.name && notification.feature !== 'PLANT_INVITATION' && (
                        <span className="text-xs text-neutral-500">{plant?.name}</span>
                    )}
                    <span className="mt-1 whitespace-nowrap text-xs text-neutral-500 md:hidden">
                        {dayjs
                            .unix(notification?.createdOn ?? 0)
                            .tz(browserTimeZone)
                            .format(chosenLanguage === 'de' ? format24 : format12)}
                    </span>
                </div>
            </div>
        </NavLink>
    );
}

export default function NotificationItem({
    notification,
    lastNotification,
    setNotificationOpen,
}: {
    notification: NotificationItemData;
    lastNotification: boolean;
    setNotificationOpen: (arg: boolean) => void;
}): JSX.Element {
    const queryClient = useQueryClient();

    const { mutateAsync: setNotificationToReadMutation } = useMutation<string, Error, NotificationReadUnread[]>({
        mutationFn: setNotificationAsReadUnread,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['notifications'],
            });
            queryClient.invalidateQueries({
                queryKey: ['notificationsLast7'],
            });
            queryClient.invalidateQueries({
                queryKey: ['notificationCount'],
            });
        },
        onError: (error) => {
            console.log(error.message);
        },
    });

    const { mutateAsync: setNotificationToUnreadMutation } = useMutation<string, Error, NotificationReadUnread[]>({
        mutationFn: setNotificationAsReadUnread,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['notifications'],
            });
            queryClient.invalidateQueries({
                queryKey: ['notificationsLast7'],
            });
            queryClient.invalidateQueries({
                queryKey: ['notificationCount'],
            });
        },
        onError: (error) => {
            console.log(error.message);
        },
    });

    const setNotificationRead = async (notificationId: string, setRead: boolean) => {
        return setRead
            ? await setNotificationToReadMutation([{ notificationId: notificationId, read: setRead }])
            : await setNotificationToUnreadMutation([{ notificationId: notificationId, read: setRead }]);
    };

    return (
        <NotificationListItem
            notification={notification}
            lastNotification={lastNotification}
            setNotificationOpen={setNotificationOpen}
            setNotificationRead={setNotificationRead}
        />
    );
}
