import React, { useContext, useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../pages/App';
import { AxisRenderer, CategoryAxis, ColumnSeries } from '@amcharts/amcharts5/xy';
import { Root } from '@amcharts/amcharts5';
import * as am5 from '@amcharts/amcharts5';
import am5locales_de_DE from '@amcharts/amcharts5/locales/de_DE';
import am5locales_en_US from '@amcharts/amcharts5/locales/en_US';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';
import { v4 as uuidv4 } from 'uuid';
import { useFetchElectricityConsumptionOverviewData } from '../../api/resourceManager';
import { Plant } from '../../api/plants';
import { addMissingMonths, sortDataByYearMonth, updateColumnNames } from './HelperComponents';

export default function ElectricityConsumptionOverview({ plant }: { plant: Plant | undefined }): JSX.Element {
    const { t } = useTranslation();
    const id = uuidv4();
    const chosenLanguage = useContext(LanguageContext);
    const rootRef = useRef<Root | null>(null);
    const xAxisRef = useRef<CategoryAxis<AxisRenderer> | null>(null);
    const series1Ref = useRef<ColumnSeries | null>(null);
    const series2Ref = useRef<ColumnSeries | null>(null);
    const series3Ref = useRef<ColumnSeries | null>(null);

    const { data: electricityConsumptionOverviewData } = useFetchElectricityConsumptionOverviewData(plant?.id ?? '');
    useLayoutEffect(() => {
        let root: Root | null = null;
        am5.addLicense('AM5C446337179');
        if (rootRef.current === null) {
            root = am5.Root.new('electricity-consumption-overview' + id, {
                tooltipContainerBounds: {
                    top: 200,
                    right: 0,
                    bottom: 200,
                    left: 0,
                },
            });
        } else {
            root = rootRef.current;
            root.dispose();
            root = am5.Root.new('electricity-consumption-overview' + id, {
                tooltipContainerBounds: {
                    top: 200,
                    right: 0,
                    bottom: 200,
                    left: 0,
                },
            });
        }
        root.locale = chosenLanguage === 'de' ? am5locales_de_DE : am5locales_en_US;
        root.setThemes([am5themes_Animated.new(root)]);
        root.numberFormatter.set('numberFormat', '#,###.');
        root.dateFormatter.setAll({
            dateFormat: chosenLanguage === 'en' ? 'MM-dd-YYYY' : 'dd/MM/YYYY',
            dateFields: ['dateStart', 'dateEnd'],
        });

        const chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                paddingLeft: 0,
                layout: root.verticalLayout,
            }),
        );
        chart.set('height', am5.percent(100));
        chart.set('width', am5.percent(100));

        const cursor = chart.set(
            'cursor',
            am5xy.XYCursor.new(root, {
                behavior: 'zoomX',
            }),
        );
        cursor.lineY.set('visible', false);
        cursor.lineX.set('visible', false);

        const xRenderer = am5xy.AxisRendererX.new(root, {
            cellStartLocation: 0.2,
            cellEndLocation: 0.8,
            minGridDistance: 0,
        });

        xRenderer.labels.template.setAll({
            fontSize: 10,
            fill: am5.color('#000000'),
            paddingTop: 8,
        });
        xRenderer.grid.template.setAll({ fill: am5.color('#000000') });
        xRenderer.grid.template.set('visible', false);

        const xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                categoryField: 'monthDisplay',
                renderer: xRenderer,
            }),
        );

        xAxis.get('renderer').labels.template.setAll({
            oversizedBehavior: 'truncate',
        });

        const yRenderer = am5xy.AxisRendererY.new(root, {});
        yRenderer.labels.template.setAll({
            fill: am5.color('#000000'),
            fontSize: '10px',
            paddingRight: 8,
        });

        const yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: yRenderer,
                extraMax: 0.1,
            }),
        );

        yAxis.children.unshift(
            am5.Label.new(root as Root, {
                rotation: -90,
                text: 'kWh',
                y: am5.p50,
                centerX: am5.p50,
                fill: am5.color('#000000'),
            }),
        );

        const series1 = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                name: 'PV supply',
                id: 'PV supply' + id,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: 'power_pv',
                sequencedInterpolation: true,
                categoryXField: 'monthDisplay',
                fill: am5.color('#2BB351'),
                stroke: am5.color('#2BB351'),
                legendLabelText: t('RESOURCE_MANAGER.PV_SUPPLY'),
                stacked: true,
            }),
        );

        series1.columns.template.setAll({
            width: am5.percent(100),
            strokeOpacity: 0,
            tooltipY: 0,
            tooltip: am5.Tooltip.new(root, {
                paddingTop: 4,
                paddingBottom: 4,
                paddingLeft: 8,
                paddingRight: 8,
                pointerOrientation: 'down',
            }),
        });

        series1.columns.template.adapters.add('tooltipText', function () {
            return `[fontSize: 12px]${t('RESOURCE_MANAGER.PV_SUPPLY')} - {monthDisplay}: {valueY} kWh[/]`;
        });

        /*series1.bullets.push(function (root) {
            return am5.Bullet.new(root, {
                locationX: 0.5,
                locationY: 0.5,
                sprite: am5.Label.new(root, {
                    text: '{valueY}',
                    centerX: am5.percent(50),
                    centerY: am5.percent(50),
                    populateText: true,
                    fontSize: 10,
                    minWidth: 0,
                    maxWidth: 100,
                    fill: am5.color('#fff'),
                }),
            });
        });*/

        const series2 = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                name: 'Grid supply',
                id: 'Grid supply' + id,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: 'power_plant',
                sequencedInterpolation: true,
                categoryXField: 'monthDisplay',
                fill: am5.color('#0064FA'),
                stroke: am5.color('#0064FA'),
                legendLabelText: t('RESOURCE_MANAGER.GRID_SUPPLY'),
                stacked: true,
            }),
        );

        series2.columns.template.setAll({
            width: am5.percent(100),
            cornerRadiusTL: 4,
            cornerRadiusTR: 4,
            strokeOpacity: 0,
            tooltipY: 0,
            tooltip: am5.Tooltip.new(root, {
                paddingTop: 4,
                paddingBottom: 4,
                paddingLeft: 8,
                paddingRight: 8,
                pointerOrientation: 'down',
            }),
        });

        series2.columns.template.adapters.add('tooltipText', function () {
            return `[fontSize: 12px]${t('RESOURCE_MANAGER.GRID_SUPPLY')} - {monthDisplay}: {valueY} kWh[/]`;
        });

        /*series2.bullets.push(function (root) {
            return am5.Bullet.new(root, {
                locationX: 0.5,
                locationY: 0.5,
                sprite: am5.Label.new(root, {
                    text: '{valueY}',
                    centerX: am5.percent(50),
                    centerY: am5.percent(50),
                    populateText: true,
                    fontSize: 10,
                    minWidth: 0,
                    maxWidth: 100,
                    fill: am5.color('#fff'),
                }),
            });
        });*/

        const series3 = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                name: 'PV feed-in',
                id: 'PV feed-in' + id,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: 'pv_to_grid',
                sequencedInterpolation: true,
                categoryXField: 'monthDisplay',
                fill: am5.color('#A1E6B3'),
                stroke: am5.color('#A1E6B3'),
                legendLabelText: t('RESOURCE_MANAGER.PV_FEEDIN'),
                stacked: true,
                stackToNegative: true,
            }),
        );

        series3.columns.template.setAll({
            width: am5.percent(100),
            cornerRadiusTL: 4,
            cornerRadiusTR: 4,
            strokeOpacity: 0,
            tooltipY: 0,
            tooltip: am5.Tooltip.new(root, {
                paddingTop: 4,
                paddingBottom: 4,
                paddingLeft: 8,
                paddingRight: 8,
                pointerOrientation: 'down',
            }),
        });

        series3.columns.template.adapters.add('tooltipText', function () {
            return `[fontSize: 12px]${t('RESOURCE_MANAGER.PV_FEEDIN')} - {monthDisplay}: {valueY} kWh[/]`;
        });

        /*series3.bullets.push(function (root) {
            return am5.Bullet.new(root, {
                locationX: 0.5,
                locationY: 0.5,
                sprite: am5.Label.new(root, {
                    text: "{valueY.formatNumber('#,###.##s')}",
                    centerX: am5.percent(50),
                    centerY: am5.percent(50),
                    populateText: true,
                    fontSize: 10,
                    minWidth: 0,
                    maxWidth: 100,
                }),
            });
        });*/

        const legend = chart.children.push(
            am5.Legend.new(root, {
                centerX: am5.percent(50),
                x: am5.percent(50),
                marginTop: 14,
                marginBottom: 14,
            }),
        );
        legend.labels.template.setAll({
            fontSize: 12,
            fontWeight: '400',
            fill: am5.color('#000000'),
        });

        legend.valueLabels.template.set('forceHidden', true);

        // It's important to set legend data after all the events are set on template, otherwise events won't be copied
        legend.data.setAll(chart.series.values);

        legend.markerRectangles.template.setAll({
            dy: 2.5,
            width: 12,
            height: 12,
            cornerRadiusTL: 4,
            cornerRadiusTR: 4,
            cornerRadiusBL: 4,
            cornerRadiusBR: 4,
        });
        legend.labels.template.setAll({
            fontSize: 12,
            fill: am5.color('#000000'),
        });
        legend.valueLabels.template.set('forceHidden', true);

        // It's important to set legend data after all the events are set on template, otherwise events won't be copied
        legend.data.setAll(chart.series.values);

        xAxisRef.current = xAxis;
        series1Ref.current = series1;
        series2Ref.current = series2;
        series3Ref.current = series3;
        rootRef.current = root;

        return () => {
            if (root) {
                root.dispose();
            }
        };
    }, []);

    useLayoutEffect(() => {
        if (electricityConsumptionOverviewData) {
            const fullArray = addMissingMonths(electricityConsumptionOverviewData, 'last12', 'overview');
            const updatedColumnNames = updateColumnNames(fullArray, 'overview');
            xAxisRef?.current?.data?.setAll(sortDataByYearMonth(updatedColumnNames));
            series1Ref?.current?.data?.setAll(sortDataByYearMonth(updatedColumnNames));
            series2Ref?.current?.data?.setAll(sortDataByYearMonth(updatedColumnNames));
            series3Ref?.current?.data?.setAll(sortDataByYearMonth(updatedColumnNames));
        }
    }, []);

    return (
        <div className="flex h-80 flex-col bg-white lg:w-1/2 lg:border lg:border-neutral-200">
            <div className="opensans-semibold flex items-center bg-mainBackground px-4 pb-2 pt-6 lg:h-10 lg:bg-white lg:px-6 lg:pt-2">
                <span className="hidden text-xs text-neutral-500 lg:block">
                    {t('RESOURCE_MANAGER.ELECTRICITY_CONSUMPTION_OVERVIEW')}
                </span>
                <span className="lg:hidden">{t('RESOURCE_MANAGER.ENERGY_BALANCE')}</span>
            </div>

            <div id={'electricity-consumption-overview' + id} className="h-5/6 w-full py-5 text-xs text-neutral-500" />
        </div>
    );
}
