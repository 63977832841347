import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LiveDot from '../../assets/resource_manager_energy_flow_live_dot.gif';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtilityPoleDouble, faSolarPanel, faIndustry } from '@fortawesome/pro-regular-svg-icons';
import { ArcherContainer, ArcherElement } from 'react-archer';
import { RelationType } from 'react-archer/lib/types';
import { useFetchEnergyFlow } from '../../api/resourceManager';

export default function EnergyFlow({
    startTime,
    endTime,
    timezoneId,
    plantId,
}: {
    startTime: number;
    endTime: number;
    timezoneId: string;
    plantId: string;
}): JSX.Element {
    const { t } = useTranslation();

    const [relations, setRelations] = useState<RelationType[]>([]);
    const { data: energyFlowData } = useFetchEnergyFlow(plantId, timezoneId, startTime, endTime);
    const [pv, setPV] = useState<boolean>(energyFlowData?.currentPowerPhotovoltaic != null);

    useEffect(() => {
        if (energyFlowData) {
            if (energyFlowData.currentPowerPhotovoltaic != null) {
                setPV(true);
            }
        }
        if (energyFlowData?.currentPowerAllAssets) {
            setRelations(
                energyFlowData.currentPowerAllAssets?.map((item) => ({
                    targetId: item.assetId + '_label',
                    targetAnchor: 'left',
                    sourceAnchor: 'right',
                    style: {
                        strokeDasharray: '4',
                        strokeColor: '#0064FA',
                        strokeWidth: 1,
                        endMarker: false,
                    },
                })),
            );
        }
    }, [energyFlowData]);

    return (
        <div className="w-full bg-mainBackground lg:border lg:border-neutral-200 lg:bg-white">
            <div className="flex items-center justify-between px-4 pb-2 pt-6 lg:h-10 lg:px-6 lg:pt-2">
                <span className="opensans-semibold text-black lg:text-xs lg:text-neutral-500">
                    {t('RESOURCE_MANAGER.ENERGY_FLOW')}
                </span>
                <img src={LiveDot} alt="Live Dot" width={'40px'} />
            </div>
            <div className="flex flex-col gap-2.5 bg-white p-4 lg:hidden">
                <span className="text-sm text-neutral-500">{t('RESOURCE_MANAGER.SOURCE')}</span>
                <div className="flex flex-col gap-2.5 pl-6">
                    <div className="flex flex-row justify-between text-sm">
                        <div className="flex flex-row items-center gap-2">
                            <FontAwesomeIcon icon={faUtilityPoleDouble} className="w-5 text-neutral-500" />
                            <span>{t('RESOURCE_MANAGER.MAINS')}</span>
                        </div>
                        <span>{energyFlowData?.currentPowerGrid.toFixed(0) + ' kW'}</span>
                    </div>
                    {pv && (
                        <div className="flex flex-row justify-between text-sm">
                            <div className="flex flex-row items-center gap-2">
                                <FontAwesomeIcon icon={faSolarPanel} className="w-5 text-green-500" />
                                <span>{t('RESOURCE_MANAGER.PV')}</span>
                            </div>
                            <span>
                                {energyFlowData?.currentPowerPhotovoltaic != null
                                    ? energyFlowData?.currentPowerPhotovoltaic?.toFixed(0) + ' kW'
                                    : 'no data'}
                            </span>
                        </div>
                    )}
                </div>
                <hr />
                <span className="text-sm text-neutral-500">{t('RESOURCE_MANAGER.CONSUMER')}</span>
                <div className="flex flex-col gap-2.5 pl-6">
                    {energyFlowData?.currentPowerAllAssets?.map((item) => {
                        return (
                            <div key={item.assetId} className="flex flex-row justify-between text-sm">
                                <div className="flex flex-row items-center gap-2">{item.displayName}</div>
                                <span>{item.energyValue.toFixed(0) + ' kW'}</span>
                            </div>
                        );
                    })}
                </div>
            </div>
            <ArcherContainer noCurves className="hidden lg:block">
                <div className="flex h-full w-full flex-row px-8 py-4 xl:px-36">
                    <div className="flex flex-row items-center">
                        {pv ? (
                            <div className="flex flex-row items-center">
                                <div className="flex w-40 flex-col gap-y-40">
                                    <div className="flex flex-row justify-between">
                                        <ArcherElement
                                            id="mains"
                                            relations={[
                                                {
                                                    targetId: 'mains_plant',
                                                    targetAnchor: 'left',
                                                    sourceAnchor: 'right',
                                                    style: {
                                                        strokeDasharray: '4',
                                                        strokeColor: '#97A0AF',
                                                        strokeWidth: 1,
                                                        endMarker: false,
                                                    },
                                                },
                                            ]}
                                        >
                                            <div className="p-1">
                                                <div className="bg-colorSecondary flex h-14 w-14 items-center justify-center rounded-full border border-neutral-400">
                                                    <FontAwesomeIcon
                                                        icon={faUtilityPoleDouble}
                                                        className="text-2xl text-neutral-500"
                                                    />
                                                </div>
                                            </div>
                                        </ArcherElement>
                                        <ArcherElement
                                            id="mains_plant"
                                            relations={[
                                                {
                                                    targetId: 'plant',
                                                    targetAnchor: 'top',
                                                    sourceAnchor: 'right',
                                                    style: {
                                                        strokeDasharray: '4',
                                                        strokeColor: '#97A0AF',
                                                        strokeWidth: 1,
                                                    },
                                                },
                                            ]}
                                        >
                                            <div className="flex flex-row items-center whitespace-nowrap text-xxs">
                                                <span className="px-2 py-1 text-neutral-500">
                                                    {energyFlowData?.currentPowerGrid.toFixed(0) + ' kW'}
                                                </span>
                                            </div>
                                        </ArcherElement>
                                    </div>
                                    <div className="flex flex-row justify-between">
                                        <ArcherElement
                                            id="pv"
                                            relations={[
                                                {
                                                    targetId: 'mains',
                                                    targetAnchor: 'bottom',
                                                    sourceAnchor: 'top',
                                                    style: {
                                                        strokeDasharray: '4',
                                                        strokeColor: '#2BB351',
                                                        strokeWidth: 1,
                                                    },
                                                    label: (
                                                        <div className="whitespace-nowrap bg-white px-2 py-1 text-xxs text-neutral-500">
                                                            {energyFlowData?.currentPowerPhotovoltaicToGrid
                                                                ? energyFlowData?.currentPowerPhotovoltaicToGrid + ' kW'
                                                                : 'no data'}
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    targetId: 'pv_plant',
                                                    targetAnchor: 'left',
                                                    sourceAnchor: 'right',
                                                    style: {
                                                        strokeDasharray: '4',
                                                        strokeColor: '#2BB351',
                                                        strokeWidth: 1,
                                                        endMarker: false,
                                                    },
                                                },
                                            ]}
                                        >
                                            <div className="p-1">
                                                <div className="flex h-14 w-14 items-center justify-center rounded-full border border-green-400 bg-green-50">
                                                    <FontAwesomeIcon
                                                        icon={faSolarPanel}
                                                        className="text-2xl text-green-400"
                                                    />
                                                </div>
                                            </div>
                                        </ArcherElement>
                                        <ArcherElement
                                            id="pv_plant"
                                            relations={[
                                                {
                                                    targetId: 'plant',
                                                    targetAnchor: 'bottom',
                                                    sourceAnchor: 'right',
                                                    style: {
                                                        strokeDasharray: '4',
                                                        strokeColor: '#2BB351',
                                                        strokeWidth: 1,
                                                    },
                                                },
                                            ]}
                                        >
                                            <div className="flex flex-row items-center whitespace-nowrap px-2 py-1 text-xxs">
                                                <span className="bg-white text-neutral-500">
                                                    {energyFlowData?.currentPowerPhotovoltaic
                                                        ? energyFlowData?.currentPowerPhotovoltaic.toFixed(0) + ' kW'
                                                        : 'no data'}
                                                </span>
                                            </div>
                                        </ArcherElement>
                                    </div>
                                </div>
                                <div className="ml-10">
                                    <ArcherElement id="plant" relations={relations}>
                                        <div className="p-1">
                                            <div className="flex h-14 w-14 items-center justify-center rounded-full border border-blue-400 bg-blue-50">
                                                <FontAwesomeIcon icon={faIndustry} className="text-2xl text-blue-400" />
                                            </div>
                                        </div>
                                    </ArcherElement>
                                </div>
                            </div>
                        ) : (
                            <>
                                <ArcherElement
                                    id="mains"
                                    relations={[
                                        {
                                            targetId: 'plant',
                                            targetAnchor: 'left',
                                            sourceAnchor: 'right',
                                            style: { strokeDasharray: '4', strokeColor: '#2BB351', strokeWidth: 1 },
                                            label: (
                                                <div
                                                    className="whitespace-nowrap bg-white"
                                                    style={{ color: '#6F7B8C', fontSize: '10px' }}
                                                >
                                                    {energyFlowData?.currentPowerGrid.toFixed(0) + ' kW'}
                                                </div>
                                            ),
                                        },
                                    ]}
                                >
                                    <div className="p-1">
                                        <div className="bg-colorSecondary flex h-14 w-14 items-center justify-center rounded-full border border-neutral-400">
                                            <FontAwesomeIcon
                                                icon={faUtilityPoleDouble}
                                                className="text-2xl text-neutral-500"
                                            />
                                        </div>
                                    </div>
                                </ArcherElement>
                                <ArcherElement id="plant" relations={relations}>
                                    <div className="ml-35 p-1">
                                        <div className="flex h-14 w-14 items-center justify-center rounded-full border border-blue-400 bg-blue-50">
                                            <FontAwesomeIcon icon={faIndustry} className="text-2xl text-blue-400" />
                                        </div>
                                    </div>
                                </ArcherElement>
                            </>
                        )}
                    </div>
                    <div className="flex w-full flex-col items-end justify-center gap-4 text-xs">
                        {energyFlowData?.currentPowerAllAssets?.map((item) => {
                            return (
                                <div key={item.assetId} className="flex flex-row justify-between">
                                    <div className="flex w-50 flex-row">
                                        <ArcherElement
                                            id={item.assetId + '_label'}
                                            relations={[
                                                {
                                                    targetId: `${item.assetId}`,
                                                    targetAnchor: 'left',
                                                    sourceAnchor: 'right',
                                                    style: {
                                                        strokeDasharray: '4',
                                                        strokeColor: '#0064FA',
                                                        strokeWidth: 1,
                                                    },
                                                },
                                            ]}
                                        >
                                            <div className="flex flex-row items-center whitespace-nowrap px-2 py-1 text-xxs">
                                                <span className="bg-white text-neutral-500">
                                                    {item.energyValue.toFixed(0) + ' kW'}
                                                </span>
                                            </div>
                                        </ArcherElement>
                                    </div>
                                    <ArcherElement id={item.assetId}>
                                        <div className="pl-1">
                                            <div className="bg-colorSecondary flex w-[136px] flex-col items-center rounded border border-neutral-200 px-4 py-1.5">
                                                <span>{item.displayName}</span>
                                            </div>
                                        </div>
                                    </ArcherElement>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </ArcherContainer>
        </div>
    );
}
