import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { getCookie, globalLanguageKey } from '@cipa-gmbh/o6k-component-library';

export const languages = ['de', 'en', 'pt'];

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        react: { useSuspense: false },
        returnNull: false,
        fallbackLng: 'en',
        debug: false,
        resources: {
            en: {
                translation: {
                    DASHBOARD_PERIOD_TOO_LONG_HEADLINE: 'Period too long',
                    DASHBOARD_PERIOD_TOO_LONG_SUBLINE_1: 'Only data for a maximum period of 3 days',
                    DASHBOARD_PERIOD_TOO_LONG_SUBLINE_2: 'can be displayed for this widget.',
                    ERROR: {
                        TRY_AGAIN: 'An error has occurred. Please try again.',
                    },
                    EDUCATION: {
                        NUMBER_HITS: {
                            SUITABLE_SINGLE: 'suitable training',
                            SUITABLE_MULTIPLE: 'suitable trainings',
                            TRAINING_SINGLE: 'training',
                            TRAINING_MULTIPLE: 'trainings',
                        },
                        BANNER_TITLE: 'Trainings around packaging',
                        BANNER_BETA:
                            'Welcome to the beta version of our brand new Education App! Please note that the app is still in the beta phase, which means that it is still under development and improvement.',
                        BANNER_DESCRIPTION:
                            'Would you like to educate more about the extensive field of packaging in a competent and easy way? Then use our training app to find the course that best suits you from a wide range of educational offers.',
                        BANNER_VARIANTS: 'Continuing Education | Training | Online Courses',
                        SEARCH_PLACEHOLDER: 'Search your suitable training',
                        FILTERS: {
                            ON_DEMAND: 'On demand',
                            PRICE_ON_DEMAND: 'Price on demand',
                            ALL: 'All',
                            CLEAR_ALL: 'Clear all',
                            APPLY_FILTER: 'Apply filter',
                            UNDER: 'Under',
                            OVER: 'Over',
                            FILTER: 'Filter',
                            PROVIDER: 'Provider',
                            PERIOD: 'Period',
                            DURATION: 'Duration',
                            COSTS: 'Costs',
                            LOCATION: 'Location',
                            CONTENT: 'Content',
                            NOTES: 'Notes',
                            GOAL: 'Goal',
                            TARGET_GROUP: 'Target Group',
                            ELECTRICS: 'Electrics',
                            MECHANICS: 'Mechanics',
                            MONTHS: {
                                0: 'January',
                                1: 'February',
                                2: 'March',
                                3: 'April',
                                4: 'May',
                                5: 'June',
                                6: 'July',
                                7: 'August',
                                8: 'September',
                                9: 'October',
                                10: 'November',
                                11: 'December',
                            },
                        },
                        MORE_COURSES: 'More courses of',
                        ACTIONS: {
                            TO_THE_COURSE: 'To the course',
                        },
                    },
                    SERVICE_STORE: {
                        TOP_SERVICES: 'TOP-Services of the industry',
                        ADVERTISEMENT: 'Advertisement',
                        SEARCH_SERVICES: 'Search services',
                        TO_THE_COMPANY: 'To the company',
                        DOCUMENTS_AVAILABLE: 'documents available',
                        DOCUMENT_AVAILABLE: 'document available',
                        GO_TO_SERVICE: 'Go to service',
                        GET_IN_CONTACT: 'Get in contact',
                        RECOMMENDED: 'Recommended',
                        MORE_INFOS: 'More Infos',
                        DISCOVER: 'Discover',
                    },
                    COCKPIT: {
                        MY_PLANTS: 'My Plants',
                        MY_APPS: {
                            TITLE: 'Tools',
                            MOST_USED: 'FREQUENTLY USED',
                            MORE_APPS: 'MORE',
                            PARTNER: 'Partner',
                            PACKAGES: 'Products',
                            AVAILABLE: 'Available for',
                            NOT_AVAILABLE: 'Not available',
                            TOOLS: 'Tools',
                            DATA: 'Data',
                            CONTACT: 'Contact',
                            DIGITAL_FACTORY: {
                                LIVE_VIEW: 'Live View',
                                CONDITION_MONITORING: 'Condition Monitoring',
                                DOCUMENTATION: 'Documentation',
                                DASHBOARD: 'Dashboard',
                                PRODUCTION_DATA: 'Production data',
                            },
                            RESOURCE_CONTROL: {
                                LIVE_VIEW: 'Live View',
                                CONDITION_MONITORING: 'Condition Monitoring',
                                DOCUMENTATION: 'Documentation',
                                DASHBOARD: 'Dashboard',
                                RESOURCE_MANAGER: 'Resource Manager',
                                RESOURCE_DATA: 'Resource data',
                            },
                            PROCUREMENT: {
                                MARKETPLACE: 'Marketplace',
                                REPORTING: 'Reporting',
                                OCI_EDI: 'OCI/EDI',
                                SUPP_MANAGEMENT: 'Suppliermanagement',
                                ORDER_DATA: 'Order data',
                                SHOPPING_BEHAVIOUR: 'Purchasing behaviour',
                                COMPANY_CATEGORIZATION: 'Company-specific product categorisation',
                            },
                        },
                        RECOMMENDED_SERVICES: {
                            TITLE: 'Recommended Services',
                            LEARN_MORE: 'Learn More',
                            CONTACT: 'Contact Us',
                        },
                        INVITED_BY: 'Invited by',
                        PLANTS: {
                            INTEREST: 'Global facts.',
                            IN_A_NUTSHELL: 'In a nutshell.',
                            CONNECTED_MACHINE: 'Connected machine',
                            CONNECTED_MACHINES: 'Connected machines',
                            PLANT: 'Plant',
                            PLANTS: 'Plants',
                            UPLOADED_DOCUMENTS: 'Uploaded documents',
                            UPLOADED_DOCUMENT: 'Uploaded document',
                            PRODUCED_SQUAREMETERS: 'Produced squaremeters',
                        },
                        NEWS: {
                            WORTH_KNOWING: 'Worth knowing at a glance.',
                            TO_WEBSITE: 'Go to Website',
                            LAST_UPDATED: 'Last updated',
                            NEWS: {
                                MORE_NEWS: 'Interested in more news?',
                                REDIRECT: 'Continue to the news page',
                                HEADER: 'Latest news from the industry',
                            },
                        },
                        WEATHER: { FEELS_LIKE: 'Feels like' },
                        STOCKS: { MORE_INFORMATION: 'More information', STOCKS: 'Stocks' },
                        FEFCO_BANNER: {
                            DATE: 'October 20 - 22, 2021',
                            DAYS: 'Days',
                            HOURS: 'Hours',
                            MINUTES: 'Minutes',
                            SECONDS: 'Seconds',
                        },
                        VISUALIZATION: {
                            DATA_SOURCE: 'Data source',
                            DATA_USE: 'Data usage',
                            COLLECT: 'Connect',
                            STANDARDIZE: 'Standardise',
                            SHARE: 'Share',
                            RECORDS_MIN: 'Data sets/min',
                            CURRENT_DATA_EXCHANGE: 'Current data exchange',
                            TOTAL_DATA_PROCESSED: 'Total data processed',
                            VARIABLE_USED: 'Used variables',
                            FROM_HUB: 'From Hub',
                            TO_HUB: 'To Hub',
                            DATA_TOTAL: 'Total data',
                            NOT_AVAILABLE: 'n.a.',
                        },
                        EMPTY_STATE_HEADER: "Oops, it seems there's nothing to display.",
                        EMPTY_STATE_TEXT1: 'We apologize, there is no information available currently.',
                        EMPTY_STATE_TEXT2: 'Please check back later.',
                    },
                    CALENDAR: {
                        JANUARY: 'January',
                        FEBRUARY: 'February',
                        MARCH: 'March',
                        APRIL: 'April',
                        MAY: 'May',
                        JUNE: 'June',
                        JULY: 'July',
                        AUGUST: 'August',
                        SEPTEMBER: 'September',
                        OCTOBER: 'October',
                        NOVEMBER: 'November',
                        DECEMBER: 'December',
                        MO: 'Mo',
                        TU: 'Tu',
                        WE: 'We',
                        TH: 'Th',
                        FR: 'Fr',
                        SA: 'Sa',
                        SU: 'Su',
                        APPLY: 'Apply',
                        CANCEL: 'Cancel',
                        FROM: 'From',
                        TO: 'To',
                        TIME_ERROR: 'Please check the selected timeframe!',
                        SHIFT_ERROR: 'Choose at least one shift',
                        END_DATE_ERROR: 'Please select an end date.',
                    },
                    GREETING: {
                        GOOD_MORNING: 'Good Morning',
                        GOOD_DAY: 'Good Morning',
                        GOOD_AFTERNOON: 'Good Afternoon',
                        GOOD_EVENING: 'Good Evening',
                    },
                    WORKFLOW_AREA: {
                        OUTPUT: 'Output',
                        STORAGE: 'Storage',
                        AVERAGE_SPEED: 'Ø-Speed',
                        PAPER_STORAGE: 'Paper Storage',
                        INTERMEDIATE_STORAGE: 'Intermediate Storage',
                        WAREHOUSE: 'Warehouse',
                        PRODUCTION: 'Production',
                        STOP: 'Stop',
                        SETUP: 'Setup',
                        IDLENESS: 'Idleness',
                        NOT_AVAILABLE: 'n.a.',
                        RUN_DOWN_TIME: 'Run- and Downtime',
                        SINCE: 'since',
                        EARLY_SHIFT: 'Early shift',
                        LATE_SHIFT: 'Late shift',
                        NIGHT_SHIFT: 'Night shift',
                    },
                    ASSET_DATA: {
                        OTHER_TYPE: 'Other',
                        CORRUGATOR: {
                            SPLICER: 'Splicer',
                            REEL_STAND: 'Reel Stand',
                            SINGLE_FACER: 'Single Facer',
                            ADVANCE_FACER: 'Advance Facer',
                            QUICK_FACER: 'Quick Facer',
                            BRIDGE: 'Bridge',
                            PRE_HEATER: 'Preheater',
                            GLUE_UNIT: 'Glue Unit',
                            DOUBLE_FACER: 'Double Facer',
                            ROTARY_SHEAR: 'Rotary Shear',
                            SLITTER_SCORER: 'Slitter Scorer',
                            CUTOFF: 'Cutoff',
                            STACKER: 'Stacker',
                            AUTOMATION_MODULES: 'Automation Modules',
                            STEAM_SYSTEM: 'Steam System',
                        },
                    },
                    ASSET_DETAILS: {
                        MACHINE_TYPE: 'Type',
                        MANUFACTURER: 'Manufacturer',
                        SERIAL_NUMBER: 'Serial number',
                        YEAR_OF_MANUFACTURE: 'Year of manufacture',
                        ERROR_ADD: 'Error while adding Asset',
                        ERROR_EDIT: 'Error while editing Asset',
                        UPLOADFILE: 'Upload',
                        FILES: 'Files',
                        FILE: 'File',
                        DISPLAY_NAME: 'Name',
                        DOCUMENTS: 'Files',
                        PRODUCTION_YEAR: 'Year of manufacture',
                        YEAR_OF_COMMISSIONING: 'Year of commissioning',
                        PERIOD_OF_USE: 'Period of use',
                        COST_CENTER: 'Cost center',
                        AREA: 'Area',
                        BASIC_INFORMATION: 'Basic information',
                        CAPACITY_INFORMATION: 'Capacity information',
                        MACHINE_INFORMATION: 'Machine information',
                        INSTALLATION_INFORMATION: 'Installation information',
                        DIMENSIONS: 'Dimensions',
                        LENGTH: 'Length',
                        WIDTH: 'Width',
                        HEIGHT: 'Height',
                        ENERGY_SOURCE: 'Energy source',
                        POWER_CONNECTIONS: 'Power connections',
                        GAS_CONNECTION: 'Gas connection',
                        SENSORS: 'Sensors',
                        SERIES: 'Series (model)',
                        TYPE: 'Type',
                        WORKING_WIDTH: 'Working width',
                        MAX_PRODUCTION_SPEED: 'Max. speed',
                        SHEETS_H: 'Sheet/h',
                        FLUTE_TYPE: 'Flute type',
                        SHEET_HEIGHT: 'Sheet height',
                        SHEET_LENGTH: 'Sheet length',
                        SHEET_WIDTH: 'Sheet width',
                    },
                    ASSET_ADD_EDIT_MODAL: {
                        TYPE_WARNING_MODAL: {
                            CONFIRMATION_BUTTON: 'Next',
                            HEADLINE: 'Impaired display in Live',
                            CONFIRMATION_TEXT1:
                                'You are about to remove the type of the asset. As a result, no data for this asset can be displayed in Live.',
                            CONFIRMATION_TEXT2: 'Do you still want to continue?',
                        },
                        TYPE_SELECTION_INFO: 'The information displayed in Live is based on the selection of the type.',
                        SERIES: 'Series (model)',
                        HEADLINE_ADD: 'Add Asset',
                        HEADLINE_EDIT: 'Edit Asset',
                        ADD_BUTTON: 'Add Asset',
                        EDIT_BUTTON: 'Edit Asset',
                        ADD_SUCCESS: 'Asset successfully created',
                        EDIT_SUCCESS: 'Asset successfully edited',
                        BASIC_INFORMATION: 'Basic information',
                        TYPE_INFORMATION: 'Type',
                        MANUFACTURER_INFORMATION: 'Manufacturer information',
                        DIMENSIONS: 'Dimensions',
                        DISPLAY_NAME: 'Display name',
                        PICTURE: 'Picture',
                        TYPE: 'Type',
                        FLUTE_TYPE: 'Flute type',
                        SHEET_HEIGHT: 'Sheet height',
                        SHEET_LENGTH: 'Sheet length',
                        SHEET_WIDTH: 'Sheet width',
                        AREA: 'Area',
                        ADD: 'Add',
                        PRODUCTION_YEAR: 'Year of manufacture',
                        YEAR_OF_COMMISSIONING: 'Year of commissioning',
                        MANUFACTURER: 'Manufacturer',
                        PERIOD_OF_USE: 'Period of use',
                        COST_CENTER: 'Cost center',
                        SERIAL_NUMBER: 'Serial number',
                        LENGTH: 'Length',
                        WIDTH: 'Width',
                        HEIGHT: 'Height',
                        CORRUGATOR: 'Corrugator',
                        GLUE_KITCHEN: 'Glue Kitchen',
                        STACKER: 'Stacker',
                        REEL_STAND: 'Reel Stand',
                        PREHEATER: 'Preheater',
                        DOUBLE_FACER: 'Double Facer',
                        LAMINATING_MACHINE: 'Laminating machine',
                        ROTARY_SHEAR: 'Rotary Shear',
                        CUTOFF: 'Cutoff',
                        RIM_VENTILATION: 'Rim Ventilation',
                        SLITTER_SCORER: 'Slitter Scorer',
                        SHREDDER: 'Shredder',
                        STEAM_BOILER: 'Steam Boiler',
                        TOTAL_PLANT: 'Total Plant',
                        COMPRESSOR: 'Compressor',
                        PHOTOVOLTAIC_SYSTEM: 'Photovoltaic system',
                        LIGHTS: 'Lights',
                        BOARD_DIMENSION_CONTROL: 'Board Dimension Control',
                        HYPERBLADE: 'Hyperblade',
                        ZERO_DEFECT_SYSTEM: 'Zero Defect System',
                        SPLICER: 'Splicer',
                        SINGLE_FACER: 'Single Facer',
                        STEAM_SYSTEM: 'Steam System',
                        GLUE_UNIT: 'Glue Unit',
                        BRIDGE: 'Bridge',
                        WORKING_WIDTH: 'Working width',
                        MAX_PRODUCTION_SPEED: 'Max. speed',
                        CONVERSION: 'Conversion',
                        INLINER: 'Flexo Folder Gluer',
                        ROTARY_DIE_CUTTER: 'Rotary die cutter',
                        FLATBED_DIE_CUTTER: 'Flatbed die cutter',
                        FOLDER_GLUER: 'Folder-gluer',
                        FEEDER: 'Feeder',
                        PALLETIZER: 'Palletizer',
                        BUNDLING_MACHINE: 'Bundling machine',
                        DIE_CUTTER: 'Die cutter',
                        GLUER: 'Gluer',
                        GLUING_UNIT: 'Gluing unit',
                        WINDER: 'Winder',
                        PRINTING_UNIT: 'Printing unit',
                        FOLDER: 'Folder',
                        SLOTTER: 'Slotter',
                        TAPER: 'Taper',
                        INSERTER: 'Inserter',
                        COUNTER: 'Counter',
                        ERROR_MESSAGES: {
                            ENTER_DISPLAY_NAME: 'Please enter a display name.',
                        },
                        STORAGE: 'Storage',
                        PAPER_STORAGE: 'Paper storage',
                        INTERMEDIATE_STORAGE: 'Intermediate storage',
                        FINISHED_GOODS_WAREHOUSE: 'Finished goods warehouse',
                        BALE_STORAGE: 'Bale storage',
                        CLICHE_STORAGE: 'Cliché storage',
                        TOOL_STORAGE: 'Tool storage',
                        INK_STORAGE: 'Ink storage',
                        SPARE_PARTS_STORAGE: 'Spare parts storage',
                        MAXIMUM_CAPACITY: 'Maximum capacity',
                        ROLLS: 'Rolls',
                        PALLETS: 'Pallets',
                        BALES: 'Bales',
                        TRANSPORTATION_SYSTEM: 'Transportation system',
                        SHUTTLE: 'Shuttle',
                        CONVEYOR: 'Conveyor',
                        TRANSFER_TROLLEY: 'Transfer trolley',
                        TRANSPORTATION_STACKER: 'Stacker',
                        TRANSPORTATION: 'Transportation',
                        OTHERS: 'Others',
                        BALING_PRESS: 'Baling press',
                        WATER_TREATMENT: 'Water treatment',
                        PALLETIZING_CENTER: 'Palletizing center',
                        PAINT_MIXING_SYSTEM: 'Paint mixing system',
                        WEBTROL: 'Webtrol',
                        CREATE: 'Create',
                    },
                    DELETE_ASSET: {
                        HEADLINE: 'Delete Asset?',
                        SUCCESS: 'Asset successfully deleted',
                        TEXT: 'Do you really want to delete the machine and all related information and files?',
                        DELETE: 'Delete',
                        DELETE_TOOLTIP: 'Delete Asset',
                        CANCEL: 'Cancel',
                        SERIAL_NUMBER: 'Serialnr.',
                        CONFIRMATION_BUTTON: 'Delete Asset',
                    },
                    DATA: {
                        QUANTITY: {
                            HEADLINE: 'Quantity',
                            PRODUCED: 'Produced Quantity',
                            ORDER: 'Order Quantity',
                        },
                        ENERGY: {
                            HEADLINE: 'Energy',
                            OVERALL_CONSUMPTION: 'Overall Consumption',
                            UNIT: 'kWh',
                        },
                        SPEED: {
                            HEADLINE: 'Speed',
                            CURRENT_SPEED: 'Current Speed',
                            AVG_SPEED: 'Ø-Speed',
                            TARGET_SPEED: 'Target Speed',
                            UNIT: 'Sheets/h',
                        },
                        PLANNED_VS_PRODUCED_QUANTITY_PER_HOUR: {
                            HEADLINE: 'Planned vs. produced quantity',
                            HOUR: 'Hour',
                            STOP_TIME: 'Stop time',
                            SETUP_TIME: 'Setup time',
                            PLANNED_QUANTITY: 'planned quantity',
                            PRODUCED_QUANTITY: 'produced quantity',
                            DELTA: 'Delta',
                            PLANNED: 'Planned',
                            PRODUCED: 'Produced',
                            STOPS: 'Stops',
                            STOP: 'Stop',
                            SETUPS: 'Setups',
                            SETUP: 'Setup',
                        },
                        CURRENT_ORDER: {
                            ORDER: 'Order',
                            TRIM: 'Trim',
                            PRODUCED: 'Produced',
                            PREDICTED: 'Predicted',
                            START: 'Start',
                            FINISH: 'Finish',
                            HEADLINE: 'Current Order',
                            CUSTOMER: 'Customer',
                            ORDER_NO: 'Order No.',
                            PRODUCTION_RUN: 'Production Run',
                            PLANNED_START: 'Planned Start',
                            ACTUAL_START: 'Actual Start',
                            ORDERS: 'Orders',
                            PLANNED_QUANTITY: 'Planned Quantity',
                            PLANNED_QUANTITY_CONV: 'Planned Quantity [boxes]',
                            PLANNED_QUANTITY_CORR: 'Planned Quantity [m]',
                            PROGRESS: 'Progress',
                            ACTUAL_SPEED: 'Current Speed',
                            COMPLETED: 'Completed',
                            NOT_YET_STARTED: 'Not yet started',
                            GRADE: 'Grade',
                            REST_RUNNING_METER: 'Remaining',
                            NO: 'No.',
                            PLANNED: 'Planned',
                            PRODUCED_ORDERS: 'Produced',
                            SHEETS: 'Sheets',
                        },
                        STATUS: {
                            PRODUCTION: 'Production',
                            SETUP: 'Setup',
                            IDLESNESS: 'Stop',
                            FAULT: 'Error',
                            RUN_STOP: 'Runtime / Downtime',
                            TIME: 'Time',
                            REASON: 'Stop reason',
                            STOPS: 'Stops',
                            DURATION: 'Duration',
                        },
                        GOODS_WAREHOUSE: {
                            FLUTE_TYPES: 'Stored flutes',
                            UTILIZATION: 'Utilization',
                            UTILIZATION_GRAPH: 'Utilization (last 24 hours)',
                            SIMULATED_UTILIZATION: 'Simulated utilization',
                            ACCUMULATION: 'Storage accumulation',
                            OCCUPIED: 'Occupied',
                            PALLETS: '[Pallets]',
                            BALES: '[Bales]',
                            MAX_CAPACITY: 'Max. Capacity',
                            NOT_AVAILABLE: 'n.a.',
                            LAST_24_HOURS: 'Last 24 hours',
                            CURRENT_STORED_ORDERS: 'Currently stored orders',
                            STORAGE: 'Storage',
                            CUSTOMER: 'Customer',
                            CUSTOMER_ARTICLE: 'Customer article',
                            ORDER_NUMBER: 'Order number',
                            NUMBER_OF_PALLETS: 'Number of pallets',
                            STORAGE_DURATION: 'Storage duration',
                            SMALL: 'narrow',
                            MEDIUM: 'medium',
                            WIDE: 'wide',
                            TOTAL: 'total',
                            ERROR_MESSAGE_CRITICAL_STACK_1: 'critical stacks forecasted.',
                            ERROR_MESSAGE_CRITICAL_STACK_2: 'There is no suitable lane for the stacks.',
                            ERROR_MESSAGE_CRITICAL_STACK_1_SINGLE: 'critical stack forecasted.',
                            ERROR_MESSAGE_CRITICAL_STACK_2_SINGLE: 'There is no suitable lane for the stack.',
                            ERROR_MESSAGE_CRITICAL_STACK_3: 'For details, see the "Forecasted utilization" diagram.',
                            FORECASTED_UTILIZATION: 'Forecasted capacity utilization',
                            CRITICAL_STACK: 'Critical stack',
                        },
                        BALE_STORAGE: { NUMBER_OF_BALES: 'Number of bales' },
                        WASTE_PRESS: { PASSAGE: 'Passage' },
                        MEAN_TIMES: { MTTR: 'Mean time to recover', MTBF: 'Mean time between failures' },
                    },
                    CONFIGURATION: {
                        ADD_SUB_ASSET: 'Add child asset',
                        ADD_ASSET: 'Add asset',
                        EDIT_ASSET: 'Save',
                        CHOOSE_AREA: 'Please select an area!',
                        CHOOSE_ASSET: 'Please select an asset.',
                        COST_CENTER: 'Cost Center',
                        EDIT: 'Edit',
                        OR: 'OR',
                        TO: 'to',
                        DELETE_ASSET: 'Delete asset',
                        SHOW_INFOS: 'Show infos',
                        SHOW_ASSETS: 'Show assets',
                    },
                    DOCUMENTATION: {
                        QUICK_ACCESS: 'Quick Access',
                        FAVORITE: 'Favorites',
                        DESCRIPTION: 'If you mark content as favorites, it will be displayed here.',
                        HEADER: 'Choose an asset and start immediately.',
                        TEXT1: 'As soon as you have worked with the first documents,',
                        TEXT2: ' the last used files will be displayed here.',
                        SHOW_DOCUMENTS: 'Show documents',
                        SHOW_ASSETS: 'Show assets',
                        NO_SUBORDINATE_ASSETS: 'This asset has no subordinate assets.',
                        UPLOAD_DOCUMENTS: 'Upload documents',
                        CREATE_FOLDER: 'Create folder',
                        NO_ROOT_ASSETS: 'No assets available',
                    },
                    LINE_DOCUMENTS: {
                        DOCUMENTS: 'Documents',
                        PARTS: 'Parts',
                        INFOS: 'Infos',
                        NAME: 'Name',
                    },
                    AREA_TYPES: {
                        VALUES: {
                            STORAGE: 'Storage',
                            CORRUGATOR: 'Corrugator',
                            STARCHKITCHEN: 'Starch kitchen',
                            INTRALOGISTICS: 'Intralogistics',
                            CONVERSION: 'Conversion',
                            INOUTBOUND_LOGISTICS: 'In- / Outbound logistics',
                            INTERMEDIATE_STORAGE: 'Intermediate Storage',
                            PAPER_STORAGE: 'Paper Storage',
                            LOGISTICS: 'Logistics',
                            FINISHED_GOODS_WAREHOUSE: 'Finished Goods Warehouse',
                            WASTE_MANAGEMENT: 'Waste management',
                            OTHERS: 'Others',
                        },
                    },
                    LINE_DETAILS: {
                        NAME: 'Name',
                        ASSET: 'Asset',
                        ASSETS: 'Assets',
                        MACHINES: 'Machines',
                        DOCUMENTS: 'Files',
                        DOCUMENT: 'File',
                        FILES: 'Files',
                        FILE: 'File',
                        FOLDER: 'Create Folder',
                        LINE: 'Line',
                        TYPE: 'Type',
                        DISPLAY_NAME: 'Name',
                        COST_CENTRE: 'Cost centre',
                        NUMBER_OF_ASSETS: 'Number of Assets',
                        NUMBER_OF_OEMS: 'Number of OEMs',
                        ADD_ASSET: 'Add Asset',
                        ADD_FILE: 'Upload',
                        ERROR_ADD: 'Error while adding Line',
                        ERROR_EDIT: 'Error while editing Line',
                        MANUFACTURER: 'Manufacturer',
                        MACHINE_TYPE: 'Machine type',
                        YEAR_OF_MANUFACTURE: 'Year of Manufacture',
                        SERIAL_NUMBER: 'Serial number',
                        FILTER_IN_ASSETS: 'Browse Assets',
                        FILTER_IN_FILES: 'Browse files',
                        DETAILS_IN: 'Show details',
                        DETAILS_OUT: 'Hide details',
                    },
                    LINE_ADD_EDIT_MODAL: {
                        HEADLINE_EDIT: 'Edit Line',
                        HEADLINE_ADD: 'Add Line',
                        ADD_BUTTON: 'Add Line',
                        EDIT_BUTTON: 'Edit Line',
                        SUCCESS_ADD: 'Line successfully created',
                        SUCCESS_EDIT: 'Line successfully edited',
                    },
                    LINE_DELETE: {
                        HEADLINE: 'Delete Line',
                        TEXT: 'Do you really want to delete the line and all related machines and files?',
                        LINE: 'Line',
                        CONFIRMATION_BUTTON: 'Delete Line',
                    },
                    DELETE_LINE_ASSET: {
                        HEADLINE_LINE: 'Sure you want to delete the line?',
                        HEADLINE_ASSET: 'Sure you want to delete the asset?',
                        CONFIRMATION_TEXT:
                            'Are sure you want to delete this Asset? All other Assets attached to this Asset will be deleted as well!',
                        DELETE: 'DELETE',
                    },
                    NAVIGATION_TREE: {
                        PAPERSTORAGE: 'Paper Storage',
                        CORRUGATOR: 'Corrugator',
                        INTERMEDIATE_STORAGE: 'Intermediate Storage',
                        CONVERSION: 'Conversion',
                        PALLETIZER: 'Palletizer',
                        LOGISTICS: 'Logistics',
                        FINISHED_GOODS_WAREHOUSE: 'Finished goods warehouse',
                        CHOOSE_PLANT: 'Please choose an existing plant in the map!',
                        ADD_SUB_ASSET: 'Add child asset',
                    },
                    LIVE: {
                        ACTIVE: 'Active',
                        CONSUMPTION_TODAY: 'Consumption (today)',
                        CONSUMPTION_CURRENT_SHIFT: 'Consumption (current shift)',
                        CURRENT_POWER: 'Current power',
                        CURRENT: 'Current',
                        ELECTRICITY: 'Electricity',
                        GAS: 'Gas',
                        COMPRESSED_AIR: 'Compressed Air',
                        WATER: 'Water',
                        STEAM: 'Steam',
                        CONSUMPTION: 'Consumption',
                        SHEETS: 'Sheets',
                        POWER_CONSUMPTION_TODAY: 'Power consumption (today)',
                        POWER_CONSUMPTION_CURRENT_SHIFT: 'Power consumption (current shift)',
                        ELECTRICAL_POWER: 'Electrical power',
                        MISSING_TYPE: 'Data cannot be displayed.',
                        MISSING_TYPE_TEXT: 'Please make sure that the correct type is stored in the configuration.',
                        NOT_CONNECTED_HEADING: 'Machines not yet connected',
                        NOT_CONNECTED: 'Please contact openpack to take advantage of Live.',
                        NO_SVG_HEADING: 'Plant not yet connected',
                        NO_SVG: 'Please contact openpack to connect your plant',
                        NOT_CONNECTED_TOOLTIP: 'This asset is not yet connected!',
                        NOT_CONNECTED_TOOLTIP_WAREHOUSE: 'This warehouse is not yet connected!',
                        LAST_SHIFT: 'Last shift',
                        CURRENT_SHIFT: 'Current Shift',
                        BACK: 'Back',
                        DOCUMENTS: 'Documents',
                        ALARM_OVERVIEW: 'Alarm overview',
                        NO_DATA_AVAILABLE: 'Currently no data is available.',
                        STATUS: {
                            PRODUCTION: 'Production',
                            SETUP: 'Setup',
                            IDLENESS: 'Idleness',
                            STOP: 'Stop',
                        },
                        OPEN_DETAILS: 'Open Details',
                        PLANT_VIEW: 'Plant view',
                        LIST_VIEW: 'List view',
                        UPGRADE_RESOURCE_CONTROL: 'Upgrade Resource Control',
                        UPGRADE_MAINTENANCE: 'Upgrade Maintenance Solution',
                        TOTAL: 'Total',
                        ELECTRICITY_CONSUMPTION: 'Electricity | Consumption',
                        ELECTRICITY_CONSUMPTION_TODAY: 'Electricity | Consumption (today)',
                        ELECTRICITY_CONSUMPTION_CURRENT_SHIFT: 'Electricity | Consumption (current shift)',
                        GAS_CONSUMPTION: 'Gas | Consumption',
                        GAS_CONSUMPTION_TODAY: 'Gas | Consumption (today)',
                        COMPRESSED_AIR_CONSUMPTION: 'Compressed Air | Consumption',
                        COMPRESSED_AIR_CONSUMPTION_TODAY: 'Compressed Air | Consumption (today)',
                        COMPRESSED_AIR_CONSUMPTION_CURRENT_SHIFT: 'Compressed Air | Consumption (current shift)',
                        WATER_CONSUMPTION: 'Water | Consumption',
                        WATER_CONSUMPTION_TODAY: 'Water | Consumption (today)',
                        WATER_CONSUMPTION_CURRENT_SHIFT: 'Water | Consumption (current shift)',
                        STEAM_CONSUMPTION: 'Steam | Consumption',
                        STEAM_CONSUMPTION_TODAY: 'Steam | Consumption (today)',
                        STEAM_CONSUMPTION_CURRENT_SHIFT: 'Steam | Consumption (current shift)',
                        PRO: 'Per',
                        SHOW_CONSUMPTION_DETAIL: 'Show consumption details',
                        HIDE_CONSUMPTION_DETAIL: 'Hide consumption details',
                        OPEN_MAINTENANCE: 'Open Maintenance',
                        OPEN_INCIDENTS: 'Open Incidents',
                        MAINTENANCE_TASKS: 'Maintenance Tasks',
                        INCIDENTS_TASKS: 'Incidents Tasks',
                        NO_OPEN_MAINTENANCE: 'Currently no open maintenance tasks',
                        NO_OPEN_INCIDENTS: 'Currently no open incidents',
                        NO_INCIDENTS: 'No incidents',
                        IN_PROGRESS: 'In progress',
                        OPEN_INCIDENT: 'Open incident',
                        TABLE: {
                            PRIO: 'Prio',
                            TITLE: 'Title',
                            DESCRIPTION: 'Description',
                            AREA: 'Area',
                            OCCURRED_AT: 'Occurred at',
                            DUE_ON: 'Due on',
                            STATUS: 'Status',
                            CHART_M_TITLE_1: 'Historical Statistics',
                            CHART_M_TITLE_2: 'Current Statistics',
                            CHART_I_TITLE_1: 'Number of closed incidents',
                            CHART_I_TITLE_2: 'Incidents',
                            CHARTS: {
                                COMPLETED_M: 'Completed maintenance tasks',
                                DELAYED_M: 'Delayed maintenance tasks',
                                PENDING_M: 'Pending maintenance tasks',
                                FILTER_YESTERDAY: 'Yesterday',
                                FILTER_LAST_WEEK: 'Last week',
                                FILTER_TODAY: 'Today',
                                FILTER_CURRENT_WEEK: 'Current week',
                                CW: 'CW',
                                NUMBER: 'Number',
                            },
                            HIGH: 'HIGH',
                            MEDIUM: 'MEDIUM',
                            LOW: 'LOW',
                            PENDING: 'PENDING',
                            DELAYED: 'DELAYED',
                            COMPLETED: 'COMPLETED',
                            OPEN: 'OPEN',
                            NEW: 'NEW',
                            ACCOMPLISHED: 'ACCOMPLISHED',
                            FAILED: 'FAILED',
                            RESET: 'RESET',
                            REJECTED: 'REJECTED',
                            IN_WORK: 'IN WORK',
                            CLOSED: 'CLOSED',
                            ELECTRICS: 'Electrics',
                            MECHANICS: 'Mechanics',
                        },

                        FURTHER: 'further',
                    },
                    MAIL_DETAILS: {
                        FROM: 'From',
                        TO: 'To',
                        DATE: 'Date',
                        TO_EMAIL: 'To the email',
                    },
                    GLOBAL_ERROR: {
                        SOMETHING_WENT_WRONG: 'Something must have gone wrong here',
                        BACK: 'Back',
                        OR: 'or',
                        BACK_TO_HOME: 'Back to the map',
                    },
                    TUTORIAL: {
                        STEP: 'Step {{step}} of 4',
                        BACK: 'Back',
                        FORWARD: 'Next',
                        FIRST: {
                            HEADLINE: `Let's go`,
                            TEXT: 'Nice to have you on board. Here you get a short insight into the most important functions of the cockpit.',
                            LATER: 'Later',
                            BUTTON: 'Start guide',
                        },
                        SECOND: {
                            HEADLINE: `Your Apps`,
                            TEXT: 'With the openpack apps you can manage and monitor your plants, read the latest news from the corrugated board industry and much more.',
                        },
                        THIRD: {
                            HEADLINE: 'Our strong partners',
                            TEXT: 'In the lower part of the cockpit you will find our partners. They offer you a wide range of services around your corrugated board factory.',
                        },
                        FOURTH: {
                            HEADLINE: 'Your profile and your plants',
                            TEXT: 'In the upper right area you can edit your profile and create and manage all your plants.',
                        },
                        FIFTH: {
                            HEADLINE: 'Get started now',
                            TEXT: 'You are currently not a member of a plant. Either create a new plant now or contact a colleague and let him add you as a member.',
                            BUTTON: 'Create plant',
                            GET_ADDED: 'I will be added',
                        },
                        SIXTH: {
                            HEADLINE: 'Get started now',
                            TEXT: 'You are already a member of a plant and can start immediately.',
                            BUTTON: 'Start now',
                        },
                    },
                    NO_PLANTS_MODAL: {
                        HEADLINE: 'You are not a member of a plant',
                        TEXT: 'Get invited',
                        OR: 'or',
                        BUTTON: 'Create your plant',
                    },
                    SEARCH: {
                        SEARCH: 'Search',
                        SORRY_INCONVENIENCE: 'Sorry for your inconvenience',
                        MARKETPLACE_NOT_AVAILABLE: 'Marketplace search is not available at the moment',
                        HEADLINE: 'Search results',
                        RESULTS: ' results for ',
                        RESULT: ' result for ',
                        PLACEHOLDER: 'Search documents, machines, spare parts etc.',
                        BACK: 'Back',
                        ERROR: 'For a better search result please use at least two characters.',
                        ATTACHMENTS: 'attachments',
                        ATTACHMENT: 'attachment',
                        FROM: 'From',
                        TO: 'To',
                        SHOW_MORE: 'Show details',
                        SHOW_LESS: 'Hide details',
                        FROM_SUPPLIER: 'From: ',
                        PREVIOUS: 'Previous',
                        NEXT: 'Next',
                        SHOW_ALL: 'Show all',
                        SHOW_ALL_DOCS: 'Show all documents',
                        SHOW_ALL_PRODUCTS: 'Show all products',
                        SHOW_ALL_MACHINES: 'Show all machines',
                        SHOW_ALL_NEWS: 'Show all news',
                        SHOW_ALL_SERVICES: 'Show all services',
                        NO_RESULTS_HEADER: 'No results found',
                        NO_RESULTS_TEXT: 'Try adjusting your search or filter to find what you are looking for.',
                        SHOW_FILTER: 'Show filter',
                        RESET_ALL: 'Reset all',
                        FILTER: 'Filter',
                        ALL_PLANTS: 'All plants',
                        ALL_PERIODS: 'All periods',
                        ALL_FORMATS: 'All formats',
                        ALL_SOURCES: 'All sources',
                        ANY_LANGUAGE: 'Any language',
                        ALL_COMPANIES: 'All companies',
                        ALL_MARKETPLACES: 'All marketplaces',
                    },
                    SEARCH_TABS: {
                        TAB_ALL: 'All',
                        TAB_DOCUMENTS: 'Documents',
                        TAB_NEWS: 'News',
                        TAB_MACHINES: 'Machines',
                        TAB_SHOP: 'One-Stop-Shop',
                        BUTTON_SHOW_MORE: 'Show more',
                    },
                    SEARCH_BAR: {
                        DATE: 'Date',
                        LAST_TERM: 'Last search term',
                    },
                    SEARCH_ADVERTISMENT: {
                        ADVERTISMENT: 'ADVERTISMENT',
                        HEADER1: 'Your digital supplier',
                        HEADER2: 'for individual packaging',
                        TEXT1: 'Save time and money with our web-based',
                        TEXT2: 'purchasing process.',
                    },
                    SEARCH_FILTER_DOCUMENTS: {
                        PLANT: 'Plant',
                        UPLOAD_DATE: 'Upload period',
                        FORMAT: 'Format',
                    },
                    SEARCH_FILTER_SHOP: {
                        PRICE: 'Price',
                        MARKETPLACE: 'Marketplace',
                    },
                    SEARCH_FILTER_MACHINES: {
                        MANUFACTURER: 'Manufacturer',
                    },
                    SEARCH_FILTER_NEWS: {
                        PERIOD: 'Period',
                        SOURCE: 'Source',
                        LANGUAGE: 'Language',
                    },
                    SEARCH_FILTER_SERVICES: {
                        COMPANY: 'Company',
                    },
                    UPLOAD_PERIOD: {
                        LAST24: 'Last 24 hours',
                        LAST7: 'Last 7 days',
                        LAST30: 'Last 30 days',
                        LAST365: 'Last 365 days',
                    },
                    NOTIFICATIONS: {
                        INVITATION_TO: 'Invitation to:',
                        REPORT_TEXT_1: 'Your report',
                        REPORT_TEXT_2: 'is available now.',
                        SET_AS_READ: 'Set as read',
                        SET_AS_UNREAD: 'Set as unread',
                        DELETE_NOTIFICATION: 'Delete notification',
                        ONLY_SHOW_UNREAD: 'only show unread',
                        SHOW_ALL: 'Show all',
                        ZERO_HINTS: 'You have no notifications from the last 7 days',
                        LAST_7_DAYS_HINTS: "That's all your notifications from the last 7 days.",
                        NOTIFICATION_SETTINGS: 'Notification Settings',
                        NOTIFICATIONS: 'Notifications',
                        INVITE_NO_USER: 'invited you',
                        INVITATION_1: 'invited you to',
                        INVITATION_2: '',
                        NEWEST: 'NEWEST',
                        SET_ALL_READ: 'Set all as read',
                        TODAY: 'Today',
                        YESTERDAY: 'Yesterday',
                        UNKNOWN_USER: 'A user',
                        UNKNOWN_USER_COCKPIT: 'a user',
                        NO_NOTIFICATIONS_TITLE: 'No notifications available',
                        NO_NOTIFICATIONS_BODY:
                            'Once you have received the first notifications, they will be displayed here.',
                        DECLINED: 'Declined',
                        ACCEPTED: 'Accepted',
                    },
                    MAIN: {
                        MORE: 'more',
                        LESS: 'less',
                        START_NOW: 'Start now',
                        READ_NOW: 'Read now',
                        BROWSE_NOW: 'Browse now',
                        VIEW_NOW: 'View now',
                        WATCH_DEMO: 'Watch demo',
                        READY_TO_USE: 'Ready for use',
                        CATEGORIES: {
                            OUR_STRONG: 'Our strong',
                            PARTNER_NETWORK: 'partner network',
                        },
                        MY_COCKPIT: 'My Cockpit',
                        TO_PARTNER: 'To the partner',
                        SEND_MAIL: 'Send mail',
                        ASSET_MANAGER: {
                            DESCRIPTION: 'All relevant information about your machines on openpack',
                        },
                        NEWS: {
                            DESCRIPTION: 'The latest social network news of the corrugated industry',
                        },
                        MMM: {
                            DESCRIPTION: 'The live view of your production',
                        },
                        MARKETPLACE: {
                            DESCRIPTION: 'Your one-stop-shop around the corrugated industry',
                        },
                        RC: {
                            DESCRIPTION: 'Description is in progress',
                        },
                    },
                    FIRST_LOGIN_INITIALIZATION: {
                        FIRST: 'Your profile was successfully initialized.',
                        SECOND: 'You can now go back to the Asset Manager',
                        HEADLINE: 'Initialization done',
                    },
                    NOTFOUND: {
                        SUBHEADLINE: 'Something went wrong',
                        ONE: 'We can’t find the page you are looking for...',
                        OR: 'or',
                        BUTTON_BACK: 'Back',
                    },
                    USER_ADD_MODAL: {
                        HEADLINE: 'Add User(s)',
                        PLACEHOLDER_EMAIL: 'Email',
                        NOT_FOUND_MESSAGE: 'No User found. Please try again',
                        ADD_USERBUTTON: 'Add user(s)',
                        USER_ALREADY_IN_PLANT: 'User is already in the plant',
                    },
                    DELETE_USER_FROM_PLANT_MODAL: {
                        HEADLINE: 'Remove user from plant?',
                        TEXT: 'Are you sure to remove this user from the plant?',
                    },
                    FORM_VALIDATION: {
                        ONLY_NUMBER: 'Due to the variable selection, only a number is allowed as input.',
                        FILL_ERROR: 'Please fill all mandatory fields',
                        MAX_LENGTH: 'Not more than 255 characters are allowed',
                        REQUIRED_FIELD: 'Mandatory field',
                        CHOOSE_AN_OPTION: 'Choose an option',
                        NOT_VALID: 'Please do not use special characters',
                        NOT_UNIQUE: 'Name already exists, please choose another',
                        NOT_VALID_AND_NOT_UNIQUE:
                            'Name already exists, please choose another and use no special characters',
                        BETWEEN_ZERO_HUNDRED: 'The value must be between 0 and 100.',
                        CHOOSE_ASSET: 'Please select an asset.',
                    },
                    APP: {
                        CHECKING_EMAIL_VERIFICATION: 'Please verify your email before logging in',
                    },
                    HEADER: {
                        MARKETPLACE: 'Marketplace',
                        PLANTS: 'Plants',
                        PROFILE: 'Profile',
                        LOGOUT: 'Log out',
                        NOTIFICATIONS: 'Notifications',
                        CONFIGURATION: 'Configuration',
                    },
                    SIDEBAR: {
                        COCKPIT: 'Cockpit',
                        DIGITAL_FACTORY: 'Digital Factory',
                        LIVE: 'Live View',
                        DASHBOARDS: 'Dashboards',
                        RESOURCE_MANAGER: 'Resource Manager',
                        SERVICE_STORE: 'Service Store',
                        EDUCATION: 'Education',
                        NEWS: 'News',
                        FINANCE: 'Finance',
                        CONFIGURATION: 'Configuration',
                        MARKETPLACE: 'Marketplace',
                        FEEDBACK: 'Give feedback',
                        HELP: 'Help',
                        HELP_MENU: {
                            CONTACT: 'Contact',
                            IMPRINT: 'Imprint',
                            PRIVACY: 'Privacy',
                            TERMS_OF_USE: 'Terms of use',
                        },
                        DOCUMENTATION: 'Documentation',
                        NOTIFICATIONS: 'Notifications',
                        ACCOUNT: 'Account',
                    },
                    CHANGE_PICTURE_MODAL: {
                        HEADLINE: {
                            USER: 'Add profile picture',
                            PLANT: 'Add image',
                        },
                        CONFIRMATION_BUTTON: {
                            PLANT: 'Add image',
                            USER: 'Add profile picture',
                        },
                        SELECTFILE: 'Select file',
                        SIZE_EXCEEDED: 'File size exceeds {{size}} MB, please use smaller image',
                    },
                    DELETE_PICTURE_MODAL: {
                        HEADLINE: {
                            USER: 'Delete profile picture?',
                            PLANT: 'Delete image?',
                        },
                        CONFIRMATION_BUTTON: {
                            PLANT: 'Delete image',
                            USER: 'Delete profile picture',
                        },
                    },
                    PROFILE: {
                        CHANGE_PASSWORD: 'Change password',
                        MY_PROFILE: 'My Profile',
                        PASSWORD: 'Password',
                        NAME: 'Name',
                        CHANGE_NAME: 'Change name',
                        EMAIL: 'E-Mail',
                        LANGUAGE: 'Language',
                        SAVE_CHANGES: 'Save changes',
                        EDIT_MY_PROFILE: 'Edit my profile',
                        EMAIL_NOTIFICATIONS_HEADER: 'E-Mail Notifications',
                        EMAIL_NOTIFICATIONS_TEXT:
                            'Specify which additional functions you would like to be notified about by e-mail.',
                        EMAIL_SEVERITY_TEXT: 'Set at what severities you want to be notified by email',
                        INFORMATION: 'Information',
                        WARNING: 'Warning',
                        ERROR: 'Error',
                        MY_PLANTS: 'Plant invitations',
                        REPORTING: 'Reports',
                        CONDITION_MONITORING: 'Condition Monitoring',
                        HISTORIAN: 'Historian',
                    },
                    PLANTS: {
                        NAME: 'Name',
                        CREATE: {
                            NAME: 'Name',
                            TAXID: 'Tax ID',
                            COUNTRY: 'Country',
                            CITY: 'City',
                            HOUSENUMBER: 'House number',
                            STREET: 'Street',
                            POSTCODE: 'Postcode',
                            SUCCESS: 'Plant created successfully',
                            FAILURE: 'An error has occurred. Please try again.',
                            CONFIRMATION: 'By creating the plant, you confirm the correctness of your information.',
                        },
                        PROFILE: {
                            MEMBERS: 'Members',
                            INVITATIONS: 'Invitations',
                            SEND_INVITATION: 'Send invitation',
                            SEND_INVITATIONS: 'Send invitations',
                            TYPE_EMAIL: 'Invite a member by email address...',
                            DELETE_INVITATION: 'Are you sure you want to withdraw the invitation?',
                            DELETE_INVITATION_BUTTON: 'Withdraw invitation',
                            DELETE_INVITATION_HEADLINE: 'Withdraw invitation from plant?',
                            INVITE_MORE: 'Invite more user',
                            ADD_EMAIL_INPUT: 'Invite another member',
                            EMAIL_ERROR: 'Please check input',
                            MANAGE_USER_ROLE: 'Manage role',
                            REMOVE: 'Remove',
                            UPDATE_ROLE: 'Update role',
                            ROLE: 'Role',
                            ADMIN_USER_GENERAL: 'Permissions',
                            ADMIN_GENERAL_INVITE_DESCRIPTION: 'Invite / remove users',
                            ADMIN_GENERAL_MNG_USER_DESCRIPTION: 'Manage user roles',
                            ADMIN_GENERAL_MNG_PLANT_DESCRIPTION: 'Manage plant information',
                            ADMIN_ASSET_MANAGER_LINE_ASSET_DESCRIPTION: 'Add / remove lines & assets',
                            ADMIN_ASSET_MANAGER_MNG_FILES_DESCRIPTION: 'Manage files within the lines & assets',
                            USER_GENERAL_MNG_PLANT_DESCRIPTION: 'View plant information',
                            USER_ASSET_MANAGER_MNG_FILES_DESCRIPTION: 'Manage files within the lines',
                            WITHDRAW_INVITATION: 'Withdraw invitation',
                            TIMEZONE: 'Timezone:',
                            INVITATIONS_TAB: 'Invitations',
                            MEMBERS_TAB: 'Members',
                            ENERGY_TAB: 'Energy tariffs',
                            YOU: '(YOU)',
                            SEARCH_MEMBERS: 'Search members ...',
                        },
                        EDIT: {
                            SUCCESS: 'Plant changed successfully',
                            FAILURE: 'An error has occurred. Please try again.',
                        },
                        OVERVIEW: {
                            MY_PLANTS: 'My Plants',
                            MY_PLANT: 'My Plant',
                            INVITATIONS: 'Invitations',
                            INVITATION: 'Invitation',
                            NO_INVITATIONS: 'Currently you have no outstanding invitations.',
                            REMAINING_INVITATIONS: 'You have {{count}} outstanding invitations',
                            REMAINING_INVITATION: 'You have {{count}} outstanding invitation',
                            DECLINE: 'Decline',
                            ACCEPT: 'Accept',
                            NO_MEMBER: 'You are not yet a member of this plant',
                            NOT_CREATED: 'This plant is not yet created',
                            ADMIN: 'Admin',
                            USER: 'User',
                        },
                        HEADLINE: 'Plants',
                        HEADLINE_SINGLE: 'Plant',
                        CREATE_LABEL: 'Create Plant',
                        EDIT_LABEL: 'Edit Plant',
                        UNASSIGNED: 'Unassigned',
                        ENERGY_CONTRACTS: {
                            ENERGY_CONTRACTS: 'Energy contracts',
                            ADD_ENERGY_CONTRACT: 'Add Energy tariff',
                            MY_ENERGY_CONTRACTS: 'My energy contracts',
                            ELECTRICITY: 'Electricity',
                            GAS: 'Gas',
                            PROVIDER: 'Provider',
                            PROVIDER_NAME: 'Provider name',
                            ADDRESS: 'Address',
                            DURATION: 'Duration',
                            CONTRACT_PERIOD: 'Contract period',
                            PERIOD_OF_NOTICE: 'Period of notice',
                            PRICES: 'Prices',
                            BASE_PRICE: 'Base price',
                            PRICE_PER_UNIT: 'Price / unit',
                            PRICE_GUARANTEE: 'Price guarantee',
                            DISCOUNTS: 'Discounts',
                            DELETE_CONTRACT: 'Delete energy contract',
                            DELETE_CONTRACT_TEXT: 'Do you really want to delete the energy contract?',
                            MONTH: 'Month',
                            MONTHS: 'Months',
                            NOT_SPECIFIED: 'Not specified',
                            ACTIVE: 'Active',
                            INACTIVE: 'Inactive',
                            EDIT_ENERGY_CONTRACT: 'Edit energy Tariff',
                            ENERGY_PROVIDER: 'Energy Provider',
                            ENERGY_TYPE: 'Type of energy tariff',
                            ENERGY_TARIFF_NAME: 'Name of energy tariff',
                            ENERGY_PROVIDER_NAME: 'Name of energy provider',
                            STREET: 'Street',
                            HOUSE_NUMBER: 'House number',
                            POSTAL_CODE: 'Postal code',
                            CITY: 'City',
                            TARIFF_DETAILS: 'Tariff details',
                            PRICE_PER_KWH: 'Price per kWh',
                            REBATES_AND_BONUS: 'Rebate and bonus',
                            MANDATORY_FIELDS: 'Fields marked with * are required.',
                            PERCENT: 'Percent',
                            START: 'Start',
                            END: 'End',
                        },
                    },
                    CHANGE_PASSWORD_MODAL: {
                        TEXT: 'With the confirmation you will receive an email to reset your password. For a secure password change you will be logged out.',
                        CHANGE_PASSWORD: 'Change password',
                        CONFIRMATION_BUTTON: 'Change password',
                        SURE: 'Are you sure?',
                    },
                    CHANGE_NAME_MODAL: {
                        CHANGE_NAME: 'Change name',
                        FIRST_NAME: 'Firstname',
                        LAST_NAME: 'Lastname',
                        CONFIRMATION: 'Change name',
                        SUCCESS: 'Name successfully changed',
                        ERROR_LASTNAME: 'Please use minimum {{min}} and maximum {{max}} characters for the lastname',
                        ERROR_FIRSTNAME: 'Please use minimum {{min}} and maximum {{max}} characters for the firstname',
                    },
                    DIALOG_MESSAGES: {
                        CANCEL_BUTTON: 'Cancel',
                        DELETE_BUTTON: 'Delete',
                    },
                    DOCUMENTS: {
                        EDIT: 'Edit',
                        DELETE: 'Delete',
                        MOVE: 'Move',
                        ADDED_ON: 'Added on',
                        ADDED_BY: 'Added by',
                        NO_DOCS1: 'No documents available.',
                        NO_DOCS2: 'Now upload documents or create a folder.',
                        NAME: 'Name',
                    },
                    CREATE_FOLDER: {
                        HEADLINE: 'Create Folder',
                        CANCEL: 'Cancel',
                        DONE: 'Create',
                        ERROR_UPLOAD: 'Error while create Folder',
                        LABEL: 'Folder name',
                        ERROR_DELETE: 'Error while deleting this Folder',
                        ERROR_EDIT: 'Error while updating this Folder',
                        CREATE: 'Create new folder',
                        CHOOSE_PATH: 'Select path to upload Files',
                    },
                    UPLOAD_FILE: {
                        HEADLINE: 'Upload file(s)',
                        CANCEL: 'Cancel',
                        DONE: 'Upload',
                        ERROR_UPLOAD: 'Error while uploading file(s)',
                        ERROR_DELETE: 'Error while deleting file',
                        ERROR_EDIT: 'Error while updating file',
                        FOLDER_EXISTS: 'This folder name already exists.',
                        NO_FILES: 'No files were selected for upload!',
                        MACHINE: 'Machine',
                        ADDED_ON: 'Added on',
                        ADDED_BY: 'Added by',
                    },
                    PARTS: {
                        TOGGLE: 'Parts',
                        HEADER: 'SOON AVAILABLE',
                        DESCRIPTION1: 'Soon you will be able to see the right parts for your asset.',
                        DESCRIPTION2: 'You can then order them directly via the Marketplace.',
                        DESCRIPTION3: 'But already now you can use the search to find parts there.',
                    },
                    DROPZONE: {
                        TEXT: 'Drag and drop files here or click',
                    },
                    LAST_DOCS_FILETEXT: {
                        OPEN_TODAY: 'last opened: today',
                        OPEN_YESTERDAY: 'last opened: yesterday',
                        UPLOAD_TODAY: 'uploaded: today',
                        UPLOAD_YESTERDAY: 'uploaded: yesterday',
                        OPEN: 'last opened: ',
                        UPLOAD: 'uploaded: ',
                    },
                    MOVE_DOCUMENT: {
                        HEADLINE: 'Move document to...',
                        MOVE: 'Move',
                    },
                    EDIT_FOLDER: {
                        HEADLINE: 'Edit Folder',
                        DONE: 'Rename',
                    },
                    DELETE_FOLDER: {
                        HEADLINE: 'Remove Folder?',
                        CONFIRMATION1: 'The folder',
                        CONFIRMATION2: 'and all the files it contains are deleted. This cannot be undone.',
                        DELETE: 'Delete Folder',
                    },
                    MOVE_FOLDER: {
                        HEADLINE: 'Move Folder to...',
                    },
                    MULTIPLE_DELETE: {
                        HEADLINE: 'Remove Items?',
                        CONFIRMATION: 'Are you sure to delete this items?',
                        DELETE: 'Delete',
                    },
                    MULTIPLE_MOVE: {
                        HEADLINE: 'Move Objects to...',
                    },
                    MODAL: {
                        OK_BUTTON: 'Ok',
                        CANCEL_BUTTON: 'Cancel',
                    },
                    DELETE_DOCUMENT: {
                        HEADLINE: 'Remove file?',
                        CONFIRMATION: 'Are you sure to delete this file?',
                        SUCCESS: 'File successfully removed',
                        CANCEL: 'Cancel',
                        DELETE: 'Delete File',
                        CONFIRMATION_BUTTON: 'Delete File',
                    },
                    EDIT_DOCUMENT_MODAL: {
                        HEADLINE: 'Edit {{name}}?',
                        EDIT_BUTTON: 'Edit file',
                        SUCCESS: 'File successfully updated',
                        ERROR_MESSAGE: 'Min: 1 Char, first Char: everything except "."',
                    },
                    NO_AUTHORIZATION: {
                        HEADLINE: 'Missing authorization',
                        ONE: 'We appreciate your curiosity, but unfortunately we cannot grant you access because you are',
                        ONE_BOLD: 'not an administrator',
                        ONE_END: 'of this plant.',
                        TWO: 'Please contact your administrator to gain access.',
                        BUTTON_BACK: 'Back',
                        BUTTON_COCKPIT: 'To the cockpit',
                    },
                    NO_PLANT_MEMBER: {
                        HEADLINE: 'No access',
                        ONE: 'We appreciate your curiosity, but unfortunately we cannot grant you access as you are',
                        ONE_BOLD: 'not a member',
                        ONE_END: 'of this plant.',
                        TWO: 'Please contact your administrator to gain access.',
                        BUTTON_BACK: 'Back',
                        BUTTON_COCKPIT: 'To the cockpit',
                    },
                    NOT_AVAILABLE_FOR_DEMO_PLANT: {
                        HEADLINE: 'Not available for demo plant',
                        SUBTEXT:
                            'Condition monitoring cannot be used for the demo plant. Select another plant to monitor your production using Conditions.',
                    },
                    MAP_DATA_TIP: {
                        SHEETS_H: 'Sheets/h',
                        FASTEST_RUN: 'Fastest Run',
                        AVERAGE_CURRENT_ORDER: 'Ø Current Order',
                        ORDER: 'Order',
                        NUMBER_OF_PALLETS: 'Number of pallets',
                        WASTE_PRESS: { BALES_H: 'Bales/h', BALES_QUANTITY: 'Bale count' },
                        PALLETIZING_CENTER: { PALLETS_H: 'Pallets/h', PALLETS_QUANTITY: 'Pallet count' },
                        SHREDDER: { WASTE_TOTAL: 'Waste quantity [kg]', WASTE_PER_HOUR: 'Waste [kg]/h' },
                    },
                    RESOURCE_MANAGER: {
                        HEADLINE: 'Resource Manager',
                        EXPORT: 'Export as PDF',
                        TOTAL_ELECTRICITY_CONSUMPTION: 'Development electricity consumption (Total plant)',
                        TOTAL_ENPI: 'Developement electricity EnPI (Total plant)',
                        SWITCH_TOTAL: 'Total',
                        SWITCH_ENPI: 'EnPI',
                        CURRENT_PERIOD: 'Current period',
                        PREVIOUS_YEAR: 'Previous year',
                        MONTHS: {
                            '01': 'Jan',
                            '02': 'Feb',
                            '03': 'Mar',
                            '04': 'Apr',
                            '05': 'May',
                            '06': 'Jun',
                            '07': 'Jul',
                            '08': 'Aug',
                            '09': 'Sep',
                            10: 'Oct',
                            11: 'Nov',
                            12: 'Dec',
                        },
                        ELECTRICITY_CONSUMPTION_OVERVIEW: 'Consumption overview (electricity)',
                        GRID_SUPPLY: 'Grid supply',
                        PV_SUPPLY: 'PV supply',
                        PV_FEEDIN: 'PV feed-in',
                        ENERGY_FLOW: 'Energy flow',
                        SOURCE: 'Source',
                        CONSUMER: 'Consumer',
                        MAINS: 'Mains power',
                        PV: 'Photovoltaics',
                        CONSUMPTION_DAILY_AVERAGE: 'Consumption (Daily average)',
                        ELECTRICITY: 'Electricity',
                        WATER: 'Water',
                        GAS: 'Gas',
                        COMPRESSED_AIR: 'Compressed air',
                        STEAM: 'Steam',
                        TOOLTIP:
                            'The comparative value corresponds to the daily average from the same period in the previous year.',
                        COMPARISON: 'Comparison',
                        RESOURCE_TABLE: {
                            DAILY_AVERAGE: 'Daily average',
                            TOTAL_CONSUMPTION: 'Total consumption',
                            UNITS: 'Units',
                            DEVIATION: 'Deviation',
                            TOTAL_PLANT: 'Total plant',
                            ENTER_BENCHMARK: 'Enter benchmark',
                        },
                        LAST_6_MONTHS: 'Last 6 months',
                        ENERGY_BALANCE: 'Energy balance',
                        CONSUMPTIONS: 'Consumption',
                        RESOURCE_CONSUMPTION: 'Resource consumption (Individual period)',
                        OVERVIEW: 'Overview',
                        CONSUMPTION: 'Consumption',
                        BENCHMARK: 'Benchmark',
                        ENPI_HISTORY: 'EnPI History',
                    },
                    DASHBOARDS: {
                        READD_WIDGET_HEADER: 'Add new widget',
                        READD_WIDGET_SUBLINE_1:
                            'Due to a technical adjustment, the widget must be added to the dashboard.',
                        NAME: 'Name',
                        LOADING: 'Loading',
                        ALL: 'All',
                        SETUP: 'Setup',
                        FAULT: 'Stop',
                        IDLENESS: 'Idleness',
                        MACHINE_STATUS: 'Machine status',
                        NO_PREVIEW_POSSIBLE: 'No preview possible',
                        DEFINE_YOUR_WIDGET_1: 'Define your individual',
                        DEFINE_YOUR_WIDGET_2: 'widget in more detail',
                        TOTAL: 'Total',
                        CHOOSE_VISUALIZATION: 'Choose visualization',
                        SELECT_ALL: 'Select all',
                        CLEAR_SELECTION: 'Clear selection',
                        ASSET_SELECTION: 'Asset selection',
                        VISUALIZATION: 'Visualization',
                        AREA: 'Area',
                        PLANT: 'Plant',
                        ASSET: 'Asset',
                        TABLE: 'Table',
                        CHART: 'Chart',
                        LINE_CHART: 'Line chart',
                        BAR_CHART: 'Bar chart',
                        TOTAL_DROPDOWN: '(Total)',
                        OVER_TIME_DROPDOWN: '(Over time)',
                        DELETE: 'Delete',
                        ERROR: {
                            TIMEOUT: 'Data could not be loaded',
                            NO_WIDGET_NAME: 'Please enter a name.',
                            NO_KPI: 'Please choose a key performance indicator.',
                            NO_ASSET: 'Please choose a asset.',
                            NO_ASSETS: 'Please select at least one asset.',
                            WIDGET_NAME_ALREADY_EXISTS: 'This name is already taken. Please change it.',
                            NO_PRESENTATION: 'Please choose a visualization form.',
                        },
                        DELETE_WIDGET: {
                            DELETE: 'Delete widget',
                            TEXT_1: 'Are you sure to delete the widget?',
                            TEXT_2: 'The process cannot be undone.',
                        },
                        PREVIEW: 'Preview',
                        WIDGET_TYPES: {
                            PRODUCTION: 'Production',
                            RESOURCES: 'Resources',
                            MAINTENANCE: 'Maintenance',
                            INCIDENTS: 'Incidents',
                            NOT_YET_UNLOCKED: 'Function not yet unlocked...',
                            NOT_YET_UNLOCKED_SUB_1: 'Get the Resource Control function',
                            NOT_YET_UNLOCKED_SUB_2: 'and add energy and resource data',
                            NOT_YET_UNLOCKED_SUB_3: 'to your dashboards.',
                            NOT_YET_UNLOCKED_KPI: 'No production metrics available...',
                            NOT_YET_UNLOCKED_SUB_1_KPI: 'Contact openpack to get',
                            NOT_YET_UNLOCKED_SUB_2_KPI: 'the data you need for',
                            NOT_YET_UNLOCKED_SUB_3_KPI: 'your analyses.',
                        },
                        KEY_PERFORMANCE_INDICATOR: 'Key Performance Indicator',
                        ADD_WIDGET: 'Add widget',
                        EDIT_WIDGET: 'Edit widget',
                        RENAME: 'Rename dashboard',
                        NO_WIDGETS: {
                            START_NOW: 'Start now!',
                            SUB_1: 'Add your first widget and',
                            SUB_2: 'keep track of your key metrics from now on.',
                            SUB_MOBILE: 'Add your first widget and keep track of your key metrics from now on.',
                        },
                        EDIT: 'Edit dashboard',
                        EXPORT: 'Export',
                        BACK: 'Back',
                        RHYTHM: 'Rhythm',
                        CHOOSE_ONE_TYPE: 'Please select at least one file format.',
                        NOT_ALL_RECIPIENTS_MEMBER_OF_PLANT:
                            'No widgets can be selected for this plant at the moment. Not all recipients of the report series of this dashboard are allowed for this plant. Add the users to the plant, to add new widgets.',
                        NO_REPORTS: 'Currently no reports are archived',
                        ARCHIVED: 'archived',
                        DOWNLOAD_ALL: 'Download all',
                        DELETE_REPORT: 'Are you sure to delete the report series?',
                        DELETE_SERIES: 'Delete report series',
                        UNSUBSCRIBE_AS_RECIPIENT: 'Unsubscribe as recipient',
                        TO_DASHBOARD: 'To the dashboard',
                        RECEIVER: 'Receiver',
                        ADMINISTRATOR: 'Administrator',
                        ADD_RECIPIENT: 'Add recipient',
                        MORE: 'more',
                        ENDS_ON: 'Ends on',
                        DAILY: 'Daily',
                        WEEKLY: 'Weekly',
                        MONTHLY: 'Monthly',
                        START: 'Start',
                        END: 'End (optional)',
                        WHICH_PERIOD: 'What period should each of the reports represent?',
                        WHICH_SHIFT: 'Which shift should the reports represent in each case? (optional)',
                        LAST_7_DAYS: 'Last 7 days',
                        FORMAT: 'Format',
                        CREATE_REPORT: 'Create report',
                        CREATE_REPORT_SERIES: 'Create report series',
                        MY_REPORTS: 'My reports',
                        REPORT_NAME: 'Report name',
                        REPORT_SERIES: 'Report series',
                        REPORT_ERROR: 'An input is required.',
                        ADDITIONAL_RECEIVERS: 'Additional receivers (optional)',
                        ADDITIONAL_RECEIVERS_LIST: 'Additional receivers',
                        RECEIVERS_INFO: 'Only recipients who are registered at openpack. can be added.',
                        SINGLE_REPORT: 'Single report',
                        GENERATING_PDF: 'Create PDF...',
                        GENERATING_PPTX: 'Create PPTX...',
                        GENERATING_EXCEL: 'Create Excel...',
                        GENERATING_FAILED: 'Generating export failed',
                        DELETE_DASHBOARD: 'Delete dashboard',
                        SAVE: 'Save',
                        DELETE_MODAL: {
                            HEADER: 'Delete Dashboard',
                            BODY: 'As soon as you delete the dashboard, all associated report series will be terminated. This process cannot be undone. Do you really want to delete this Dashboard?',
                            DELETE_BUTTON: 'Delete Dashboard',
                        },
                        CREATED_ON: 'Created on',
                        PRODUCTION: 'Production',
                        ENERGY_CONSUMPTION_PER_ASSET: {
                            ENERGY_CONSUMPTION: 'Energy consumption',
                            ASSET: 'Asset',
                        },
                        GAS_CONSUMPTION_PER_ASSET: {
                            GAS_CONSUMPTION: 'Gas consumption',
                            ASSET: 'Asset',
                        },
                        COMPRESSED_AIR_CONSUMPTION_PER_ASSET: {
                            COMPRESSED_AIR_CONSUMPTION: 'Compressed air consumption',
                            ASSET: 'Asset',
                        },
                        WATER_CONSUMPTION_PER_ASSET: {
                            WATER_CONSUMPTION: 'Water consumption',
                            ASSET: 'Asset',
                            LITER: 'Litre',
                        },
                        STEAM_CONSUMPTION_PER_ASSET: {
                            STEAM_CONSUMPTION: 'Steam Consumption',
                            ASSET: 'Asset',
                        },
                        ORDER_TILE: {
                            ORDERS: 'Orders',
                            CUSTOMER: 'Customer',
                            ORDER_NUMBER: 'Order Number',
                            GRADE: 'Grade',
                            SHEETS: 'Sheets',
                            START: 'Start',
                            END: 'End',
                            DURATION: 'Duration',
                            IN_PROGRESS: 'in progress',
                        },
                        PLANNED_ACTUAL_SQM: {
                            PRODUCED: 'Produced m²',
                            TARGET: 'Target',
                            ACTUAL: 'Actual',
                            CORRUGATOR: 'Corrugator',
                        },
                        PLANNED_ACTUAL_LM: {
                            LINEAR_METER: 'Linear meter',
                            CORRUGATOR: 'Corrugator',
                            TOOLTIP: 'Calculation of target linear meter based on target speeds!',
                        },
                        AVERAGE_PRODUCTION_SPEED: {
                            HEADER: 'Ø-Speed',
                            MACHINE: 'Machine',
                            INDIVIDUAL: 'individual',
                            CORRUGATOR: 'Corrugator',
                            CONVERSION: 'Conversion',
                            WITHOUT_FKM: 'without FKM',
                            SHEETS: 'Sheets',
                            ASSET: 'Asset',
                        },
                        RUNSTOPTIME: {
                            HEADER: 'Run- & Downtime',
                            RUNTIME: 'Runtime',
                            DOWNTIME: 'Downtime',
                            DOWNTIME_COSTS: 'Downtime costs',
                            CORRUGATOR: 'Corrugator',
                            CONVERSION_TOTAL: 'Conversion total',
                            ASSET: 'Asset',
                        },
                        TOP_CUSTOMER: {
                            HEADER: 'Top 10 customers by volume produced [m²]',
                        },
                        GRADE_OVERVIEW: {
                            HEADER: 'Grade overview [m²]',
                            PARETO: 'Cumulated production quantity: ',
                        },
                        STOP_REASONS: {
                            HEADER: 'Top 10 Stop reasons [count]',
                            PARETO: 'Cumulated stop reasons count: ',
                        },
                        TRIM: { TRIM: 'Trim' },
                        TODAY: 'Today',
                        YESTERDAY: 'Yesterday',
                        LAST_5_DAYS: 'Last 5 days',
                        LAST_30_DAYS: 'Last 30 days',
                        CUSTOM: 'Custom',
                        TEXT1: 'Be curious!',
                        TEXT2: 'Here you will soon find dashboards with the most important key figures of your machines. This way, you will always have them at hand quickly and clearly.',
                        WIDGET_TYPE: {
                            ENERGY_CONSUMPTION_PER_1000_M2: 'Energy consumption per 1000 sqm',
                            ENERGY_CONSUMPTION_PER_MACHINE_STATUS: 'Energy consumption by machine status',
                            TOTAL_ENERGY_CONSUMPTION_PER_MACHINE_STATUS: 'Total energy consumption by machine status',
                            ENERGY_CONSUMPTION_PER_MACHINE_STATUS_PER_ASSET: 'Energy consumption by machine status',
                            COMPRESSED_AIR_CONSUMPTION_PER_MACHINE_STATUS:
                                'Compressed air consumption by machine status',
                            TOTAL_COMPRESSED_AIR_CONSUMPTION_PER_MACHINE_STATUS:
                                'Total compressed air consumption by machine status',
                            COMPRESSED_AIR_CONSUMPTION_PER_MACHINE_STATUS_PER_ASSET:
                                'Compressed air consumption by machine status',
                            ELECTRICAL_POWER_VS_MACHINE_SPEED_OF_ASSET: 'Electrical power vs. speed',
                            COMPRESSED_AIR_VS_MACHINE_SPEED_OF_ASSET: 'Compressed air vs. speed',
                            ELECTRICAL_POWER: 'Electrical power',
                            ENERGY_CONSUMPTION: 'Energy consumption',
                            TOTAL_ENERGY_CONSUMPTION: 'Total energy consumption',
                            ENERGY_CONSUMPTION_PER_ASSET: 'Energy consumption per asset',
                            TOTAL_ELECTRICAL_POWER: 'Total electrical power',
                            ELECTRICAL_POWER_PER_ASSET: 'Electrical power per asset',
                            ORDER_OVERVIEW: 'Order Overview',
                            ORDER_OVERVIEW_DESC:
                                'Overview of orders with information on customers, varieties, order size and production time.',
                            GRADE_OVERVIEW: 'Grade Overview - Top 10',
                            GRADE_OVERVIEW_DESC:
                                'Overview of the top 10 grades produced with the quantity produced in square metres and %.',
                            TOP_10_STOP_REASONS: 'Stop reasons - Top 10',
                            SPEED_GRAPH: 'Speed Graph',
                            TOP_10_STOP_REASONS_PER_ASSET: 'Stop reasons per asset  - Top 10',
                            TOTAL_TOP_10_STOP_REASONS: 'Total Stop reasons - Top 10',
                            TOP_10_CUSTOMER_PRODUCED_QUANTITY: 'Customer Overview – Top 10',
                            TOP_10_CUSTOMER_PRODUCED_QUANTITY_DESC:
                                'Overview of the top 10 customers sorted by the order volume in square meters.',
                            LINEAR_METER: 'Linear meter (Corrugator)',
                            LINEAR_METER_DESC:
                                'Comparison of the planned and actual production quantity of the corrugator in linear metres.',
                            PRODUCED_M2: 'Produced m² (Corrugator)',
                            PRODUCED_M2_DESC:
                                'Comparison of the planned and the actual produced square meter at the corrugator.',
                            TRIM: 'Trim',
                            TRIM_DESC: 'This graph shows the edge trim at the corrugator in %.',
                            RUNTIME_DOWNTIME: 'Run- & Downtime',
                            RUNTIME_DOWNTIME_DESC: 'This table shows the run- & downtime of the different lines.',
                            AVERAGE_SPEED: 'Ø-Speed',
                            AVERAGE_SPEED_DESC:
                                'This table shows the average speed for each line individually and in m²/h.',
                            DOWNTIME_COSTS: 'Downtime costs',
                            DOWNTIME_COSTS_DESC:
                                'This diagram shows the downtime costs in total and split for each line.',
                            ORDER_LIST: 'Order list',
                            GAS_CONSUMPTION: 'Gas consumption',
                            TOTAL_GAS_CONSUMPTION: 'Total gas consumption',
                            GAS_CONSUMPTION_PER_ASSET: 'Gas consumption per asset',
                            COMPRESSED_AIR_CONSUMPTION: 'Compressed air consumption',
                            COMPRESSED_AIR: 'Compressed air',
                            TOTAL_COMPRESSED_AIR_CONSUMPTION: 'Total compressed air consumption',
                            COMPRESSED_AIR_CONSUMPTION_PER_ASSET: 'Compressed air consumption per asset',
                            WATER_CONSUMPTION: 'Water consumption',
                            TOTAL_WATER_CONSUMPTION: 'Total water consumption',
                            WATER_CONSUMPTION_PER_ASSET: 'Water consumption per asset',
                            STEAM_CONSUMPTION: 'Steam consumption',
                            TOTAL_STEAM_CONSUMPTION: 'Total steam consumption',
                            STEAM_CONSUMPTION_PER_ASSET: 'Steam consumption per asset',
                        },
                        SHIFT: {
                            DATE: 'Date',
                            SHIFT_OPTIONAL: 'Shift (optional)',
                            EARLY_SHIFT: 'Early shift',
                            LATE_SHIFT: 'Late shift',
                            NIGHT_SHIFT: 'Night shift',
                            WIDGET_NOT_AVAILABLE_1: 'Widget not available',
                            WIDGET_NOT_AVAILABLE_2:
                                'For this widget, data can only be displayed if all shifts are selected.',
                        },
                    },
                    NEW_DASHBOARDS: {
                        HEADER: 'My Dashboards',
                        CREATE_BUTTON: 'Create dashboard',
                        CHOOSE_KPI: 'Select your KPIs:',
                        CREATE: 'Create',
                        CANCEL: 'Cancel',
                        CREATE_TEXT1: 'Create your own dashboard.',
                        CREATE_TEXT2: 'Quickly and easily create an individual dashboard',
                        CREATE_TEXT3: 'that exactly fits your needs.',
                        CREATE_TEXT_MOBILE:
                            'Quickly and easily create an individual dashboard that exactly fits your needs.',
                        NO_DASHBOARD_NAME: 'Please insert a name.',
                        CHOOSE_KPI_ERROR: 'Please select at least one KPI.',
                        NAME: 'Name',
                    },
                    FINANCE: {
                        START: {
                            TYPE_OF_FINANCING: 'Type of financing',
                            START_REQUEST: 'Start request',
                            CHOOSE_TYPE: 'Choose your type of financing',
                            MORE_INFORMATION: 'More information about',
                            FINANCING: 'Financing',
                            LEASING: 'Leasing',
                            COOPERATION: 'In Cooperation with',
                            FINANCING_COMPARISON: 'Financing comparison',
                            POINT_1: 'Free of charge & without obligation',
                            POINT_2: 'Independent offers from premium partners',
                            POINT_3: 'Quickly and easily create request within 5 minutes',
                            TAB1: 'Apply for a loan',
                            TEXT1: 'In cooperation with our financial partner ',
                            TEXT2: 'you have the possibility to apply for a loan in an uncomplicated way and only within ',
                            TEXT3: '3 minutes.',
                            TEXT4: '',
                            BUTTON: 'Start now',
                            INPUT: 'Credit amount',
                            TRANSMITTED: 'Credit inquiries already submitted:',
                            NEXT: 'Next',
                            CHOOSE: 'Please select',
                            EMPTY_FIELD: 'Please fill in this field.',
                            EMPTY_FIELDS: 'Please fill in these fields.',
                            INFO: 'Info',
                            CREDIT_AREA: 'Credit must be between 50.000 and 50.000.000 EUR',
                            WRONG_MAIL: 'E-Mail not correct!',
                            NO_OPTIONS: 'No country found',
                        },
                        INFO: {
                            FINANCING: 'Financing',
                            FINANCING_DFKP: {
                                HEADER1: 'What does the process look like?',
                                HEADER2: 'What information needs to be provided in a subsequent step?',
                                HEADER3: 'Why should I pick Deutsche Leasing as a partner?',
                                HEADER4: 'What are the benefits for me?',
                                TEXT1: 'Upon submitting the leasing request form, Deutsche Leasing will prepare an individual offer and get in touch with the requestor. You will be receiving an offer within 2 business days.',
                                TEXT2: 'In preparation for an individual investment call with one of our consultants, please have the following information ready:',
                                TEXT3: 'Deutsche Leasing aims to be the leading asset finance partner for corporate customers in Germany. This ambition is why we put you, our clients and partners, at the center of our company. We align our business with you and your objectives by contributing our energy and solutions competence, both in Germany and abroad.',
                                TEXT4: 'Our customers benefit from German-speaking consultation – independent of the country where they would like to invest. We tailor our offer specific to your needs and connect you to our global network of industry experts.',
                                POINT1: 'Annual financial statement (past 2 years)',
                                POINT2: 'Business assessment statement',
                                POINT3: 'Overview of bank accounts / liquidity, maturities and collaterals',
                                POINT4: 'Current business plan',
                                POINT5: 'Fast online application and same-day contact from one of our financing experts.',
                                POINT6: 'Personal business meeting including credit potential analysis and live terms estimate',
                                POINT7: 'Preparation of documents and process management until disbursement by the appropriate financier',
                                POINT8: 'Holistic consulting after closing and implementation of follow-up financing',
                            },
                            FINANCING_DL: {
                                HEADER1: 'How does the process look like?',
                                HEADER2: 'What information needs to be provided in a subsequent step?',
                                HEADER3: 'Why should I pick Deutsche Leasing as a partner?',
                                TEXT1: 'Upon submitting the leasing request form, Deutsche Leasing will prepare an individual offer and get in touch with the requestor. You will be receiving an offer within 2 business days.',
                                TEXT2: 'In preparation for an individual investment call with one of our consultants, please have the following information ready:',
                                TEXT3: 'Deutsche Leasing aims to be the leading asset finance partner for corporate customers in Germany. This ambition is why we put you, our clients and partners, at the center of our company. We align our business with you and your objectives by contributing our energy and solutions competence, both in Germany and abroad.',
                                TEXT4: 'Our customers benefit from German-speaking consultation – independent of the country where they would like to invest. We tailor our offer specific to your needs and connect you to our global network of industry experts.',
                                POINT1: 'Annual financial statements (past 2 years)',
                                POINT2: 'Business assessment statement',
                                POINT3: 'Overview of bank accounts / liquidity, maturities and collaterals',
                                POINT4: 'Current business plan',
                            },
                            LEASING_DFKP: {
                                HEADER1: 'How does the leasing process work?',
                                HEADER2: 'What information is required for the request?',
                                HEADER3: 'What are the benefits for me?',
                                HEADER4: 'Where to apply for the credit?',
                                TEXT1: 'After the form has been filled out and transmitted to our partner, DFKP will contact the applicant. Once the necessary documents have been submitted, DFKP is able to obtain a positive credit decision from the appropriate financing partner within 48 hours. A payment is possible within 2-5 business days.',
                                TEXT2: 'For your financing request at first we need only a few details about your company (including name, legal form) and you as a contact person. In a second step (after the company meeting with DFKP) our Partner will need the following documents:',
                                TEXT3: 'Our partner, DFKP, is an independent financing platform and offers transparent and goal-oriented advice in the search for the optimal financing for your next financing project. The key building blocks of their service include:',
                                TEXT4: 'DFKP works with the most important German SME financiers. These include universal banks active throughout Germany, regional banks, cooperative banks, SME financiers, neo-banks, swarm financiers, online marketplaces, purchasing financiers and leasing companies. Savings banks are not currently among its financing partners. After reviewing the documents, the loan request is submitted to the ideal financiers by DFKP.',
                                POINT1: 'Annual financial statements for the last two fiscal years (if available) ',
                                POINT2: 'Current management analysis incl. balance sheet',
                                POINT3: 'Bank or liability statement',
                                POINT4: 'Account statements of the last 90 days',
                                POINT5: 'Fast online application and same-day contact from one of our financing experts.',
                                POINT6: 'Personal business meeting including credit potential analysis and live terms estimate',
                                POINT7: 'Preparation of documents and process management until disbursement by the appropriate financier',
                                POINT8: 'Holistic consulting after closing and implementation of follow-up financing',
                            },
                            LEASING_DL: {
                                HEADER1: 'How does the process look like?',
                                HEADER2: 'What information needs to be provided in a subsequent step?',
                                HEADER3: 'Why should I pick Deutsche Leasing as a partner?',
                                TEXT1: 'Upon submitting the leasing request form, Deutsche Leasing will prepare an individual offer and get in touch with the requestor.',
                                TEXT1_2: 'You will be receiving an offer within 48 hours.',
                                TEXT2: 'In preparation for an individual investment call with one of our consultants, please have the following information ready:',
                                TEXT3: 'Deutsche Leasing aims to be the leading asset finance partner for corporate customers in Germany. This ambition is why we put you, our clients and partners, at the centre of our company. We align our business with you and your objectives by contributing our energy and solutions competence, both in Germany and abroad.',
                                TEXT4: 'Our customers benefit from German-speaking consultation – independent of the country where they would like to invest. We tailor our offer specific to your needs and connect you to our global network of industry experts.',
                                POINT1: 'Annual financial statement (past 2 years)',
                                POINT2: 'Business assessment statement',
                                POINT3: 'Overview of bank accounts / liquidity, maturities and collaterals',
                                POINT4: 'Current business plan',
                            },
                        },
                        STEP1: {
                            COUNTRY_OF_ORIGIN: "Object's country of origin",
                            TOTAL_CONTRACT_VALUE: 'Total contract value',
                            HEADER_FINANCING: 'Credit Details',
                            HEADER_LEASING: 'Leasing Details',
                            OBJECT: 'Object',
                            RUNTIME: 'Runtime',
                            RTOPTION1: '24 months',
                            RTOPTION2: '30 months',
                            RTOPTION3: '36 months',
                            RTOPTION4: '42 months',
                            RTOPTION5: '48 months',
                            RTOPTION6: '54 months',
                            RTOPTION7: '60 months',
                            RTOPTION8: '66 months',
                            RTOPTION9: '72 months',
                            RTOPTION10: '78 months',
                            RTOPTION11: '84 months',
                            RTOPTION12: '90 months',
                            RTOPTION13: '96 months',
                            TOOLTIP_FINANCING:
                                'This is the country of origin of the good for which the financing offer is requested.',
                            TOOLTIP_LEASING:
                                'This is the country of origin of the good for which the leasing offer is requested.',
                        },
                        STEP2: {
                            SELECT_TYPE_NEW: 'Select or type new',
                            HEADER: 'Company info',
                            COMPANY: 'Company',
                            LEGALFORM: 'Legal form',
                            STREET: 'Street',
                            HOUSENUMBER: 'House number',
                            POSTALCODE: 'Postal code',
                            CITY: 'City',
                            COUNTRY: 'Country',
                            LFOPTION1: 'Limited liability company',
                            LFOPTION2: 'Limited partnership with a limited liability company as general partner',
                            LFOPTION3: 'Public limited company',
                            LFOPTION4: 'Civil law partnership',
                            LFOPTION5: 'Partnership',
                            LFOPTION6: 'General partnership',
                            LFOPTION7: 'Limited partnership',
                            LFOPTION8: 'Partnership limited by shares',
                            LFOPTION9: 'Entrepreneurial company (limited liability)',
                            LFOPTION10: 'Registered cooperative',
                            LFOPTION11: 'Registered association',
                            LFOPTION12: 'Freelancer/self-employed',
                            LFOPTION13: 'Sole proprietorship',
                            LFOPTION14: 'Other',
                            LFOPTION15: 'Limited',
                            COMPANY1: 'CIPA GmbH',
                        },
                        STEP3: {
                            HEADER: 'Contact Information',
                            FIRSTNAME: 'First name',
                            LASTNAME: 'Last name',
                            EMAIL: 'E-Mail (business)',
                            TELEPHONE: 'Telephone',
                            COMMENT: 'Note',
                            COMMENTPLACEHOLDER: 'Anything else you want to tell us?',
                            BUTTON: 'Continue',
                        },
                        STEP4: {
                            EMAIL: 'E-Mail',
                            HEADER: 'Send to',
                            TEXT1: 'To which partner should your information be sent? You will receive an independent offer from each partner.',
                            TIP: 'Tip: ',
                            TEXT2: 'Select all partners to see who has the best offer for your business needs. It´s free!',
                            DL: 'The Deutsche Leasing Group is the leading asset finance partner for German SMEs and offers a wide range of investment-related financing solutions',
                            DFKP: 'DFKP guides you purposefully to your next corporate loan with the optimally suited financier. With DFKP, the focus is on advice.',
                            GERMANY: 'Germany',
                            SEND_REQUEST: 'Send request',
                            ERROR: 'Please choose at least one partner.',
                        },
                        STEP5: {
                            HEADER: 'Summary',
                            CREDITDETAILS: 'Credit Details',
                            LEASINGDETAILS: 'Leasing Details',
                            COMPANYINFO: 'Company info',
                            PERSONALDATA: 'Personal data',
                            EDIT: 'Edit',
                            COMPANYNAME: 'Company name',
                            STREET_HOUSENUMBER: 'Street/House number',
                            POSTALCODE_CITY: 'Postalcode/City',
                            FIRSTNAME_LASTNAME: 'First name/Last name',
                            EMAIL: 'E-Mail',
                            COMMENT: 'Comment',
                            SUBMIT: 'Send request',
                            AUTHORIZED1: 'I am ',
                            AUTHORIZED2: 'authorized ',
                            AUTHORIZED3: 'within the company to make credit inquiries',
                            AGB1: 'The data I provide will be used exclusively for the Finance app, including for the preparation of a credit offer ',
                            AGB2: 'Privacy Policy.',
                            AGB3: '',
                        },
                        STEP6: {
                            HEADER1: 'The data was',
                            HEADER2: 'successfully',
                            HEADER3: 'transmitted.',
                            TEXT_DFKP: 'A contact person of DFKP (Deutsche Firmen Kreditpartner)',
                            TEXT_DL: 'A contact person of Deutsche Leasing AG',
                            TEXT_ALL: 'Our Partner',
                            TEXT: 'will get in touch with you as soon as possible to discuss the next steps. We wish you further success.',
                            BUTTON: 'Go to home page',
                        },
                        ERROR: {
                            TEXT_1: 'An error has occurred.',
                            TEXT_2: 'Sorry, the request could not be submitted.',
                            BUTTON_1: 'Send request again',
                            BUTTON_2: 'Back to home page',
                        },
                        BANNER: {
                            TEXT1: 'PAY-PER-USE',
                            TEXT2: 'financing models',
                            TEXT3: 'for the industry.',
                        },
                    },
                    SMARTENANCE: {
                        HEADER: 'Maintenance',
                        LEFT: {
                            CHART_HEADER: 'Status today',
                            OPEN: 'Pending maintenance',
                            DONE: 'Completed maintenance',
                            OVERDUE: 'Delayed maintenance',
                            THIS_WEEK: 'This week',
                            LAST_WEEK: 'Last week',
                            COMPLETED: 'completed',
                        },
                        RIGHT: {
                            HEADER1: 'Machine downtime by maintenance',
                            HEADER2: 'Machine downtime by maintenance (planned)',
                            THIS_MONTH: 'This month',
                            LAST_MONTH: 'Last month',
                            LAST_WEEK: 'Last week',
                            THIS_WEEK: 'This week',
                            NEXT_WEEK: 'Next week',
                            HOURS: 'hours',
                            MINUTES: 'minutes',
                        },
                        BOTTOM: {
                            TAB1: 'Maintenance',
                            TAB2: 'Incidents',
                            TABLE1: {
                                HEADER1: 'Status',
                                HEADER2: 'Description',
                                HEADER3: 'Due on',
                                HEADER4: 'Performed on',
                            },
                            TABLE2: { HEADER1: 'Status', HEADER2: 'Description', HEADER3: 'Timestamp' },
                            STATUS_MAINTENANCE: { GREEN: 'Done', GRAY: 'Pending', YELLOW: 'Delayed' },
                            STATUS_INCIDENT: { GREEN: 'Closed', RED: 'Open', YELLOW: 'In work' },
                            TABLE1_CONTENT: {
                                LINE1_DESCRIPTION: 'Lubrication of the printing unit',
                                LINE1_DUE: '01/01/2022',
                                LINE1_PERFORMED: '-',
                                LINE2_DESCRIPTION: 'Cleaning or changing the filters',
                                LINE2_DUE: '01/10/2022',
                                LINE2_PERFORMED: '-',
                                LINE3_DESCRIPTION: 'Cleaning and lubrication of the rollers',
                                LINE3_DUE: '01/03/2022',
                                LINE3_PERFORMED: '01/03/2022',
                                LINE4_DESCRIPTION: 'Maintenance of the compressed air unit',
                                LINE4_DUE: '01/03/2022',
                                LINE4_PERFORMED: '01/03/2022',
                                LINE5_DESCRIPTION: 'Checking and replacement of bearings',
                                LINE5_DUE: '01/05/2022',
                                LINE5_PERFORMED: '01/05/2022',
                            },
                            TABLE2_CONTENT: {
                                LINE1_DESCRIPTION: 'Blockage of the waste belt',
                                LINE1_TIMESTAMP: '01/07/2022 ',
                                LINE2_DESCRIPTION: 'Defect in the electrical system',
                                LINE2_TIMESTAMP: '01/05/2022 - 14:24',
                                LINE3_DESCRIPTION: 'Bundling machine on palletizer defective',
                                LINE3_TIMESTAMP: '01/03/2022 - 09:15',
                            },
                        },
                    },
                    DROPDOWN: {
                        NO_OPTIONS: 'No options',
                    },
                    CONDITION_MONITORING: {
                        NOT_AVAILABLE_MOBILE_HEADER: 'Condition Monitoring not available',
                        NOT_AVAILABLE_MOBILE_BODY:
                            'Condition Monitoring is currently not available on mobile devices. We are working to ensure that you will soon be able to monitor your conditions on the go.',
                        ASSET: 'Asset',
                        ONLY_NUMBER_ALLOWED: 'Please verify input',
                        TITLE_ALREADY_IN_USE: 'Title is already in use. Please choose another one.',
                        ARBITRARY: 'Arbitrary',
                        DATA_SOURCE: 'Data source',
                        EDIT_HINT:
                            'Only the values in the free text fields can be edited. For further changes create a new condition or duplicate it.',
                        ADDED_USER: 'Selected users',
                        SHARE_ONLY_O_USER: 'The condition can be shared only with users registered at openpack',
                        SHARE_CONDITION: 'Share condition',
                        TIME_INTERVAL: 'Time interval',
                        CALCULATION_FUNCTION: 'Calculation function',
                        AGGREGATION: 'Aggregation',
                        WITH: 'with',
                        INFORMATION_MODEL: 'Information model',
                        VARIABLE: 'Variable',
                        SHARE_WITH_USER: 'Share condition with other users',
                        DEACTIVATE_CONDITION: 'Reset condition, if...',
                        CONDITION_REQUIRED: 'Fill in all fields of the condition',
                        CREATE: 'Create',
                        ANY: 'Any',
                        ALL: 'All',
                        MULTIPLY: 'Multiply',
                        DIVIDE: 'Divide',
                        SUBTRACT: 'Subtract',
                        ADD: 'Add',
                        MAX: 'Maximum value',
                        MIN: 'Minimum value',
                        AVERAGE: 'Average',
                        COUNT: 'Count',
                        SUM: 'Sum',
                        AVERAGE_EXPLANATION: 'The average is calculated from the selected time period.',
                        MIN_EXPLANATION: 'The smallest value of the specified period will be selected.',
                        MAX_EXPLANATION: 'The largest value of the specified period will be selected.',
                        COUNT_EXPLANATION: 'The number of values of the selected period will be counted.',
                        SUM_EXPLANATION: 'All values of the selected period will be added up.',
                        ALL_EXPLANATION:
                            'It is checked if all values of the selected period match the comparison value.',
                        ANY_EXPLANATION:
                            'It is checked if one value of the selected period matches the comparison value.',
                        VALUE: 'Certain value',
                        SINGLE_VALUE: 'Value',
                        SINCE: 'Since',
                        BEFORE: 'Before',
                        REMOVE_TIME_INTERVAL: 'Remove time interval',
                        ADD_TIME_INTERVAL: 'Add time interval',
                        REMOVE_CALCULATION: 'Remove calculation function',
                        ADD_CALCULATION: 'Add calculation function',
                        ADD_EXPRESSION: 'Add expression',
                        ADD_CONDITION: 'Add condition',
                        BUTTON_HISTORY: 'History',
                        BUTTON_NEW_CONDITION: 'Create new condition',
                        CREATE_NEW_CONDITION: 'Create new condition',
                        DROPDOWN_BUTTON_NORMAL: 'Create individual condition',
                        DROPDOWN_BUTTON_TEMPLATE: 'Choose template',
                        TAB_ALL_CONDITIONS: 'All conditions',
                        TAB_MY_CONDITIONS: 'My conditions',
                        TAB_LIBRARY: 'Library',
                        NO_ENTRIES_HISTORY: 'No entries available',
                        NO_ENTRIES_TEXT_HISTORY:
                            'No conditions have become active yet. As soon as that happens, you will see it here.',
                        MAX_TIME_INTERVALL_180: 'Max. time interval: 180 min',
                        MAX_TIME_INTERVALL_180_ERROR: 'Enter a time interval of max. 180 min',
                        ENTER_TIMEINTERVALL: 'Specify a time interval',
                        TABLE: {
                            STATUS: 'Status',
                            SEVERITY: 'Severity',
                            TITLE: 'Title',
                            ASSETS: 'Assets',
                            LAST_OCCURED: 'Last occurred',
                        },
                        STATUS: {
                            ACTIVE: 'Active',
                            INACTIVE: 'Inactive',
                        },
                        SEVERITY: {
                            INFORMATION: 'Information',
                            WARNING: 'Warning',
                            ERROR: 'Error',
                        },
                        FILTER: {
                            ASSET: 'Asset',
                            SEVERITY: 'Severity',
                            STATUS: 'Status',
                            FILTER: 'Filter',
                            RESET_ALL: 'Reset all',
                            TAB_ALL_DESCRIPTION:
                                'All conditions which users have created for this plant are displayed here (including your conditions).',
                            TAB_MY_DESCRIPTION:
                                'The conditions which you have created or added to your conditions are displayed here. All conditions which you have created are also displayed under "All Conditions".',
                        },
                        EDIT_CONDITION: {
                            EDIT_CONDITION: 'Edit condition',
                            TITLE: 'Title',
                            SEVERITY: 'Severity',
                            RESTRICTION_INFORMATION:
                                'Only the values in the free text fields can be edited. For further changes create a new condition or duplicate this one.',
                            VALUE: 'Value',
                            VARIABLE: 'Variable',
                            DESCRIPTION_OPTIONAL: 'Description (optional)',
                            RESOLUTION_OPTIONAL: 'Resolution (optional)',
                            TITLE_ERROR: 'Enter a title',
                            SEVERITY_ERROR: 'Select a severity level',
                        },
                        EDIT_EXPRESSION: {
                            ADD_EXPRESSION: 'Add expression',
                            REMOVE_TIME_INTERVAL: 'Remove time interval',
                            REMOVE_CALCULATION_FUNCTION: 'Remove calculation function',
                            ADD_TIME_INTERVAL: 'Add time interval',
                            ADD_CALCULATION_FUNCTION: 'Add calculation function',
                            CANCEL: 'Cancel',
                            SAVE: 'Save',
                        },
                        CONDITION_DETAILS: {
                            BACK: 'Back',
                            EDIT: 'Edit',
                            DUPLICATE: 'Duplicate',
                            ACTIVATE_EMAIL: 'Activate email',
                            DEACTIVATE_EMAIL: 'Deactivate email',
                            DELETE: 'Delete',
                            ADD_CONDITIONS: 'Subscribe"',
                            REMOVE_CONDITIONS: 'No longer subscribe',
                            STATUS: 'Status',
                            SEVERITY: 'Severity',
                            ACTIVATE_CONDITION: 'Activate condition, if...',
                            RESET_CONDITION: 'Severity',
                            DESCRIPTION: 'Description',
                            RESOLUTION: 'Resolution',
                            NO_DESCRIPTION: 'No description available',
                            NO_RESOLUTION: 'No resolution available',
                            SHARE_WITH: 'Share with...',
                            USED_BY: 'Is used by...',
                            CREATOR: 'Creator',
                            EDIT_TOOLTIP: 'Only the creator can edit the condition.',
                            SHARE_TOOLTIP: 'Only the creator can share the condition.',
                        },
                        DELETE_CONDITION: {
                            DELETE_HEADER: 'Delete Condition',
                            DELETE_TEXT: 'Are you sure you want to delete the condition?',
                            DELETE_BUTTON: 'Delete',
                        },
                        ADD_USER: {
                            SEARCH_USER: 'Search for user ...',
                            USER: 'User',
                            REMOVE: 'Remove',
                        },
                        NO_CONDITIONS: {
                            HEADER: 'No conditions available',
                            TEXT: 'Create a condition now and keep your production running.',
                        },
                        DUPLICATE_CONDITION: {
                            HEADER: 'Duplicate Condition',
                            SAVE_BUTTON: 'Save',
                            CANCEL_BUTTON: 'Cancel',
                            COPY: 'Copy',
                        },
                        SCHEDULING: {
                            CHECK_CONDITION: 'When should the condition be checked?',
                            ALWAYS: 'Always',
                            CERTAIN_DAYS: 'Only on certain days',
                            ON: 'every',
                            PERIOD_FOLLOWING_DAY: 'The period continues until the following day',
                            WILL_BE_CHECKED: 'will be checked',
                            NEXT_DAY: 'the next day',
                            DAILY_FROM: 'from',
                            TO: 'to',
                            EVERY: 'Every',
                            AND: 'and',
                            FROM: 'from',
                            H: 'h',
                            MO_SHORT: 'Mon',
                            MONDAY: 'Monday',
                            TU_SHORT: 'Tue',
                            TUESDAY: 'Tuesday',
                            WE_SHORT: 'Wed',
                            WEDNESDAY: 'Wednesday',
                            TH_SHORT: 'Thu',
                            THURSDAY: 'Thursday',
                            FR_SHORT: 'Fri',
                            FRIDAY: 'Friday',
                            SA_SHORT: 'Sat',
                            SATURDAY: 'Saturday',
                            SU_SHORT: 'Sun',
                            SUNDAY: 'Sunday',
                        },
                        DATA_SOURCE_TRANSLATION: {
                            STATE_AND_SPEED: 'State and speed information',
                            ORDER_INFORMATION: 'Order information',
                            ELECTRICITY_INFORMATION: 'Electricity information',
                            ELECTRICITY_INFORMATION_SMALL: 'Electricity information (small)',
                            ELECTRICITY_INFORMATION_MEDIUM: 'Electricity information (medium)',
                            GAS_INFORMATION: 'Gas information',
                            WATER_INFORMATION: 'Water information',
                            WAREHOUSE_INFORMATION: 'Warehouse information',
                            COMPRESSED_AIR: 'Compressed air information',
                            STEAM: 'Steam information',
                            SHIFT: 'Shift information',
                            STOP: 'Stop information',
                        },
                        VARIABLE_TRANSLATION: {
                            AVERAGE_SPEED: 'Average speed',
                            CURRENT_SPEED: 'Current speed',
                            CURRENT_STATE: 'Current state',
                            TIME_INTERVAL: 'Time interval',
                            CURRENT_LINEAR_METER: 'Current Linear Meter',
                            GRADE: 'Grade',
                            PAPER_WIDTH: 'Paper Width',
                            TARGET_LINEAR_METER: 'Target Linear Meter',
                            TRIM_AREA: 'Trim Area',
                            TRIM_PERCENTAGE: 'Trim Percentage',
                            TRIM_WIDTH: 'Trim Width',
                            ACTUAL_END: 'Actual End',
                            ACTUAL_START: 'Actual Start',
                            CURRENT_QUANTITY: 'Current Quantity',
                            CUSTOMER: 'Customer',
                            IDENTIFIER: 'Identifier',
                            PLANNED_END: 'Planned End',
                            PLANNED_START: 'Planned Start',
                            PLANNED_QUANTITY: 'Planned Quantity',
                            TARGET_SPEED: 'Target Speed',
                            CURRENT_INVENTORY_LEVEL: 'Current Inventory Level',
                            ACTIVE_ENERGY: 'Electricity (Meter reading)',
                            CURRENT_ACTIVE_POWER: 'Current Active Power',
                            GAS_METER: 'Gas (Meter reading)',
                            WATER_METER: 'Water (Meter reading)',
                            ABSOLUTE_PRESSURE: 'Absolute Pressure',
                            ACCUMULATED_VOLUME: 'Accumulated Volume',
                            DEW_POINT: 'Dew Point',
                            GAUGE_PRESSURE: 'Gauge Pressure',
                            MASS_FLOW_RATE: 'Mass Flow Rate',
                            OIL_CONCENTRATION: 'Oil Concentration',
                            RELATIVE_HUMIDITY: 'Relative Humidity',
                            TEMPERATURE: 'Temperature',
                            VOLUME: 'Volume (Meter reading)',
                            VOLUME_FLOW_RATE: 'Volume Flow Rate',
                        },
                        DISPLAY_UNIT_TRANSLATIONS: {
                            SHEETS_H: 'Sheets/h',
                            SHEETS: 'Sheets',
                            METER_MIN: 'Meter/min',
                            BALE_H: 'Bale/h',
                            PALLET_H: 'Pallets/h',
                        },
                        LIBRARY: {
                            FILTER: {
                                SEARCH: 'Search for templates',
                                CATEGORY: {
                                    CATEGORY: 'Category',
                                    ORDER: 'Order',
                                    SPEED: 'Speed',
                                    RESOURCE: 'Resource',
                                    STATUS: 'State',
                                },
                                ASSET_TYPE: {
                                    ASSET_TYPE: 'Asset type',
                                    CONVERSION: 'Conversion',
                                    CORRUGATOR: 'Corrugator',
                                },
                            },
                            NO_HIT: 'No hit ...',
                            EMPTY_STATE_TEXT:
                                "Sorry, we couldn't find a suitable template for you. Adjust your filters or search and try again!",
                            ADD: 'Add',
                        },
                        TEMPLATES: {
                            ERROR_MESSAGES: {
                                FILL_ALL_FIELDS: 'Fill in all fields of the condition.',
                                FILL_ALL_FIELDS_CORRECTLY:
                                    'Fill in all fields correctly. Note the minimum and maximum values of the fields.',
                            },
                            INPUT_INFO_TOOLTIP: 'Value range:',
                        },
                    },
                    HISTORIAN: {
                        HISTORIAN: 'Historian',
                        START_BUTTON: 'Start',
                        START_TEXT:
                            'The openpack Historian serves as a key manager for all Data Scientists, Data Analysts etc. who need access to the raw data of their machines.',
                        MY_KEYS: 'My Keys',
                        MANAGE_KEYS: 'Manage Keys',
                        MY_KEYS_INFO_TEXT:
                            'Here you can request the keys for your works to get access to the raw data of the machines.',
                        KEY_REQUEST_AGAIN: 'Request key again',
                        WITHDRAW_REQUEST: 'Withdraw request',
                        SHOW_KEY: 'Show key once',
                        KEY_REQUEST: 'Request key',
                        DELETE_KEY: 'Delete key',
                        KEY: 'Key',
                        MODAL_INFO_1: 'This key is displayed only once for security reasons. Please be sure to',
                        MODAL_INFO_2: 'save the key',
                        MODAL_INFO_3: 'before closing.',
                        ID: 'ID',
                        SECRET: 'Secret',
                        S3_URL: 'S3 Bucket URL',
                        CLOSE_BUTTON: 'Close',
                        SAVE_TO_CLIPBOARD: 'Save to clipboard',
                        SAVED_TO_CLIPBOARD: 'Saved to the clipboard',
                        MANAGE_KEYS_REQUESTS: 'Requests',
                        MANAGE_KEYS_NO_REQUESTS: 'Currently no requests available',
                        MANAGE_KEYS_MANAGE_KEYS: 'Manage keys',
                        MANAGE_KEYS_NO_KEYS: 'Currently no keys available',
                        PLANT_FILTER: 'Filter by plant',
                        BUTTON_ACCEPT: 'Accept',
                        BUTTON_DECLINE: 'Decline',
                        TOAST_ACCEPTED: 'Request accepted',
                        TOAST_DECLINED: 'Request declined',
                        TOAST_REQUESTED: 'Wait for commitment',
                        TOAST_REQUESTED_TEXT:
                            'The responsible admin must confirm the request. You will be informed as soon as this is done.',
                        NO_PLANT_FOUND: 'No plant found',
                    },
                },
            },
            de: {
                translation: {
                    DASHBOARD_PERIOD_TOO_LONG_HEADLINE: 'Zeitraum zu lang',
                    DASHBOARD_PERIOD_TOO_LONG_SUBLINE_1: 'Für dieses Widget können nur Daten für einen',
                    DASHBOARD_PERIOD_TOO_LONG_SUBLINE_2: 'maximalen Zeitraum von 3 Tagen angezeigt werden.',
                    ERROR: {
                        TRY_AGAIN: 'Es ist ein Fehler aufgetreten. Bitte versuche es erneut.',
                    },
                    EDUCATION: {
                        NUMBER_HITS: {
                            SUITABLE_SINGLE: 'passende Schulung',
                            SUITABLE_MULTIPLE: 'passende Schulungen',
                            TRAINING_SINGLE: 'Schulung',
                            TRAINING_MULTIPLE: 'Schulungen',
                        },
                        BANNER_TITLE: 'Schulungen rund um Verpackung',
                        BANNER_BETA:
                            'Willkommen zur Beta-Version unserer brandneuen Education-App! Bitte beachte, dass sich die App noch in der Beta-Phase befindet, was bedeutet, dass sie sich noch in der Entwicklung und Verbesserung befindet.',
                        BANNER_DESCRIPTION:
                            'Du möchtest dich auf dem umfangreichen Gebiet der Verpackungen kompetent und einfach weiterbilden? Dann nutze unsere Schulungenapp, um aus einer Vielzahl von Bildungsangeboten, den Kurs zu finden, der am besten zu dir passt.',
                        BANNER_VARIANTS: 'Weiterbildungen | Schulungen | Onlinekurse',
                        SEARCH_PLACEHOLDER: 'Suche deine passende Schulung',
                        FILTERS: {
                            ON_DEMAND: 'Auf Anfrage',
                            PRICE_ON_DEMAND: 'Preis auf Anfrage',
                            ALL: 'Alle',
                            CLEAR_ALL: 'Alle löschen',
                            APPLY_FILTER: 'Filter anwenden',
                            UNDER: 'Unter',
                            OVER: 'Über',
                            FILTER: 'Filter',
                            PROVIDER: 'Anbieter',
                            PERIOD: 'Zeitraum',
                            DURATION: 'Dauer',
                            COSTS: 'Kosten',
                            LOCATION: 'Ort',
                            CONTENT: 'Inhalt',
                            NOTES: 'Anmerkungen',
                            GOAL: 'Ziel',
                            TARGET_GROUP: 'Zielgruppe',
                            ELECTRICS: 'Elektrik',
                            MECHANICS: 'Mechanik',
                            MONTHS: {
                                0: 'Januar',
                                1: 'Februar',
                                2: 'März',
                                3: 'April',
                                4: 'Mai',
                                5: 'Juni',
                                6: 'Juli',
                                7: 'August',
                                8: 'September',
                                9: 'Oktober',
                                10: 'November',
                                11: 'Dezember',
                            },
                        },
                        MORE_COURSES: 'Weitere Kurse von',
                        ACTIONS: {
                            TO_THE_COURSE: 'Zum Kurs',
                        },
                    },
                    SERVICE_STORE: {
                        TOP_SERVICES: 'TOP-Services der Branche',
                        ADVERTISEMENT: 'Werbeanzeige',
                        SEARCH_SERVICES: 'Suche Services',
                        TO_THE_COMPANY: 'Zum Unternehmen',
                        DOCUMENTS_AVAILABLE: 'Dokumente verfügbar',
                        DOCUMENT_AVAILABLE: 'Dokument verfügbar',
                        GO_TO_SERVICE: 'Zum Service',
                        GET_IN_CONTACT: 'Kontakt aufnehmen',
                        RECOMMENDED: 'Empfohlen',
                        MORE_INFOS: 'Mehr Infos',
                        DISCOVER: 'Entdecke',
                    },

                    COCKPIT: {
                        MY_PLANTS: 'Meine Werke',
                        MY_APPS: {
                            TITLE: 'Tools',
                            MOST_USED: 'AM MEISTEN VERWENDET',
                            MORE_APPS: 'WEITERE',
                            PARTNER: 'Partner',
                            PACKAGES: 'Produkte',
                            AVAILABLE: 'Verfügbar für',
                            NOT_AVAILABLE: 'Nicht verfügbar',
                            TOOLS: 'Tools',
                            DATA: 'Daten',
                            CONTACT: 'Kontakt aufnehmen',
                            DIGITAL_FACTORY: {
                                LIVE_VIEW: 'Live View',
                                CONDITION_MONITORING: 'Condition Monitoring',
                                DOCUMENTATION: 'Dokumentation',
                                DASHBOARD: 'Dashboard',
                                PRODUCTION_DATA: 'Produktionsdaten',
                            },
                            RESOURCE_CONTROL: {
                                LIVE_VIEW: 'Live View',
                                CONDITION_MONITORING: 'Condition Monitoring',
                                DOCUMENTATION: 'Documentation',
                                DASHBOARD: 'Dashboard',
                                PRODUCTION_DATA: 'Produktionsdaten',
                                RESOURCE_MANAGER: 'Resource Manager',
                                RESOURCE_DATA: 'Ressourcendaten',
                            },
                            PROCUREMENT: {
                                MARKETPLACE: 'Marketplace',
                                REPORTING: 'Reporting',
                                OCI_EDI: 'OCI/EDI',
                                SUPP_MANAGEMENT: 'Suppliermanagement',
                                ORDER_DATA: 'Auftragsdaten',
                                SHOPPING_BEHAVIOUR: 'Einkaufsverhalten',
                                COMPANY_CATEGORIZATION: 'Firmenspezifische Produktkategorisierung',
                            },
                        },
                        RECOMMENDED_SERVICES: {
                            TITLE: 'Empfohlene Services',
                            LEARN_MORE: 'Mehr erfahren',
                            CONTACT: 'Kontakt aufnehmen',
                        },
                        INVITED_BY: 'Eingeladen von',
                        PLANTS: {
                            INTEREST: 'Interessantes.',
                            IN_A_NUTSHELL: 'Kurz und knapp.',
                            CONNECTED_MACHINES: 'Angebundene Maschinen',
                            CONNECTED_MACHINE: 'Angebundene Maschine',
                            PLANT: 'Werk',
                            PLANTS: 'Werke',
                            UPLOADED_DOCUMENTS: 'Hochgeladene Dokumente',
                            UPLOADED_DOCUMENT: 'Hochgeladenes Dokument',
                            PRODUCED_SQUAREMETERS: 'Produzierte Quadratmeter',
                        },
                        NEWS: {
                            WORTH_KNOWING: 'Wissenswertes auf einen Blick.',
                            TO_WEBSITE: 'Zur Website',
                            LAST_UPDATED: 'Zuletzt aktualisiert',
                            NEWS: {
                                MORE_NEWS: 'Du willst noch mehr Neuigkeiten lesen?',
                                REDIRECT: 'Dann schaue auf der News Seite vorbei!',
                                HEADER: 'Aktuellste Nachrichten aus der Branche',
                            },
                        },
                        WEATHER: { FEELS_LIKE: 'Gefühlt' },
                        STOCKS: { MORE_INFORMATION: 'Mehr Informationen', STOCKS: 'Aktien' },
                        FEFCO_BANNER: {
                            DATE: '20 - 22 Oktober 2021',
                            DAYS: 'Tage',
                            HOURS: 'Stunden',
                            MINUTES: 'Minuten',
                            SECONDS: 'Sekunden',
                        },
                        VISUALIZATION: {
                            DATA_SOURCE: 'Datenquelle',
                            DATA_USE: 'Datenverwendung',
                            COLLECT: 'Verbinden',
                            STANDARDIZE: 'Standardisieren',
                            SHARE: 'Teilen',
                            RECORDS_MIN: 'Datensätze/min',
                            CURRENT_DATA_EXCHANGE: 'Aktueller Datenaustausch',
                            TOTAL_DATA_PROCESSED: 'Verarbeitete Daten gesamt',
                            VARIABLE_USED: 'Verwendete Variablen',
                            FROM_HUB: 'VOM HUB',
                            TO_HUB: 'ZUM HUB',
                            DATA_TOTAL: 'Daten gesamt',
                            NOT_AVAILABLE: 'n.v.',
                        },
                        EMPTY_STATE_HEADER: 'Ups, es scheint, als gäbe es nichts zu sehen.',
                        EMPTY_STATE_TEXT1: 'Es tut uns leid, es sind derzeit keine Informationen verfügbar.',
                        EMPTY_STATE_TEXT2: 'Bitte schaue später wieder vorbei.',
                    },
                    CALENDAR: {
                        JANUARY: 'Januar',
                        FEBRUARY: 'Februar',
                        MARCH: 'März',
                        APRIL: 'April',
                        MAY: 'Mai',
                        JUNE: 'Juni',
                        JULY: 'Juli',
                        AUGUST: 'August',
                        SEPTEMBER: 'September',
                        OCTOBER: 'Oktober',
                        NOVEMBER: 'November',
                        DECEMBER: 'Dezember',
                        MO: 'Mo',
                        TU: 'Di',
                        WE: 'Mi',
                        TH: 'Do',
                        FR: 'Fr',
                        SA: 'Sa',
                        SU: 'So',
                        APPLY: 'Anwenden',
                        CANCEL: 'Abbrechen',
                        FROM: 'Von',
                        TO: 'Bis',
                        TIME_ERROR: 'Bitte überprüfe den ausgewählten Zeitraum',
                        SHIFT_ERROR: 'Wähle mindestens eine Schicht aus',
                        END_DATE_ERROR: 'Bitte wähle einen Endzeitpunkt aus.',
                    },
                    GREETING: {
                        GOOD_MORNING: 'Guten Morgen',
                        GOOD_DAY: 'Guten Tag',
                        GOOD_AFTERNOON: 'Guten Tag',
                        GOOD_EVENING: 'Guten Abend',
                    },
                    WORKFLOW_AREA: {
                        OUTPUT: 'Produzierte Menge',
                        STORAGE: 'Lagerabgleich',
                        AVERAGE_SPEED: 'Ø-Geschwindigkeit',
                        PAPER_STORAGE: 'Papierlager',
                        INTERMEDIATE_STORAGE: 'Zwischenlager',
                        WAREHOUSE: 'Fertigwarenlager',
                        PRODUCTION: 'Produktion',
                        STOP: 'Stopp',
                        SETUP: 'Rüsten',
                        IDLENESS: 'Leerlauf',
                        NOT_AVAILABLE: 'n.v.',
                        RUN_DOWN_TIME: 'Lauf- & Stoppzeiten',
                        SINCE: 'ab',
                        EARLY_SHIFT: 'Frühschicht',
                        LATE_SHIFT: 'Spätschicht',
                        NIGHT_SHIFT: 'Nachtschicht',
                    },
                    ASSET_DATA: {
                        OTHER_TYPE: 'Anderer',
                        CORRUGATOR: {
                            SPLICER: 'Splicer',
                            REEL_STAND: 'Abroller',
                            SINGLE_FACER: 'Single Facer',
                            ADVANCE_FACER: 'Advance Facer',
                            QUICK_FACER: 'Quick Facer',
                            BRIDGE: 'Brücke',
                            PRE_HEATER: 'Vorheizer',
                            GLUE_UNIT: 'Leimwerk',
                            DOUBLE_FACER: 'Heiz- und Zugpartie',
                            ROTARY_SHEAR: 'Kurzquerschneider',
                            SLITTER_SCORER: 'Schneid- und Rillautomat',
                            CUTOFF: 'Querschneider',
                            STACKER: 'Ablage',
                            AUTOMATION_MODULES: 'Automatisierungsbausteine',
                            STEAM_SYSTEM: 'Dampfsystem',
                        },
                    },
                    ASSET_DETAILS: {
                        MANUFACTURER: 'Hersteller',
                        UPLOADFILE: 'Datei hochladen',
                        FILES: 'Dateien',
                        FILE: 'Datei',
                        MACHINE_TYPE: 'Maschinentyp',
                        YEAR_OF_MANUFACTURE: 'Baujahr',
                        ERROR_ADD: 'Fehler beim Hinzügen eines Assets',
                        ERROR_EDIT: 'Fehler beim Editieren eines Assets',
                        SERIAL_NUMBER: 'Seriennummer',
                        DISPLAY_NAME: 'Anzeigename',
                        DOCUMENTS: 'Dateien',
                        AREA: 'Bereich',
                        PRODUCTION_YEAR: 'Produktionsjahr',
                        YEAR_OF_COMMISSIONING: 'Inbetriebnahme',
                        PERIOD_OF_USE: 'Gepl. Nutzungsdauer',
                        COST_CENTER: 'Kostenstelle',
                        BASIC_INFORMATION: 'Basisinformationen',
                        TYPE_INFORMATION: 'Typ',
                        MACHINE_INFORMATION: 'Maschineninformationen',
                        INSTALLATION_INFORMATION: 'Installationsinformationen',
                        DIMENSIONS: 'Ausmaße',
                        LENGTH: 'Länge',
                        WIDTH: 'Breite',
                        HEIGHT: 'Höhe',
                        ENERGY_SOURCE: 'Energieträger',
                        POWER_CONNECTIONS: 'Stromanschlüsse',
                        GAS_CONNECTION: 'Gasanschluss',
                        SENSORS: 'Sensorik',
                        SERIES: 'Serie (Modell)',
                        TYPE: 'Typ',
                        WORKING_WIDTH: 'Arbeitsbreite',
                        MAX_PRODUCTION_SPEED: 'Max. Geschwindigkeit',
                        SHEETS_H: 'Bogen/h',
                        FLUTE_TYPE: 'Wellentyp',
                        SHEET_HEIGHT: 'Bogenhöhe',
                        SHEET_LENGTH: 'Bogenlänge',
                        SHEET_WIDTH: 'Bogenbreite',
                    },
                    ASSET_ADD_EDIT_MODAL: {
                        TYPE_WARNING_MODAL: {
                            CONFIRMATION_BUTTON: 'Weiter',
                            HEADLINE: 'Beeinträchtigte Anzeige in Live',
                            CONFIRMATION_TEXT1:
                                'Du bist dabei den Typ des Assets zu entfernen. Dadurch können keine Daten für dieses Asset in Live angezeigt werden.',
                            CONFIRMATION_TEXT2: 'Willst du trotzdem fortfahren?',
                        },
                        TYPE_SELECTION_INFO: 'Die in Live angezeigten Informationen basieren auf der Auswahl des Typs.',
                        SERIES: 'Serie (Modell)',
                        HEADLINE_EDIT: 'Asset bearbeiten',
                        HEADLINE_ADD: 'Asset hinzufügen',
                        ADD_BUTTON: 'Asset hinzufügen',
                        EDIT_BUTTON: 'Asset ändern',
                        ADD_SUCCESS: 'Asset erfolgreich angelegt',
                        EDIT_SUCCESS: 'Asset erfolgreich geändert',
                        BASIC_INFORMATION: 'Basisinformationen',
                        TYPE_INFORMATION: 'Typ',
                        MANUFACTURER_INFORMATION: 'Herstellerinformationen',
                        DIMENSIONS: 'Ausmaße',
                        DISPLAY_NAME: 'Anzeigename',
                        PICTURE: 'Bild',
                        TYPE: 'Typ',
                        AREA: 'Bereich',
                        ADD: 'Hinzufügen',
                        PRODUCTION_YEAR: 'Produktionsjahr',
                        YEAR_OF_COMMISSIONING: 'Jahr der Inbetriebnahme',
                        MANUFACTURER: 'Hersteller',
                        PERIOD_OF_USE: 'Geplante Nutzungsdauer',
                        COST_CENTER: 'Kostenstelle',
                        TYP: 'Typ',
                        FLUTE_TYPE: 'Wellentyp',
                        SHEET_HEIGHT: 'Bogenhöhe',
                        SHEET_LENGTH: 'Bogenlänge',
                        SHEET_WIDTH: 'Bogenbreite',
                        SERIAL_NUMBER: 'Seriennummer',
                        LENGTH: 'Länge',
                        WIDTH: 'Breite',
                        HEIGHT: 'Höhe',
                        CORRUGATOR: 'Wellpappenanlage',
                        GLUE_KITCHEN: 'Leimküche',
                        STACKER: 'Ablage',
                        REEL_STAND: 'Abroller',
                        PREHEATER: 'Vorheizer',
                        DOUBLE_FACER: 'Heiz- und Zugpartie',
                        LAMINATING_MACHINE: 'Kaschierwerk',
                        ROTARY_SHEAR: 'Kurzquerschneider',
                        CUTOFF: 'Querschneider',
                        RIM_VENTILATION: 'Randabsaugung',
                        SLITTER_SCORER: 'Schneid- und Rillautomat',
                        SHREDDER: 'Schredder',
                        STEAM_BOILER: 'Dampfkessel',
                        TOTAL_PLANT: 'Gesamtes Werk',
                        COMPRESSOR: 'Kompressor',
                        PHOTOVOLTAIC_SYSTEM: 'Photovoltaikanlage',
                        LIGHTS: 'Beleuchtung',
                        BOARD_DIMENSION_CONTROL: 'Board Dimension Control',
                        HYPERBLADE: 'Hyperblade',
                        ZERO_DEFECT_SYSTEM: 'Zero Defect System',
                        SPLICER: 'Splicer',
                        SINGLE_FACER: 'Single Facer',
                        STEAM_SYSTEM: 'Dampfsystem',
                        GLUE_UNIT: 'Leimwerk',
                        BRIDGE: 'Brücke',
                        WORKING_WIDTH: 'Arbeitsbreite',
                        MAX_PRODUCTION_SPEED: 'Max. Geschwindigkeit',
                        CONVERSION: 'Weiterverarbeitung',
                        INLINER: 'Inlinemaschine',
                        ROTARY_DIE_CUTTER: 'Rotationsstanze',
                        FLATBED_DIE_CUTTER: 'Flachbettstanze',
                        FOLDER_GLUER: 'Faltschachtelklebemaschine',
                        FEEDER: 'Feeder',
                        PALLETIZER: 'Palettierer',
                        BUNDLING_MACHINE: 'Bündelmaschine',
                        DIE_CUTTER: 'Stanzmaschine',
                        GLUER: 'Klebemaschine',
                        GLUING_UNIT: 'Leimsystem',
                        WINDER: 'Wickler',
                        PRINTING_UNIT: 'Druckwerk',
                        FOLDER: 'Falter',
                        SLOTTER: 'Rillstation',
                        TAPER: 'Taper',
                        INSERTER: 'Einschub',
                        COUNTER: 'Zählstation',
                        ERROR_MESSAGES: {
                            ENTER_DISPLAY_NAME: 'Bitte gebe einen Anzeigenamen ein.',
                        },
                        STORAGE: 'Lager',
                        PAPER_STORAGE: 'Papierlager',
                        INTERMEDIATE_STORAGE: 'Zwischenlager',
                        FINISHED_GOODS_WAREHOUSE: 'Fertigwarenlager',
                        BALE_STORAGE: 'Ballenlager',
                        CLICHE_STORAGE: 'Klischeelager',
                        TOOL_STORAGE: 'Werkzeuglager',
                        INK_STORAGE: 'Farblager',
                        SPARE_PARTS_STORAGE: 'Ersatzteillager',
                        MAXIMUM_CAPACITY: 'Maximale Kapazität',
                        ROLLS: 'Rollen',
                        PALLETS: 'Palleten',
                        BALES: 'Ballen',
                        TRANSPORTATION_SYSTEM: 'Transportsystem',
                        SHUTTLE: 'Shuttle',
                        CONVEYOR: 'Förderband',
                        TRANSFER_TROLLEY: 'Transferwagen',
                        TRANSPORTATION_STACKER: 'Stapler',
                        TRANSPORTATION: 'Transport',
                        OTHERS: 'Andere',
                        BALING_PRESS: 'Ballenpresse',
                        WATER_TREATMENT: 'Wasseraufbereitung',
                        PALLETIZING_CENTER: 'Palettierzentrum',
                        PAINT_MIXING_SYSTEM: 'Farbmischanlage',
                        WEBTROL: 'Webtrol',
                        CREATE: 'erstellen',
                    },
                    DELETE_ASSET: {
                        HEADLINE: 'Asset löschen?',
                        SUCCESS: 'Asset erfolgreich gelöscht',
                        TEXT: 'Möchtest du das Asset und alle zugehörigen Informationen und Dateien wirlich löschen??',
                        DELETE: 'Löschen',
                        CANCEL: 'Abbrechen',
                        DELETE_TOOLTIP: 'Asset löschen',
                        SERIAL_NUMBER: 'Seriennr.',
                        CONFIRMATION_BUTTON: 'Asset löschen',
                    },
                    DATA: {
                        QUANTITY: {
                            HEADLINE: 'Stückzahl',
                            PRODUCED: 'Produzierte Stückzahl',
                            ORDER: 'Stückzahl Auftrag',
                        },
                        ENERGY: {
                            HEADLINE: 'Strom',
                            OVERALL_CONSUMPTION: 'Gesamtverbrauch',
                            UNIT: 'kWh',
                        },
                        SPEED: {
                            HEADLINE: 'Geschwindigkeit',
                            CURRENT_SPEED: 'Akt. Geschwindigkeit',
                            AVG_SPEED: 'Ø-Geschwindigkeit',
                            TARGET_SPEED: 'Zielgeschwindigkeit',
                            UNIT: 'Bogen/h',
                        },
                        PLANNED_VS_PRODUCED_QUANTITY_PER_HOUR: {
                            HEADLINE: 'Geplante vs. produzierte Menge',
                            HOUR: 'Stunde',
                            STOP_TIME: 'Stoppzeit',
                            SETUP_TIME: 'Rüstzeit',
                            PLANNED_QUANTITY: 'geplante Menge',
                            PRODUCED_QUANTITY: 'produzierte Menge',
                            DELTA: 'Delta',
                            PLANNED: 'Geplant',
                            PRODUCED: 'Produziert',
                            STOPS: 'Stopps',
                            STOP: 'Stopp',
                            SETUPS: 'Setups',
                            SETUP: 'Setup',
                        },
                        CURRENT_ORDER: {
                            ORDER: 'Auftrag',
                            TRIM: 'Randbeschnitt',
                            PRODUCED: 'Produzierte',
                            PREDICTED: 'Vorhergesagt',
                            START: 'Start',
                            FINISH: 'Ende',
                            HEADLINE: 'Aktueller Auftrag',
                            CUSTOMER: 'Kunde',
                            ORDER_NO: 'Auftragsnummer',
                            PRODUCTION_RUN: 'Fertigungsauftrag',
                            PLANNED_START: 'Geplanter Start',
                            ACTUAL_START: 'Tatsächlicher Start',
                            ORDERS: 'Aufträge',
                            PLANNED_QUANTITY: 'Geplante Menge',
                            PLANNED_QUANTITY_CONV: 'Geplante Menge [Boxen]',
                            PLANNED_QUANTITY_CORR: 'Geplante Menge [m]',
                            PROGRESS: 'Fortschritt',
                            ACTUAL_SPEED: 'Aktuelle Geschwindigkeit',
                            COMPLETED: 'Abgeschlossen',
                            NOT_YET_STARTED: 'Noch nicht begonnen',
                            GRADE: 'Sorte',
                            REST_RUNNING_METER: 'Verbleibend',
                            NO: 'Nr.',
                            PLANNED: 'Geplant',
                            PRODUCED_ORDERS: 'Produziert',
                            SHEETS: 'Bögen',
                        },
                        STATUS: {
                            PRODUCTION: 'Produktion',
                            SETUP: 'Rüsten',
                            IDLESNESS: 'Stopp',
                            FAULT: 'Fehler',
                            RUN_STOP: 'Laufzeit / Stoppzeit',
                            TIME: 'Uhrzeit',
                            REASON: 'Stoppgrund',
                            STOPS: 'Stopps',
                            DURATION: 'Dauer',
                        },
                        GOODS_WAREHOUSE: {
                            FLUTE_TYPES: 'Eingelagerte Wellen',
                            UTILIZATION: 'Auslastung',
                            UTILIZATION_GRAPH: 'Auslastung (Letzte 24 Stunden)',
                            SIMULATED_UTILIZATION: 'Simulierte Auslastung',
                            CURRENT_STORED_ORDERS: 'Aktuell eingelagerte Aufträge',
                            ACCUMULATION: 'Lageraufbau / -abbau',
                            OCCUPIED: 'Belegt',
                            PALLETS: '[Paletten]',
                            BALES: '[Ballen]',
                            MAX_CAPACITY: 'Max. Kapazität',
                            NOT_AVAILABLE: 'n.v.',
                            LAST_24_HOURS: 'Letzte 24 Stunden',
                            STORAGE: 'Einlagerung',
                            CUSTOMER: 'Kunde',
                            CUSTOMER_ARTICLE: 'Kundenartikelnummer',
                            ORDER_NUMBER: 'Auftragsnummer',
                            NUMBER_OF_PALLETS: 'Paletten',
                            STORAGE_DURATION: 'Lagerdauer',
                            SMALL: 'Schmal',
                            MEDIUM: 'Mittel',
                            WIDE: 'Breit',
                            TOTAL: 'Gesamt',
                            ERROR_MESSAGE_CRITICAL_STACK_1: 'kritische Stapel prognostiziert.',
                            ERROR_MESSAGE_CRITICAL_STACK_2: 'Für diese Stapel gibt es keine passende Bahn.',
                            ERROR_MESSAGE_CRITICAL_STACK_1_SINGLE: 'kritischer Stapel prognostiziert.',
                            ERROR_MESSAGE_CRITICAL_STACK_2_SINGLE: 'Für diesen Stapel gibt es keine passende Bahn.',
                            ERROR_MESSAGE_CRITICAL_STACK_3: 'Details siehe Diagramm "Prognostizierte Auslastung"',
                            FORECASTED_UTILIZATION: 'Prognostizierte Auslastung',
                            CRITICAL_STACK: 'Kritischer Stapel',
                        },
                        BALE_STORAGE: { NUMBER_OF_BALES: 'Ballen' },
                        WASTE_PRESS: { PASSAGE: 'Durchlauf' },
                        MEAN_TIMES: { MTTR: 'Ø Reparaturzeit', MTBF: 'Ø Betriebszeit zwischen Ausfällen' },
                    },
                    CONFIGURATION: {
                        ADD_SUB_ASSET: 'Untergeordnetes Asset hinzufügen',
                        ADD_ASSET: 'Asset hinzufügen',
                        EDIT_ASSET: 'Speichern',
                        CHOOSE_AREA: 'Bitte wähle einen Bereich aus!',
                        CHOOSE_ASSET: 'Bitte wähle ein Asset aus.',
                        COST_CENTER: 'Kostenstelle',
                        EDIT: 'Bearbeiten',
                        OR: 'ODER',
                        TO: 'zu',
                        DELETE_ASSET: 'Asset löschen',
                        SHOW_INFOS: 'Show infos',
                        SHOW_ASSETS: 'Show assets',
                    },
                    DOCUMENTATION: {
                        QUICK_ACCESS: 'Schnellzugriff',
                        FAVORITE: 'Favoriten',
                        DESCRIPTION: 'Wenn Sie Inhalte als Favoriten markieren, werden diese hier angezeigt.',
                        HEADER: 'Wähle ein Asset und leg sofort los',
                        TEXT1: 'Sobald du mit den ersten Dokumenten gearbeitet hast,',
                        TEXT2: ' werden dir die zuletzt verwendeten Dateien hier angezeigt.',
                        SHOW_DOCUMENTS: 'Dokumente anzeigen',
                        SHOW_ASSETS: 'Assets anzeigen',
                        NO_SUBORDINATE_ASSETS: 'Dieses Asset hat keine untergeordneten Assets.',
                        UPLOAD_DOCUMENTS: 'Dokumente hochladen',
                        CREATE_FOLDER: 'Ordner erstellen',
                        NO_ROOT_ASSETS: 'Keine Assets vorhanden',
                    },
                    LINE_DOCUMENTS: {
                        DOCUMENTS: 'Dokumente',
                        PARTS: 'Teile',
                        INFOS: 'Infos',
                        NAME: 'Name',
                    },
                    AREA_TYPES: {
                        VALUES: {
                            STORAGE: 'Lager',
                            CORRUGATOR: 'Wellpappenanlage',
                            STARCHKITCHEN: 'Leimküche',
                            INTRALOGISTICS: 'Intralogistik',
                            CONVERSION: 'Weiterverarbeitung',
                            INOUTBOUND_LOGISTICS: 'Wareneingang / -ausgang',
                            INTERMEDIATE_STORAGE: 'Zwischenlager',
                            PAPER_STORAGE: 'Papierlager',
                            LOGISTICS: 'Logistik',
                            FINISHED_GOODS_WAREHOUSE: 'Fertigwarenlager',
                            WASTE_MANAGEMENT: 'Abfallentsorgung',
                            OTHERS: 'Andere',
                        },
                    },
                    LINE_DETAILS: {
                        NAME: 'Name',
                        ASSETS: 'Assets',
                        MACHINES: 'Maschinen',
                        DOCUMENTS: 'Dateien',
                        DOCUMENT: 'Datei',
                        FILES: 'Dateien',
                        FILE: 'Datei',
                        FOLDER: 'Ordner hinzufügen',
                        LINE: 'Bereich',
                        TYPE: 'Typ',
                        ERROR_ADD: 'Fehler beim Hinzügen eines Bereichs',
                        ERROR_EDIT: 'Fehler beim Editieren eines Bereichs',
                        DISPLAY_NAME: 'Name',
                        COST_CENTRE: 'Kostenstelle',
                        NUMBER_OF_ASSETS: 'Anzahl Assets',
                        NUMBER_OF_OEMS: 'Anzahl OEMs',
                        ADD_ASSET: 'Asset hinzufügen',
                        ADD_FILE: 'Hochladen',
                        FILTER_IN_ASSETS: 'Assets filtern',
                        FILTER_IN_FILES: 'Dateien filtern',
                        DETAILS_IN: 'Details anzeigen',
                        DETAILS_OUT: 'Details ausblenden',
                    },
                    LINE_ADD_EDIT_MODAL: {
                        HEADLINE_ADD: 'Linie hinzufügen',
                        HEADLINE_EDIT: 'Linie ändern',
                        ADD_BUTTON: 'Linie hinzufügen',
                        EDIT_BUTTON: 'Linie ändern',
                        TYPE: 'Typ',
                        SUCCESS_EDIT: 'Linie erfolgreich geändert',
                        SUCCESS_ADD: 'Linie erfolgreich angelegt',
                    },
                    LINE_DELETE: {
                        HEADLINE: 'Bereich löschen?',
                        TEXT: 'Möchtest du den Bereich sowie alle zugehörigen Maschinen und Dateien wirklich löschen?',
                        LINE: 'Bereich',
                        CONFIRMATION_BUTTON: 'Linie löschen',
                    },
                    DELETE_LINE_ASSET: {
                        HEADLINE_LINE: 'Willst du die Linie wirklich löschen?',
                        HEADLINE_ASSET: 'Willst du das Asset wirklich löschen?',
                        CONFIRMATION_TEXT:
                            'Bist Du sicher, dass Du dieses Asset löschen möchtest? Alle anderen Assets, die an diesem Asset hängen werden ebenfalls gelöscht!',
                        DELETE: 'DELETE',
                    },
                    NAVIGATION_TREE: {
                        PAPERSTORAGE: 'Papierlager',
                        CORRUGATOR: 'Wellpappenanlage',
                        INTERMEDIATE_STORAGE: 'Zwischenlager',
                        CONVERSION: 'Weiterverarbeitung',
                        PALLETIZER: 'Palettierer',
                        LOGISTICS: 'Logistik',
                        FINISHED_GOODS_WAREHOUSE: 'Fertigwarenlager',
                        CHOOSE_PLANT: 'Bitte wähle ein vorhandenes Werk in der Karte aus!',
                        ADD_SUB_ASSET: 'Untergeordnetes Asset hinzufügen',
                    },
                    LIVE: {
                        ACTIVE: 'Aktiv',
                        CONSUMPTION_TODAY: 'Verbrauch (heute)',
                        CONSUMPTION_CURRENT_SHIFT: 'Verbrauch (aktuelle Schicht)',
                        CURRENT_POWER: 'Aktuelle Leistung',
                        CURRENT: 'Aktuell',
                        ELECTRICITY: 'Strom',
                        GAS: 'Gas',
                        COMPRESSED_AIR: 'Druckluft',
                        WATER: 'Wasser',
                        STEAM: 'Dampf',
                        CONSUMPTION: 'Verbrauch',
                        SHEETS: 'Bogen',
                        POWER_CONSUMPTION_TODAY: 'Stromverbrauch (heute)',
                        POWER_CONSUMPTION_CURRENT_SHIFT: 'Stromverbrauch (aktuelle Schicht)',
                        ELECTRICAL_POWER: 'Elektrische Leistung',
                        MISSING_TYPE: 'Daten können nicht angezeigt werden.',
                        MISSING_TYPE_TEXT:
                            'Bitte stelle sicher, dass der richtige Typ in der Konfiguration gespeichert ist.',
                        NOT_CONNECTED_HEADING: 'Maschinen noch nicht angebunden.',
                        NOT_CONNECTED: 'Bitte kontaktiere openpack um die Vorteile von Live nutzen zu können.',
                        NO_SVG_HEADING: 'Werk noch nicht angebunden.',
                        NO_SVG: 'Bitte kontaktieren Sie openpack, um Ihr Werk anzubinden.',
                        NOT_CONNECTED_TOOLTIP: 'Dieses Asset ist noch nicht angebunden!',
                        NOT_CONNECTED_TOOLTIP_WAREHOUSE: 'Dieses Lager ist noch nicht angebunden!',
                        LAST_SHIFT: 'Vorherige Schicht',
                        CURRENT_SHIFT: 'Aktuelle Schicht',
                        BACK: 'Zurück',
                        DOCUMENTS: 'Dokumente',
                        ALARM_OVERVIEW: 'Alarmübersicht',
                        NO_DATA_AVAILABLE: 'Aktuell sind keine Daten verfügbar',
                        STATUS: {
                            PRODUCTION: 'Produktion',
                            SETUP: 'Rüsten',
                            IDLENESS: 'Leerlauf',
                            STOP: 'Stopp',
                        },
                        OPEN_DETAILS: 'Öffne Details',
                        PLANT_VIEW: 'Werksansicht',
                        LIST_VIEW: 'Listenansicht',
                        UPGRADE_RESOURCE_CONTROL: 'Upgrade Resource Control',
                        UPGRADE_MAINTENANCE: 'Upgrade Wartungslösung',
                        TOTAL: 'Gesamt',
                        ELECTRICITY_CONSUMPTION: 'Strom | Verbrauch',
                        ELECTRICITY_CONSUMPTION_TODAY: 'Strom | Verbrauch (heute)',
                        ELECTRICITY_CONSUMPTION_CURRENT_SHIFT: 'Strom | Verbrauch (aktuelle Schicht)',
                        GAS_CONSUMPTION: 'Gas | Verbrauch',
                        GAS_CONSUMPTION_TODAY: 'Gas | Verbrauch (heute)',
                        GAS_CONSUMPTION_CURRENT_SHIFT: 'Gas | Verbrauch (aktuelle Schicht)',
                        COMPRESSED_AIR_CONSUMPTION: 'Druckluft | Verbrauch',
                        COMPRESSED_AIR_CONSUMPTION_TODAY: 'Druckluft | Verbrauch (heute)',
                        COMPRESSED_AIR_CONSUMPTION_CURRENT_SHIFT: 'Druckluft | Verbrauch (aktuelle Schicht)',
                        WATER_CONSUMPTION: 'Wasser | Verbrauch',
                        WATER_CONSUMPTION_TODAY: 'Wasser | Verbrauch (heute)',
                        WATER_CONSUMPTION_CURRENT_SHIFT: 'Wasser | Verbrauch (aktuelle Schicht)',
                        STEAM_CONSUMPTION: 'Dampf | Verbrauch',
                        STEAM_CONSUMPTION_TODAY: 'Dampf | Verbrauch (heute)',
                        STEAM_CONSUMPTION_CURRENT_SHIFT: 'Dampf | Verbrauch (aktuelle Schicht)',
                        PRO: 'Pro',
                        SHOW_CONSUMPTION_DETAIL: 'Detaillierten Verbrauch anzeigen',
                        HIDE_CONSUMPTION_DETAIL: 'Detaillierten Verbrauch ausblenden',
                        OPEN_MAINTENANCE: 'Offene Wartung',
                        OPEN_INCIDENTS: 'Offene Störungen',
                        MAINTENANCE_TASKS: 'Wartungsaufgaben',
                        INCIDENTS_TASKS: 'Störungen',
                        NO_OPEN_MAINTENANCE: 'Aktuell keine offenen Wartungsaufgaben',
                        NO_OPEN_INCIDENTS: 'Aktuell keine offenen Störungen',
                        NO_INCIDENTS: 'Keine Störung',
                        IN_PROGRESS: 'In Arbeit',
                        OPEN_INCIDENT: 'Offene Störung',
                        TABLE: {
                            PRIO: 'Prio',
                            TITLE: 'Titel',
                            DESCRIPTION: 'Beschreibung',
                            AREA: 'Bereich',
                            OCCURRED_AT: 'Aufgetreten am',
                            DUE_ON: 'Fällig am',
                            STATUS: 'Status',
                            CHART_M_TITLE_1: 'Historische Statistiken',
                            CHART_M_TITLE_2: 'Aktuelle Statistiken',
                            CHART_I_TITLE_1: 'Anzahl der abgeschlossenen Störungen',
                            CHART_I_TITLE_2: 'Störungen',
                            CHARTS: {
                                COMPLETED_M: 'Abgeschlossene Wartung',
                                DELAYED_M: 'Verzögerte Wartung',
                                PENDING_M: 'Offene Wartung',
                                FILTER_YESTERDAY: 'Gestern',
                                FILTER_LAST_WEEK: 'Letze Woche',
                                FILTER_TODAY: 'Heute',
                                FILTER_CURRENT_WEEK: 'Aktuelle Woche',
                                CW: 'KW',
                                NUMBER: 'Anzahl',
                            },
                            HIGH: 'HOCH',
                            MEDIUM: 'MITTEL',
                            LOW: 'NIEDRIG',
                            PENDING: 'OFFEN',
                            DELAYED: 'VERZÖGERT',
                            COMPLETED: 'ABGESCHLOSSEN',
                            OPEN: 'OFFEN',
                            NEW: 'NEU',
                            ACCOMPLISHED: 'ABGESCHLOSSEN',
                            FAILED: 'FEHLGESCHLAGEN',
                            RESET: 'ZURÜCKGESETZT',
                            REJECTED: 'ABGEWIESEN',
                            IN_WORK: 'IN ARBEIT',
                            CLOSED: 'GESCHLOSSEN',
                            ELECTRICS: 'Elektrik',
                            MECHANICS: 'Mechanik',
                        },
                        FURTHER: 'weitere',
                    },
                    MAIL_DETAILS: { FROM: 'Von', TO: 'An', DATE: 'Datum', TO_EMAIL: 'Zur Email' },
                    GLOBAL_ERROR: {
                        SOMETHING_WENT_WRONG: 'Hier ist wohl etwas schief gelaufen',
                        BACK: 'Zurück',
                        OR: 'oder',
                        BACK_TO_HOME: 'Zur Karte',
                    },
                    TUTORIAL: {
                        STEP: 'Schritt {{step}} von 5',
                        BACK: 'Zurück',
                        FORWARD: 'Weiter',
                        FIRST: {
                            HEADLINE: `Los geht's`,
                            TEXT: 'Schön, dass du da bist. Hier bekommst du einen kurzen Einblick zu den wichtigsten Funktionen des Cockpits.',
                            LATER: 'Später',
                            BUTTON: 'Tour starten',
                        },
                        SECOND: {
                            HEADLINE: `Deine Apps`,
                            TEXT: 'Mithilfe der openpack Apps kannst du deine Werke verwalten und überwachen, die neusten Nachrichten aus der Wellpappenbranche lesen und vieles mehr.',
                        },
                        THIRD: {
                            HEADLINE: 'Unsere starken Partner',
                            TEXT: 'Im unteren Bereich des Cockpits findest du unsere Partner. Sie bieten dir ein breites Spektrum an Leistungen rund um deine Wellpappenfabrik.',
                        },
                        FOURTH: {
                            HEADLINE: 'Dein Profil und deine Werke',
                            TEXT: 'Im rechten oberen Bereich kannst du dein Profil bearbeiten sowie all deine Werke anlegen und anschließend verwalten.',
                        },
                        FIFTH: {
                            HEADLINE: 'Leg jetzt los.',
                            TEXT: 'Du bist aktuell noch kein Mitglied eines Werkes. Lege entweder jetzt ein neues Werk an oder kontaktiere einen Kollegen und lasse dich von ihm als Mitglied hinzufügen.',
                            BUTTON: 'Werk erstellen',
                            GET_ADDED: 'Ich lasse mich hinzufügen',
                        },
                        SIXTH: {
                            HEADLINE: 'Leg jetzt los.',
                            TEXT: 'Du bist bereits Mitglied eines Werkes und kannst daher sofort loslegen.',
                            BUTTON: 'Jetzt starten',
                        },
                    },
                    NO_PLANTS_MODAL: {
                        HEADLINE: 'Du bist kein Mitglied eines Werkes',
                        TEXT: 'Lass dich einladen',
                        OR: 'oder',
                        BUTTON: 'Erstelle dein Werk',
                    },
                    SEARCH: {
                        SEARCH: 'Suche',
                        MARKETPLACE_NOT_AVAILABLE: 'Die Marketplace Suche ist aktuell nicht verfügbar',
                        HEADLINE: 'Suchergebnisse',
                        RESULTS: ' Treffer für ',
                        RESULT: ' Treffer für ',
                        PLACEHOLDER: 'Suche Dokumente, Maschinen, Ersatzteile etc.',
                        BACK: 'Zurück',
                        ERROR: 'Für ein besseres Suchergebnis bitte mindestens zwei Zeichen verwenden.',
                        ATTACHMENT: 'Anhang',
                        ATTACHMENTS: 'Anhänge',
                        FROM: 'Von',
                        TO: 'An',
                        SHOW_MORE: 'Details einblenden',
                        SHOW_LESS: 'Details ausblenden',
                        FROM_SUPPLIER: 'Von: ',
                        PREVIOUS: 'Zurück',
                        NEXT: 'Weiter',
                        SHOW_ALL: 'Alle anzeigen',
                        SHOW_ALL_DOCS: 'Alle Dokumente anzeigen',
                        SHOW_ALL_PRODUCTS: 'Alle Produkte anzeigen',
                        SHOW_ALL_MACHINES: 'Alle Maschinen anzeigen',
                        SHOW_ALL_NEWS: 'Alle News anzeigen',
                        SHOW_ALL_SERVICES: 'Alle Services anzeigen',
                        NO_RESULTS_HEADER: 'Keinen Treffer gefunden',
                        NO_RESULTS_TEXT:
                            'Versuche deine Suche oder Filter anzupassen, um das zu finden, was du suchst.',
                        SHOW_FILTER: 'Filter anzeigen',
                        RESET_ALL: 'Alles zurücksetzen',
                        FILTER: 'Filter',
                        ALL_PLANTS: 'Alle Werke',
                        ALL_PERIODS: 'Alle Zeiträume',
                        ALL_FORMATS: 'Alle Formate',
                        ALL_SOURCES: 'Alle Quellen',
                        ANY_LANGUAGE: 'Jede Sprache',
                        ALL_COMPANIES: 'Alle Unternehmen',
                        ALL_MARKETPLACES: 'Alle Marktplätze',
                    },
                    SEARCH_TABS: {
                        TAB_ALL: 'Alles',
                        TAB_DOCUMENTS: 'Dokumente',
                        TAB_NEWS: 'News',
                        TAB_MACHINES: 'Maschinen',
                        TAB_SHOP: 'One-Stop-Shop',
                        BUTTON_SHOW_MORE: 'Mehr anzeigen',
                    },
                    SEARCH_BAR: {
                        DATE: 'Zeitpunkt',
                        LAST_TERM: 'Letzter Suchbegriff',
                    },
                    SEARCH_ADVERTISMENT: {
                        ADVERTISMENT: 'ANZEIGE',
                        HEADER1: 'Ihr digitaler Lieferant für',
                        HEADER2: 'individuelle Verpackungen',
                        TEXT1: 'Sparen Sie Zeit und Geld mit unserem',
                        TEXT2: 'web-basierten Einkaufsprozess.',
                    },
                    SEARCH_FILTER_DOCUMENTS: {
                        PLANT: 'Werk',
                        UPLOAD_DATE: 'Uploadzeitraum',
                        FORMAT: 'Format',
                    },
                    SEARCH_FILTER_SHOP: {
                        PRICE: 'Preis',
                        MARKETPLACE: 'Marktplatz',
                    },
                    SEARCH_FILTER_MACHINES: {
                        MANUFACTURER: 'Hersteller',
                    },
                    SEARCH_FILTER_NEWS: {
                        PERIOD: 'Zeitraum',
                        SOURCE: 'Quelle',
                        LANGUAGE: 'Sprache',
                    },
                    SEARCH_FILTER_SERVICES: {
                        COMPANY: 'Unternehmen',
                    },
                    UPLOAD_PERIOD: {
                        LAST24: 'Letzten 24 Stunden',
                        LAST7: 'Letzten 7 Tage',
                        LAST30: 'Letzten 30 Tage',
                        LAST365: 'Letzten 365 Tage',
                    },
                    NOTIFICATIONS: {
                        INVITATION_TO: 'Einladung zu:',
                        REPORT_TEXT_1: 'Dein Report',
                        REPORT_TEXT_2: 'ist jetzt verfügbar.',
                        SET_AS_READ: 'Als gelesen markieren',
                        SET_AS_UNREAD: 'Als ungelesen markieren',
                        DELETE_NOTIFICATION: 'Nachricht löschen',
                        ONLY_SHOW_UNREAD: 'Nur ungelesene anzeigen',
                        SHOW_ALL: 'Alle anzeigen',
                        ZERO_HINTS: 'Sie haben keine Benachrichtigungen aus den letzten 7 Tagen',
                        LAST_7_DAYS_HINTS: 'Das sind alle Ihre Benachrichtigungen der letzten 7 Tage.',
                        NOTIFICATION_SETTINGS: 'Einstellungen für Benachrichtigungen',
                        NOTIFICATIONS: 'Mitteilungen',
                        INVITATION_1: 'hat dich zu',
                        INVITATION_2: ' eingeladen',
                        INVITE_NO_USER: 'hat dich',
                        NEWEST: 'NEUESTE',
                        SET_ALL_READ: 'Alle als gelesen markieren',
                        TODAY: 'Heute',
                        YESTERDAY: 'Gestern',
                        UNKNOWN_USER: 'Ein User',
                        UNKNOWN_USER_COCKPIT: 'einem User',
                        NO_NOTIFICATIONS_TITLE: 'Keine Mitteilungen vorhanden',
                        NO_NOTIFICATIONS_BODY:
                            'Sobald du die ersten Mitteilungen erhalten hast, werden diese hier angezeigt.',
                        DECLINED: 'Abgelehnt',
                        ACCEPTED: 'Akzeptiert',
                    },
                    MAIN: {
                        MORE: 'mehr',
                        LESS: 'weniger',
                        START_NOW: 'Jetzt starten',
                        READ_NOW: 'Jetzt lesen',
                        BROWSE_NOW: 'Jetzt stöbern',
                        WATCH_DEMO: 'Demo ansehen',
                        VIEW_NOW: 'Jetzt ansehen',
                        READY_TO_USE: 'Einsatzbereit',
                        CATEGORIES: {
                            OUR_STRONG: 'Unser starkes',
                            PARTNER_NETWORK: 'Partnernetzwerk',
                        },
                        MY_COCKPIT: 'Mein Cockpit',
                        TO_PARTNER: 'Zum Partner',
                        SEND_MAIL: 'Mail senden',
                        ASSET_MANAGER: {
                            DESCRIPTION: 'Alle relevanten Informationen zu deinen Maschinen auf openpack',
                        },
                        NEWS: {
                            DESCRIPTION: 'Die aktuellsten News aus den sozialen Medien der Wellpappenbranche',
                        },
                        MMM: {
                            DESCRIPTION: 'Die Live-Ansicht deiner Produktion',
                        },
                        MARKETPLACE: {
                            DESCRIPTION: 'Dein One-Stop-Shop für die Wellpappenbranche',
                        },
                        RC: {
                            DESCRIPTION: 'Beschreibung ist in Arbeit',
                        },
                    },
                    FIRST_LOGIN_INITIALIZATION: {
                        FIRST: 'Dein Profil wurde erfolgreich initialisiert.',
                        SECOND: 'Du kannst nun zurück zum Asset Manager',
                        HEADLINE: 'Initalisierung fertiggestellt',
                    },
                    NOTFOUND: {
                        SUBHEADLINE: 'Etwas ist schief gelaufen',
                        ONE: 'Wir können die von dir gesuchte Seite nicht finden...',
                        OR: 'oder',
                        BUTTON_BACK: 'Zurück',
                    },
                    USER_ADD_MODAL: {
                        HEADLINE: 'Nutzer hinzufügen',
                        PLACEHOLDER_EMAIL: 'Email',
                        NOT_FOUND_MESSAGE: 'Kein Nutzer gefunden. Bitte erneut versuchen',
                        ADD_USERBUTTON: 'Nutzer hinzufügen',
                        USER_ALREADY_IN_PLANT: 'Nutzer ist bereits im Werk',
                    },
                    DELETE_USER_FROM_PLANT_MODAL: {
                        HEADLINE: 'Nutzer aus Werk entfernen?',
                        TEXT: 'Bist du dir sicher, dass du den Nutzer aus dem Werk entfernen willst?',
                    },
                    FORM_VALIDATION: {
                        ONLY_NUMBER: 'Aufgrund der Variablenauswahl ist nur eine Zahl als Eingabe zulässig',
                        FILL_ERROR: 'Bitte sämtliche Pflichtfelder füllen',
                        REQUIRED_FIELD: 'Pflichtfeld',
                        CHOOSE_AN_OPTION: 'Wähle eine Option aus',
                        MAX_LENGTH: 'Maximal 255 Zeichen sind erlaubt',
                        NOT_VALID: 'Bitte keine Sonderzeichen verwenden',
                        NOT_UNIQUE: 'Name existiert bereits, bitte einen Anderen verwenden',
                        NOT_VALID_AND_NOT_UNIQUE:
                            'Name existiert bereits, bitte einen Anderen und keine Sonderzeichen verwenden',
                        BETWEEN_ZERO_HUNDRED: 'Der Wert muss zwischen 0 und 100 liegen.',
                        CHOOSE_ASSET: 'Bitte wähle ein Asset aus.',
                    },
                    APP: {
                        CHECKING_EMAIL_VERIFICATION: 'Bitte validieren Sie Ihre E-Mail vor dem Login',
                    },
                    HEADER: {
                        MARKETPLACE: 'Marktplatz',
                        PROFILE: 'Profil',
                        PLANTS: 'Werke',
                        LOGOUT: 'Abmelden',
                        NOTIFICATIONS: 'Mitteilungen',
                        CONFIGURATION: 'Konfiguration',
                    },
                    SIDEBAR: {
                        COCKPIT: 'Cockpit',
                        DIGITAL_FACTORY: 'Digital Factory',
                        LIVE: 'Live View',
                        DASHBOARDS: 'Dashboards',
                        RESOURCE_MANAGER: 'Resource Manager',
                        SERVICE_STORE: 'Service Store',
                        EDUCATION: 'Education',
                        NEWS: 'News',
                        FINANCE: 'Finance',
                        CONFIGURATION: 'Konfiguration',
                        MARKETPLACE: 'Marketplace',
                        FEEDBACK: 'Feedback geben',
                        HELP: 'Hilfe',
                        HELP_MENU: {
                            CONTACT: 'Kontakt',
                            IMPRINT: 'Impressum',
                            PRIVACY: 'Datenschutz',
                            TERMS_OF_USE: 'Nutzungsbedingungen',
                        },
                        DOCUMENTATION: 'Dokumentation',
                        NOTIFICATIONS: 'Mitteilungen',
                        ACCOUNT: 'Konto',
                    },
                    PROFILE: {
                        CHANGE_PASSWORD: 'Passwort ändern',
                        MY_PROFILE: 'Mein Profil',
                        PASSWORD: 'Passwort',
                        NAME: 'Name',
                        CHANGE_NAME: 'Name ändern',
                        EMAIL: 'E-Mail',
                        LANGUAGE: 'Sprache',
                        SAVE_CHANGES: 'Änderungen speichern',
                        EDIT_MY_PROFILE: 'Mein Profil bearbeiten',
                        EMAIL_NOTIFICATIONS_HEADER: 'E-Mail Benachrichtigungen',
                        EMAIL_NOTIFICATIONS_TEXT:
                            'Lege fest, bei welchen Funktionen du zusätzlich noch gerne per E-Mail benachrichtigt werden willst.',
                        EMAIL_SEVERITY_TEXT:
                            'Stelle ein, bei welchen Schweregraden du per E-Mail benachrichtigt werden willst.',
                        INFORMATION: 'Information',
                        WARNING: 'Warnung',
                        ERROR: 'Störung',
                        MY_PLANTS: 'Werkseinladungen',
                        REPORTING: 'Reports',
                        CONDITION_MONITORING: 'Condition Monitoring',
                        HISTORIAN: 'Historian',
                    },
                    CHANGE_PICTURE_MODAL: {
                        HEADLINE: {
                            USER: 'Profilbild hinzufügen',
                            PLANT: 'Bild hinzufügen',
                        },
                        CONFIRMATION_BUTTON: {
                            PLANT: 'Bild hinzufügen',
                            USER: 'Profilbild hinzufügen',
                        },
                        SELECTFILE: 'Datei auswählen',
                        SIZE_EXCEEDED: 'Dateigröße überschreitet {{size}} MB, bitte kleineres Bild verwenden',
                    },
                    DELETE_PICTURE_MODAL: {
                        HEADLINE: {
                            USER: 'Profilbild löschen?',
                            PLANT: 'Bild löschen?',
                        },
                        CONFIRMATION_BUTTON: {
                            PLANT: 'Bild löschen',
                            USER: 'Profilbild löschen',
                        },
                    },
                    PLANTS: {
                        NAME: 'Name',
                        CREATE: {
                            NAME: 'Name',
                            TAXID: 'Umsatzsteuer-Identifikationsnummer',
                            COUNTRY: 'Land',
                            CITY: 'Stadt',
                            HOUSENUMBER: 'Hausnummer',
                            STREET: 'Straße',
                            POSTCODE: 'Postleitzahl',
                            SUCCESS: 'Werk erfolgreich erstellt.',
                            FAILURE: 'Es ist ein Fehler aufgetreten. Bitte noch einmal versuchen.',
                            CONFIRMATION: 'Mit dem Erstellen des Werks bestätigst du die Richtigkeit deiner Angaben.',
                        },
                        EDIT: {
                            SUCCESS: 'Werk erfolgreich geändert.',
                            FAILURE: 'Es ist ein Fehler aufgetreten. Bitte noch einmal versuchen.',
                        },
                        PROFILE: {
                            MEMBERS: 'Mitglieder',
                            ADD_USER: 'Nutzer hinzufügen',
                            INVITATIONS: 'Einladungen',
                            SEND_INVITATION: 'Einladung senden',
                            SEND_INVITATIONS: 'Einladungen senden',
                            TYPE_EMAIL: 'Lade ein Mitglied per Email Adresse ein...',
                            DELETE_INVITATION: 'Bist du dir sicher, dass du die Einladung zurückziehen willst?',
                            DELETE_INVITATION_BUTTON: 'Einladung zurückziehen',
                            DELETE_INVITATION_HEADLINE: 'Einladung zum Werk zurückziehen?',
                            INVITE_MORE: 'Mehrere Mitglieder einladen',
                            ADD_EMAIL_INPUT: 'Weiteres Mitglied einladen',
                            EMAIL_ERROR: 'Bitte Eingabe überprüfen',
                            MANAGE_USER_ROLE: 'Rolle verwalten',
                            REMOVE: 'Entfernen',
                            UPDATE_ROLE: 'Rolle aktualisieren',
                            ROLE: 'Rolle',
                            ADMIN_USER_GENERAL: 'Berechtigungen',
                            ADMIN_GENERAL_INVITE_DESCRIPTION: 'Benutzer einladen / entfernen',
                            ADMIN_GENERAL_MNG_USER_DESCRIPTION: 'Rollen der Benutzer verwalten',
                            ADMIN_GENERAL_MNG_PLANT_DESCRIPTION: 'Werksinformationen verwalten',
                            ADMIN_ASSET_MANAGER_LINE_ASSET_DESCRIPTION: 'Linien & Assets erstellen / löschen',
                            ADMIN_ASSET_MANAGER_MNG_FILES_DESCRIPTION:
                                'Dateien innerhalb der Linien & Assets verwalten',
                            USER_GENERAL_MNG_PLANT_DESCRIPTION: 'Werksinformationen einsehen',
                            USER_ASSET_MANAGER_MNG_FILES_DESCRIPTION: 'Dateien innerhalb der Linien verwalten',
                            WITHDRAW_INVITATION: 'Einladung zurückziehen',
                            TIMEZONE: 'Zeitzone:',
                            INVITATIONS_TAB: 'Einladungen',
                            MEMBERS_TAB: 'Mitglieder',
                            ENERGY_TAB: 'Energietarife',
                            YOU: '(Du)',
                            SEARCH_MEMBERS: 'Mitglieder suchen ...',
                        },
                        OVERVIEW: {
                            MY_PLANTS: 'Meine Werke',
                            INVITATIONS: 'Einladungen',
                            INVITATION: 'Einladung',
                            NO_INVITATIONS: 'Derzeit hast du keine ausstehenden Einladungen.',
                            MY_PLANT: 'Mein Werk',
                            REMAINING_INVITATIONS: 'Du hast {{count}} ausstehende Einladungen',
                            REMAINING_INVITATION: 'Du hast {{count}} ausstehende Einladung',
                            DECLINE: 'Ablehnen',
                            ACCEPT: 'Annehmen',
                            NO_MEMBER: 'Du bist noch kein Mitglied dieses Werks',
                            NOT_CREATED: 'Dieses Werk ist noch nicht angelegt',
                            ADMIN: 'Admin',
                            USER: 'Nutzer',
                        },
                        HEADLINE: 'Werke',
                        HEADLINE_SINGLE: 'Werk',
                        CREATE_LABEL: 'Werk erstellen',
                        EDIT_LABEL: 'Werk ändern',
                        UNASSIGNED: 'Nicht zugewiesen',
                        ENERGY_CONTRACTS: {
                            ENERGY_CONTRACTS: 'Energieverträge',
                            ADD_ENERGY_CONTRACT: 'Energietarif hinzufügen',
                            MY_ENERGY_CONTRACTS: 'Meine Energieverträge',
                            ELECTRICITY: 'Strom',
                            GAS: 'Gas',
                            PROVIDER: 'Anbieter',
                            PROVIDER_NAME: 'Anbietername',
                            ADDRESS: 'Adresse',
                            DURATION: 'Laufzeit',
                            CONTRACT_PERIOD: 'Vertragslaufzeit',
                            PERIOD_OF_NOTICE: 'Kündigungsfrist',
                            PRICES: 'Preise',
                            BASE_PRICE: 'Grundpreis',
                            PRICE_PER_UNIT: 'Preis / Einheit',
                            PRICE_GUARANTEE: 'Preisgarantie',
                            DISCOUNTS: 'Rabatt',
                            DELETE_CONTRACT: 'Energievertrag löschen',
                            DELETE_CONTRACT_TEXT: 'Möchtest du den Energievertrag wirklich löschen?',
                            MONTH: 'Monat',
                            MONTHS: 'Monate',
                            NOT_SPECIFIED: 'Keine Angabe',
                            ACTIVE: 'Aktiv',
                            INACTIVE: 'Inaktiv',
                            EDIT_ENERGY_CONTRACT: 'Energietarif bearbeiten',
                            ENERGY_PROVIDER: 'Energieanbieter',
                            ENERGY_TYPE: 'Art des Energietarifes',
                            ENERGY_TARIFF_NAME: 'Name des Energietarifs',
                            ENERGY_PROVIDER_NAME: 'Name des Energieanbieters',
                            STREET: 'Straße',
                            HOUSE_NUMBER: 'Hausnummer',
                            POSTAL_CODE: 'Postleitzahl',
                            CITY: 'Ort',
                            TARIFF_DETAILS: 'Tarifdetails',
                            PRICE_PER_KWH: 'Preis pro kWh',
                            REBATES_AND_BONUS: 'Rabatte und Boni',
                            MANDATORY_FIELDS: 'Felder mit einem * sind Pflichtfelder.',
                            PERCENT: 'Prozent',
                            START: 'Start',
                            END: 'Ende',
                        },
                    },
                    CHANGE_PASSWORD_MODAL: {
                        TEXT: 'Durch die Bestätigung erhalten Sie eine Email, mit der Sie ihr Passwort zurücksetzen können. Für eine sichere Passwortänderung werden Sie ausgeloggt.',
                        CHANGE_PASSWORD: 'Passwort ändern',
                        CONFIRMATION_BUTTON: 'Passwort ändern',
                        SURE: 'Bist du sicher?',
                    },
                    CHANGE_NAME_MODAL: {
                        CHANGE_NAME: 'Name ändern',
                        FIRST_NAME: 'Vorname',
                        LAST_NAME: 'Nachname',
                        CONFIRMATION: 'Name ändern',
                        SUCCESS: 'Name erfolgreich geändert',
                        ERROR_LASTNAME:
                            'Bitte verwenden Sie mindestens {{min}} und maximal {{max}} Zeichen für den Nachnamen',
                        ERROR_FIRSTNAME:
                            'Bitte verwenden Sie mindestens {{min}} und maximal {{max}} Zeichen für den Vornamen',
                    },
                    DIALOG_MESSAGES: {
                        CANCEL_BUTTON: 'Abbrechen',
                        DELETE_BUTTON: 'Löschen',
                    },
                    DOCUMENTS: {
                        EDIT: 'Bearbeiten',
                        DELETE: 'Löschen',
                        MOVE: 'Verschieben',
                        ADDED_ON: 'Hinzugefügt am',
                        ADDED_BY: 'Hinzugefügt von',
                        NO_DOCS1: 'Keine Dokumente vorhanden.',
                        NO_DOCS2: 'Lade jetzt Dokumente hoch oder erstelle einen Ordner.',
                        NAME: 'Name',
                    },
                    CREATE_FOLDER: {
                        HEADLINE: 'Neuer Ordner',
                        CANCEL: 'Abbrechen',
                        DONE: 'Erstellen',
                        LABEL: 'Ordnername',
                        ERROR_UPLOAD: 'Fehler beim Erstellen des Ordners',
                        ERROR_DELETE: 'Fehler beim Löschen des Ordners',
                        ERROR_EDIT: 'Fehler beim Ändern des Ordners',
                        CREATE: 'Neuen Ordner erstellen',
                        CHOOSE_PATH: 'Speicherpfad für Dateien auswählen',
                    },
                    UPLOAD_FILE: {
                        HEADLINE: 'Datei(en) hochladen',
                        CANCEL: 'Abbrechen',
                        DONE: 'Hochladen',
                        ERROR_UPLOAD: 'Fehler beim Hochladen der Datei(en)',
                        ERROR_DELETE: 'Fehler beim Löschen der Datei',
                        ERROR_EDIT: 'Fehler beim Ändern der Datei',
                        FOLDER_EXISTS: 'Dieser Ordnername existiert bereits.',
                        NO_FILES: 'Es wurden keine Dateien zum Upload ausgewählt!',
                        MACHINE: 'Maschine',
                        ADDED_ON: 'Hinzugefügt am',
                        ADDED_BY: 'Hinzugefügt von',
                    },
                    DROPZONE: {
                        TEXT: 'Dateien hierher ziehen oder klicken',
                    },
                    PARTS: {
                        TOGGLE: 'Teile',
                        HEADER: 'BALD VERF\u00dcGBAR',
                        DESCRIPTION1: 'In Kürze werden dir hier direkt die passenden Teile für dein Asset angezeigt.',
                        DESCRIPTION2: 'Diese kannst du dann direkt über den Marketplace bestellen.',
                        DESCRIPTION3: 'Schon jetzt kannst du aber die Suche nutzen, um dort Teile zu finden.',
                    },
                    LAST_DOCS_FILETEXT: {
                        OPEN_TODAY: 'Zuletzt geöffnet: heute',
                        OPEN_YESTERDAY: 'Zuletzt geöffnet: gestern',
                        UPLOAD_TODAY: 'Hochgeladen: heute',
                        UPLOAD_YESTERDAY: 'Hochgeladen: gestern',
                        OPEN: 'Zuletzt geöffnet: ',
                        UPLOAD: 'Hochgeladen: ',
                    },
                    MOVE_DOCUMENT: {
                        HEADLINE: 'Dokument verschieben nach...',
                        MOVE: 'Bewegen',
                    },
                    EDIT_FOLDER: {
                        HEADLINE: 'Ordner bearbeiten',
                        DONE: 'Umbenennen',
                    },
                    DELETE_FOLDER: {
                        HEADLINE: 'Ordner entfernen?',
                        CONFIRMATION1: 'Der Ordner',
                        CONFIRMATION2:
                            'und alle darin enthaltenen Dateien werden gelöscht. Dies kann nicht rückgängig gemacht werden.',
                        DELETE: 'Ordner löschen',
                    },
                    MOVE_FOLDER: {
                        HEADLINE: 'Ordner verschieben nach...',
                    },
                    MULTIPLE_MOVE: {
                        HEADLINE: 'Objekte verschieben nach...',
                    },
                    MULTIPLE_DELETE: {
                        HEADLINE: 'Objekte entfernen?',
                        CONFIRMATION: 'Bist du dir sicher das du folgende Objekte löschen willst?',
                        DELETE: 'Löschen',
                    },
                    MODAL: {
                        OK_BUTTON: 'Ok',
                        CANCEL_BUTTON: 'Abbrechen',
                    },
                    DELETE_DOCUMENT: {
                        HEADLINE: 'Datei entfernen?',
                        CONFIRMATION: 'Bist du sicher, dass du die Datei löschen willst?',
                        SUCCESS: 'Datei erfolgreich entfernt',
                        CANCEL: 'Abbrechen',
                        DELETE: 'Datei löschen',
                        CONFIRMATION_BUTTON: 'Datei löschen',
                    },
                    EDIT_DOCUMENT_MODAL: {
                        HEADLINE: '{{name}} ändern?',
                        EDIT_BUTTON: 'Datei ändern',
                        SUCCESS: 'Datei erfolgreich geändert',
                        ERROR_MESSAGE: 'Min: 1 Zeichen, erstes Zeichen: kein "."',
                    },
                    NO_AUTHORIZATION: {
                        HEADLINE: 'Fehlende Berechtigung',
                        ONE: 'Wir wissen deine Neugierde zu schätzen, aber leider können wir dir keinen Zugang gewähren, da du',
                        ONE_BOLD: 'kein Administrator',
                        ONE_END: 'dieses Werkes bist.',
                        TWO: 'Bitte wende dich an deinen Administrator, um Zugriff zu erhalten.',
                        BUTTON_BACK: 'Zurück',
                        BUTTON_COCKPIT: 'Zum Cockpit',
                    },
                    NO_PLANT_MEMBER: {
                        HEADLINE: 'Kein Zutritt',
                        ONE: 'Wir wissen deine Neugierde zu schätzen, aber leider können wir dir keinen Zugang gewähren, da du',
                        ONE_BOLD: 'kein Mitglied',
                        ONE_END: 'dieses Werkes bist.',
                        TWO: 'Bitte wende dich an deinen Administrator, um Zugang zu erhalten.',
                        BUTTON_BACK: 'Zurück',
                        BUTTON_COCKPIT: 'Zum Cockpit',
                    },
                    NOT_AVAILABLE_FOR_DEMO_PLANT: {
                        HEADLINE: 'Für Demowerk nicht verfügbar',
                        SUBTEXT:
                            'Condition Monitoring ist für das Demowerk nicht nutzbar. Wähle ein anderes Werk aus, um deine Produktion mithilfe von Conditions zu überwachen.',
                    },
                    MAP_DATA_TIP: {
                        SHEETS_H: 'Bogen/h',
                        FASTEST_RUN: 'Schnellster Lauf',
                        AVERAGE_CURRENT_ORDER: 'Ø aktueller Auftrag',
                        ORDER: 'Auftrag',
                        NUMBER_OF_PALLETS: 'Anzahl der Paletten',
                        WASTE_PRESS: { BALES_H: 'Ballen/h', BALES_QUANTITY: 'Anzahl Ballen' },
                        PALLETIZING_CENTER: { PALLETS_H: 'Paletten/h', PALLETS_QUANTITY: 'Anzahl Paletten' },
                        SHREDDER: { WASTE_TOTAL: 'Abfallmenge [kg]', WASTE_PER_HOUR: 'Abfall [kg]/h' },
                    },
                    RESOURCE_MANAGER: {
                        HEADLINE: 'Resource Manager',
                        EXPORT: 'Als PDF exportieren',
                        TOTAL_ELECTRICITY_CONSUMPTION: 'Entwicklung Stromverbrauch (Gesamtes Werk)',
                        TOTAL_ENPI: 'Entwicklung EnPI Strom (Gesamtes Werk)',
                        SWITCH_TOTAL: 'Absolut',
                        SWITCH_ENPI: 'EnPI',
                        CURRENT_PERIOD: 'Aktueller Zeitraum',
                        PREVIOUS_YEAR: 'Vorjahreszeitraum',
                        MONTHS: {
                            '01': 'Jan',
                            '02': 'Feb',
                            '03': 'März',
                            '04': 'Apr',
                            '05': 'Mai',
                            '06': 'Jun',
                            '07': 'Jul',
                            '08': 'Aug',
                            '09': 'Sept',
                            10: 'Okt',
                            11: 'Nov',
                            12: 'Dez',
                        },
                        ELECTRICITY_CONSUMPTION_OVERVIEW: 'Verbrauchsübersicht (Strom)',
                        GRID_SUPPLY: 'Netzbezug',
                        PV_SUPPLY: 'PV-Nutzung',
                        PV_FEEDIN: 'PV-Einspeisung',
                        ENERGY_FLOW: 'Energiefluss',
                        SOURCE: 'Quelle',
                        CONSUMER: 'Verbraucher',
                        MAINS: 'Netz',
                        PV: 'Photovoltaik',
                        CONSUMPTION_DAILY_AVERAGE: 'Verbräuche (Tagesdurchschnitt)',
                        ELECTRICITY: 'Strom',
                        WATER: 'Wasser',
                        GAS: 'Gas',
                        COMPRESSED_AIR: 'Druckluft',
                        STEAM: 'Dampf',
                        TOOLTIP:
                            'Der Vergleichswert entspricht dem Tagesdurchschnitt aus dem selben Zeitraum im Jahr davor.',
                        COMPARISON: 'Vergleich',
                        RESOURCE_TABLE: {
                            DAILY_AVERAGE: 'Tagesdurchschnitt',
                            TOTAL_CONSUMPTION: 'Gesamtverbrauch',
                            UNITS: 'Einheiten',
                            DEVIATION: 'Abweichung',
                            TOTAL_PLANT: 'Gesamtes Werk',
                            ENTER_BENCHMARK: 'Benchmark eintragen',
                        },
                        LAST_6_MONTHS: 'Letzten 6 Monate',
                        ENERGY_BALANCE: 'Energiebilanz',
                        CONSUMPTIONS: 'Verbräuche',
                        RESOURCE_CONSUMPTION: 'Ressourcenverbräuche (Individueller Zeitraum)',
                        OVERVIEW: 'Übersicht',
                        CONSUMPTION: 'Verbrauch',
                        BENCHMARK: 'Benchmark',
                        ENPI_HISTORY: 'Verlauf EnPI',
                    },
                    DASHBOARDS: {
                        READD_WIDGET_HEADER: 'Widget neu hinzufügen',
                        READD_WIDGET_SUBLINE_1:
                            'Aufgrund einer technischen Anpassung muss das Widget neu zum Dashboard hinzugefügt werden.',
                        NAME: 'Name',
                        LOADING: 'Wird geladen',
                        ALL: 'Alle',
                        SETUP: 'Rüsten',
                        FAULT: 'Stopp',
                        IDLENESS: 'Leerlauf',
                        MACHINE_STATUS: 'Maschinenstatus',
                        NO_PREVIEW_POSSIBLE: 'Noch keine Vorschau möglich',
                        DEFINE_YOUR_WIDGET_1: 'Definiere dein individuelles',
                        DEFINE_YOUR_WIDGET_2: 'Widget noch ausführlicher',
                        TOTAL: 'Gesamt',
                        CHOOSE_VISUALIZATION: 'Wähle eine Darstellungsform',
                        SELECT_ALL: 'Alle auswählen',
                        CLEAR_SELECTION: 'Auswahl löschen',
                        ASSET_SELECTION: 'Assetauswahl',
                        VISUALIZATION: 'Darstellungsform',
                        AREA: 'Bereich',
                        PLANT: 'Werk',
                        ASSET: 'Asset',
                        TABLE: 'Tabelle',
                        CHART: 'Diagramm',
                        LINE_CHART: 'Liniendiagramm',
                        BAR_CHART: 'Balkendiagramm',
                        TOTAL_DROPDOWN: '(Summe)',
                        OVER_TIME_DROPDOWN: '(Zeitverlauf)',
                        DELETE: 'Löschen',
                        ERROR: {
                            TIMEOUT: 'Daten konnten nicht geladen werden',
                            NO_WIDGET_NAME: 'Bitte gebe einen Namen ein.',
                            NO_KPI: 'Bitte wähle eine Kennzahl aus.',
                            NO_ASSET: 'Bitte wähle ein Asset aus.',
                            NO_ASSETS: 'Bitte wähle mindestens ein Asset aus.',
                            WIDGET_NAME_ALREADY_EXISTS: 'Dieser Name ist bereits vergeben. Bitte ändere ihn ab.',
                            NO_PRESENTATION: 'Bitte wähle eine Darstellungsform aus.',
                        },
                        DELETE_WIDGET: {
                            DELETE: 'Widget löschen',
                            TEXT_1: 'Bist du sicher, dass du das Widget löschen willst?',
                            TEXT_2: 'Dieser Vorgang kann nicht rückgängig gemacht werden.',
                        },
                        PREVIEW: 'Vorschau',
                        WIDGET_TYPES: {
                            PRODUCTION: 'Produktion',
                            RESOURCES: 'Ressourcen',
                            MAINTENANCE: 'Wartung',
                            INCIDENTS: 'Störungen',
                            NOT_YET_UNLOCKED: 'Funktion noch nicht freigeschalten...',
                            NOT_YET_UNLOCKED_SUB_1: 'Hole dir die Resource Control Funktion',
                            NOT_YET_UNLOCKED_SUB_2: 'und erweitere deine Dashboards um',
                            NOT_YET_UNLOCKED_SUB_3: 'Energie- und Ressourcendaten.',
                            NOT_YET_UNLOCKED_KPI: 'Keine Produktionskennzahlen verfügbar...',
                            NOT_YET_UNLOCKED_SUB_1_KPI: 'Kontaktiere openpack, um die',
                            NOT_YET_UNLOCKED_SUB_2_KPI: 'notwendigen Daten für deine',
                            NOT_YET_UNLOCKED_SUB_3_KPI: 'Analysen zu erhalten.',
                        },
                        KEY_PERFORMANCE_INDICATOR: 'Kennzahl',
                        ADD_WIDGET: 'Widget hinzufügen',
                        EDIT_WIDGET: 'Widget ändern',
                        RENAME: 'Dashboard umbenennen',
                        NO_WIDGETS: {
                            START_NOW: 'Jetzt starten!',
                            SUB_1: 'Füge dein erstes Widget hinzu und',
                            SUB_2: 'behalte deine wichtigsten Kennzahlen ab sofort im Blick.',
                            SUB_MOBILE:
                                'Füge dein erstes Widget hinzu und behalte deine wichtigsten Kennzahlen ab sofort im Blick.',
                        },
                        EDIT: 'Dashboard bearbeiten',
                        EXPORT: 'Exportieren',
                        BACK: 'Zurück',
                        CHOOSE_ONE_TYPE: 'Bitte wähle mindestens ein Dateiformat aus.',
                        NOT_ALL_RECIPIENTS_MEMBER_OF_PLANT:
                            'Für dieses Werk können im Moment keine Widgets ausgewählt werden. Nicht alle Empfänger der Reportserien dieses Dashboards sind für das Werk zugelassen. Fügen Sie die Nutzer zum Werk hinzu, um neue Widgets hinzufügen zu können.',
                        NO_REPORTS: 'Aktuell sind keine Reports archiviert',
                        DOWNLOAD_ALL: 'Alle downloaden',
                        ARCHIVED: 'archiviert',
                        DELETE_REPORT: 'Wollen Sie die Report Serie wirklich löschen?',
                        DELETE_SERIES: 'Serie löschen',
                        UNSUBSCRIBE_AS_RECIPIENT: 'Als Empfänger abmelden',
                        TO_DASHBOARD: 'Zum Dashboard',
                        RHYTHM: 'Rhythmus',
                        ADD_RECIPIENT: 'Empfänger hinzufügen',
                        MORE: 'Weitere',
                        ENDS_ON: 'Endet am',
                        RECEIVER: 'Empfänger',
                        ADMINISTRATOR: 'Ersteller',
                        DAILY: 'Täglich',
                        WEEKLY: 'Wöchentlich',
                        MONTHLY: 'Monatlich',
                        START: 'Start',
                        END: 'Ende (optional)',
                        WHICH_PERIOD: 'Welchen Zeitraum sollen die Reports jeweils darstellen?',
                        WHICH_SHIFT: 'Welche Schicht sollen die Reports jeweils darstellen? (optional)',
                        LAST_7_DAYS: 'Letzte 7 Tage',
                        FORMAT: 'Format',
                        CREATE_REPORT: 'Report erstellen',
                        CREATE_REPORT_SERIES: 'Reportserie erstellen',
                        MY_REPORTS: 'Meine Reports',
                        REPORT_NAME: 'Reportname',
                        REPORT_SERIES: 'Report Serie',
                        REPORT_ERROR: 'Es wird eine Eingabe benötigt.',
                        ADDITIONAL_RECEIVERS: 'Zusätzliche Empfänger (optional)',
                        ADDITIONAL_RECEIVERS_LIST: 'Zusätzliche Empfänger',
                        RECEIVERS_INFO:
                            'Es können nur Empfänger hinzugefügt werden, die bei openpack. registriert sind.',
                        SINGLE_REPORT: 'Einmaliger Report',
                        GENERATING_PDF: 'PDF wird erstellt...',
                        GENERATING_PPTX: 'PPTX wird erstellt...',
                        GENERATING_EXCEL: 'Excel wird erstellt...',
                        GENERATING_FAILED: 'Erstellung des Exports fehlgeschlagen',
                        DELETE_DASHBOARD: 'Dashboard löschen',
                        SAVE: 'Speichern',
                        DELETE_MODAL: {
                            HEADER: 'Dashboard löschen',
                            BODY: 'Sobald Sie das Dashboard löschen werden alle zugehörigen Report Serien beendet. Dieser Vorgang kann nicht mehr rückgängig gemacht werden. Möchten Sie das Dashboard wirklich löschen?',
                            DELETE_BUTTON: 'Dashboard löschen',
                        },
                        CREATED_ON: 'Erstellt am',
                        PRODUCTION: 'Produktion',
                        ENERGY_CONSUMPTION_PER_ASSET: {
                            ENERGY_CONSUMPTION: 'Energieverbrauch',
                            ASSET: 'Asset',
                        },
                        GAS_CONSUMPTION_PER_ASSET: {
                            GAS_CONSUMPTION: 'Gasverbrauch',
                            ASSET: 'Asset',
                        },
                        COMPRESSED_AIR_CONSUMPTION_PER_ASSET: {
                            COMPRESSED_AIR_CONSUMPTION: 'Druckluftverbrauch',
                            ASSET: 'Asset',
                        },
                        WATER_CONSUMPTION_PER_ASSET: {
                            WATER_CONSUMPTION: 'Wasserverbrauch',
                            ASSET: 'Asset',
                            LITER: 'Liter',
                        },
                        STEAM_CONSUMPTION_PER_ASSET: {
                            STEAM_CONSUMPTION: 'Dampfverbrauch',
                            ASSET: 'Asset',
                        },
                        ORDER_TILE: {
                            ORDERS: 'Aufträge',
                            CUSTOMER: 'Kunde',
                            ORDER_NUMBER: 'Auftragsnummer',
                            GRADE: 'Sorte',
                            SHEETS: 'Bogen',
                            START: 'Startzeit',
                            END: 'Endzeit',
                            DURATION: 'Dauer',
                            IN_PROGRESS: 'In Bearbeitung',
                        },
                        PLANNED_ACTUAL_SQM: {
                            PRODUCED: 'Produzierte m²',
                            TARGET: 'Soll',
                            ACTUAL: 'Ist',
                            CORRUGATOR: 'WPA',
                        },
                        PLANNED_ACTUAL_LM: {
                            LINEAR_METER: 'Laufmeter',
                            CORRUGATOR: 'WPA',
                            TOOLTIP: 'Berechnung der Soll Laufmeter basierend auf Zielgeschwindigkeiten!',
                        },
                        AVERAGE_PRODUCTION_SPEED: {
                            HEADER: 'Ø-Geschwindigkeit',
                            MACHINE: 'Maschine',
                            INDIVIDUAL: 'individuell',
                            CORRUGATOR: 'WPA',
                            CONVERSION: 'Weiterverarbeitung',
                            WITHOUT_FKM: 'ohne FKM',
                            SHEETS: 'Bogen',
                            ASSET: 'Asset',
                        },
                        RUNSTOPTIME: {
                            HEADER: 'Lauf- & Stoppzeiten',
                            RUNTIME: 'Laufzeit',
                            DOWNTIME: 'Stoppzeit',
                            DOWNTIME_COSTS: 'Stillstandskosten',
                            CORRUGATOR: 'WPA',
                            CONVERSION_TOTAL: 'Weiterverarbeitung gesamt',
                            ASSET: 'Asset',
                        },
                        TOP_CUSTOMER: {
                            HEADER: 'Top 10 Kunden nach produziertem Volumen [m²]',
                        },
                        GRADE_OVERVIEW: {
                            HEADER: 'Sortenübersicht [m²]',
                            PARETO: 'Kumulierte Produktionsmenge: ',
                        },
                        STOP_REASONS: {
                            HEADER: 'Top 10 Stoppgründe [Anzahl]',
                            PARETO: 'Kumulierte Anzahl der Stoppgründe: ',
                        },
                        TRIM: { TRIM: 'Randbeschnitt' },
                        TODAY: 'Heute',
                        YESTERDAY: 'Gestern',
                        LAST_5_DAYS: 'Letzte 5 Tage',
                        LAST_30_DAYS: 'Letzte 30 Tage',
                        CUSTOM: 'Benutzerdefiniert',
                        TEXT1: 'Sei gespannt!',
                        TEXT2: 'Hier findest du demnächst Dashboard mit den wichtigsten Kennzahlen deiner Maschinen. Somit hast du diese stets schnell und übersichtlich griffbereit.',
                        WIDGET_TYPE: {
                            ENERGY_CONSUMPTION_PER_1000_M2: 'Energieverbrauch je 1000 Quadratmeter',
                            ENERGY_CONSUMPTION_PER_MACHINE_STATUS: 'Energieverbrauch nach Maschinenstatus',
                            TOTAL_ENERGY_CONSUMPTION_PER_MACHINE_STATUS: 'Energieverbrauch nach Maschinenstatus gesamt',
                            ENERGY_CONSUMPTION_PER_MACHINE_STATUS_PER_ASSET: 'Energieverbrauch nach Maschinenstatus',
                            COMPRESSED_AIR_CONSUMPTION_PER_MACHINE_STATUS: 'Druckluftverbrauch nach Maschinenstatus',
                            TOTAL_COMPRESSED_AIR_CONSUMPTION_PER_MACHINE_STATUS:
                                'Druckluftverbrauch nach Maschinenstatus gesamt',
                            COMPRESSED_AIR_CONSUMPTION_PER_MACHINE_STATUS_PER_ASSET:
                                'Druckluftverbrauch nach Maschinenstatus',
                            ELECTRICAL_POWER_VS_MACHINE_SPEED_OF_ASSET: 'Elektrische Leistung vs. Geschwindigkeit',
                            COMPRESSED_AIR_VS_MACHINE_SPEED_OF_ASSET: 'Druckluft vs. Geschwindigkeit',
                            ELECTRICAL_POWER: 'Elektrische Leistung',
                            ENERGY_CONSUMPTION: 'Energieverbrauch',
                            TOTAL_ENERGY_CONSUMPTION: 'Energieverbrauch gesamt',
                            ENERGY_CONSUMPTION_PER_ASSET: 'Energieverbrauch je Asset',
                            TOTAL_ELECTRICAL_POWER: 'Elektrische Leistung gesamt',
                            ELECTRICAL_POWER_PER_ASSET: 'Elektrische Leistung je Asset',
                            ORDER_OVERVIEW: 'Aufträge',
                            ORDER_OVERVIEW_DESC:
                                'Überblick der Aufträge mit Informationen zu Kunden, Sorten, Auftragsgröße und Produktionszeit.',
                            GRADE_OVERVIEW: 'Sortenübersicht - Top 10',
                            GRADE_OVERVIEW_DESC:
                                'Übersicht der zehn meist produzierten Sorten mit der Produktionsmenge in Quadratmeter und %.',
                            TOP_10_STOP_REASONS: 'Stoppgründe - Top 10',
                            SPEED_GRAPH: 'Geschwindigkeitsverlauf',
                            TOP_10_STOP_REASONS_PER_ASSET: 'Stoppgründe je Asset  - Top 10',
                            TOTAL_TOP_10_STOP_REASONS: 'Stoppgründe gesamt - Top 10',
                            TOP_10_CUSTOMER_PRODUCED_QUANTITY: 'Kunden Übersicht - Top 10',
                            TOP_10_CUSTOMER_PRODUCED_QUANTITY_DESC:
                                'Übersicht der zehn besten Kunden sortiert nach der bestellten Menge in m².',
                            LINEAR_METER: 'Laufmeter (WPA)',
                            LINEAR_METER_DESC:
                                'Vergleich der geplanten und tatsächlichen Produktionsmenge der Wellpappenanlage in Laufmeter.',
                            PRODUCED_M2: 'Produzierte m² (WPA)',
                            PRODUCED_M2_DESC: 'Vergleich der geplanten und produzierten Menge der WPA in m².',
                            TRIM: 'Randbeschnitt',
                            TRIM_DESC: 'Diese Grafik zeigt den Randbeschnitt an der Wellpappenanlage in %.',
                            RUNTIME_DOWNTIME: 'Lauf- & Stoppzeiten',
                            RUNTIME_DOWNTIME_DESC:
                                'Diese Tabelle zeigt die Lauf- & Stoppzeiten der verschiedenen Linien.',
                            AVERAGE_SPEED: 'Ø-Geschwindigkeit',
                            AVERAGE_SPEED_DESC:
                                'Diese Tabelle zeigt die Durchschnittsgeschwindigkeit jeder Linie individuell und in m²/h.',
                            DOWNTIME_COSTS: 'Stillstandskosten',
                            DOWNTIME_COSTS_DESC:
                                'Diese Grafik enthält die Stillstandskosten gesamt und bezogen auf jede Linie.',
                            ORDER_LIST: 'Auftragsliste',
                            GAS_CONSUMPTION: 'Gasverbrauch',
                            TOTAL_GAS_CONSUMPTION: 'Gasverbrauch gesamt',
                            GAS_CONSUMPTION_PER_ASSET: 'Gasverbrauch je Asset',
                            COMPRESSED_AIR_CONSUMPTION: 'Druckluftverbrauch',
                            COMPRESSED_AIR: 'Druckluft',
                            TOTAL_COMPRESSED_AIR_CONSUMPTION: 'Druckluftverbrauch gesamt',
                            COMPRESSED_AIR_CONSUMPTION_PER_ASSET: 'Druckluftverbrauch je Asset',
                            WATER_CONSUMPTION: 'Wasserverbrauch',
                            TOTAL_WATER_CONSUMPTION: 'Wasserverbrauch gesamt',
                            WATER_CONSUMPTION_PER_ASSET: 'Wasserverbrauch je Asset',
                            STEAM_CONSUMPTION: 'Dampfverbrauch',
                            TOTAL_STEAM_CONSUMPTION: 'Dampfverbrauch gesamt',
                            STEAM_CONSUMPTION_PER_ASSET: 'Dampfverbrauch je Asset',
                        },
                        SHIFT: {
                            DATE: 'Datum',
                            SHIFT_OPTIONAL: 'Schicht (optional)',
                            EARLY_SHIFT: 'Frühschicht',
                            LATE_SHIFT: 'Spätschicht',
                            NIGHT_SHIFT: 'Nachtschicht',
                            WIDGET_NOT_AVAILABLE_1: 'Widget nicht verfügbar',
                            WIDGET_NOT_AVAILABLE_2:
                                'Für dieses Widget können nur Daten angezeigt werden, wenn alle Schichten ausgewählt sind.',
                        },
                    },
                    NEW_DASHBOARDS: {
                        HEADER: 'Meine Dashboards',
                        CREATE_BUTTON: 'Dashboard erstellen',
                        CHOOSE_KPI: 'Wähle deine Kennzahlen aus:',
                        CREATE: 'Erstellen',
                        CANCEL: 'Abbrechen',
                        CREATE_TEXT1: 'Erstelle dein individuelles Dashboard.',
                        CREATE_TEXT2: 'Lege dir schnell und einfach ein individuelles Dashboard an,',
                        CREATE_TEXT3: 'das ganz auf deine Bedürfnisse angepasst ist.',
                        CREATE_TEXT_MOBILE:
                            'Lege dir schnell und einfach ein individuelles Dashboard an, das ganz auf deine Bedürfnisse angepasst ist.',
                        NO_DASHBOARD_NAME: 'Bitte gib einen Namen ein.',
                        CHOOSE_KPI_ERROR: 'Bitte wähle mindestens eine Kennzahl aus.',
                        NAME: 'Name',
                    },
                    FINANCE: {
                        START: {
                            TYPE_OF_FINANCING: 'Art der Finanzierung',
                            START_REQUEST: 'Jetzt starten',
                            CHOOSE_TYPE: 'Wähle die gewünschte Art der Finanzierung aus',
                            MORE_INFORMATION: 'Mehr Informationen über',
                            FINANCING: 'Finanzierung',
                            LEASING: 'Leasing',
                            COOPERATION: 'In Kooperation mit',
                            FINANCING_COMPARISON: 'Finanzierungsvergleich',
                            POINT_1: 'Kostenfrei & unverbindlich',
                            POINT_2: 'Unabhängige Angebote von Premiumpartnern',
                            POINT_3: 'Schnelles und einfaches Erstellen von Anfragen innerhalb von 5 Minuten',
                            HEADER: 'Schnell und einfach einen Kredit anfragen',
                            TAB1: 'Kredit beantragen',
                            TEXT1: 'In Kooperation mit unserem Finanzpartner ',
                            TEXT2: ' haben Sie die Möglichkeit unkompliziert und innerhalb',
                            TEXT3: ' von nur 3 Minuten',
                            TEXT4: ' einen Kredit zu beantragen.',
                            BUTTON: 'Jetzt starten',
                            INPUT: 'Kreditbetrag',
                            TRANSMITTED: 'Bereits übermittelte Kreditanfragen:',
                            NEXT: 'Weiter',
                            CHOOSE: 'Bitte wählen',
                            EMPTY_FIELD: 'Bitte fülle dieses Feld aus.',
                            EMPTY_FIELDS: 'Bitte fülle diese Felder aus.',
                            INFO: 'Infos',
                            CREDIT_AREA: 'Der Kredit muss zwischen 50.000 und 50.000.000 EUR liegen',
                            WRONG_MAIL: 'E-Mail nicht korrekt!',
                            NO_OPTIONS: 'Kein passendes Land gefunden',
                        },
                        INFO: {
                            FINANCING: 'Finanzierung',
                            FINANCING_DFKP: {
                                HEADER1: 'Wie läuft die Kreditfinanzierung ab?',
                                HEADER2: 'Welche Informationen sind für die Anfrage notwendig?',
                                HEADER3: 'Welche Vorteile habe ich?',
                                HEADER4: 'Wo wird der Kredit beantragt?',
                                TEXT1: 'Nachdem das Formular ausgefüllt und anschließend an unseren Partner übermittelt wurde, setzt sich unser Partner mit dem/der Antragssteller/-in in Verbindung. Nach Vorlage der notwendigen Unterlagen ist die DFKP in der Lage, binnen 48h eine positive Kreditentscheidung durch den passenden Finanzierungspartner herbeizuführen. Im Anschluss ist eine Auszahlung innerhalb von 2-5 Tagen möglich.',
                                TEXT2: 'Für Ihre Finanzierungsanfrage benötigen wir im ersten Schritt nur wenige Angaben zu Ihrem Unternehmen (darunter Name, Rechtsform, Gründungsdatum) und Ihnen als Kontaktperson. In einem zweiten Schritt (nach dem Unternehmergespräch) benötigen wir die folgenden Dokumente:',
                                TEXT3: 'Unser Partner, die DFKP, ist eine unabhängige Finanzierungsplattform und bietet eine transparente und zielorientierte Beratung bei der Suche nach der optimalen Finanzierung für Ihr nächstes Finanzierungsvorhaben. Zu den wichtigsten Bausteinen ihrer Dienstleistung gehören:',
                                TEXT4: 'Die DFKP arbeitet mit den wichtigsten deutschen KMU-Finanzierern zusammen. Darunter befinden sich bundesweit aktive Universalbanken, Regionalbanken, Genossenschaftsbanken, KMU-Finanzierer, Neobanken, Schwarmfinanzierer, Online-Marktplätze, Einkaufsfinanzierer und Leasinggesellschaften. Sparkassen zählen zurzeit nicht zu ihren Finanzierungspartnern. Nach Prüfung der Unterlagen wird die Kreditanfrage an die ideale Finanzierer von seitens DFKP gestellt.',
                                POINT1: 'Jahresabschlüsse der letzten beiden Geschäftsjahre (falls vorhanden)',
                                POINT2: 'Aktuelle BWA inkl. SuSa',
                                POINT3: 'Banken- bzw. Verbindlichkeitenspiegel',
                                POINT4: 'Kontoauszüge der letzten 90 Tage',
                                POINT5: 'Schneller Online-Antrag und Kontaktaufnahme durch einen unserer Finanzierungsberater am gleichen Tag',
                                POINT6: 'Persönliches Unternehmergespräch einschl. Kreditpotenzialanalyse und Live-Konditionsschätzung',
                                POINT7: 'Unterlagenaufbereitung und Prozessmanagement bis zur Auszahlung beim passenden Finanzierer',
                                POINT8: 'Ganzheitliche Beratung nach Abschluss und Durchführung von Folgefinanzierungen',
                            },
                            FINANCING_DL: {
                                HEADER1: 'Wie läuft die Erstellung des Finanzierungsangebots ab?',
                                HEADER2:
                                    'Welche Informationen müssen im weiteren Prozess zur Verfügung gestellt werden?',
                                HEADER3: 'Welche Vorteile habe ich durch die Deutsche Leasing?',
                                TEXT1: 'Nachdem das Formular ausgefüllt und anschließend an die Deutsche Leasing AG übermittelt wurde, setzt sich die Deutsche Leasing mit dem/der Antragssteller/-in in Verbindung. Auf Basis der Informationen erhalten Sie binnen 2 Arbeitstagen ein länderspezifisches Angebot.',
                                TEXT2: 'In Vorbereitung des konkreten Investitionsgesprächs mit dem Unternehmen benötigen wir',
                                TEXT3: 'Als Asset-Finance-Partner des deutschen Mittelstands bieten wir Ihnen die für Sie passenden Finanzierungslösungen. Die Deutsche Leasing bietet Ihnen in 24 Ländern lokale Finanzierungslösungen an. In vielen weiteren Ländern können wir Ihnen bei der Finanzierung von Maschinen Lösungen anbieten.',
                                TEXT4: 'Profitieren Sie von individuellen Finanzierungsstrukturen und einem deutsch sprechenden German Desk im Land Ihrer Finanzierungswünsche. Persönlich, kompetent und zuverlässig. Darüber hinaus können wir Ihnen auch den Zugang zu unserem internationalen Netzwerk mit Experten ermöglichen. Finanzierungsthemen sind sehr länderspezifisch. Ein zentraler Ansprechpartner betreut Sie weltweit!',
                                POINT1: 'Jahresabschlüsse der letzten beiden Geschäftsjahre',
                                POINT2: 'Aktuelle BWA inkl. SuSa sowie Vorjahresvergleich',
                                POINT3: 'Bankenspiegel / Liquiditätsplanung inkl. Fristigkeiten und Sicherheiten',
                                POINT4: 'Akt. Planung sowie Folgejahre',
                            },
                            LEASING_DFKP: {
                                HEADER1: 'Wie läuft die Erstellung des Leasing-Angebots ab ab?',
                                HEADER2: 'Welche Informationen sind für die Anfrage notwendig?',
                                HEADER3: 'Welche Vorteile habe ich?',
                                HEADER4: 'Wo wird der Kredit beantragt?',
                                TEXT1: 'Nachdem das Formular ausgefüllt und anschließend an unseren Partner übermittelt wurde, setzt sich unser Partner mit dem/der Antragssteller/-in in Verbindung. Nach Vorlage der notwendigen Unterlagen ist die DFKP in der Lage, binnen 48h eine positive Kreditentscheidung durch den passenden Finanzierungspartner herbeizuführen. Im Anschluss ist eine Auszahlung innerhalb von 2-5 Tagen möglich.',
                                TEXT2: 'Für Ihre Finanzierungsanfrage benötigen wir im ersten Schritt nur wenige Angaben zu Ihrem Unternehmen (darunter Name, Rechtsform, Gründungsdatum) und Ihnen als Kontaktperson. In einem zweiten Schritt (nach dem Unternehmergespräch) benötigen wir die folgenden Dokumente:',
                                TEXT3: 'Unser Partner, die DFKP, ist eine unabhängige Finanzierungsplattform und bietet eine transparente und zielorientierte Beratung bei der Suche nach der optimalen Finanzierung für Ihr nächstes Finanzierungsvorhaben. Zu den wichtigsten Bausteinen ihrer Dienstleistung gehören:',
                                TEXT4: 'Die DFKP arbeitet mit den wichtigsten deutschen KMU-Finanzierern zusammen. Darunter befinden sich bundesweit aktive Universalbanken, Regionalbanken, Genossenschaftsbanken, KMU-Finanzierer, Neobanken, Schwarmfinanzierer, Online-Marktplätze, Einkaufsfinanzierer und Leasinggesellschaften. Sparkassen zählen zurzeit nicht zu ihren Finanzierungspartnern. Nach Prüfung der Unterlagen wird die Kreditanfrage an die ideale Finanzierer von seitens DFKP gestellt.',
                                POINT1: 'Jahresabschlüsse der letzten beiden Geschäftsjahre (falls vorhanden)',
                                POINT2: 'Aktuelle BWA inkl. SuSa',
                                POINT3: 'Banken- bzw. Verbindlichkeitenspiegel',
                                POINT4: 'Kontoauszüge der letzten 90 Tage',
                                POINT5: 'Schneller Online-Antrag und Kontaktaufnahme durch einen unserer Finanzierungsberater am gleichen Tag',
                                POINT6: 'Persönliches Unternehmergespräch einschl. Kreditpotenzialanalyse und Live-Konditionsschätzung',
                                POINT7: 'Unterlagenaufbereitung und Prozessmanagement bis zur Auszahlung beim passenden Finanzierer',
                                POINT8: 'Ganzheitliche Beratung nach Abschluss und Durchführung von Folgefinanzierungen',
                            },
                            LEASING_DL: {
                                HEADER1: 'Wie läuft die Erstellung des Leasing-Angebots ab?',
                                HEADER2:
                                    'Welche Informationen müssen im weiteren Prozess zur Verfügung gestellt werden?',
                                HEADER3: 'Welche Vorteile habe ich durch die Deutsche Leasing?',
                                TEXT1: 'Nachdem das Formular ausgefüllt und anschließend an die Deutsche Leasing AG übermittelt wurde, setzt sich die Deutsche Leasing mit dem/der Antragssteller/-in in Verbindung.',
                                TEXT1_2:
                                    'Auf Basis der Informationen erhalten Sie binnen 48 h ein länderspezifisches Angebot.',
                                TEXT2: 'In Vorbereitung des konkreten Investitionsgesprächs mit dem Unternehmen benötigen wir:',
                                TEXT3: 'Als Asset-Finance-Partner des deutschen Mittelstands bieten wir Ihnen die für Sie passenden Finanzierungslösungen. Die Deutsche Leasing bietet Ihnen in 24 Ländern lokale Finanzierungslösungen an. In vielen weiteren Ländern können wir Ihnen bei der Finanzierung von Maschinen Lösungen anbieten.',
                                TEXT4: 'Profitieren Sie von individuellen Finanzierungsstrukturen und einem deutsch sprechenden German Desk im Land Ihrer Finanzierungswünsche. Persönlich, kompetent und zuverlässig. Darüber hinaus können wir Ihnen auch den Zugang zu unserem internationalen Netzwerk mit Experten ermöglichen. Finanzierungsthemen sind sehr länderspezifisch. Ein zentraler Ansprechpartner betreut Sie weltweit!',
                                POINT1: 'Jahresabschlüsse der letzten beiden Geschäftsjahre',
                                POINT2: 'Aktuelle BWA inkl. SuSa sowie Vorjahresvergleich',
                                POINT3: 'Bankenspiegel / Liquiditätsplanung inkl. Fristigkeiten und Sicherheiten',
                                POINT4: 'Akt. Planung sowie Folgejahre',
                            },
                        },
                        STEP1: {
                            COUNTRY_OF_ORIGIN: 'Ursprungsland der Ware',
                            TOTAL_CONTRACT_VALUE: 'Auftragswert',
                            HEADER_FINANCING: 'Kreditdetails',
                            HEADER_LEASING: 'Leasingdetails',
                            OBJECT: 'Vertragsgegenstand',
                            RUNTIME: 'Laufzeit',
                            RTOPTION1: '24 Monate',
                            RTOPTION2: '30 Monate',
                            RTOPTION3: '36 Monate',
                            RTOPTION4: '42 Monate',
                            RTOPTION5: '48 Monate',
                            RTOPTION6: '54 Monate',
                            RTOPTION7: '60 Monate',
                            RTOPTION8: '66 Monate',
                            RTOPTION9: '72 Monate',
                            RTOPTION10: '78 Monate',
                            RTOPTION11: '84 Monate',
                            RTOPTION12: '90 Monate',
                            RTOPTION13: '96 Monate',
                            TOOLTIP_FINANCING:
                                'Hierbei handelt es sich um den Ursprungsland des Gutes, für das das Finanzierungsangebot eingefordert wird.',
                            TOOLTIP_LEASING:
                                'Hierbei handelt es sich um den Ursprungsland des Gutes, für das das Leasingangebot eingefordert wird.',
                        },
                        STEP2: {
                            SELECT_TYPE_NEW: 'Wähle ein Bestehendes oder nenne Neues',
                            HEADER: 'Unternehmensinfos',
                            COMPANY: 'Unternehmen',
                            LEGALFORM: 'Rechtsform',
                            STREET: 'Straße',
                            HOUSENUMBER: 'Hausnummer',
                            POSTALCODE: 'Postleitzahl',
                            CITY: 'Stadt',
                            COUNTRY: 'Land',
                            LFOPTION1: 'GmbH',
                            LFOPTION2: 'GmbH & Co. KG',
                            LFOPTION3: 'AG',
                            LFOPTION4: 'GbR',
                            LFOPTION5: 'PartG',
                            LFOPTION6: 'OHG',
                            LFOPTION7: 'KG',
                            LFOPTION8: 'KGaA',
                            LFOPTION9: 'UG',
                            LFOPTION10: 'eG',
                            LFOPTION11: 'eV',
                            LFOPTION12: 'Freiberufler/Selbstständige',
                            LFOPTION13: 'Einzelunternehmen',
                            LFOPTION14: 'Sonstige',
                            LFOPTION15: 'Ltd',
                            COMPANY1: 'CIPA GmbH',
                        },
                        STEP3: {
                            HEADER: 'Kontaktdaten',
                            FIRSTNAME: 'Vorname',
                            LASTNAME: 'Nachname',
                            EMAIL: 'E-Mail (geschäftlich)',
                            TELEPHONE: 'Telefon',
                            COMMENT: 'Bemerkung',
                            COMMENTPLACEHOLDER: 'Sie wollen uns noch etwas mitteilen?',
                            BUTTON: 'Weiter',
                        },
                        STEP4: {
                            EMAIL: 'E-Mail',
                            HEADER: 'Senden an',
                            TEXT1: 'An welchen Partner sollen deine Daten geschickt werden? Du wirst von jedem Partner ein unabhängiges Angebot erhalten.',
                            TIP: 'Tipp: ',
                            TEXT2: 'Wähle alle Partner aus, um zu sehen, wer das beste Angebot für deine Geschäftsanforderungen hat. Es ist kostenlos!',
                            DL: 'Die Deutsche Leasing Gruppe ist der führende Asset-Finance-Partner für den deutschen Mittelstand und bietet ein breites Spektrum an investitionsbezogenen Finanzierungslösungen. ',
                            DFKP: 'DFKP begleitet dich zielgerichtet zu deinem nächsten Firmenkredit beim optimal passenden Finanzierer. Hierbei steht die Beratung im Mittelpunkt.',
                            GERMANY: 'Deutschland',
                            SEND_REQUEST: 'Anfrage senden',
                            ERROR: 'Bitte wähle mindestens einen Partner aus.',
                        },
                        STEP5: {
                            HEADER: 'Zusammenfassung',
                            CREDITDETAILS: 'Kreditdetails',
                            LEASINGDETAILS: 'Leasingdetails',
                            COMPANYINFO: 'Unternehmensinfos',
                            PERSONALDATA: 'Persönliche Daten',
                            EDIT: 'Bearbeiten',
                            COMPANYNAME: 'Unternehmensname',
                            STREET_HOUSENUMBER: 'Straße/Hausnummer',
                            POSTALCODE_CITY: 'PLZ/Stadt',
                            FIRSTNAME_LASTNAME: 'Vor-/Nachname',
                            EMAIL: 'E-Mail',
                            COMMENT: 'Bemerkung',
                            SUBMIT: 'Unverbindliche Anfrage senden',
                            AUTHORIZED1: 'Ich bin unternehmensintern dazu ',
                            AUTHORIZED2: 'berechtigt ',
                            AUTHORIZED3: 'Kreditanfragen zu stellen.',
                            AGB1: 'Meine angegebenen Daten werden ausschließlich für die Finance-App, unter anderem für die Erstellung eines Kreditangebots, gemäß openpack ',
                            AGB2: 'Datenschutzbestimmungen',
                            AGB3: ' , verwendet.',
                        },
                        STEP6: {
                            HEADER1: 'Die Daten wurden',
                            HEADER2: 'erfolgreich ',
                            HEADER3: 'übermittelt.',
                            TEXT_DFKP: 'Ein Ansprechpartner der DFKP (Deutsche Firmen Kreditpartner) wird',
                            TEXT_DL: 'Ein Ansprechpartner der Deutsche Leasing AG wird',
                            TEXT_ALL: 'Unsere Partner werden',
                            TEXT: 'sich schnellstmöglich mit Ihnen in Verbindung setzen, um die weiteren Schritte zu besprechen. Wie wünschen weiterhin viel Erfolg.',
                            BUTTON: 'Zurück zur Startseite',
                        },
                        ERROR: {
                            TEXT_1: 'Es ist ein Fehler aufgetreten.',
                            TEXT_2: 'Die Anfrage konnte leider nicht übermittelt werden.',
                            BUTTON_1: 'Anfrage erneut senden',
                            BUTTON_2: 'Zurück zur Startseite',
                        },
                        BANNER: {
                            TEXT1: 'PAY-PER-USE',
                            TEXT2: 'Finanzierungsmodelle',
                            TEXT3: 'für die Industrie.',
                        },
                    },
                    SMARTENANCE: {
                        HEADER: 'Wartung',
                        LEFT: {
                            CHART_HEADER: 'Status heute',
                            OPEN: 'Offene Wartungen',
                            DONE: 'Erledigte Wartungen',
                            OVERDUE: 'Verspätete Wartungen',
                            THIS_WEEK: 'Diese Woche',
                            LAST_WEEK: 'Letzte Woche',
                            COMPLETED: 'abgeschlossen',
                        },
                        RIGHT: {
                            HEADER1: 'Stillstand durch Wartung',
                            HEADER2: 'Stillstand durch Wartung (geplant)',
                            THIS_MONTH: 'Laufender Monat',
                            LAST_MONTH: 'Letzter Monat',
                            LAST_WEEK: 'Letzte Woche',
                            THIS_WEEK: 'Laufende Woche',
                            NEXT_WEEK: 'Nächste Woche',
                            HOURS: 'Stunden',
                            MINUTES: 'Minuten',
                        },
                        BOTTOM: {
                            TAB1: 'Wartungen',
                            TAB2: 'Störungen',
                            TABLE1: {
                                HEADER1: 'Status',
                                HEADER2: 'Beschreibung',
                                HEADER3: 'Fällig am',
                                HEADER4: 'Durchgeführt am',
                            },
                            TABLE2: { HEADER1: 'Status', HEADER2: 'Beschreibung', HEADER3: 'Zeitstempel' },
                            STATUS_MAINTENANCE: { GREEN: 'Erledigt', GRAY: 'Offen', YELLOW: 'Überfällig' },
                            STATUS_INCIDENT: { GREEN: 'Geschlossen', RED: 'Offen', YELLOW: 'In Arbeit' },
                            TABLE1_CONTENT: {
                                LINE1_DESCRIPTION: 'Schmierung des Druckwerks',
                                LINE1_DUE: '01.01.2022',
                                LINE1_PERFORMED: '-',
                                LINE2_DESCRIPTION: 'Reinigung oder Wechsel der Filter ',
                                LINE2_DUE: '10.01.2022',
                                LINE2_PERFORMED: '-',
                                LINE3_DESCRIPTION: 'Reinigung und Schmierung der Walzen',
                                LINE3_DUE: '03.01.2022',
                                LINE3_PERFORMED: '03.01.2022',
                                LINE4_DESCRIPTION: 'Wartung des Druckluftaggregats',
                                LINE4_DUE: '03.01.2022',
                                LINE4_PERFORMED: '03.01.2022',
                                LINE5_DESCRIPTION: 'Kontrolle und Austausch der Lager',
                                LINE5_DUE: '05.01.2022',
                                LINE5_PERFORMED: '05.01.2022',
                            },
                            TABLE2_CONTENT: {
                                LINE1_DESCRIPTION: 'Verstopfung des Abfallbands',
                                LINE1_TIMESTAMP: '-',
                                LINE2_DESCRIPTION: 'Defekt in der Elektrik',
                                LINE2_TIMESTAMP: '05.01.2022 - 14:24',
                                LINE3_DESCRIPTION: 'Bündelmaschine am Palettierer defekt',
                                LINE3_TIMESTAMP: '03.01.2022 -  09:15',
                            },
                        },
                    },
                    DROPDOWN: {
                        NO_OPTIONS: 'Keine Optionen',
                    },
                    CONDITION_MONITORING: {
                        NOT_AVAILABLE_MOBILE_HEADER: 'Condition Monitoring nicht verfügbar',
                        NOT_AVAILABLE_MOBILE_BODY:
                            'Aktuell ist das Condition Monitoring auf mobilen Endgeräten nicht verfügbar. Wir arbeiten daran, dass du auch bald unterwegs deine Conditions verwalten kannst.',
                        ASSET: 'Asset',
                        ONLY_NUMBER_ALLOWED: 'Bitte eingabe überprüfen',
                        TITLE_ALREADY_IN_USE: 'Der Titel ist bereits in Gebrauch. Bitte wählen Sie einen Anderen.',
                        ARBITRARY: 'Beliebiger Wert',
                        DATA_SOURCE: 'Datenquelle',
                        EDIT_HINT:
                            'Es können lediglich die Werte in den Freitextfeldern bearbeitet werden. Für weitere Änderungen erstelle eine neue Condition oder dupliziere diese.',
                        ADDED_USER: 'Ausgewählte Nutzer',
                        SHARE_ONLY_O_USER:
                            'Die Condition kann nur mit Usern geteilt werden, die bei openpack. registriert sind',
                        SHARE_CONDITION: 'Condition teilen',
                        TIME_INTERVAL: 'Zeitintervall',
                        CALCULATION_FUNCTION: 'Rechenfunktion',
                        AGGREGATION: 'Aggregation',
                        WITH: 'mit',
                        INFORMATION_MODEL: 'Informationsmodell',
                        VARIABLE: 'Variable',
                        SHARE_WITH_USER: 'Condition mit weiteren Usern teilen',
                        DEACTIVATE_CONDITION: 'Deaktiviere Bedingung, wenn...',
                        CONDITION_REQUIRED: 'Fülle alle Felder der Bedingung aus',
                        CREATE: 'Erstellen',
                        ANY: 'Nur',
                        ALL: 'Alle',
                        MULTIPLY: 'Multiplizieren',
                        DIVIDE: 'Dividieren',
                        SUBTRACT: 'Subtrahieren',
                        ADD: 'Addieren',
                        MAX: 'Maximalwert',
                        MIN: 'Minimalwert',
                        AVERAGE: 'Durchschnitt',
                        COUNT: 'Anzahl',
                        SUM: 'Summe',
                        AVERAGE_EXPLANATION: 'Es wird der Durchschnitt aus dem angegebene Zeitraum gebildet.',
                        MIN_EXPLANATION: 'Es wird der kleinste Wert aus dem angegebene Zeitraum ausgewählt.',
                        MAX_EXPLANATION: 'Es wird der größte Wert aus dem angegebene Zeitraum ausgewählt.',
                        COUNT_EXPLANATION: 'Es wird die Anzahl der Werte des gewählten Zeitraums gezählt.',
                        SUM_EXPLANATION: 'Es werden alle Werte des gewählten Zeitraums zusammengezählt.',
                        ALL_EXPLANATION:
                            'Es wird geprüft, ob alle Werte des gewählten Zeitraums dem Vergleichswert entsprechen.',
                        ANY_EXPLANATION:
                            'Es wird geprüft, ob ein Wert des gewählten Zeitraums dem Vergleichswert entspricht.',
                        VALUE: 'Bestimmter Wert',
                        SINGLE_VALUE: 'Wert',
                        SINCE: 'Seit',
                        BEFORE: 'Vor',
                        REMOVE_TIME_INTERVAL: 'Zeitintervall entfernen',
                        ADD_TIME_INTERVAL: 'Zeitintervall hinzufügen',
                        REMOVE_CALCULATION: 'Rechenfunktion entfernen',
                        ADD_CALCULATION: 'Rechenfunktion hinzufügen',
                        ADD_EXPRESSION: 'Ausdruck hinzufügen',
                        ADD_CONDITION: 'Bedingung hinzufügen',
                        BUTTON_HISTORY: 'Historie',
                        BUTTON_NEW_CONDITION: 'Neue Condition',
                        CREATE_NEW_CONDITION: 'Neue Condition erstellen',
                        DROPDOWN_BUTTON_NORMAL: 'Individuelle Condition erstellen',
                        DROPDOWN_BUTTON_TEMPLATE: 'Vorlage auswählen',
                        TAB_ALL_CONDITIONS: 'Alle Conditions',
                        TAB_MY_CONDITIONS: 'Meine Conditions',
                        TAB_LIBRARY: 'Bibliothek',
                        NO_ENTRIES_HISTORY: 'Keine Einträge vorhanden',
                        NO_ENTRIES_TEXT_HISTORY:
                            'Es wurden bisher noch keine Conditions aktiv. Sobald das passiert, siehst du es hier.',
                        MAX_TIME_INTERVALL_180: 'Max. Zeitintervall: 180 min',
                        MAX_TIME_INTERVALL_180_ERROR: 'Gib ein Zeitintervall von max. 180 min ein',
                        ENTER_TIMEINTERVALL: 'Gib ein Zeitintervall an',
                        TABLE: {
                            STATUS: 'Status',
                            SEVERITY: 'Schweregrad',
                            TITLE: 'Titel',
                            ASSETS: 'Assets',
                            LAST_OCCURED: 'Zuletzt aufgetreten',
                        },
                        STATUS: {
                            ACTIVE: 'Aktiv',
                            INACTIVE: 'Inaktiv',
                        },
                        SEVERITY: {
                            INFORMATION: 'Information',
                            WARNING: 'Warnung',
                            ERROR: 'Störung',
                        },
                        FILTER: {
                            ASSET: 'Asset',
                            SEVERITY: 'Schweregrad',
                            STATUS: 'Status',
                            FILTER: 'Filter',
                            RESET_ALL: 'Alle zurücksetzen',
                            TAB_ALL_DESCRIPTION:
                                'Hier werden alle Conditions angezeigt, die User für dieses Werk angelegt haben (inkl. deiner Conditions).',
                            TAB_MY_DESCRIPTION:
                                'Hier werden die Conditions angezeigt, die du erstellt oder zu deinen Conditions hinzugefügt hast. Alle Conditions, die du erstellst, werden auch bei “Alle Conditions” angezeigt.',
                        },
                        EDIT_CONDITION: {
                            EDIT_CONDITION: 'Condition bearbeiten',
                            TITLE: 'Titel',
                            SEVERITY: 'Schweregrad',
                            RESTRICTION_INFORMATION:
                                'Es können lediglich die Werte in den Freitextfeldern bearbeitet werden. Für weitere Änderungen erstelle eine neue Condition oder dupliziere diese.',
                            VALUE: 'Wert',
                            VARIABLE: 'Variable',
                            DESCRIPTION_OPTIONAL: 'Beschreibung (optional)',
                            RESOLUTION_OPTIONAL: 'Lösung (optional)',
                            TITLE_ERROR: 'Gib einen Titel ein',
                            SEVERITY_ERROR: 'Wähle einen Schweregrad aus',
                        },
                        EDIT_EXPRESSION: {
                            ADD_EXPRESSION: 'Ausdruck hinzufügen',
                            REMOVE_TIME_INTERVAL: 'Zeitintervall entfernen',
                            REMOVE_CALCULATION_FUNCTION: 'Umrechnungsfunktion entfernen',
                            ADD_TIME_INTERVAL: 'Zeitintervall hinzufügen',
                            ADD_CALCULATION_FUNCTION: 'Umrechnungsfunktion hinzufügen',
                            CANCEL: 'Abbrechen',
                            SAVE: 'Speichern',
                        },
                        CONDITION_DETAILS: {
                            BACK: 'Zurück',
                            EDIT: 'Bearbeiten',
                            DUPLICATE: 'Duplizieren',
                            ACTIVATE_EMAIL: 'Email aktivieren',
                            DEACTIVATE_EMAIL: 'Email deaktivieren',
                            DELETE: 'Löschen',
                            ADD_CONDITIONS: 'Abonnieren',
                            REMOVE_CONDITIONS: 'Nicht mehr abonnieren',
                            STATUS: 'Status',
                            SEVERITY: 'Schweregrad',
                            ACTIVATE_CONDITION: 'Aktiviere Bedingung, wenn...',
                            RESET_CONDITION: 'Deaktiviere Bedingung, wenn...',
                            DESCRIPTION: 'Beschreibung',
                            RESOLUTION: 'Lösungshinweis',
                            NO_DESCRIPTION: 'Keine Beschreibung vorhanden',
                            NO_RESOLUTION: 'Kein Lösungshinweis vorhanden',
                            SHARE_WITH: 'Teilen mit...',
                            USED_BY: 'Wird genutzt von...',
                            CREATOR: 'Ersteller',
                            EDIT_TOOLTIP: 'Nur der Ersteller kann die Condition bearbeiten.',
                            SHARE_TOOLTIP: 'Nur der Ersteller kann die Condition teilen.',
                        },
                        DELETE_CONDITION: {
                            DELETE_HEADER: 'Condition löschen',
                            DELETE_TEXT: 'Bist du sicher, dass du die Condition löschen möchtest?',
                            DELETE_BUTTON: 'Löschen',
                        },
                        ADD_USER: {
                            SEARCH_USER: 'Nach User suchen ...',
                            USER: 'User',
                            REMOVE: 'Entfernen',
                        },
                        NO_CONDITIONS: {
                            HEADER: 'Keine Conditions vorhanden',
                            TEXT: 'Erstelle dir jetzt eine Condition und halte somit deine Produktion am Laufen.',
                        },
                        DUPLICATE_CONDITION: {
                            HEADER: 'Condition duplizieren',
                            SAVE_BUTTON: 'Speichern',
                            CANCEL_BUTTON: 'Abbrechen',
                            COPY: 'Kopie',
                        },
                        SCHEDULING: {
                            CHECK_CONDITION: 'Wann soll die Bedingung geprüft werden?',
                            ALWAYS: 'Immer',
                            CERTAIN_DAYS: 'Nur an bestimmten Tagen',
                            ON: 'jeden',
                            PERIOD_FOLLOWING_DAY: 'Der Zeitraum geht bis in den Folgetag',
                            WILL_BE_CHECKED: 'wird geprüft',
                            NEXT_DAY: 'am Folgetag',
                            DAILY_FROM: 'von',
                            TO: 'bis',
                            EVERY: 'Jeden',
                            AND: 'und',
                            FROM: 'von',
                            UHR: 'h',
                            H: 'Uhr',
                            MO_SHORT: 'Mo',
                            MONDAY: 'Montag',
                            TU_SHORT: 'Di',
                            TUESDAY: 'Dienstag',
                            WE_SHORT: 'Mi',
                            WEDNESDAY: 'Mittwoch',
                            TH_SHORT: 'Do',
                            THURSDAY: 'Donnerstag',
                            FR_SHORT: 'Fr',
                            FRIDAY: 'Freitag',
                            SA_SHORT: 'Sa',
                            SATURDAY: 'Samstag',
                            SU_SHORT: 'So',
                            SUNDAY: 'Sonntag',
                        },
                        DATA_SOURCE_TRANSLATION: {
                            STATE_AND_SPEED: 'Status- und Geschwindigkeitsinformationen',
                            ORDER_INFORMATION: 'Auftragsinformationen',
                            ELECTRICITY_INFORMATION: 'Elektrizitätsinformationen',
                            ELECTRICITY_INFORMATION_SMALL: 'Elektrizitätsinformationen (klein)',
                            ELECTRICITY_INFORMATION_MEDIUM: 'Elektrizitätsinformationen (mittel)',
                            GAS_INFORMATION: 'Gasinformationen',
                            WATER_INFORMATION: 'Wasserinformationen',
                            WAREHOUSE_INFORMATION: 'Lagerinformationen',
                            COMPRESSED_AIR: 'Druckluftinformationen',
                            STEAM: 'Dampfinformationen',
                            SHIFT: 'Schichtinformationen',
                            STOP: 'Stoppinformationen',
                        },
                        VARIABLE_TRANSLATION: {
                            AVERAGE_SPEED: 'Durchschnittliche Geschwindigkeit',
                            CURRENT_SPEED: 'Aktuelle Geschwindigkeit',
                            CURRENT_STATE: 'Aktueller Status',
                            TIME_INTERVAL: 'Zeitintervall',
                            CURRENT_LINEAR_METER: 'Bisher produzierte Meter',
                            GRADE: 'Sorte',
                            PAPER_WIDTH: 'Papierbreite',
                            TARGET_LINEAR_METER: 'Geplante Meter',
                            TRIM_AREA: 'Trim Fläche',
                            TRIM_PERCENTAGE: 'Trim Prozent',
                            TRIM_WIDTH: 'Trim Breite',
                            ACTUAL_END: 'Tatsächliches Ende',
                            ACTUAL_START: 'Tatsächlicher Beginn',
                            CURRENT_QUANTITY: 'Bisher produzierte Menge',
                            CUSTOMER: 'Kunde',
                            IDENTIFIER: 'Kennnummer',
                            PLANNED_END: 'Geplantes Ende',
                            PLANNED_START: 'Geplanter Start',
                            PLANNED_QUANTITY: 'Geplante Menge',
                            TARGET_SPEED: 'Zielgeschwindigkeit',
                            CURRENT_INVENTORY_LEVEL: 'Aktuelle Lagerauslastung',
                            ACTIVE_ENERGY: 'Strom (Zählerstand)',
                            CURRENT_ACTIVE_POWER: 'Leistungsaufnahme',
                            GAS_METER: 'Gas (Zählerstand)',
                            WATER_METER: 'Wasser (Zählerstand)',
                            ABSOLUTE_PRESSURE: 'Absoluter Druck',
                            ACCUMULATED_VOLUME: 'Kumuliertes Volumen',
                            DEW_POINT: 'Taupunkt',
                            GAUGE_PRESSURE: 'Manometer Druck',
                            MASS_FLOW_RATE: 'Massenstrom',
                            OIL_CONCENTRATION: 'Öl-Konzentration',
                            RELATIVE_HUMIDITY: 'Relative Luftfeuchtigkeit',
                            TEMPERATURE: 'Temperatur',
                            VOLUME: 'Volumen (Zählerstand)',
                            VOLUME_FLOW_RATE: 'Volumenstrom',
                        },
                        DISPLAY_UNIT_TRANSLATIONS: {
                            SHEETS_H: 'Bogen/h',
                            SHEETS: 'Bogen',
                            METER_MIN: 'Meter/min',
                            BALE_H: 'Ballen/h',
                            PALLET_H: 'Paletten/h',
                        },
                        LIBRARY: {
                            FILTER: {
                                SEARCH: 'Suche nach Vorlagen',
                                CATEGORY: {
                                    CATEGORY: 'Kategorie',
                                    ORDER: 'Auftrag',
                                    SPEED: 'Geschwindigkeit',
                                    RESOURCE: 'Ressource',
                                    STATUS: 'Status',
                                },
                                ASSET_TYPE: {
                                    ASSET_TYPE: 'Assettyp',
                                    CONVERSION: 'Weiterverarbeitung',
                                    CORRUGATOR: 'Wellpappenanlage',
                                },
                            },
                            NO_HIT: 'Keine Treffer ...',
                            EMPTY_STATE_TEXT:
                                'Wir konnten leider kein passendes Template für dich finden. Passe deine Filter oder Suche an und versuche es erneut!',
                            ADD: 'Hinzufügen',
                        },
                        TEMPLATES: {
                            ERROR_MESSAGES: {
                                FILL_ALL_FIELDS: 'Fülle alle Felder der Bedingung aus.',
                                FILL_ALL_FIELDS_CORRECTLY:
                                    'Fülle alle Felder der Bedingung richtig aus. Beachte die Minimal- und Maximalwerte der Felder.',
                            },
                            INPUT_INFO_TOOLTIP: 'Wertebereich:',
                        },
                    },
                    HISTORIAN: {
                        HISTORIAN: 'Historian',
                        START_BUTTON: 'Starten',
                        START_TEXT:
                            'Der openpack Historian dient als Key Manager für alle Data Scientisten, Data Analysten etc, die Zugang zu den Rohdaten ihrer Maschinen benötigen.',
                        MY_KEYS: 'Meine Keys',
                        MANAGE_KEYS: 'Keys verwalten',
                        MY_KEYS_INFO_TEXT:
                            'Hier kannst du die Keys für deine Werke anfragen, um den Zugang zu den Rohdaten der Maschinen zu erhalten.',
                        KEY_REQUEST_AGAIN: 'Key erneut anfragen',
                        WITHDRAW_REQUEST: 'Anfrage zurückziehen',
                        SHOW_KEY: 'Key einmalig anzeigen',
                        KEY_REQUEST: 'Key anfragen',
                        DELETE_KEY: 'Key löschen',
                        KEY: 'Schlüssel',
                        MODAL_INFO_1: 'Dieser Schlüssel wird aus Sicherheitsgründen nur einmal angezeigt. Bitte',
                        MODAL_INFO_2: 'speichere den Schlüssel',
                        MODAL_INFO_3: 'unbedingt vor dem Schließen.',
                        ID: 'ID',
                        SECRET: 'Secret',
                        S3_URL: 'S3 Bucket URL',
                        CLOSE_BUTTON: 'Schließen',
                        SAVE_TO_CLIPBOARD: 'In der Zwischenablage speichern',
                        SAVED_TO_CLIPBOARD: 'In der Zwischenablage gespeichert',
                        MANAGE_KEYS_REQUESTS: 'Anfragen',
                        MANAGE_KEYS_NO_REQUESTS: 'Derzeit keine Anfragen vorhanden',
                        MANAGE_KEYS_MANAGE_KEYS: 'Keys verwalten',
                        MANAGE_KEYS_NO_KEYS: 'Derzeit keine Keys vorhanden',
                        PLANT_FILTER: 'Nach Werk filtern',
                        BUTTON_ACCEPT: 'Akzeptieren',
                        BUTTON_DECLINE: 'Verweigern',
                        TOAST_ACCEPTED: 'Anfrage akzeptiert',
                        TOAST_DECLINED: 'Anfrage verweigert',
                        TOAST_REQUESTED: 'Auf Zusage warten',
                        TOAST_REQUESTED_TEXT:
                            'Der zuständige Admin muss die Anfrage bestätigen. Du wirst informiert, sobald dies geschehen ist.',
                        NO_PLANT_FOUND: 'Kein passendes Werk gefunden',
                    },
                },
            },
            pt: {
                translation: {
                    DASHBOARD_PERIOD_TOO_LONG_HEADLINE: 'Período muito longo',
                    DASHBOARD_PERIOD_TOO_LONG_SUBLINE_1: 'Somente os dados de um período máximo',
                    DASHBOARD_PERIOD_TOO_LONG_SUBLINE_2: 'de 3 dias podem ser exibidos para esse widget.',
                    ERROR: {
                        TRY_AGAIN: 'Ocorreu um erro. Por favor, tente novamente.',
                    },
                    EDUCATION: {
                        NUMBER_HITS: {
                            SUITABLE_SINGLE: 'formação adequada',
                            SUITABLE_MULTIPLE: 'formações adequadas',
                            TRAINING_SINGLE: 'adequada',
                            TRAINING_MULTIPLE: 'adequadas',
                        },
                        BANNER_TITLE: 'Cursos de formação sobre embalagens',
                        BANNER_BETA:
                            'Bem-vindos à versão beta da nossa novíssima aplicação Educação! Tenha em atenção que a aplicação ainda se encontra em versão beta, o que significa que ainda está a ser desenvolvida e melhorada.',
                        BANNER_DESCRIPTION:
                            'Gostaria de aprender mais sobre o vasto campo da embalagem de uma forma competente e fácil? Então, utilize a nossa aplicação de formação para encontrar o curso que melhor se adequa a si a partir de uma vasta gama de ofertas educativas.',
                        BANNER_VARIANTS: 'Formação contínua | Cursos de formação | Cursos online',
                        SEARCH_PLACEHOLDER: 'Procurar a formação correcta',
                        FILTERS: {
                            ON_DEMAND: 'A pedido',
                            PRICE_ON_DEMAND: 'Preço a pedido',
                            ALL: 'All',
                            CLEAR_ALL: 'Apagar Tudo',
                            APPLY_FILTER: 'Aplicar filtro',
                            UNDER: 'Menos de',
                            OVER: 'Mais de',
                            FILTER: 'Filtro',
                            PROVIDER: 'Provedor',
                            PERIOD: 'Período',
                            DURATION: 'Duração',
                            COSTS: 'Custos',
                            LOCATION: 'Localização',
                            CONTENT: 'Conteúdo',
                            NOTES: 'Notas',
                            GOAL: 'Objetivo',
                            TARGET_GROUP: 'Grupo-alvo',
                            ELECTRICS: 'Eléctrica',
                            MECHANICS: 'Mecânica',
                            MONTHS: {
                                0: 'Janeiro',
                                1: 'Fevereiro',
                                2: 'Março',
                                3: 'Abril',
                                4: 'Maio',
                                5: 'Junho',
                                6: 'Julho',
                                7: 'Agosto',
                                8: 'Setembro',
                                9: 'Outubro',
                                10: 'Novembro',
                                11: 'Dezembro',
                            },
                        },
                        MORE_COURSES: 'Mais cursos de',
                        ACTIONS: {
                            TO_THE_COURSE: 'Sobre o curso',
                        },
                    },
                    SERVICE_STORE: {
                        TOP_SERVICES: 'TOP-Serviços da indústria',
                        ADVERTISEMENT: 'Aviso',
                        SEARCH_SERVICES: 'Serviços de pesquisa',
                        TO_THE_COMPANY: 'Para a empresa',
                        DOCUMENTS_AVAILABLE: 'documentos disponíveis',
                        DOCUMENT_AVAILABLE: 'documento disponível',
                        GO_TO_SERVICE: 'Ir para o serviço',
                        GET_IN_CONTACT: 'Entre em contacto',
                        RECOMMENDED: 'Recomendado',
                        MORE_INFOS: 'Mais informações',
                        DISCOVER: 'Descubra',
                    },
                    COCKPIT: {
                        MY_PLANTS: 'As minhas plantas',
                        MY_APPS: {
                            TITLE: 'Ferramentas',
                            MOST_USED: 'MAIS UTILIZADOS',
                            MORE_APPS: 'AINDA',
                            PARTNER: 'Parceiro',
                            PACKAGES: 'Produtos',
                            AVAILABLE: 'Disponível para',
                            NOT_AVAILABLE: 'Não disponível',
                            TOOLS: 'Ferramentas',
                            DATA: 'Dados',
                            CONTACT: 'Contacto',
                            DIGITAL_FACTORY: {
                                LIVE_VIEW: 'Live View',
                                CONDITION_MONITORING: 'Condition Monitoring',
                                DOCUMENTATION: 'Documentation',
                                DASHBOARD: 'Dashboard',
                                PRODUCTION_DATA: 'Dados de produção',
                            },
                            RESOURCE_CONTROL: {
                                LIVE_VIEW: 'Live View',
                                CONDITION_MONITORING: 'Condition Monitoring',
                                DOCUMENTATION: 'Documentation',
                                DASHBOARD: 'Dashboard',
                                RESOURCE_MANAGER: 'Resource Manager',
                                RESOURCE_DATA: 'Dados do recurso',
                            },
                            PROCUREMENT: {
                                MARKETPLACE: 'Marketplace',
                                REPORTING: 'Relatórios',
                                OCI_EDI: 'OCI/EDI',
                                SUPP_MANAGEMENT: 'Gestão de fornecedores',
                                ORDER_DATA: 'Dados da encomenda',
                                SHOPPING_BEHAVIOUR: 'Comportamento de compra',
                                COMPANY_CATEGORIZATION: 'Categorização de produtos específicos da empresa',
                            },
                        },
                        RECOMMENDED_SERVICES: {
                            TITLE: 'Serviços recomendados',
                            LEARN_MORE: 'Saiba mais',
                            CONTACT: 'Contacte-nos',
                        },
                        INVITED_BY: 'Convidado por',
                        PLANTS: {
                            INTEREST: 'Factos globais.',
                            IN_A_NUTSHELL: 'Em resumo.',
                            CONNECTED_MACHINE: 'Máquina ligada',
                            CONNECTED_MACHINES: 'Máquinas ligadas',
                            PLANT: 'Planta',
                            PLANTS: 'Plantas',
                            UPLOADED_DOCUMENTS: 'Documentos carregados',
                            UPLOADED_DOCUMENT: 'Documento carregado',
                            PRODUCED_SQUAREMETERS: 'Metros quadrados produzidos',
                        },
                        NEWS: {
                            WORTH_KNOWING: 'Vale a pena saber num relance.',
                            TO_WEBSITE: 'Ir para o Website',
                            LAST_UPDATED: 'Última actualização',
                            NEWS: {
                                MORE_NEWS: 'Interessado em mais notícias?',
                                REDIRECT: 'Continuar para a página de notícias',
                                HEADER: 'Últimas notícias da indústria',
                            },
                        },
                        WEATHER: { FEELS_LIKE: 'Sente-se como' },
                        STOCKS: { MORE_INFORMATION: 'Mais informações', STOCKS: 'Existências' },
                        FEFCO_BANNER: {
                            DATE: '20 - 22 de Outubro, 2021',
                            DAYS: 'Dias',
                            HOURS: 'Horas',
                            MINUTES: 'Minutos',
                            SECONDS: 'Segundos',
                        },
                        VISUALIZATION: {
                            DATA_SOURCE: 'Fonte de dados',
                            DATA_USE: 'Utilização de dados',
                            COLLECT: 'Ligar',
                            STANDARDIZE: 'Normalizar',
                            SHARE: 'Partilhar',
                            RECORDS_MIN: 'Conjuntos dados/min',
                            CURRENT_DATA_EXCHANGE: 'Troca atual de dados',
                            TOTAL_DATA_PROCESSED: 'Total de dados tratados',
                            VARIABLE_USED: 'Variáveis utilizadas',
                            FROM_HUB: 'De Hub',
                            TO_HUB: 'Para o Hub',
                            DATA_TOTAL: 'Total de dados',
                            NOT_AVAILABLE: 'n.d.',
                        },
                        EMPTY_STATE_HEADER: 'Oops, parece que não há nada para mostrar.',
                        EMPTY_STATE_TEXT1: 'Pedimos desculpa, mas de momento não há informações disponíveis.',
                        EMPTY_STATE_TEXT2: 'Por favor, volte mais tarde.',
                    },
                    CALENDAR: {
                        JANUARY: 'Janeiro',
                        FEBRUARY: 'Fevereiro',
                        MARCH: 'Março',
                        APRIL: 'Abril',
                        MAY: 'Maio',
                        JUNE: 'Junho',
                        JULY: 'Julho',
                        AUGUST: 'Agosto',
                        SEPTEMBER: 'Setembro',
                        OCTOBER: 'Outubro',
                        NOVEMBER: 'Novembro',
                        DECEMBER: 'Dezembro',
                        MO: 'Se',
                        TU: 'Te',
                        WE: 'Qua',
                        TH: 'Qui',
                        FR: 'Se',
                        SA: 'Sá',
                        SU: 'Do',
                        APPLY: 'Aplicar',
                        CANCEL: 'Cancelar',
                        FROM: 'A partir de',
                        TO: 'Para',
                        TIME_ERROR: 'Por favor, verifique o período de tempo seleccionado!',
                        SHIFT_ERROR: 'Escolha pelo menos um turno',
                        END_DATE_ERROR: 'Por favor, seleccione uma data de fim.',
                    },
                    GREETING: {
                        GOOD_MORNING: 'Bom dia',
                        GOOD_DAY: 'Bom dia',
                        GOOD_AFTERNOON: 'Boa Tarde',
                        GOOD_EVENING: 'Boa Noite',
                    },
                    WORKFLOW_AREA: {
                        OUTPUT: 'Saída',
                        STORAGE: 'Armazenamento',
                        AVERAGE_SPEED: 'Ø-Velocidade',
                        PAPER_STORAGE: 'Armazenamento de papel',
                        INTERMEDIATE_STORAGE: 'Armazenamento Intermediário',
                        WAREHOUSE: 'Armazém',
                        PRODUCTION: 'Produção',
                        STOP: 'Parar',
                        SETUP: 'Configuração',
                        IDLENESS: 'Ociosidade',
                        NOT_AVAILABLE: 'n.d.',
                        RUN_DOWN_TIME: 'Tempo de execução e paragem',
                        SINCE: 'desde',
                        EARLY_SHIFT: 'Turno da manhã',
                        LATE_SHIFT: 'Turno da tarde',
                        NIGHT_SHIFT: 'Turno da noite',
                    },
                    ASSET_DATA: {
                        OTHER_TYPE: 'Outros',
                        CORRUGATOR: {
                            SPLICER: 'Empalmador',
                            REEL_STAND: 'Porta Bobines',
                            SINGLE_FACER: 'Simples Face',
                            ADVANCE_FACER: 'Facer Avançado',
                            QUICK_FACER: 'Facer Rápido',
                            BRIDGE: 'Ponte',
                            PRE_HEATER: 'Pré-aquecedor',
                            GLUE_UNIT: 'Unidade de cola',
                            DOUBLE_FACER: 'Facer duplo',
                            ROTARY_SHEAR: 'Cizalha',
                            SLITTER_SCORER: 'Slitter',
                            CUTOFF: 'Cutoff',
                            STACKER: 'Empilhador',
                            AUTOMATION_MODULES: 'Módulos de automatização',
                            STEAM_SYSTEM: 'Sistema de vapor',
                        },
                    },
                    ASSET_DETAILS: {
                        MACHINE_TYPE: 'Tipo',
                        MANUFACTURER: 'Fabricante',
                        SERIAL_NUMBER: 'Número de série',
                        YEAR_OF_MANUFACTURE: 'Ano de fabrico',
                        ERROR_ADD: 'Erro ao adicionar Activo',
                        ERROR_EDIT: 'Erro durante a edição de Bens',
                        UPLOADFILE: 'Carregar',
                        FILES: 'Ficheiros',
                        FILE: 'Ficheiro',
                        DISPLAY_NAME: 'Nome de exibição',
                        DOCUMENTS: 'Ficheiros',
                        TYPE: 'Tipo',
                        WORKING_WIDTH: 'Largura de trabalho',
                        MAX_PRODUCTION_SPEED: 'Máx. Velocidade',
                        SHEETS_H: 'Folha/h',
                        FLUTE_TYPE: 'Tipo de eixo',
                        SHEET_HEIGHT: 'Altura da folha',
                        SHEET_LENGTH: 'Comprimento da folha',
                        SHEET_WIDTH: 'Largura da folha',
                        AREA: 'Área',
                        PRODUCTION_YEAR: 'Ano de fabrico',
                        YEAR_OF_COMMISSIONING: 'Ano de comissionamento',
                        PERIOD_OF_USE: 'Período de utilização',
                        COST_CENTER: 'Centro de custos',
                        BASIC_INFORMATION: 'Informação básica',
                        TYPE_INFORMATION: 'Tipo',
                        MACHINE_INFORMATION: 'Informação da máquina',
                        INSTALLATION_INFORMATION: 'Informação de instalação',
                        DIMENSIONS: 'Dimensões',
                        LENGTH: 'Comprimento',
                        WIDTH: 'Largura',
                        HEIGHT: 'Altura',
                        ENERGY_SOURCE: 'Fonte de energia',
                        POWER_CONNECTIONS: 'Ligações de energia',
                        GAS_CONNECTION: 'Ligação de gás',
                        SENSORS: 'Tecnologia de sensores',
                        SERIES: 'Série (modelo)',
                    },
                    ASSET_ADD_EDIT_MODAL: {
                        TYPE_WARNING_MODAL: {
                            CONFIRMATION_BUTTON: 'Mais',
                            HEADLINE: 'Visualização deficiente na Live',
                            CONFIRMATION_TEXT1:
                                'Está prestes a remover o tipo de bem. Isto significa que nenhum dado para este bem pode ser exibido na Live.',
                            CONFIRMATION_TEXT2: 'Ainda quer continuar?',
                        },
                        TYPE_SELECTION_INFO: 'A informação apresentada na Live baseia-se na selecção do tipo.',
                        HEADLINE_ADD: 'Adicionar Activo',
                        HEADLINE_EDIT: 'Editar recurso',
                        ADD_BUTTON: 'Adicionar Activo',
                        EDIT_BUTTON: 'Editar recurso',
                        ADD_SUCCESS: 'Bens criados com sucesso',
                        EDIT_SUCCESS: 'Bens editados com sucesso',
                        BASIC_INFORMATION: 'Informação básica',
                        TYPE_INFORMATION: 'Tipo',
                        MANUFACTURER_INFORMATION: 'Informação sobre o fabricante',
                        DIMENSIONS: 'Dimensões',
                        DISPLAY_NAME: 'Nome de exibição',
                        PICTURE: 'Fotografia',
                        SERIES: 'Série (modelo)',
                        TYPE: 'Tipo',
                        FLUTE_TYPE: 'Tipo de eixo',
                        SHEET_HEIGHT: 'Altura da folha',
                        SHEET_LENGTH: 'Comprimento da folha',
                        SHEET_WIDTH: 'Largura da folha',
                        AREA: 'Área',
                        ADD: 'Acrescentar',
                        PRODUCTION_YEAR: 'Ano de fabrico',
                        YEAR_OF_COMMISSIONING: 'Ano de comissionamento',
                        MANUFACTURER: 'Fabricar',
                        PERIOD_OF_USE: 'Período de utilização',
                        COST_CENTER: 'Centro de custos',
                        SERIAL_NUMBER: 'Número de série',
                        LENGTH: 'Comprimento',
                        WIDTH: 'Largura',
                        HEIGHT: 'Altura',
                        CORRUGATOR: 'Caneladora',
                        GLUE_KITCHEN: 'Cozinha de Cola',
                        STACKER: 'Empilhador',
                        REEL_STAND: 'Porta Bobines',
                        PREHEATER: 'Pré-aquecedor',
                        DOUBLE_FACER: 'Facer duplo',
                        LAMINATING_MACHINE: 'Máquina laminadora',
                        ROTARY_SHEAR: 'Cizalha',
                        CUTOFF: 'Cutoff',
                        RIM_VENTILATION: 'Aspiração de trim',
                        SLITTER_SCORER: 'Slitter',
                        SHREDDER: 'Destroçador',
                        STEAM_BOILER: 'Steam Boiler',
                        TOTAL_PLANT: 'Total plant',
                        COMPRESSOR: 'Compressor',
                        PHOTOVOLTAIC_SYSTEM: 'Photovoltaic system',
                        LIGHTS: 'Lights',
                        BOARD_DIMENSION_CONTROL: 'Board Dimension Control',
                        HYPERBLADE: 'Hyperblade',
                        ZERO_DEFECT_SYSTEM: 'Zero Defect System',
                        SPLICER: 'Empalmador',
                        SINGLE_FACER: 'Simples Face',
                        STEAM_SYSTEM: 'Sistema Vapor',
                        GLUE_UNIT: 'Unidade de cola',
                        BRIDGE: 'Ponte',
                        WORKING_WIDTH: 'Largura de trabalho',
                        MAX_PRODUCTION_SPEED: 'Máx. Velocidade',
                        CONVERSION: 'Conversion',
                        INLINER: 'Casemaker',
                        ROTARY_DIE_CUTTER: 'Máquina Cortante Rotativos',
                        FLATBED_DIE_CUTTER: 'Máquina Cortantes Planos',
                        FOLDER_GLUER: 'Coladora',
                        FEEDER: 'Alimentador (Feeder)',
                        PALLETIZER: 'Paletizador',
                        BUNDLING_MACHINE: 'Máquina de empacotamento',
                        DIE_CUTTER: 'Cortante',
                        GLUER: 'Cola',
                        GLUING_UNIT: 'Unidade de cola',
                        WINDER: 'Envolvedora',
                        PRINTING_UNIT: 'Unidade de impressão',
                        FOLDER: 'Ponte',
                        SLOTTER: 'Slotter',
                        TAPER: 'Taper',
                        INSERTER: 'Alimentador',
                        COUNTER: 'Contador',
                        ERROR_MESSAGES: {
                            ENTER_DISPLAY_NAME: 'por favor introduza um nome de exibição.',
                        },
                        STORAGE: 'Armazenamento',
                        PAPER_STORAGE: 'Armazenamento de papel',
                        INTERMEDIATE_STORAGE: 'Armazenamento Intermediário',
                        FINISHED_GOODS_WAREHOUSE: 'Armazém de Produtos Acabados',
                        BALE_STORAGE: 'Armazenamento de fardos',
                        CLICHE_STORAGE: 'Armazenamento de clichés',
                        TOOL_STORAGE: 'Armazém de cortantes',
                        INK_STORAGE: 'Armazém de tintas',
                        SPARE_PARTS_STORAGE: 'Armazém de peças suplentes',
                        MAXIMUM_CAPACITY: 'Capacidade máxima',
                        ROLLS: 'Rolos',
                        PALLETS: 'Paletes',
                        BALES: 'Fardos',
                        TRANSPORTATION_SYSTEM: 'Sistema de transporte',
                        SHUTTLE: 'Shuttle',
                        CONVEYOR: 'Tapete transportador',
                        TRANSFER_TROLLEY: 'Carro de transporte',
                        TRANSPORTATION_STACKER: 'Empilhador',
                        TRANSPORTATION: 'Transporte',
                        OTHERS: 'Outros',
                        BALING_PRESS: 'Prensa de enfardamento',
                        WATER_TREATMENT: 'Tratamento de águas',
                        PALLETIZING_CENTER: 'Paletização',
                        PAINT_MIXING_SYSTEM: 'Sistema de mistura de tinta',
                        WEBTROL: 'Webtrol',
                        CREATE: 'Criar',
                    },
                    DELETE_ASSET: {
                        HEADLINE: 'Eliminar Bens?',
                        SUCCESS: 'Bens eliminados com sucesso',
                        TEXT: 'Quer realmente apagar a máquina e todas as informações e ficheiros relacionados?',
                        DELETE: 'Eliminar',
                        DELETE_TOOLTIP: 'Eliminar recurso',
                        CANCEL: 'Cancelar',
                        SERIAL_NUMBER: 'Número de série',
                        CONFIRMATION_BUTTON: 'Eliminar recurso',
                    },
                    DATA: {
                        QUANTITY: {
                            HEADLINE: 'Quantidade',
                            PRODUCED: 'Quantidade produzida',
                            ORDER: 'Quantidade da encomenda',
                        },
                        ENERGY: {
                            HEADLINE: 'Energia',
                            OVERALL_CONSUMPTION: 'Consumo global',
                            UNIT: 'kWh',
                        },
                        SPEED: {
                            HEADLINE: 'Velocidade',
                            CURRENT_SPEED: 'Velocidade actual',
                            AVG_SPEED: 'Ø-Velocidade',
                            TARGET_SPEED: 'Velocidade Alvo',
                            UNIT: 'Placas/h',
                        },
                        PLANNED_VS_PRODUCED_QUANTITY_PER_HOUR: {
                            HEADLINE: 'Quantidade planeada vs. quantidade produzida',
                            HOUR: 'Hora',
                            STOP_TIME: 'Tempo de paragem',
                            SETUP_TIME: 'Tempo de configuração',
                            PLANNED_QUANTITY: 'quantidade planeada',
                            PRODUCED_QUANTITY: 'quantidade produzida',
                            DELTA: 'Delta',
                            PLANNED: 'Planeado',
                            PRODUCED: 'Produzido',
                            STOPS: 'Paragens',
                            STOP: 'Paragem',
                            SETUPS: 'Setups',
                            SETUP: 'Setup',
                        },
                        CURRENT_ORDER: {
                            ORDER: 'Encomenda',
                            PRODUCED: 'Produzida',
                            PREDICTED: 'Predito',
                            START: 'Início',
                            FINISH: 'Fim',
                            HEADLINE: 'Pedido actual',
                            CUSTOMER: 'Cliente',
                            ORDER_NO: 'N.º de encomenda',
                            PRODUCTION_RUN: 'Execução da produção',
                            PLANNED_START: 'Início planeado',
                            ACTUAL_START: 'Início real',
                            ORDERS: 'Encomendas',
                            PLANNED_QUANTITY: 'Planejada Quantidade',
                            PLANNED_QUANTITY_CONV: 'Quantidade planeada [Caixas]',
                            PLANNED_QUANTITY_CORR: 'Quantidade planeada [m]',
                            PROGRESS: 'Progresso',
                            ACTUAL_SPEED: 'Velocidade actual',
                            COMPLETED: 'Concluído',
                            NOT_YET_STARTED: 'Ainda não começou',
                            GRADE: 'Nota',
                            REST_RUNNING_METER: 'Restante',
                            NO: 'Não.',
                            PLANNED: 'Planeado',
                            PRODUCED_ORDERS: 'Produzido',
                            SHEETS: 'Folhas',
                        },
                        STATUS: {
                            PRODUCTION: 'Produção',
                            SETUP: 'Configuração',
                            IDLESNESS: 'Parar',
                            FAULT: 'Erro',
                            RUN_STOP: 'Tempo de execução / Tempo de paragem',
                            TIME: 'Hora',
                            REASON: 'Parar o solo',
                            STOPS: 'Paragens',
                            DURATION: 'Duração',
                        },
                        GOODS_WAREHOUSE: {
                            FLUTE_TYPES: 'Flautas armazenadas',
                            UTILIZATION: 'Utilização',
                            UTILIZATION_GRAPH: 'Utilização (últimas 24 horas)',
                            SIMULATED_UTILIZATION: 'Simulado utilização',
                            ACCUMULATION: 'Acumulação de armazenamento',
                            OCCUPIED: 'Ocupado',
                            PALLETS: '[Paletes]',
                            BALES: '[Fardos]',
                            MAX_CAPACITY: 'Máx. Capacidade',
                            NOT_AVAILABLE: 'n.d.',
                            LAST_24_HOURS: 'Últimas 24 horas',
                            CURRENT_STORED_ORDERS: 'Encomendas actualmente armazenadas',
                            STORAGE: 'Armazenamento',
                            CUSTOMER: 'Cliente',
                            CUSTOMER_ARTICLE: 'Artigo do cliente',
                            ORDER_NUMBER: 'Número de encomenda',
                            NUMBER_OF_PALLETS: 'Número de paletes',
                            STORAGE_DURATION: 'Duração de armazenamento',
                            SMALL: 'estreita',
                            MEDIUM: 'média',
                            WIDE: 'larga',
                            TOTAL: 'total',
                            ERROR_MESSAGE_CRITICAL_STACK_1: 'pilhas críticas previstas',
                            ERROR_MESSAGE_CRITICAL_STACK_2: 'Não existe uma via adequada para as pilhas.',
                            ERROR_MESSAGE_CRITICAL_STACK_1_SINGLE: 'pilha crítica prevista.',
                            ERROR_MESSAGE_CRITICAL_STACK_2_SINGLE: 'Não existe uma pista adequada para esta pilha.',
                            ERROR_MESSAGE_CRITICAL_STACK_3:
                                'Para mais pormenores, ver o diagrama "Utilização prevista".',
                            FORECASTED_UTILIZATION: 'Previsão de utilização da capacidade',
                            CRITICAL_STACK: 'Pilha crítica',
                        },
                        BALE_STORAGE: { NUMBER_OF_BALES: 'Número de bale' },
                        WASTE_PRESS: { PASSAGE: 'Passagem' },
                        MEAN_TIMES: { MTTR: 'Tempo médio a recuperar', MTBF: 'Tempo médio entre falhas' },
                    },
                    CONFIGURATION: {
                        ADD_SUB_ASSET: 'Acrescentar bens subordinados',
                        ADD_LINE: 'Adicionar linha',
                        ADD_ASSET: 'Adicionar Activo',
                        EDIT_ASSET: 'Salvar',
                        CHOOSE_AREA: 'Por favor, seleccione uma área!',
                        CHOOSE_LINE: 'Por favor, seleccione uma linha.',
                        COST_CENTER: 'Centro de Custos',
                        EDIT: 'Editar',
                        OR: 'OU',
                        DELETE_ASSET: 'Eliminar Activo asset',
                        SHOW_INFOS: 'Mostrar informação',
                        SHOW_ASSETS: 'Mostrar bens',
                    },
                    DOCUMENTATION: {
                        QUICK_ACCESS: 'Acesso rápido',
                        FAVORITE: 'Favoritos',
                        DESCRIPTION: 'Se marcar o conteúdo como favorito, este será exibido aqui.',
                        HEADER: 'Escolher uma linha e começar imediatamente.',
                        TEXT1: 'Assim que tiver trabalhado com os primeiros documentos,',
                        TEXT2: ' os últimos ficheiros utilizados serão exibidos aqui.',
                        SHOW_DOCUMENTS: 'Mostrar documentos',
                        SHOW_ASSETS: 'Mostrar bens',
                        NO_SUBORDINATE_ASSETS: 'Este bem não tem bens subordinados.',
                        UPLOAD_DOCUMENTS: 'Carregar documentos',
                        CREATE_FOLDER: 'Criar pasta',
                        NO_ROOT_ASSETS: 'Não há activos disponíveis',
                    },
                    LINE_DOCUMENTS: {
                        DOCUMENTS: 'Documentos',
                        PARTS: 'Partes',
                        INFOS: 'Infos',
                        NAME: 'Nome',
                    },
                    AREA_TYPES: {
                        VALUES: {
                            STORAGE: 'Armazenamento',
                            CORRUGATOR: 'Caneladora',
                            STARCHKITCHEN: 'Cozinha de Cola',
                            INTRALOGISTICS: 'Intralogística',
                            CONVERSION: 'Transformação',
                            INOUTBOUND_LOGISTICS: 'Logística de entrada/saída',
                            INTERMEDIATE_STORAGE: 'Armazenamento Intermediário',
                            PAPER_STORAGE: 'Armazenamento de papel',
                            LOGISTICS: 'Logística',
                            FINISHED_GOODS_WAREHOUSE: 'Armazém de Produtos Acabados',
                            WASTE_MANAGEMENT: 'Gestão de resíduos',
                            OTHERS: 'Outros',
                        },
                    },
                    LINE_DETAILS: {
                        NAME: 'Nome',
                        ASSET: 'recursos',
                        ASSETS: 'recursos',
                        MACHINES: 'Máquinas',
                        DOCUMENTS: 'Ficheiros',
                        DOCUMENT: 'Ficheiro',
                        FILES: 'Ficheiros',
                        FILE: 'Ficheiro',
                        FOLDER: 'Criar Pasta',
                        LINE: 'Linha',
                        TYPE: 'Tipo',
                        DISPLAY_NAME: 'Nome',
                        COST_CENTRE: 'Centro de custos',
                        NUMBER_OF_ASSETS: 'Número de recursos',
                        NUMBER_OF_OEMS: 'Número de OEMs',
                        ADD_ASSET: 'Adicionar recursos',
                        ADD_FILE: 'Carregar',
                        ERROR_ADD: 'Erro ao adicionar Linha',
                        ERROR_EDIT: 'Erro durante a linha de edição',
                        MANUFACTURER: 'Fabricante',
                        MACHINE_TYPE: 'Tipo de máquina',
                        YEAR_OF_MANUFACTURE: 'Ano de fabrico',
                        SERIAL_NUMBER: 'Número de série',
                        FILTER_IN_ASSETS: 'Pesqueisar recursos',
                        FILTER_IN_FILES: 'Pesquisar ficheiros',
                        DETAILS_IN: 'Mostrar detalhes',
                        DETAILS_OUT: 'Ocultar detalhes',
                    },
                    LINE_ADD_EDIT_MODAL: {
                        HEADLINE_EDIT: 'Linha de edição',
                        HEADLINE_ADD: 'Adicionar linha',
                        ADD_BUTTON: 'Adicionar linha',
                        EDIT_BUTTON: 'Linha de edição',
                        SUCCESS_ADD: 'Linha criada com sucesso',
                        SUCCESS_EDIT: 'Linha editada com sucesso',
                    },
                    LINE_DELETE: {
                        HEADLINE: 'Eliminar linha',
                        TEXT: 'Quer realmente apagar a linha e todas as máquinas e ficheiros relacionados?',
                        LINE: 'Linha',
                        CONFIRMATION_BUTTON: 'Eliminar linha',
                    },
                    DELETE_LINE_ASSET: {
                        CONFIRMATION_TEXT:
                            'Tem a certeza de que quer eliminar este bem? Todos os outros Bens ligados a este Bem serão também eliminados!',
                        HEADLINE_LINE: 'Tem a certeza de que quer apagar a linha?',
                        HEADLINE_ASSET: 'Tem a certeza de que quer eliminar o recurso?',
                        TEXT1_LINE:
                            'A eliminação da linha remove-a e todos os recursos associados, informações, documentos, etc. da fábrica.',
                        TEXT1_ASSET:
                            'A eliminação do recurso remove-o e toda a informação, documentos, etc. associados da fábrica.',
                        TEXT2: 'Isto não pode ser desfeito.',
                        TEXT3_LINE: 'Quer mesmo apagar a linha?',
                        TEXT3_ASSET: 'Quer mesmo apagar o recurso?',
                        DELETE: 'ELIMINAR',
                    },
                    NAVIGATION_TREE: {
                        PAPERSTORAGE: 'Armazém de papel',
                        CORRUGATOR: 'Caneladora',
                        INTERMEDIATE_STORAGE: 'Armazenamento Intermediário',
                        CONVERSION: 'Transformação',
                        PALLETIZER: 'Paletizador',
                        LOGISTICS: 'Logística',
                        FINISHED_GOODS_WAREHOUSE: 'Armazém de produtos acabados',
                        CHOOSE_PLANT: 'Por favor escolha uma planta existente no mapa!',
                    },
                    LIVE: {
                        ACTIVE: 'Activo',
                        CONSUMPTION_TODAY: 'Consumo (hoje)',
                        CONSUMPTION_CURRENT_SHIFT: 'Consumo (camada atual)',
                        CURRENT_POWER: 'Potência da corrente',
                        ELECTRICITY: 'Electricidade',
                        GAS: 'Gás',
                        COMPRESSED_AIR: 'Ar comprimido',
                        WATER: 'Água',
                        STEAM: 'Vapor',
                        CURRENT: 'Corrente',
                        CONSUMPTION: 'Consumo',
                        SHEETS: 'Placas',
                        POWER_CONSUMPTION_TODAY: 'Consumo de energia (hoje)',
                        POWER_CONSUMPTION_CURRENT_SHIFT: 'Consumo de energia (camada atual)',
                        ELECTRICAL_POWER: 'Energia eléctrica',
                        MISSING_TYPE: 'Os dados não podem ser exibidos.',
                        MISSING_TYPE_TEXT: 'Certifique-se por favor de que o tipo correcto é guardado na configuração.',
                        NOT_CONNECTED_HEADING: 'Máquinas ainda não ligadas',
                        NOT_CONNECTED: 'Por favor contacte openpack para tirar partido da Live.',
                        NO_SVG_HEADING: 'Planta ainda não ligadas',
                        NO_SVG: 'Por favor contacte openpack para ligar a sua planta',
                        NOT_CONNECTED_TOOLTIP: 'Esta asset ainda não está ligada!',
                        NOT_CONNECTED_TOOLTIP_WAREHOUSE: 'Este armazém ainda não está ligado!',
                        LAST_SHIFT: 'último turno',
                        CURRENT_SHIFT: 'turno atual',
                        BACK: 'Voltar',
                        DOCUMENTS: 'Documentos',
                        ALARM_OVERVIEW: 'Resumo do Alarme',
                        NO_DATA_AVAILABLE: 'Não existem actualmente dados disponíveis.',
                        STATUS: {
                            PRODUCTION: 'Produção',
                            SETUP: 'Configuração',
                            IDLENESS: 'Ociosidade',
                            STOP: 'Parar',
                        },
                        OPEN_DETAILS: 'Abrir Detalhes',
                        PLANT_VIEW: 'Vista de fábrica',
                        LIST_VIEW: 'Vista de lista',
                        UPGRADE_RESOURCE_CONTROL: 'Actualização do Resource Control',
                        UPGRADE_Maintenance: 'Actualização do Maintenance Solution',
                        TOTAL: 'Total',
                        ELECTRICITY_CONSUMPTION: 'Electricidade | Consumo',
                        ELECTRICITY_CONSUMPTION_TODAY: 'Electricidade | Consumo (hoje)',
                        ELECTRICITY_CONSUMPTION_CURRENT_SHIFT: 'Electricidade | Consumo (camada atual)',
                        GAS_CONSUMPTION: 'Gás | Consumo',
                        GAS_CONSUMPTION_TODAY: 'Gás | Consumo (hoje)',
                        GAS_CONSUMPTION_CURRENT_SHIFT: 'Gás | Consumo (camada atual)',
                        COMPRESSED_AIR_CONSUMPTION: 'Ar comprimido | Consumo',
                        COMPRESSED_AIR_CONSUMPTION_TODAY: 'Ar comprimido | Consumo (hoje)',
                        COMPRESSED_AIR_CONSUMPTION_CURRENT_SHIFT: 'Ar comprimido | Consumo (camada atual)',
                        WATER_CONSUMPTION: 'Água | Consumo',
                        WATER_CONSUMPTION_TODAY: 'Água | Consumo (hoje)',
                        WATER_CONSUMPTION_CURRENT_SHIFT: 'Água | Consumo (camada atual)',
                        STEAM_CONSUMPTION: 'Vapor | Consumo',
                        STEAM_CONSUMPTION_TODAY: 'Vapor | Consumo (hoje)',
                        STEAM_CONSUMPTION_CURRENT_SHIFT: 'Vapor | Consumo (camada atual)',
                        PRO: 'Por',
                        SHOW_CONSUMPTION_DETAIL: 'Mostrar consumo detalhado',
                        HIDE_CONSUMPTION_DETAIL: 'Ocultar o consumo pormenorizado',
                        OPEN_MAINTENANCE: 'Manutenção abertos',
                        OPEN_INCIDENTS: 'Incidentes abertos',
                        MAINTENANCE_TASKS: 'Tarefas de manutenção',
                        INCIDENTS_TASKS: 'Tarefas de incidentes',
                        NO_OPEN_MAINTENANCE: 'Atualmente não há tarefas de manutenção em aberto',
                        NO_OPEN_INCIDENTS: 'Atualmente não há incidentes em aberto',
                        NO_INCIDENTS: 'Sem incidentes',
                        IN_PROGRESS: 'Em curso',
                        OPEN_INCIDENT: 'Incidentes em aberto',
                        TABLE: {
                            PRIO: 'Prio',
                            TITLE: 'Título',
                            DESCRIPTION: 'Descrição',
                            AREA: 'Àrea',
                            OCCURRED_AT: 'Ocorreu em',
                            DUE_ON: 'Vencimento em',
                            STATUS: 'Estatuto',
                            CHART_M_TITLE_1: 'Estatísticas Históricas',
                            CHART_M_TITLE_2: 'Estatísticas Actuais',
                            CHART_I_TITLE_1: 'Número de incidentes encerrados',
                            CHART_I_TITLE_2: 'Incidents',
                            CHARTS: {
                                COMPLETED_M: 'Tarefas de manutenção concluídas',
                                DELAYED_M: 'Tarefas de manutenção atrasadas',
                                PENDING_M: 'Tarefas de manutenção pendentes',
                                FILTER_YESTERDAY: 'Ontem',
                                FILTER_LAST_WEEK: 'Semana passada',
                                FILTER_TODAY: 'Hoje',
                                FILTER_CURRENT_WEEK: 'Semana atual',
                                CW: 'CW',
                                NUMBER: 'Número',
                            },
                            HIGH: 'Elevado',
                            MEDIUM: 'Médio',
                            LOW: 'Baixa',
                            PENDING: 'Pendente',
                            DELAYED: 'Atrasado',
                            COMPLETED: 'Concluído',
                            OPEN: 'Aberto',
                            NEW: 'NOVO',
                            ACCOMPLISHED: 'ACOMPANHADO',
                            FAILED: 'REPROVADO',
                            RESET: 'REINICIAR',
                            REJECTED: 'REJEITADO',
                            IN_WORK: 'Em Curso',
                            CLOSED: 'Fechado',
                            ELECTRICS: 'Eléctrica',
                            MECHANICS: 'Mecânica',
                        },

                        FURTHER: 'mais',
                    },
                    MAIL_DETAILS: {
                        FROM: 'De',
                        TO: 'Para',
                        DATE: 'Data',
                        TO_EMAIL: 'Para o e-mail',
                    },
                    GLOBAL_ERROR: {
                        SOMETHING_WENT_WRONG: 'Algo deve ter corrido mal aqui',
                        BACK: 'Voltar',
                        OR: 'ou',
                        BACK_TO_HOME: 'Voltar para o mapa',
                    },
                    TUTORIAL: {
                        STEP: 'Etapa {{etapa}} de 4',
                        BACK: 'Voltar',
                        FORWARD: 'Próximo',
                        FIRST: {
                            HEADLINE: `Vamos`,
                            TEXT: 'É um prazer tê-lo a bordo. Aqui obtém uma breve visão sobre as funções mais importantes do cockpit.',
                            LATER: 'Mais tarde',
                            BUTTON: 'Guia de início',
                        },
                        SECOND: {
                            HEADLINE: `As suas aplicações`,
                            TEXT: 'Com as aplicações openpack pode gerir e monitorizar as suas plantas, ler as últimas notícias da indústria de cartão canelado e muito mais.',
                        },
                        THIRD: {
                            HEADLINE: 'Os nossos parceiros fortes',
                            TEXT: 'Na parte inferior do cockpit, encontrará os nossos parceiros. Eles oferecem-lhe uma vasta gama de serviços em torno da sua fábrica de cartão canelado.',
                        },
                        FOURTH: {
                            HEADLINE: 'O seu perfil e as suas plantas',
                            TEXT: 'Na zona superior direita pode editar o seu perfil e criar e gerir todas as suas plantas.',
                        },
                        FIFTH: {
                            HEADLINE: 'Comece agora',
                            TEXT: 'Actualmente não é membro de uma fábrica. Ou cria agora uma nova fábrica ou contacta um colega e deixa-o adicioná-lo como membro.',
                            BUTTON: 'Criar planta',
                            GET_ADDED: 'Serei acrescentado',
                        },
                        SIXTH: {
                            HEADLINE: 'Comece agora',
                            TEXT: 'Já é um membro de uma fábrica e pode começar imediatamente.',
                            BUTTON: 'Comece agora',
                        },
                    },
                    NO_PLANTS_MODAL: {
                        HEADLINE: 'Não é um membro de uma planta',
                        TEXT: 'Ser convidado',
                        OR: 'ou',
                        BUTTON: 'Crie a sua planta',
                    },
                    SEARCH: {
                        SEARCH: 'Pesquisa',
                        SORRY_INCONVENIENCE: 'Desculpe pelo seu incómodo',
                        MARKETPLACE_NOT_AVAILABLE: 'A pesquisa no mercado não está disponível de momento',
                        HEADLINE: 'Resultados da pesquisa',
                        RESULTS: ' resultados para ',
                        RESULT: ' resultado para ',
                        PLACEHOLDER: 'Pesquisar documentos, máquinas, peças subplentes, etc.',
                        BACK: 'Voltar',
                        ERROR: 'Para um melhor resultado de pesquisa, por favor utilize pelo menos dois caracteres.',
                        ATTACHMENTS: 'anexos',
                        ATTACHMENT: 'anexo',
                        FROM: 'A partir de',
                        TO: 'Para',
                        SHOW_MORE: 'Mostrar detalhes',
                        SHOW_LESS: 'Ocultar detalhes',
                        FROM_SUPPLIER: 'De: ',
                        PREVIOUS: 'Anterior',
                        NEXT: 'Próximo',
                        SHOW_ALL: 'Mostrar todos',
                        SHOW_ALL_DOCS: 'Mostrar todos os documentos',
                        SHOW_ALL_PRODUCTS: 'Mostrar todos os produtos',
                        SHOW_ALL_MACHINES: 'Mostrar todas as máquinas',
                        SHOW_ALL_NEWS: 'Mostrar todas as notícias',
                        SHOW_ALL_SERVICES: 'Mostrar todos os serviços',
                        NO_RESULTS_HEADER: 'Não foram encontrados resultados',
                        NO_RESULTS_TEXT: 'Tente ajustar a sua pesquisa ou filtro para encontrar o que procura.',
                        SHOW_FILTER: 'Mostrar filtro',
                        RESET_ALL: 'Repor tudo',
                        FILTER: 'Filtro',
                        ALL_PLANTS: 'Todas as plantas',
                        ALL_PERIODS: 'Todos os períodos',
                        ALL_FORMATS: 'Todos os formatos',
                        ALL_SOURCES: 'Todas as fontes',
                        ANY_LANGUAGE: 'Qualquer idioma',
                        ALL_COMPANIES: 'Todas as empresas',
                        ALL_MARKETPLACES: 'Todos os mercados',
                    },
                    SEARCH_TABS: {
                        TAB_ALL: 'Todos',
                        TAB_DOCUMENTS: 'Documentos',
                        TAB_NEWS: 'Notícias',
                        TAB_MACHINES: 'Máquinas',
                        TAB_SHOP: 'Balcão Único',
                        BUTTON_SHOW_MORE: 'Mostrar mais',
                    },
                    SEARCH_BAR: {
                        DATE: 'Data',
                        LAST_TERM: 'Último termo de pesquisa',
                    },
                    SEARCH_ADVERTISMENT: {
                        ADVERTISMENT: 'PUBLICIDADE',
                        HEADER1: 'O seu fornecedor digital',
                        HEADER2: 'para embalagem individual',
                        TEXT1: 'Poupe tempo e dinheiro com a nossa web-based',
                        TEXT2: 'processo de compra.',
                    },
                    SEARCH_FILTER_DOCUMENTS: {
                        PLANT: 'Planta',
                        UPLOAD_DATE: 'Período de carregamento',
                        FORMAT: 'Formato',
                    },
                    SEARCH_FILTER_SHOP: {
                        PRICE: 'Preço',
                        MARKETPLACE: 'Mercado',
                    },
                    SEARCH_FILTER_MACHINES: {
                        MANUFACTURER: 'Fabricante',
                    },
                    SEARCH_FILTER_NEWS: {
                        PERIOD: 'Período',
                        SOURCE: 'Fonte',
                        LANGUAGE: 'Língua',
                    },
                    SEARCH_FILTER_SERVICES: {
                        COMPANY: 'Empresa',
                    },
                    UPLOAD_PERIOD: {
                        LAST24: 'Últimas 24 horas',
                        LAST7: 'Últimos 7 dias',
                        LAST30: 'Últimos 30 dias',
                        LAST365: 'Últimos 365 dias',
                    },
                    NOTIFICATIONS: {
                        INVITATION_TO: 'Convite para:',
                        REPORT_TEXT_1: 'O seu relatório',
                        REPORT_TEXT_2: 'está agora disponível.',
                        SET_AS_READ: 'definido como lido',
                        SET_AS_UNREAD: 'definido como não lido',
                        DELETE_NOTIFICATION: 'Eliminar notificação',
                        ONLY_SHOW_UNREAD: 'Mostrar só não lido',
                        SHOW_ALL: 'Mostrar tudo',
                        ZERO_HINTS: 'Não tem notificações dos últimos 7 dias',
                        LAST_7_DAYS_HINTS: 'Estas são todas as suas notificações dos últimos 7 dias.',
                        NOTIFICATION_SETTINGS: 'Definições de Notificação',
                        NOTIFICATIONS: 'Notificações',
                        INVITE_NO_USER: 'convidou-o',
                        INVITATION_1: 'convidou-o para',
                        INVITATION_2: '',
                        NEWEST: 'NOVO',
                        SET_ALL_READ: 'Definir tudo como lido',
                        TODAY: 'Hoje',
                        YESTERDAY: 'Ontem',
                        UNKNOWN_USER: 'Um utilizador',
                        UNKNOWN_USER_COCKPIT: 'um utilizador',

                        NO_NOTIFICATIONS_TITLE: 'Não há notificações disponíveis',
                        NO_NOTIFICATIONS_BODY:
                            'Assim que tiver recebido as primeiras notificações, estas serão afixadas aqui.',
                        DECLINED: 'Rejeitado',
                        ACCEPTED: 'Aceite',
                    },
                    MAIN: {
                        MORE: 'mais',
                        LESS: 'menos',
                        START_NOW: 'Comece agora',
                        READ_NOW: 'Leia agora',
                        BROWSE_NOW: 'Navegar agora',
                        VIEW_NOW: 'Ver agora',
                        WATCH_DEMO: 'Ver demonstração',
                        READY_TO_USE: 'Pronto a usar',
                        CATEGORIES: {
                            OUR_STRONG: 'O nosso forte',
                            PARTNER_NETWORK: 'rede de parceiros',
                        },
                        MY_COCKPIT: 'O Meu Cockpit',
                        TO_PARTNER: 'Para o parceiro',
                        SEND_MAIL: 'Enviar correio',
                        ASSET_MANAGER: {
                            DESCRIPTION: 'Toda a informação relevante sobre as suas máquinas em openpack',
                        },
                        NEWS: {
                            DESCRIPTION: 'As últimas notícias da rede social da indústria do canelado',
                        },
                        MMM: {
                            DESCRIPTION: 'A visão ao vivo da sua produção',
                        },
                        MARKETPLACE: {
                            DESCRIPTION: 'O seu balcão único em torno da indústria do canelado',
                        },
                        RC: {
                            DESCRIPTION: 'Descrição está em curso',
                        },
                    },
                    FIRST_LOGIN_INITIALIZATION: {
                        FIRST: 'O seu perfil foi inicializado com sucesso.',
                        SECOND: 'Pode agora voltar para o Gestor de Activos',
                        HEADLINE: 'Inicialização feita',
                    },
                    NOTFOUND: {
                        SUBHEADLINE: 'Algo correu mal',
                        ONE: 'Não conseguimos encontrar a página que procura...',
                        OR: 'ou',
                        BUTTON_BACK: 'Voltar',
                    },
                    USER_ADD_MODAL: {
                        HEADLINE: 'Adicionar Utilizador(es)',
                        PLACEHOLDER_EMAIL: 'Email',
                        NOT_FOUND_MESSAGE: 'Nenhum Utilizador encontrado. Por favor, tente novamente',
                        ADD_USERBUTTON: 'Adicionar Utilizador(es)',
                        USER_ALREADY_IN_PLANT: 'O utilizador já está na fábrica',
                    },
                    DELETE_USER_FROM_PLANT_MODAL: {
                        HEADLINE: 'Retirar o utilizador da fábrica?',
                        TEXT: 'Tem a certeza de remover este utilizador da fábrica?',
                    },
                    FORM_VALIDATION: {
                        ONLY_NUMBER: 'Devido à selecção da variável, apenas um número é permitido como entrada.',
                        FILL_ERROR: 'Por favor preencha todos os campos obrigatórios',
                        MAX_LENGTH: 'Não são permitidos mais de 255 caracteres',
                        REQUIRED_FIELD: 'Campo Obrigatório',
                        CHOOSE_AN_OPTION: 'Selecionar uma opção',
                        NOT_VALID: 'Por favor não utilize caracteres especiais',
                        NOT_UNIQUE: 'O nome já existe, por favor escolha outro',
                        NOT_VALID_AND_NOT_UNIQUE:
                            'O nome já existe, por favor escolha outro e não utilize caracteres especiais',
                        BETWEEN_ZERO_HUNDRED: 'O valor deve estar compreendido entre 0 e 100.',
                        CHOOSE_ASSET: 'Seleccione um ativo.',
                    },
                    APP: {
                        CHECKING_EMAIL_VERIFICATION: 'Por favor, verifique o seu e-mail antes de iniciar sessão',
                    },
                    HEADER: {
                        MARKETPLACE: 'Mercado',
                        PLANTS: 'Plantas',
                        PROFILE: 'Perfil',
                        LOGOUT: 'Sair',
                        NOTIFICATIONS: 'Notificações',
                        CONFIGURATION: 'Configuração',
                    },
                    SIDEBAR: {
                        COCKPIT: 'Cockpit',
                        DIGITAL_FACTORY: 'Digital Factory',
                        LIVE: 'Live View',
                        DASHBOARDS: 'Dashboards',
                        RESOURCE_MANAGER: 'Resource Manager',
                        SERVICE_STORE: 'Service Store',
                        EDUCATION: 'Education',
                        NEWS: 'News',
                        FINANCE: 'Finance',
                        CONFIGURATION: 'Configuração',
                        MARKETPLACE: 'Marketplace',
                        FEEDBACK: 'Dar feedback',
                        HELP: 'Ajuda',
                        HELP_MENU: {
                            CONTACT: 'Contacto',
                            IMPRINT: 'Impressão',
                            PRIVACY: 'Privacidade',
                            TERMS_OF_USE: 'Termos de utilização',
                        },
                        DOCUMENTATION: 'Documentação',
                        NOTIFICATIONS: 'Notificações',
                        ACCOUNT: 'Conta',
                    },
                    CHANGE_PICTURE_MODAL: {
                        HEADLINE: {
                            USER: 'Adicionar imagem de perfil',
                            PLANT: 'Adicionar logótipo',
                        },
                        CONFIRMATION_BUTTON: {
                            PLANT: 'Adicionar logótipo',
                            USER: 'Adicionar imagem de perfil',
                        },
                        SELECTFILE: 'Seleccionar ficheiro',
                        SIZE_EXCEEDED:
                            'O tamanho do ficheiro excede {{{size}} MB, por favor utilize imagem mais pequena',
                    },
                    DELETE_PICTURE_MODAL: {
                        HEADLINE: {
                            USER: 'Eliminar imagem de perfil?',
                            PLANT: 'Apagar logótipo?',
                        },
                        CONFIRMATION_BUTTON: {
                            PLANT: 'Apagar logótipo',
                            USER: 'Apagar imagem de perfil',
                        },
                    },
                    PROFILE: {
                        CHANGE_PASSWORD: 'Alterar palavra-passe',
                        MY_PROFILE: 'O meu perfil',
                        PASSWORD: 'Senha',
                        NAME: 'Nome',
                        CHANGE_NAME: 'Mudar o nome',
                        EMAIL: 'E-Mail',
                        LANGUAGE: 'Idioma',
                        SAVE_CHANGES: 'Guardar alterações',
                        EDIT_MY_PROFILE: 'Editar o meu perfil',
                        EMAIL_NOTIFICATIONS_HEADER: 'Notificações por e-mail',
                        EMAIL_NOTIFICATIONS_TEXT:
                            'Especifique as funções adicionais para as quais gostaria de ser notificado por e-mail.',
                        EMAIL_SEVERITY_TEXT:
                            'Definir os níveis de gravidade em que pretende ser notificado por correio electrónico',
                        INFORMATION: 'Informação',
                        WARNING: 'Alerta',
                        ERROR: 'Erro',
                        MY_PLANTS: 'Convites de fábrica',
                        REPORTING: 'Reports',
                        CONDITION_MONITORING: 'Condition Monitoring',
                        HISTORIAN: 'Historian',
                    },
                    PLANTS: {
                        NAME: 'Nome',
                        CREATE: {
                            NAME: 'Nome',
                            TAXID: 'Identificação fiscal',
                            COUNTRY: 'País',
                            CITY: 'Cidade',
                            HOUSENUMBER: 'Número da casa',
                            STREET: 'Rua',
                            POSTCODE: 'Código postal',
                            SUCCESS: 'Planta criada com sucesso',
                            FAILURE: 'Ocorreu um erro. Por favor, tente novamente.',
                            CONFIRMATION: 'Ao criar a planta, confirma a exactidão das suas informações.',
                        },
                        PROFILE: {
                            MEMBERS: 'Membros',
                            INVITATIONS: 'Convites',
                            SEND_INVITATION: 'Enviar convite',
                            SEND_INVITATIONS: 'Enviar convites',
                            TYPE_EMAIL: 'Convidar um membro por endereço eletrónico...',
                            DELETE_INVITATION: 'Tem a certeza de que quer retirar o convite?',
                            DELETE_INVITATION_BUTTON: 'Retirar convite',
                            DELETE_INVITATION_HEADLINE: 'Retirar o convite da planta?',
                            INVITE_MORE: 'Convidar mais utilizadores',
                            ADD_EMAIL_INPUT: 'Convidar outro membro',
                            EMAIL_ERROR: 'Por favor, verifique as entradas',
                            MANAGE_USER_ROLE: 'Gerir a função',
                            REMOVE: 'Remover',
                            UPDATE_ROLE: 'Função de actualização',
                            ROLE: 'Função',
                            ADMIN_USER_GENERAL: 'Permissões',
                            ADMIN_GENERAL_INVITE_DESCRIPTION: 'Convidar / remover utilizadores',
                            ADMIN_GENERAL_MNG_USER_DESCRIPTION: 'Gerir as funções do utilizador',
                            ADMIN_GENERAL_MNG_PLANT_DESCRIPTION: 'Gerir a informação da fábrica',
                            ADMIN_ASSET_MANAGER_LINE_ASSET_DESCRIPTION: 'Adicionar/ remover linhas e recursos',
                            ADMIN_ASSET_MANAGER_MNG_FILES_DESCRIPTION: 'Gerir ficheiros dentro das linhas e recursos',
                            USER_GENERAL_MNG_PLANT_DESCRIPTION: 'Ver informação sobre a fábrica',
                            USER_ASSET_MANAGER_MNG_FILES_DESCRIPTION: 'Gerir ficheiros dentro das linhas',
                            WITHDRAW_INVITATION: 'Retirar convite',
                            TIMEZONE: 'Fuso horário:',
                            INVITATIONS_TAB: 'Convites',
                            MEMBERS_TAB: 'Membros',
                            ENERGY_TAB: 'Tarifas de energia',
                            YOU: '(Você)',
                            SEARCH_MEMBERS: 'Procurar membros ...',
                        },
                        EDIT: {
                            SUCCESS: 'Planta mudada com sucesso',
                            FAILURE: 'Ocorreu um erro. Por favor, tente novamente.',
                        },
                        OVERVIEW: {
                            MY_PLANTS: 'As Minhas Plantas',
                            MY_PLANT: 'A minha planta',
                            INVITATIONS: 'Convites',
                            INVITATION: 'Convite',
                            NO_INVITATIONS: 'Actualmente não tem nenhum convite pendente.',
                            REMAINING_INVITATIONS: 'Tem {{count}} convites pendentes',
                            REMAINING_INVITATION: 'Tem {{count}} convite pendente',
                            DECLINE: 'Declínio',
                            ACCEPT: 'Aceitar',
                            NO_MEMBER: 'Ainda não é um membro desta planta',
                            NOT_CREATED: 'Esta planta ainda não foi criada',
                            ADMIN: 'Admin',
                            USER: 'Utilizador',
                        },
                        HEADLINE: 'Plantas',
                        HEADLINE_SINGLE: 'Planta',
                        CREATE_LABEL: 'Criar planta',
                        EDIT_LABEL: 'Editar Planta',
                        UNASSIGNED: 'Não atribuído',
                        ENERGY_CONTRACTS: {
                            ENERGY_CONTRACTS: 'Contratos de energia',
                            ADD_ENERGY_CONTRACT: 'Aditar tarifa de energia',
                            MY_ENERGY_CONTRACTS: 'Minhas contratações de energia',
                            ELECTRICITY: 'Eletricidade',
                            GAS: 'Gás',
                            PROVIDER: 'Prestador',
                            PROVIDER_NAME: 'Nome do prestador',
                            ADDRESS: 'Endereço',
                            DURATION: 'Duração do contrato',
                            CONTRACT_PERIOD: 'Período do contrato',
                            PERIOD_OF_NOTICE: 'Prazo de pré-aviso',
                            PRICES: 'Preços',
                            BASE_PRICE: 'Preço de base',
                            PRICE_PER_UNIT: 'Preço / unidade',
                            PRICE_GUARANTEE: 'Garantia de preço',
                            DISCOUNTS: 'Descontos e bónus',
                            DELETE_CONTRACT: 'Apagar contrato de energia',
                            DELETE_CONTRACT_TEXT: 'Quer mesmo apagar o contrato de energia?',
                            MONTH: 'Mês',
                            MONTHS: 'Meses',
                            NOT_SPECIFIED: 'Não especificado',
                            ACTIVE: 'Ativo',
                            INACTIVE: 'Inativo',
                            EDIT_ENERGY_CONTRACT: 'Editar tarifa de energia',
                            ENERGY_PROVIDER: 'Provedor de energia',
                            ENERGY_TYPE: 'Tipo de tarifa de energia',
                            ENERGY_TARIFF_NAME: 'Nome da tarifa de energia',
                            ENERGY_PROVIDER_NAME: 'Nome do fornecedor de energia',
                            STREET: 'Rua',
                            HOUSE_NUMBER: 'Número da casa',
                            POSTAL_CODE: 'Código postal',
                            CITY: 'Cidade',
                            TARIFF_DETAILS: 'Detalhes da tarifa',
                            PRICE_PER_KWH: 'Preço por quilowatt-hora',
                            REBATES_AND_BONUS: 'Descontos e bónus',
                            MANDATORY_FIELDS: 'Os campos marcados com * são obrigatórios.',
                            PERCENT: 'Percentagem',
                            START: 'Início',
                            END: 'Fim',
                        },
                    },
                    CHANGE_PASSWORD_MODAL: {
                        TEXT: 'Com a confirmação, receberá um e-mail para redefinir a sua palavra-passe. Para uma alteração segura da palavra-passe, será desconectado do sistema.',
                        CHANGE_PASSWORD: 'Alterar palavra-passe',
                        CONFIRMATION_BUTTON: 'Alterar palavra-passe',
                        SURE: 'Tem a certeza?',
                    },
                    CHANGE_NAME_MODAL: {
                        CHANGE_NAME: 'Mudar o nome',
                        FIRST_NAME: 'Primeiro nome',
                        LAST_NAME: 'Último nome',
                        CONFIRMATION: 'Mudar o nome',
                        SUCCESS: 'Nome alterado com sucesso',
                        ERROR_LASTNAME:
                            'Por favor, utilize o mínimo {{min}} e máximo {{max}} caracteres para o último nome',
                        ERROR_FIRSTNAME:
                            'Por favor, utilize o mínimo {{min}} e máximo {{max}} caracteres para o primeiro nome',
                    },
                    DIALOG_MESSAGES: {
                        CANCEL_BUTTON: 'Cancelar',
                        DELETE_BUTTON: 'Eliminar',
                    },
                    DOCUMENTS: {
                        EDIT: 'Editar',
                        DELETE: 'Eliminar',
                        MOVE: 'Mudança',
                        ADDED_ON: 'Adicionado em',
                        ADDED_BY: 'Adicionado por',
                        NO_DOCS1: 'Não há documentos disponíveis.',
                        NO_DOCS2: 'Agora carregue documentos ou crie uma pasta.',
                        NAME: 'Nome',
                    },
                    CREATE_FOLDER: {
                        HEADLINE: 'Criar Pasta',
                        CANCEL: 'Cancelar',
                        DONE: 'Criar',
                        ERROR_UPLOAD: 'Erro ao criar Pasta',
                        LABEL: 'Nome da pasta',
                        ERROR_DELETE: 'Erro ao apagar esta Pasta',
                        ERROR_EDIT: 'Erro ao actualizar esta Pasta',
                        CREATE: 'Criar nova pasta',
                        CHOOSE_PATH: 'Seleccione o caminho para carregar ficheiros',
                    },
                    UPLOAD_FILE: {
                        HEADLINE: 'Carregar ficheiro(s)',
                        CANCEL: 'Cancelar',
                        DONE: 'Carregar',
                        ERROR_UPLOAD: 'Erro durante o carregamento de ficheiro(s)',
                        ERROR_DELETE: 'Erro durante a eliminação do ficheiro',
                        ERROR_EDIT: 'Erro durante a actualização do ficheiro',
                        FOLDER_EXISTS: 'O nome desta pasta já existe.',
                        NO_FILES: 'Nenhum ficheiro foi seleccionado para upload!',
                        MACHINE: 'Máquina',
                        ADDED_ON: 'Adicionado em',
                        ADDED_BY: 'Adicionado por',
                    },
                    PARTS: {
                        TOGGLE: 'Partes',
                        HEADER: 'DISPONÍVEL EM BREVE',
                        DESCRIPTION1: 'Em breve poderá ver as peças certas para o seu recurso.',
                        DESCRIPTION2: 'Pode então encomendar directamente através do Marketplace.',
                        DESCRIPTION3: 'Mas já agora pode usar a pesquisa para encontrar peças.',
                    },
                    DROPZONE: {
                        TEXT: 'Arraste e solte arquivos aqui ou clique',
                    },
                    LAST_DOCS_FILETEXT: {
                        OPEN_TODAY: 'última abertura: hoje',
                        OPEN_YESTERDAY: 'última abertura: ontem',
                        UPLOAD_TODAY: 'carregado: hoje',
                        UPLOAD_YESTERDAY: 'carregado: ontem',
                        OPEN: 'última abertura: ',
                        UPLOAD: 'carregado: ',
                    },
                    MOVE_DOCUMENT: {
                        HEADLINE: 'Mover documento para...',
                        MOVE: 'Mudança',
                    },
                    EDIT_FOLDER: {
                        HEADLINE: 'Editar Pasta',
                        DONE: 'Renomear',
                    },
                    DELETE_FOLDER: {
                        HEADLINE: 'Retirar pasta?',
                        CONFIRMATION1: 'A pasta',
                        CONFIRMATION2: 'e todos os ficheiros que contém são eliminados. Isto não pode ser desfeito.',
                        DELETE: 'Apagar pasta',
                    },
                    MOVE_FOLDER: {
                        HEADLINE: 'Mover pasta para...',
                    },
                    MULTIPLE_DELETE: {
                        HEADLINE: 'Remover artigos?',
                        CONFIRMATION: 'Tem a certeza de apagar estes itens?',
                        DELETE: 'Eliminar',
                    },
                    MULTIPLE_MOVE: {
                        HEADLINE: 'Mover Objectos para...',
                    },
                    MODAL: {
                        OK_BUTTON: 'Ok',
                        CANCEL_BUTTON: 'Cancelar',
                    },
                    DELETE_DOCUMENT: {
                        HEADLINE: 'Remover ficheiro?',
                        CONFIRMATION: 'Tem a certeza de que vai apagar este ficheiro?',
                        SUCCESS: 'Arquivo removido com sucesso',
                        CANCEL: 'Cancelar',
                        DELETE: 'Apagar ficheiro',
                        CONFIRMATION_BUTTON: 'Apagar ficheiro',
                    },
                    EDIT_DOCUMENT_MODAL: {
                        HEADLINE: 'Editar {{name}}?',
                        EDIT_BUTTON: 'Editar ficheiro',
                        SUCCESS: 'Ficheiro actualizado com sucesso',
                        ERROR_MESSAGE: 'Min: 1 Caractere, primeiro Caractere: tudo excepto "."',
                    },
                    NO_AUTHORIZATION: {
                        HEADLINE: 'Falta de autorização',
                        ONE: 'Agradecemos a sua curiosidade, mas infelizmente não podemos conceder-lhe acesso porque',
                        ONE_BOLD: 'não é um administrador',
                        ONE_END: 'desta instalação.',
                        TWO: 'Contacte o seu administrador para obter acesso.',
                        BUTTON_BACK: 'Voltar',
                        BUTTON_COCKPIT: 'Para o cockpit',
                    },
                    NO_PLANT_MEMBER: {
                        HEADLINE: 'Sem acesso',
                        ONE: 'Agradecemos a sua curiosidade, mas infelizmente não podemos conceder-lhe acesso porque',
                        ONE_BOLD: 'não é membro',
                        ONE_END: 'desta fábrica.',
                        TWO: 'Contacte o seu administrador para obter acesso.',
                        BUTTON_BACK: 'Voltar',
                        BUTTON_COCKPIT: 'Para o cockpit',
                    },
                    NOT_AVAILABLE_FOR_DEMO_PLANT: {
                        HEADLINE: 'Não disponível para instalações de demonstração',
                        SUBTEXT:
                            'A monitorização de condições não pode ser utilizada para o centro de demonstração. Selecione outro centro para monitorar sua produção usando Condições.',
                    },
                    MAP_DATA_TIP: {
                        SHEETS_H: 'Placas/h',
                        FASTEST_RUN: 'Velocidade máxima',
                        AVERAGE_CURRENT_ORDER: 'Ø Pedido actual',
                        ORDER: 'Pedido',
                        NUMBER_OF_PALLETS: 'Número de paletes',
                        WASTE_PRESS: { BALES_H: 'Fardos/h', BALES_QUANTITY: 'Contagem de fardos' },
                        PALLETIZING_CENTER: { PALLETS_H: 'Paletes/h', PALLETS_QUANTITY: 'Contagem de paletes' },
                        SHREDDER: { WASTE_TOTAL: 'Quantidade de resíduos [kg]', WASTE_PER_HOUR: 'Resíduos [kg]/h' },
                    },
                    RESOURCE_MANAGER: {
                        HEADLINE: 'Resource Manager',
                        EXPORT: 'Exportar para PDF',
                        TOTAL_ELECTRICITY_CONSUMPTION: 'Evolução consumo elétrico (Total planta)',
                        TOTAL_ENPI: 'Evolução EnPI elétrico (Total planta)',
                        SWITCH_TOTAL: 'Total',
                        SWITCH_ENPI: 'EnPI',
                        CURRENT_PERIOD: 'Período atual',
                        PREVIOUS_YEAR: 'Período do ano anterior',
                        MONTHS: {
                            '01': 'Jan',
                            '02': 'Fev',
                            '03': 'Mar',
                            '04': 'Abr',
                            '05': 'Mai',
                            '06': 'Jun',
                            '07': 'Jul',
                            '08': 'Ago',
                            '09': 'Set',
                            10: 'Out',
                            11: 'Nov',
                            12: 'Dez',
                        },
                        ELECTRICITY_CONSUMPTION_OVERVIEW: 'Resumo do consumo (eletricidade)',
                        GRID_SUPPLY: 'Rede eléctrica',
                        PV_SUPPLY: 'Alimentação PV',
                        PV_FEEDIN: 'Injeção PV',
                        ENERGY_FLOW: 'Fluxo Energético',
                        SOURCE: 'Fonte',
                        CONSUMER: 'Consumidor',
                        MAINS: 'Alimentação eléctrica',
                        PV: 'Fotovoltaica',
                        CONSUMPTION_DAILY_AVERAGE: 'Consumo (Média diária)',
                        ELECTRICITY: 'Eletricidade',
                        WATER: 'Água',
                        GAS: 'Gás',
                        COMPRESSED_AIR: 'Ar comprimido',
                        STEAM: 'Vapor',
                        TOOLTIP: 'O valor comparativo corresponde à média diária do mesmo período do ano anterior.',
                        COMPARISON: 'Comparação',
                        RESOURCE_TABLE: {
                            DAILY_AVERAGE: 'Média diária',
                            TOTAL_CONSUMPTION: 'Consumo total',
                            UNITS: 'Unidades',
                            DEVIATION: 'Desviol',
                            TOTAL_PLANT: 'Total planta',
                            ENTER_BENCHMARK: 'Entrar benchmark',
                        },
                        LAST_6_MONTHS: 'Últimos 6 meses',
                        ENERGY_BALANCE: 'Balanço energético',
                        CONSUMPTIONS: 'Consumo',
                        RESOURCE_CONSUMPTION: 'Consumo dos recursos (Período individual)',
                        OVERVIEW: 'Visão geral',
                        CONSUMPTION: 'Consumo',
                        BENCHMARK: 'Referência',
                        ENPI_HISTORY: 'História EnPI',
                    },
                    DASHBOARDS: {
                        READD_WIDGET_HEADER: 'Adicionar novo widget',
                        READD_WIDGET_SUBLINE_1:
                            'Devido a um ajuste técnico, o widget deve ser adicionado ao painel de controlo.',
                        NAME: 'Nome',
                        LOADING: 'Carregamento',
                        ALL: 'Todos',
                        SETUP: 'Configuração',
                        FAULT: 'Parar',
                        IDLENESS: 'Ociosidade',
                        MACHINE_STATUS: 'Estado da máquina',
                        NO_PREVIEW_POSSIBLE: 'Não é possível qualquer pré-visualização',
                        DEFINE_YOUR_WIDGET_1: 'Defina o seu indivíduo',
                        DEFINE_YOUR_WIDGET_2: 'Ferramenta em mais detalhe',
                        TOTAL: 'Total',
                        CHOOSE_VISUALIZATION: 'Escolher visualização',
                        SELECT_ALL: 'Seleccionar todos',
                        CLEAR_SELECTION: 'Selecção defenida',
                        ASSET_SELECTION: 'Selecção de asset',
                        VISUALIZATION: 'Visualização',
                        AREA: 'Área',
                        PLANT: 'Planta',
                        ASSET: 'Asset',
                        TABLE: 'Quadro',
                        CHART: 'Gráfico',
                        LINE_CHART: 'Gráfico de linhas',
                        BAR_CHART: 'Gráfico de barras',
                        TOTAL_DROPDOWN: '(Soma)',
                        OVER_TIME_DROPDOWN: '(Ao longo do tempo)',
                        DELETE: 'Eliminar',
                        ERROR: {
                            TIMEOUT: 'Os dados não podiam ser carregados',
                            NO_WIDGET_NAME: 'Por favor, introduza um nome.',
                            NO_KPI: 'Por favor escolha um KPI',
                            NO_ASSET: 'Por favor, escolha uma asset.',
                            NO_ASSETS: 'Por favor, seleccione pelo menos uma asset.',
                            WIDGET_NAME_ALREADY_EXISTS: 'Este nome já foi utilizado. Por favor, mude-o',
                            NO_PRESENTATION: 'Por favor, escolha um formulário de visualização.',
                        },
                        DELETE_WIDGET: {
                            DELETE: 'Apagar ferramenta',
                            TEXT_1: 'Tem a certeza de que vai apagar o ferramenta?',
                            TEXT_2: 'O processo não pode ser desfeito.',
                        },
                        PREVIEW: 'Pré-visualização',
                        WIDGET_TYPES: {
                            PRODUCTION: 'Produção',
                            RESOURCES: 'Recursos',
                            MAINTENANCE: 'Manutenção',
                            INCIDENTS: 'Incidentes',
                            NOT_YET_UNLOCKED: 'Função ainda não desbloqueada...',
                            NOT_YET_UNLOCKED_SUB_1: 'Obter a função de Controlo de Recursos',
                            NOT_YET_UNLOCKED_SUB_2: 'e acrescentar dados sobre energia e recursos',
                            NOT_YET_UNLOCKED_SUB_3: 'aos seus painéis de bordo.',
                            NOT_YET_UNLOCKED_KPI: 'Não há métricas de produção disponíveis...',
                            NOT_YET_UNLOCKED_SUB_1_KPI: 'Contacte a openpack para',
                            NOT_YET_UNLOCKED_SUB_2_KPI: ' obter os dados de que necessita',
                            NOT_YET_UNLOCKED_SUB_3_KPI: 'as suas análises.',
                        },
                        KEY_PERFORMANCE_INDICATOR: 'KPI',
                        ADD_WIDGET: 'Adicionar ferramenta',
                        EDIT_WIDGET: 'Editar ferramenta',
                        RENAME: 'Renomear painel de navegação',
                        NO_WIDGETS: {
                            START_NOW: 'Comece agora!',
                            SUB_1: 'Adicione o seu primeiro widget e',
                            SUB_2: 'mantenha-se a par das suas principais métricas a partir de agora.',
                            SUB_MOBILE:
                                'Adicione o seu primeiro widget e mantenha-se a par das suas principais métricas a partir de agora.',
                        },
                        EDIT: 'Editar painel de gráficos',
                        EXPORT: 'Exportação',
                        CHOOSE_ONE_TYPE: 'Por favor seleccione pelo menos um formato de ficheiro.',
                        NOT_ALL_RECIPIENTS_MEMBER_OF_PLANT:
                            'Neste momento, não podem ser seleccionados widgets para esta planta. Nem todos os destinatários da série de relatórios deste painel de bordo são permitidos para a planta.Adicionar os utilizadores a planta, para adicionar novos widgets.',
                        NO_REPORTS: 'Nenhum relatório está actualmente arquivado',
                        ARCHIVED: 'arquivado',
                        DOWNLOAD_ALL: 'Todos os downloads',
                        DELETE_REPORT: 'Quer mesmo eliminar a série de relatórios?',
                        BACK: 'Voltar',
                        DELETE_SERIES: 'Eliminar séries',
                        UNSUBSCRIBE_AS_RECIPIENT: 'Sair como destinatário',
                        TO_DASHBOARD: 'Para o dashboard',
                        ADD_RECIPIENT: 'Adicionar destinatário',
                        MORE: 'mais',
                        ENDS_ON: 'Termina em',
                        RECEIVER: 'Receptor',
                        ADMINISTRATOR: 'Criador',
                        RHYTHM: 'Ritmo',
                        DAILY: 'Diário',
                        WEEKLY: 'Semanário',
                        MONTHLY: 'Mensal',
                        START: 'Iniciar',
                        END: 'Terminar (opcional)',
                        WHICH_PERIOD: 'Que período devem representar os relatórios?',
                        WHICH_SHIFT: 'Que camada devem os relatórios representar em cada caso? (opcional)',
                        LAST_7_DAYS: 'Últimos 7 dias',
                        FORMAT: 'Formato',
                        CREATE_REPORT: 'Criar relatório',
                        CREATE_REPORT_SERIES: 'Criar séries de relatórios',
                        MY_REPORTS: 'Os meus relatórios',
                        REPORT_NAME: 'Nome do relatório',
                        REPORT_ERROR: 'É necessário um Input.',
                        ADDITIONAL_RECEIVERS: 'Receptores adicionais (opcional)',
                        ADDITIONAL_RECEIVERS_LIST: 'Receptores adicionais',
                        RECEIVERS_INFO: 'Só podem ser adicionados os recipientes que estão registados com openpack.',
                        REPORT_SERIES: 'Série de relatórios',
                        SINGLE_REPORT: 'Relatório único',
                        GENERATING_PDF: 'Criar PDF...',
                        GENERATING_PPTX: 'Criar PPTX...',
                        GENERATING_EXCEL: 'Criar o Excel...',
                        GENERATING_FAILED: 'A criação para exportação falhou',
                        DELETE_DASHBOARD: 'Eliminar painel de gráficos',
                        SAVE: 'Guardar',
                        DELETE_MODAL: {
                            HEADER: 'Apagar Painel de gráficos',
                            BODY: 'Assim que se eliminar o painel de instrumentos, todas as séries de relatórios associados serão encerradas. Este processo não pode ser desfeito. Quer mesmo apagar este Painel de instrumentos?',
                            DELETE_BUTTON: 'Apagar Painel de gráficos',
                        },
                        CREATED_ON: 'Criado em',
                        PRODUCTION: 'Produção',
                        ENERGY_CONSUMPTION_PER_ASSET: {
                            ENERGY_CONSUMPTION: 'Consumo de energia',
                            ASSET: 'Bens',
                        },
                        GAS_CONSUMPTION_PER_ASSET: {
                            GAS_CONSUMPTION: 'Consumo de gas',
                            ASSET: 'Bens',
                        },
                        STEAM_CONSUMPTION_PER_ASSET: {
                            STEAM_CONSUMPTION: 'Consumo de vapor',
                            ASSET: 'Bens',
                        },
                        COMPRESSED_AIR_CONSUMPTION_PER_ASSET: {
                            COMPRESSED_AIR_CONSUMPTION: 'Consumo de ar comprimido',
                            ASSET: 'Asset',
                        },
                        WATER_CONSUMPTION_PER_ASSET: {
                            WATER_CONSUMPTION: 'Consumo de água',
                            ASSET: 'Asset',
                            LITER: 'Litros',
                        },
                        ORDER_TILE: {
                            ORDERS: 'Encomendas',
                            CUSTOMER: 'Cliente',
                            ORDER_NUMBER: 'Número de encomenda',
                            GRADE: 'Variedade',
                            SHEETS: 'Placas',
                            START: 'Início',
                            END: 'Fim',
                            DURATION: 'Duração',
                            IN_PROGRESS: 'em curso',
                        },
                        PLANNED_ACTUAL_SQM: {
                            PRODUCED: 'm² produzidos',
                            TARGET: 'Alvo',
                            ACTUAL: 'Actual',
                            CORRUGATOR: 'Caneladora',
                        },
                        PLANNED_ACTUAL_LM: {
                            LINEAR_METER: 'Metros lineares',
                            CORRUGATOR: 'Caneladora',
                            TOOLTIP: 'Cálculo do metro linear alvo com base nas velocidades do alvo!',
                        },
                        AVERAGE_PRODUCTION_SPEED: {
                            HEADER: 'Ø-Velocidade',
                            MACHINE: 'Máquina',
                            INDIVIDUAL: 'indivíduo',
                            CORRUGATOR: 'Caneladora',
                            CONVERSION: 'Transformação',
                            WITHOUT_FKM: 'sem FKM',
                            SHEETS: 'Placas',
                            ASSET: 'Bens',
                        },
                        RUNSTOPTIME: {
                            HEADER: 'Tempo de execução e paragem',
                            RUNTIME: 'Tempo de execução',
                            DOWNTIME: 'Tempo de paragem',
                            DOWNTIME_COSTS: 'Downtime costs',
                            CORRUGATOR: 'Caneladora',
                            CONVERSION_TOTAL: 'Transformação total',
                            ASSET: 'Bens',
                        },
                        TOP_CUSTOMER: {
                            HEADER: '10 principais clientes por volume produzido [m²]',
                        },
                        GRADE_OVERVIEW: {
                            HEADER: 'Vista geral da classificação [m²]',
                            PARETO: 'Quantidade de produção acumulada: ',
                        },
                        STOP_REASONS: {
                            HEADER: '10 principais motivos de paragem [número]',
                            PARETO: 'Número acumulado de motivos de paragem: ',
                        },
                        TRIM: { TRIM: 'Trim' },
                        TODAY: 'Hoje',
                        YESTERDAY: 'Ontem',
                        LAST_5_DAYS: 'Últimos 5 dias',
                        LAST_30_DAYS: 'Últimos 30 dias',
                        CUSTOM: 'Personalizado',
                        TEXT1: 'Seja curioso!',
                        TEXT2: 'Aqui encontrará em breve painéis de instrumentos com as figuras-chave mais importantes das suas máquinas. Desta forma, tê-los-á sempre à mão de forma rápida e clara.',
                        WIDGET_TYPE: {
                            ENERGY_CONSUMPTION_PER_1000_M2: 'Consumo de energia por 1000 metros quadrados',
                            ENERGY_CONSUMPTION_PER_MACHINE_STATUS: 'Consumo de energia por estado de máquina',
                            TOTAL_ENERGY_CONSUMPTION_PER_MACHINE_STATUS:
                                'Consumo total de energia por estado de máquina',
                            ENERGY_CONSUMPTION_PER_MACHINE_STATUS_PER_ASSET: 'Consumo de energia por estado de máquina',
                            COMPRESSED_AIR_CONSUMPTION_PER_MACHINE_STATUS:
                                'Consumo de ar comprimido por estado de máquina',
                            TOTAL_COMPRESSED_AIR_CONSUMPTION_PER_MACHINE_STATUS:
                                'Consumo total de ar comprimido por estado de máquina',
                            COMPRESSED_AIR_CONSUMPTION_PER_MACHINE_STATUS_PER_ASSET:
                                'Consumo de ar comprimido por estado de máquina',
                            ELECTRICAL_POWER_VS_MACHINE_SPEED_OF_ASSET: 'Potência eléctrica vs. velocidade',
                            COMPRESSED_AIR_VS_MACHINE_SPEED_OF_ASSET: 'Ar comprimido vs. Geschwindigkeit',
                            ELECTRICAL_POWER: 'Energia eléctrica',
                            ENERGY_CONSUMPTION: 'Consumo de energia',
                            TOTAL_ENERGY_CONSUMPTION: 'Consumo total de energia',
                            ENERGY_CONSUMPTION_PER_LINE: 'Consumo de energia por bens',
                            TOTAL_ELECTRICAL_POWER: 'Potência eléctrica total',
                            ELECTRICAL_POWER_PER_ASSET: 'Energia eléctrica por bens',
                            ORDER_OVERVIEW: 'Visão geral da encomenda',
                            ORDER_OVERVIEW_DESC:
                                'Visão geral das encomendas com informação sobre clientes, variedades, tamanho da encomenda e tempo de produção.',
                            GRADE_OVERVIEW: 'Visão geral das notas - Top 10',
                            GRADE_OVERVIEW_DESC:
                                'Visão geral dos 10 graus superiores produzidos com a quantidade produzida em metros quadrados e %.',
                            TOP_10_STOP_REASONS: 'Motivos de paragem - Top 10',
                            SPEED_GRAPH: 'Gráfico de velocidade',
                            TOP_10_STOP_REASONS_PER_ASSET: 'Motivos de paragem por ativo  - Top 10',
                            TOTAL_TOP_10_STOP_REASONS: 'Motivos de paragem total - Top 10',
                            TOP_10_CUSTOMER_PRODUCED_QUANTITY: 'Visão geral dos clientes - Top 10',
                            TOP_10_CUSTOMER_PRODUCED_QUANTITY_DESC:
                                'Visão geral dos 10 principais clientes ordenados pelo volume de encomendas em metros quadrados.',
                            LINEAR_METER: 'Metros lineares (Caneladora)',
                            LINEAR_METER_DESC:
                                'Comparação da quantidade de produção prevista e real da caneladora em metros lineares.',
                            PRODUCED_M2: 'm² produzidos (Caneladora)',
                            PRODUCED_M2_DESC:
                                'Comparação entre o metro quadrado planeado e o efectivamente produzido na onduladora.',
                            TRIM: 'Trim',
                            TRIM_DESC: 'Este gráfico mostra o trim lateral na caneladora em %',
                            RUNTIME_DOWNTIME: 'Tempo de execução e paragem',
                            RUNTIME_DOWNTIME_DESC:
                                'Esta tabela mostra o tempo de execução e paragem das diferentes linhas.',
                            AVERAGE_SPEED: 'Ø-Velocidade',
                            AVERAGE_SPEED_DESC:
                                'Esta tabela mostra a velocidade média para cada linha individualmente e em m²/h.',
                            DOWNTIME_COSTS: 'Custos de inactividade',
                            DOWNTIME_COSTS_DESC:
                                'Este diagrama mostra os custos totais do tempo de inactividade e a divisão para cada linha.',
                            ORDER_LIST: 'Lista de pedidos',
                            GAS_CONSUMPTION: 'Consumo de gás',
                            TOTAL_GAS_CONSUMPTION: 'Consumo total de gás',
                            GAS_CONSUMPTION_PER_ASSET: 'Consumo de gás por recurso',
                            COMPRESSED_AIR_CONSUMPTION: 'Consumo de ar comprimido',
                            TOTAL_COMPRESSED_AIR_CONSUMPTION: 'Consumo total de ar comprimido',
                            COMPRESSED_AIR: 'Ar comprimido',
                            COMPRESSED_AIR_CONSUMPTION_PER_ASSET: 'Consumo de ar comprimido por recurso',
                            WATER_CONSUMPTION: 'Consumo total de água',
                            TOTAL_WATER_CONSUMPTION: 'Consumo total de água',
                            WATER_CONSUMPTION_PER_ASSET: 'Consumo de água por recurso',
                            STEAM_CONSUMPTION: 'Consumo de vapor',
                            TOTAL_STEAM_CONSUMPTION: 'Consumo total de vapor',
                            STEAM_CONSUMPTION_PER_ASSET: 'Consumo de vapor por recurso',
                        },
                        SHIFT: {
                            DATE: 'Data',
                            SHIFT_OPTIONAL: 'Turno (opcional)',
                            EARLY_SHIFT: 'Turno da manhã',
                            LATE_SHIFT: 'Turno da noite',
                            NIGHT_SHIFT: 'Turno da tarde',
                            WIDGET_NOT_AVAILABLE_1: 'Widget não disponível',
                            WIDGET_NOT_AVAILABLE_2:
                                'Para este widget, os dados só podem ser apresentados se todas as camadas estiverem seleccionadas.',
                        },
                    },
                    NEW_DASHBOARDS: {
                        HEADER: 'Meus Dashboards',
                        CREATE_BUTTON: 'Criar Dashboard',
                        CHOOSE_KPI: 'Seleccione os seus KPIs:',
                        CREATE: 'Criar',
                        CANCEL: 'Cancelar',
                        CREATE_TEXT1: 'Crie o seu próprio Dashboard',
                        CREATE_TEXT2: 'Criar rápida e facilmente um painel de instrumentos individual',
                        CREATE_TEXT3: 'que se adapte exactamente às suas necessidades.',
                        CREATE_TEXT_MOBILE:
                            'Criar rápida e facilmente um painel de instrumentos individual que se adapte exactamente às suas necessidades.',
                        NO_DASHBOARD_NAME: 'Por favor, insira um nome.',
                        CHOOSE_KPI_ERROR: 'Por favor, seleccione pelo menos um KPI.',
                        NAME: 'Nome',
                    },
                    FINANCE: {
                        START: {
                            TYPE_OF_FINANCING: 'Tipo de financiamento',
                            START_REQUEST: 'Pedido de início',
                            CHOOSE_TYPE: 'Escolha o seu tipo de financiamento',
                            MORE_INFORMATION: 'Mais informações sobre',
                            FINANCING: 'Financiamento',
                            LEASING: 'Leasing',
                            COOPERATION: 'Em cooperação com',
                            FINANCING_COMPARISON: 'Comparação de financiamento',
                            POINT_1: 'Gratuito e sem compromisso',
                            POINT_2: 'Ofertas independentes de parceiros premium',
                            POINT_3: 'Criação rápida e fácil de pedidos em 5 minutos',
                            TAB1: 'Pedir um empréstimo',
                            TEXT1: 'Em cooperação com o nosso parceiro financeiro ',
                            TEXT2: 'tem a possibilidade de solicitar um empréstimo de uma forma simples e apenas dentro de ',
                            TEXT3: '3 minutos.',
                            TEXT4: '',
                            BUTTON: 'Comece agora',
                            INPUT: 'Montante do crédito',
                            TRANSMITTED: 'Consultas de crédito já submetidas:',
                            NEXT: 'Próximo',
                            CHOOSE: 'Por favor seleccione',
                            EMPTY_FIELD: 'Por favor preencha este campo.',
                            EMPTY_FIELDS: 'Por favor, preencha estes campos.',
                            INFO: 'Informação',
                            CREDIT_AREA: 'O crédito deve estar entre 50.000 e 50.000.000 EUR',
                            WRONG_MAIL: 'O E-Mail não está correcto!',
                            NO_OPTIONS: 'Nenhum país encontrado',
                        },
                        INFO: {
                            FINANCING: 'Financiamento',
                            FINANCING_DFKP: {
                                HEADER1: 'Como é que é o processo?',
                                HEADER2: 'Que informação precisa de ser fornecida numa etapa posterior?',
                                HEADER3: 'Porque devo escolher a Deutsche Leasing como parceiro?',
                                HEADER4: 'Quais são os benefícios para mim?',
                                TEXT1: 'Ao submeter o formulário de pedido de arrendamento, a Deutsche Leasing preparará uma oferta individual e entrará em contacto com o requisitante. Receberá uma oferta no prazo de 2 dias úteis.',
                                TEXT2: 'Em preparação para uma chamada de investimento individual com um dos nossos consultores, por favor tenha as seguintes informações prontas:',
                                TEXT3: 'A Deutsche Leasing pretende ser o principal parceiro financeiro de activos para clientes empresariais na Alemanha. É por esta ambição que o colocamos a si, nossos clientes e parceiros, no centro da nossa empresa. Alinhamos o nosso negócio consigo e os seus objectivos, contribuindo com a nossa competência em energia e soluções, tanto na Alemanha como no estrangeiro.',
                                TEXT4: 'Os nossos clientes beneficiam da consulta em língua alemã - independentemente do país onde gostariam de investir. Adaptamos a nossa oferta às suas necessidades específicas e ligamo-lo à nossa rede global de especialistas da indústria.',
                                POINT1: 'Ficha financeira anual (últimos 2 anos)',
                                POINT2: 'Declaração de avaliação das empresas',
                                POINT3: 'Visão geral das contas bancárias / liquidez, vencimentos e garantias',
                                POINT4: 'Plano de negócios actual',
                                POINT5: 'Candidatura rápida em linha e contacto no mesmo dia de um dos nossos especialistas em financiamento.',
                                POINT6: 'Reunião pessoal de negócios incluindo análise do potencial de crédito e estimativa de termos em directo',
                                POINT7: 'Preparação de documentos e gestão de processos até ao desembolso por parte do financiador apropriado',
                                POINT8: 'Consultoria holística após o encerramento e implementação do financiamento de acompanhamento',
                            },
                            FINANCING_DL: {
                                HEADER1: 'Como é que é o processo?',
                                HEADER2: 'Que informação precisa de ser fornecida numa etapa posterior?',
                                HEADER3: 'Porque devo escolher a Deutsche Leasing como parceiro?',
                                TEXT1: 'Ao submeter o formulário de pedido de arrendamento, a Deutsche Leasing preparará uma oferta individual e entrará em contacto com o requisitante. Receberá uma oferta no prazo de 2 dias úteis.',
                                TEXT2: 'Em preparação para uma chamada de investimento individual com um dos nossos consultores, por favor tenha as seguintes informações prontas:',
                                TEXT3: 'A Deutsche Leasing pretende ser o principal parceiro financeiro de activos para clientes empresariais na Alemanha. É por esta ambição que o colocamos a si, nossos clientes e parceiros, no centro da nossa empresa. Alinhamos o nosso negócio consigo e os seus objectivos, contribuindo com a nossa competência em energia e soluções, tanto na Alemanha como no estrangeiro.',
                                TEXT4: 'Os nossos clientes beneficiam da consulta em língua alemã - independentemente do país onde gostariam de investir. Adaptamos a nossa oferta às suas necessidades específicas e ligamo-lo à nossa rede global de especialistas da indústria.',
                                POINT1: 'Demonstrações financeiras anuais (últimos 2 anos)',
                                POINT2: 'Declaração de avaliação das empresas',
                                POINT3: 'Visão geral das contas bancárias / liquidez, vencimentos e garantias',
                                POINT4: 'Plano de negócios actual',
                            },
                            LEASING_DFKP: {
                                HEADER1: 'Como funciona o processo de leasing?',
                                HEADER2: 'Que informações são necessárias para o pedido?',
                                HEADER3: 'Quais são os benefícios para mim?',
                                HEADER4: 'Onde solicitar o crédito?',
                                TEXT1: 'Após o preenchimento e transmissão do formulário ao nosso parceiro, a DFKP entrará em contacto com o candidato. Uma vez apresentados os documentos necessários, a DFKP poderá obter uma decisão de crédito positiva do parceiro financiador apropriado no prazo de 48 horas. Um pagamento é possível no prazo de 2-5 dias úteis.',
                                TEXT2: 'Para o seu pedido de financiamento no início só precisamos de alguns detalhes sobre a sua empresa (incluindo nome, forma jurídica) e você como pessoa de contacto. Numa segunda fase (após a reunião da empresa com a DFKP), o nosso Parceiro precisará dos seguintes documentos:',
                                TEXT3: 'O nosso parceiro, DFKP, é uma plataforma de financiamento independente e oferece aconselhamento transparente e orientado para objectivos na procura do melhor financiamento para o seu próximo projecto de financiamento. Os elementos-chave do seu serviço incluem:',
                                TEXT4: 'A DFKP trabalha com os financiadores alemães mais importantes de PMEs. Estes incluem bancos universais activos em toda a Alemanha, bancos regionais, bancos cooperativos, financiadores de PMEs, neo-bancos, financiadores de enxames, mercados em linha, financiadores de compras e empresas de leasing. As caixas económicas não se encontram actualmente entre os seus parceiros de financiamento. Após análise dos documentos, o pedido de empréstimo é submetido aos financiadores ideais pela DFKP.',
                                POINT1: 'Demonstrações financeiras anuais dos dois últimos anos fiscais (se disponíveis) ',
                                POINT2: 'Análise da gestão corrente incl. balanço',
                                POINT3: 'Declaração bancária ou de responsabilidade',
                                POINT4: 'Extractos de conta dos últimos 90 dias',
                                POINT5: 'Candidatura rápida em linha e contacto no mesmo dia de um dos nossos especialistas em financiamento.',
                                POINT6: 'Reunião pessoal de negócios incluindo análise do potencial de crédito e estimativa de termos em directo',
                                POINT7: 'Preparação de documentos e gestão de processos até ao desembolso por parte do financiador apropriado',
                                POINT8: 'Consultoria holística após o encerramento e implementação do financiamento de acompanhamento',
                            },
                            LEASING_DL: {
                                HEADER1: 'Como é que é o processo?',
                                HEADER2: 'Que informação precisa de ser fornecida numa etapa posterior?',
                                HEADER3: 'Porque devo escolher a Deutsche Leasing como parceiro?',
                                TEXT1: 'Ao submeter o formulário de pedido de arrendamento, a Deutsche Leasing preparará uma oferta individual e entrará em contacto com o requisitante.',
                                TEXT1_2: 'Receberá uma oferta dentro de 48 horas.',
                                TEXT2: 'Em preparação para uma chamada de investimento individual com um dos nossos consultores, por favor tenha as seguintes informações prontas:',
                                TEXT3: 'A Deutsche Leasing pretende ser o principal parceiro financeiro de activos para clientes empresariais na Alemanha. É por esta ambição que o colocamos a si, aos nossos clientes e parceiros, no centro da nossa empresa. Alinhamos o nosso negócio consigo e os seus objectivos, contribuindo com a nossa competência em energia e soluções, tanto na Alemanha como no estrangeiro.',
                                TEXT4: 'Os nossos clientes beneficiam da consulta em língua alemã - independentemente do país onde gostariam de investir. Adaptamos a nossa oferta às suas necessidades específicas e ligamo-lo à nossa rede global de especialistas da indústria.',
                                POINT1: 'Ficha financeira anual (últimos 2 anos)',
                                POINT2: 'Declaração de avaliação das empresas',
                                POINT3: 'Visão geral das contas bancárias / liquidez, vencimentos e garantias',
                                POINT4: 'Plano de negócios actual',
                            },
                        },
                        STEP1: {
                            COUNTRY_OF_ORIGIN: 'País de origem do objecto',
                            TOTAL_CONTRACT_VALUE: 'Valor total do contrato',
                            HEADER_FINANCING: 'Detalhes de Crédito',
                            HEADER_LEASING: 'Detalhes do aluguer',
                            OBJECT: 'Objecto',
                            RUNTIME: 'Tempo de execução',
                            RTOPTION1: '24 meses',
                            RTOPTION2: '30 meses',
                            RTOPTION3: '36 meses',
                            RTOPTION4: '42 meses',
                            RTOPTION5: '48 meses',
                            RTOPTION6: '54 meses',
                            RTOPTION7: '60 meses',
                            RTOPTION8: '66 meses',
                            RTOPTION9: '72 meses',
                            RTOPTION10: '78 meses',
                            RTOPTION11: '84 meses',
                            RTOPTION12: '90 meses',
                            RTOPTION13: '96 meses',
                            TOOLTIP_FINANCING:
                                'Este é o país de origem do bem para o qual a oferta de financiamento é solicitada.',
                            TOOLTIP_LEASING:
                                'Este é o país de origem do bem para o qual a oferta de leasing é solicitada.',
                        },
                        STEP2: {
                            SELECT_TYPE_NEW: 'Seleccionar ou digitar novo',
                            HEADER: 'Informações sobre a empresa',
                            COMPANY: 'Empresa',
                            LEGALFORM: 'Forma legal',
                            STREET: 'Rua',
                            HOUSENUMBER: 'Número da casa',
                            POSTALCODE: 'Código postal',
                            CITY: 'Cidade',
                            COUNTRY: 'País',
                            LFOPTION1: 'Sociedade de responsabilidade limitada',
                            LFOPTION2:
                                'Sociedade em comandita simples com uma sociedade de responsabilidade limitada como sócio comanditado',
                            LFOPTION3: 'Sociedade anónima',
                            LFOPTION4: 'Parceria de direito civil',
                            LFOPTION5: 'Parceria',
                            LFOPTION6: 'Sociedade em nome colectivo',
                            LFOPTION7: 'Sociedade em comandita simples',
                            LFOPTION8: 'Sociedade limitada por acções',
                            LFOPTION9: 'Empresa empresarial (responsabilidade limitada)',
                            LFOPTION10: 'Cooperativa registada',
                            LFOPTION11: 'Associação registada',
                            LFOPTION12: 'Trabalhador independente/auto-empregado',
                            LFOPTION13: 'Propriedade exclusiva',
                            LFOPTION14: 'Outros',
                            LFOPTION15: 'Limitado',
                            COMPANY1: 'CIPA GmbH',
                        },
                        STEP3: {
                            HEADER: 'Informação de contacto',
                            FIRSTNAME: 'Primeiro nome',
                            LASTNAME: 'Apelido',
                            EMAIL: 'E-Mail (negócio)',
                            TELEPHONE: 'Telefone',
                            COMMENT: 'Nota',
                            COMMENTPLACEHOLDER: 'Mais alguma coisa que nos queira dizer?',
                            BUTTON: 'Continuar',
                        },
                        STEP4: {
                            EMAIL: 'E-Mail',
                            HEADER: 'Enviar para',
                            TEXT1: 'A qual parceiro devem ser enviadas as suas informações? Receberá uma oferta independente de cada parceiro.',
                            TIP: 'Dica: ',
                            TEXT2: 'Seleccione todos os parceiros para ver quem tem a melhor oferta para as suas necessidades comerciais. É gratuito!',
                            DL: 'O Deutsche Leasing Group é o parceiro líder no financiamento de activos para PMEs alemãs e oferece uma vasta gama de soluções de financiamento relacionadas com o investimento',
                            DFKP: 'A DFKP guia-o propositadamente para o seu próximo empréstimo empresarial com o financiador optimamente adequado. Com a DFKP, o foco está no aconselhamento.',
                            GERMANY: 'Alemanha',
                            SEND_REQUEST: 'Enviar pedido',
                            ERROR: 'Por favor, escolha pelo menos um parceiro.',
                        },
                        STEP5: {
                            HEADER: 'Resumo',
                            CREDITDETAILS: 'Detalhes de Crédito',
                            LEASINGDETAILS: 'Detalhes do aluguer',
                            COMPANYINFO: 'Informações sobre a empresa',
                            PERSONALDATA: 'Dados pessoais',
                            EDIT: 'Editar',
                            COMPANYNAME: 'Nome da empresa',
                            STREET_HOUSENUMBER: 'Número da rua/casa',
                            POSTALCODE_CITY: 'Código Postal/Cidade',
                            FIRSTNAME_LASTNAME: 'Primeiro nome/último nome',
                            EMAIL: 'E-Mail',
                            COMMENT: 'Comentário',
                            SUBMIT: 'Enviar pedido',
                            AUTHORIZED1: 'Eu sou ',
                            AUTHORIZED2: 'autorizado ',
                            AUTHORIZED3: 'dentro da empresa para fazer inquéritos de crédito',
                            AGB1: 'Os dados que forneço serão utilizados exclusivamente para a aplicação Financeira, incluindo para a preparação de uma oferta de crédito ',
                            AGB2: 'Política de Privacidade.',
                            AGB3: '',
                        },
                        STEP6: {
                            HEADER1: 'Os dados foram',
                            HEADER2: 'com sucesso',
                            HEADER3: 'transmitido.',
                            TEXT_DFKP: 'Uma pessoa de contacto da DFKP (Deutsche Firmen Kreditpartner)',
                            TEXT_DL: 'Uma pessoa de contacto da Deutsche Leasing AG',
                            TEXT_ALL: 'O nosso parceiro',
                            TEXT: 'entrará em contacto consigo o mais rapidamente possível para discutir os próximos passos. Desejamos-lhe mais sucesso.',
                            BUTTON: 'Ir para a página iniciale',
                        },
                        ERROR: {
                            TEXT_1: 'Ocorreu um erro.',
                            TEXT_2: 'Lamentamos, mas o pedido não pôde ser apresentado.',
                            BUTTON_1: 'Enviar pedido novamente',
                            BUTTON_2: 'Voltar à página inicial',
                        },
                        BANNER: {
                            TEXT1: 'PAY-PER-USE',
                            TEXT2: 'modelos de financiamento',
                            TEXT3: 'para a indústria.',
                        },
                    },
                    SMARTENANCE: {
                        HEADER: 'Manutenção',
                        LEFT: {
                            CHART_HEADER: 'Situação actual',
                            OPEN: 'Pendente de manutenção',
                            DONE: 'Manutenção concluída',
                            OVERDUE: 'Manutenção atrasada',
                            THIS_WEEK: 'Esta semana',
                            LAST_WEEK: 'Semana passada',
                            COMPLETED: 'concluído',
                        },
                        RIGHT: {
                            HEADER1: 'Tempo de paragem da máquina por manutenção',
                            HEADER2: 'Tempo de paragem da máquina por manutenção (planeado)',
                            THIS_MONTH: 'Este mês',
                            LAST_MONTH: 'Mês passado',
                            LAST_WEEK: 'Semana passada',
                            THIS_WEEK: 'Esta semana',
                            NEXT_WEEK: 'Na próxima semana',
                            HOURS: 'horas',
                            MINUTES: 'minutos',
                        },
                        BOTTOM: {
                            TAB1: 'Manutenção',
                            TAB2: 'Incidentes',
                            TABLE1: {
                                HEADER1: 'Estado',
                                HEADER2: 'Descrição',
                                HEADER3: 'Devido em',
                                HEADER4: 'Executado em',
                            },
                            TABLE2: { HEADER1: 'Estado', HEADER2: 'Descrição', HEADER3: 'Timestamp' },
                            STATUS_MAINTENANCE: { GREEN: 'Feito', GRAY: 'Pendente', YELLOW: 'Atrasado' },
                            STATUS_INCIDENT: { GREEN: 'Fechado', RED: 'Aberto', YELLOW: 'No trabalho' },
                            TABLE1_CONTENT: {
                                LINE1_DESCRIPTION: 'Lubrificação da unidade de impressão',
                                LINE1_DUE: '01/01/2022',
                                LINE1_PERFORMED: '-',
                                LINE2_DESCRIPTION: 'Limpeza ou troca dos filtros',
                                LINE2_DUE: '01/10/2022',
                                LINE2_PERFORMED: '-',
                                LINE3_DESCRIPTION: 'Limpeza e lubrificação dos rolos',
                                LINE3_DUE: '01/03/2022',
                                LINE3_PERFORMED: '01/03/2022',
                                LINE4_DESCRIPTION: 'Manutenção da unidade de ar comprimido',
                                LINE4_DUE: '01/03/2022',
                                LINE4_PERFORMED: '01/03/2022',
                                LINE5_DESCRIPTION: 'Verificação e substituição de rolamentos',
                                LINE5_DUE: '01/05/2022',
                                LINE5_PERFORMED: '01/05/2022',
                            },
                            TABLE2_CONTENT: {
                                LINE1_DESCRIPTION: 'Bloqueio do cinturão de lixo',
                                LINE1_TIMESTAMP: '01/07/2022 ',
                                LINE2_DESCRIPTION: 'Defeito no sistema eléctrico',
                                LINE2_TIMESTAMP: '01/05/2022 - 14:24',
                                LINE3_DESCRIPTION: 'Máquina de empacotamento em paletizador com defeito',
                                LINE3_TIMESTAMP: '01/03/2022 - 09:15',
                            },
                        },
                    },
                    DROPDOWN: {
                        NO_OPTIONS: 'Sem opções',
                    },
                    CONDITION_MONITORING: {
                        NOT_AVAILABLE_MOBILE_HEADER: 'Condition Monitoring não disponível',
                        NOT_AVAILABLE_MOBILE_BODY:
                            'Condition Monitoring não está atualmente disponível em dispositivos móveis. Estamos a trabalhar para que em breve seja possível gerir as suas condições em movimento.',
                        ASSET: 'Recurso',
                        ONLY_NUMBER_ALLOWED: 'Por favor, verifique as entradas',
                        TITLE_ALREADY_IN_USE: 'O título já está em uso. Por favor, escolha outro.',
                        ARBITRARY: 'Qualquer valor',
                        DATA_SOURCE: 'Fonte de dados',
                        EDIT_HINT:
                            'Apenas os valores nos campos de texto livre podem ser editados. Para outras alterações, criar uma nova condição ou duplicá-la.',
                        ADDED_USER: 'Utilizadores seleccionados',
                        SHARE_ONLY_O_USER:
                            'A condição só pode ser partilhada com utilizadores que estejam registados com openpack',
                        SHARE_CONDITION: 'Condição de partilha',
                        TIME_INTERVAL: 'Intervalo de tempo',
                        CALCULATION_FUNCTION: 'Função de cálculo',
                        AGGREGATION: 'Agregação',
                        WITH: 'com',
                        INFORMATION_MODEL: 'Information model',
                        VARIABLE: 'Variável',
                        SHARE_WITH_USER: 'Partilhar a condição com outros utilizadores',
                        DEACTIVATE_CONDITION: 'Desactivar a condição, quando...',
                        CONDITION_REQUIRED: 'Preencher todos os campos da condição',
                        CREATE: 'Criar',
                        ANY: 'Apenas',
                        ALL: 'Todos',
                        MULTIPLY: 'Multiplicar',
                        DIVIDE: 'Dividir',
                        SUBTRACT: 'Subtrair',
                        ADD: 'Adicionar',
                        MAX: 'Valor máximo',
                        MIN: 'Valor mínimo',
                        AVERAGE: 'Média',
                        COUNT: 'Número',
                        SUM: 'Total',
                        AVERAGE_EXPLANATION: 'A média é formada a partir do período especificado.',
                        MIN_EXPLANATION: ' É selecionado o valor mais pequeno do período especificado.',
                        MAX_EXPLANATION: 'É selecionado o maior valor do período especificado.',
                        COUNT_EXPLANATION: 'O número de valores do período selecionado é contado.',
                        SUM_EXPLANATION: 'Todos os valores do período selecionado são somados.',
                        ALL_EXPLANATION:
                            'É verificado se todos os valores do período selecionado correspondem ao valor de comparação.',
                        ANY_EXPLANATION:
                            'É verificado se um valor do período selecionado corresponde ao valor de comparação.',
                        VALUE: 'Certo valor',
                        SINGLE_VALUE: 'Valor',
                        SINCE: 'Desde',
                        BEFORE: 'Antes de',
                        REMOVE_TIME_INTERVAL: 'Remover intervalo de tempo',
                        ADD_TIME_INTERVAL: 'Adicionar intervalo de tempo',
                        REMOVE_CALCULATION: 'Remover função de cálculo',
                        ADD_CALCULATION: 'Adicionar função de cálculo',
                        ADD_EXPRESSION: 'Acrescentar expressão',
                        ADD_CONDITION: 'Adicionar condição',
                        BUTTON_HISTORY: 'História',
                        BUTTON_NEW_CONDITION: 'Nova Condição',
                        CREATE_NEW_CONDITION: 'Criar nova condição',
                        DROPDOWN_BUTTON_NORMAL: 'Criar condição individual',
                        DROPDOWN_BUTTON_TEMPLATE: 'Selecionar modelo',
                        TAB_ALL_CONDITIONS: 'Todas condições',
                        TAB_MY_CONDITIONS: 'Minhas condições',
                        TAB_LIBRARY: 'Biblioteca',
                        NO_ENTRIES_HISTORY: 'Não há registos disponíveis.',
                        NO_ENTRIES_TEXT_HISTORY:
                            'Ainda não foram activadas quaisquer condições. Assim que isso acontecer, verá aqui.',
                        MAX_TIME_INTERVALL_180: 'Máx. Intervalo de tempo: 180 min',
                        MAX_TIME_INTERVALL_180_ERROR: 'Introduzir um intervalo de tempo máximo de 180 min.',
                        ENTER_TIMEINTERVALL: 'Especificar um intervalo de tempo',
                        TABLE: {
                            STATUS: 'Estado',
                            SEVERITY: 'Severidade',
                            TITLE: 'Título',
                            ASSETS: 'Recursos',
                            LAST_OCCURED: 'Ocorreu por último',
                        },
                        STATUS: {
                            ACTIVE: 'Activo',
                            INACTIVE: 'Inactivo',
                        },
                        SEVERITY: {
                            INFORMATION: 'Informação',
                            WARNING: 'Alerta',
                            ERROR: 'Erro',
                        },
                        FILTER: {
                            ASSET: 'Activo',
                            SEVERITY: 'Severidade',
                            STATUS: 'Estado',
                            FILTER: 'Filtro',
                            RESET_ALL: 'Repor tudo',
                            TAB_ALL_DESCRIPTION:
                                'Todas as condições que os utilizadores criaram para esta planta são apresentadas aqui (incluindo as suas condições).',
                            TAB_MY_DESCRIPTION:
                                'As condições que criou ou adicionou às suas condições são apresentadas aqui. Todas as condições que criar são também apresentadas em "Todas condições".',
                        },
                        EDIT_CONDITION: {
                            EDIT_CONDITION: 'Condição de edição',
                            TITLE: 'Título',
                            SEVERITY: 'Severidade',
                            RESTRICTION_INFORMATION:
                                'Apenas os valores nos campos de texto livre podem ser editados. Para outras alterações, criar uma nova condição ou duplicá-la.',
                            VALUE: 'Valor',
                            VARIABLE: 'Variável',
                            DESCRIPTION_OPTIONAL: 'Descrição (opcional)',
                            RESOLUTION_OPTIONAL: 'Resolução (opcional)',
                            TITLE_ERROR: 'Introduzir um título',
                            SEVERITY_ERROR: 'Selecionar um nível de gravidade',
                        },
                        EDIT_EXPRESSION: {
                            ADD_EXPRESSION: 'Acrescentar expressão',
                            REMOVE_TIME_INTERVAL: 'Remover intervalo de tempo',
                            REMOVE_CALCULATION_FUNCTION: 'Remover função de cálculo',
                            ADD_TIME_INTERVAL: 'Adicionar intervalo de tempo',
                            ADD_CALCULATION_FUNCTION: 'Adicionar função de cálculo',
                            CANCEL: 'Cancelar',
                            SAVE: 'Guardar',
                        },
                        CONDITION_DETAILS: {
                            BACK: 'Voltar',
                            EDIT: 'Editar',
                            DUPLICATE: 'Duplicado',
                            ACTIVATE_EMAIL: 'Activar email',
                            DEACTIVATE_EMAIL: 'Desactivar email',
                            DELETE: 'Eliminar',
                            ADD_CONDITIONS: 'Subscrever',
                            REMOVE_CONDITIONS: 'Deixar de subscrever',
                            STATUS: 'Estado',
                            SEVERITY: 'Severidade',
                            ACTIVATE_CONDITION: 'Activar a condição, quando...',
                            RESET_CONDITION: 'Desactivar a condição, quando...',
                            DESCRIPTION: 'Descrição',
                            RESOLUTION: 'Solução',
                            NO_DESCRIPTION: 'Não existe descrição disponível',
                            NO_RESOLUTION: 'Não existe solução disponível',
                            SHARE_WITH: 'Partilhar com...',
                            USED_BY: 'Utilizado por...',
                            CREATOR: 'Criador',
                            EDIT_TOOLTIP: 'Apenas o criador pode editar a condição.',
                            SHARE_TOOLTIP: 'Apenas o criador pode partilhar a condição.',
                        },
                        DELETE_CONDITION: {
                            DELETE_HEADER: 'Eliminar condição',
                            DELETE_TEXT: 'Tem a certeza de que quer eliminar a condição?',
                            DELETE_BUTTON: 'Eliminar',
                        },
                        ADD_USER: {
                            SEARCH_USER: 'Procurar utilizador ...',
                            USER: 'Utilizador',
                            REMOVE: 'Remover',
                        },
                        NO_CONDITIONS: {
                            HEADER: 'Não há condições disponíveis',
                            TEXT: 'Crie uma condição agora e mantenha a sua produção a funcionar.',
                        },
                        DUPLICATE_CONDITION: {
                            HEADER: 'Condição duplicada',
                            SAVE_BUTTON: 'Guardar',
                            CANCEL_BUTTON: 'Cancelar',
                            COPY: 'Cópia',
                        },
                        SCHEDULING: {
                            CHECK_CONDITION: 'Quando é que a condição deve ser verificada?',
                            ALWAYS: 'Sempre',
                            CERTAIN_DAYS: 'Apenas em determinados dias',
                            ON: 'todos',
                            PERIOD_FOLLOWING_DAY: 'O período continua no dia seguinte',
                            WILL_BE_CHECKED: 'está a ser analisado:',
                            NEXT_DAY: 'no dia seguinte',
                            DAILY_FROM: 'de',
                            TO: 'às',
                            EVERY: 'Todas as',
                            AND: 'e',
                            FROM: 'das',
                            BIS: 'para',
                            H: 'horas',
                            MO_SHORT: 'Seg',
                            MO_LONG: 'Segunda-feira',
                            TU_SHORT: 'Ter',
                            TUESDAY: 'Terça-feira',
                            WE_SHORT: 'Qua',
                            WEDNESDAY: 'Quarta-feira',
                            TH_SHORT: 'Qui',
                            THURSDAY: 'Quinta-feira',
                            FR_SHORT: 'Sex',
                            FRIDAY: 'Sexta-feira',
                            SA_SHORT: 'Sáb',
                            SATURDAY: 'Sábado',
                            SU_SHORT: 'Dom',
                            SUNDAY: 'Domingo',
                        },
                        DATA_SOURCE_TRANSLATION: {
                            STATE_AND_SPEED: 'Informações sobre o estado e a velocidade',
                            ORDER_INFORMATION: 'Informações sobre a encomenda',
                            ELECTRICITY_INFORMATION: 'Informações sobre a eletricidade',
                            ELECTRICITY_INFORMATION_SMALL: 'Informações sobre a eletricidade (pequeno)',
                            ELECTRICITY_INFORMATION_MEDIUM: 'Informações sobre a eletricidade (médio)',
                            GAS_INFORMATION: 'Informações sobre o gás',
                            WATER_INFORMATION: 'Informações sobre a água',
                            WAREHOUSE_INFORMATION: 'Informações sobre o armazém',
                            COMPRESSED_AIR: 'Informações sobre ar comprimido',
                            STEAM: 'Informações sobre o vapor',
                            SHIFT: 'Informações sobre o turno',
                            STOP: 'Informações de paragem',
                        },
                        VARIABLE_TRANSLATION: {
                            AVERAGE_SPEED: 'Velocidade média',
                            CURRENT_SPEED: 'Velocidade atual',
                            CURRENT_STATE: 'Estado atual',
                            TIME_INTERVAL: 'Intervalo de tempo',
                            CURRENT_LINEAR_METER: 'Metros produzidos até à data',
                            GRADE: 'Variedades',
                            PAPER_WIDTH: 'Largura do papel',
                            TARGET_LINEAR_METER: 'Metro alvo',
                            TRIM_AREA: 'Trim Área',
                            TRIM_PERCENTAGE: 'Trim Percentagem',
                            TRIM_WIDTH: 'Trim Largo',
                            ACTUAL_END: 'Fim real',
                            ACTUAL_START: 'Início efetivo',
                            CURRENT_QUANTITY: 'Quantidade produzida até à data',
                            CUSTOMER: 'Cliente',
                            IDENTIFIER: 'Número de identificação',
                            PLANNED_END: 'Fim planeado',
                            PLANNED_START: 'Início planeado',
                            PLANNED_QUANTITY: 'Quantidade prevista',
                            TARGET_SPEED: 'Velocidade alvo',
                            CURRENT_INVENTORY_LEVEL: 'Nível de inventário atual',
                            ACTIVE_ENERGY: 'Eletricidade (Leitura do contador)',
                            CURRENT_ACTIVE_POWER: 'Potência ativa atual',
                            GAS_METER: 'Gás (Leitura do contador)',
                            WATER_METER: 'Água (Leitura do contador)',
                            ABSOLUTE_PRESSURE: 'Pressão absoluta',
                            ACCUMULATED_VOLUME: 'Volume acumulado',
                            DEW_POINT: 'Ponto de orvalho',
                            GAUGE_PRESSURE: 'Pressão manométrica',
                            MASS_FLOW_RATE: 'Caudal de massa',
                            OIL_CONCENTRATION: 'Concentração de óleo',
                            RELATIVE_HUMIDITY: 'Humidade relativa',
                            TEMPERATURE: 'Temperatura',
                            VOLUME: 'Volume (Leitura do contador)',
                            VOLUME_FLOW_RATE: 'Volume Caudal',
                        },
                        DISPLAY_UNIT_TRANSLATIONS: {
                            SHEETS_H: 'Folha/h',
                            SHEETS: 'Placas',
                            METER_MIN: 'Metro/min',
                            BALE_H: 'Fardo/h',
                            PALLET_H: 'Paletes/h',
                        },
                        LIBRARY: {
                            FILTER: {
                                SEARCH: 'Procurar modelos',
                                CATEGORY: {
                                    CATEGORY: 'Categoria',
                                    ORDER: 'Encomendas',
                                    SPEED: 'Velocidade',
                                    RESOURCE: 'Recursos',
                                    STATUS: 'Estado',
                                },
                                ASSET_TYPE: {
                                    ASSET_TYPE: 'Tipo de ativo',
                                    CONVERSION: 'Conversão',
                                    CORRUGATOR: 'Caneladora',
                                },
                            },
                            NO_HIT: 'Sem sucesso ...',
                            EMPTY_STATE_TEXT:
                                'Lamentamos, mas não foi possível encontrar um modelo adequado para si. Ajuste os seus filtros ou pesquise e tente novamente!',
                            ADD: 'Adicionar',
                        },
                        TEMPLATES: {
                            ERROR_MESSAGES: {
                                FILL_ALL_FIELDS: 'Preencher todos os campos da condição.',
                                FILL_ALL_FIELDS_CORRECTLY:
                                    'Preencher corretamente todos os campos. Anote os valores mínimo e máximo dos campos.',
                            },
                            INPUT_INFO_TOOLTIP: 'Gama de valores:',
                        },
                    },
                    HISTORIAN: {
                        HISTORIAN: 'Historian',
                        START_BUTTON: 'Start',
                        START_TEXT:
                            'The openpack Historian serves as a key manager for all Data Scientists, Data Analysts etc. who need access to the raw data of their machines.',
                        MY_KEYS: 'My Keys',
                        MANAGE_KEYS: 'Manage Keys',
                        MY_KEYS_INFO_TEXT:
                            'Here you can request the keys for your works to get access to the raw data of the machines.',
                        KEY_REQUEST_AGAIN: 'Request key again',
                        WITHDRAW_REQUEST: 'Withdraw request',
                        SHOW_KEY: 'Show key once',
                        KEY_REQUEST: 'Request key',
                        DELETE_KEY: 'Delete key',
                        KEY: 'Key',
                        MODAL_INFO_1: 'This key is displayed only once for security reasons. Please be sure to',
                        MODAL_INFO_2: 'save the key',
                        MODAL_INFO_3: 'before closing.',
                        ID: 'ID',
                        SECRET: 'Secret',
                        S3_URL: 'S3 Bucket URL',
                        CLOSE_BUTTON: 'Close',
                        SAVE_TO_CLIPBOARD: 'Save to clipboard',
                        SAVED_TO_CLIPBOARD: 'Saved to the clipboard',
                        MANAGE_KEYS_REQUESTS: 'Requests',
                        MANAGE_KEYS_NO_REQUESTS: 'Currently no requests available',
                        MANAGE_KEYS_MANAGE_KEYS: 'Manage keys',
                        MANAGE_KEYS_NO_KEYS: 'Currently no keys available',
                        PLANT_FILTER: 'Filter by plant',
                        BUTTON_ACCEPT: 'Accept',
                        BUTTON_DECLINE: 'Decline',
                        TOAST_ACCEPTED: 'Request accepted',
                        TOAST_DECLINED: 'Request declined',
                        TOAST_REQUESTED: 'Wait for commitment',
                        TOAST_REQUESTED_TEXT:
                            'The responsible admin must confirm the request. You will be informed as soon as this is done.',
                        NO_PLANT_FOUND: 'No plant found',
                    },
                },
            },
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export const getInitialLanguage = (): string => {
    const cookieLanguage = getCookie(globalLanguageKey);
    if (cookieLanguage !== undefined && languages.includes(cookieLanguage)) {
        return cookieLanguage;
    }
    const langCode = i18n.language.toUpperCase();
    // langCode is something like 'de-DE' or 'en-GB'
    for (const lang in languages) {
        if (langCode.startsWith(lang)) {
            return lang.toUpperCase();
        }
    }
    return 'en';
};

export default i18n;
