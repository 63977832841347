import { getAuthHeader } from '../utils/auth0Authentication';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

export interface EnergyFlowData {
    currentPowerGrid: number;
    currentPowerPhotovoltaic: null | number;
    currentPowerPhotovoltaicToGrid: null | number;
    currentPowerAllAssets: { assetId: string; displayName: string; isCorrugator: boolean; energyValue: number }[];
}

export interface TotalElectricityConsumptionData {
    sumActiveElectricity: number;
    month: string;
    monthDisplay?: string;
    year: string;
    enPi: number;
    sumSqm: number;
}

export interface ElectricityConsumptionOverviewData {
    power_pv: number;
    power_plant: number;
    pv_to_grid: number;
    month: string;
    year: string;
}

export interface ResourceAverageDailyConsumptionData {
    currentElectricityAvg: number | null;
    benchmarkElectricityAvg: number | null;
    percentElectricity: number | null;
    currentWaterAvg: number | null;
    benchmarkWaterAvg: number | null;
    percentWater: number | null;
    currentGasAvg: number | null;
    benchmarkGasAvg: number | null;
    percentGas: number | null;
    currentCompressedAirAvg: number | null;
    benchmarkCompressedAirAvg: number | null;
    percentCompressedAir: number | null;
    currentSteamAvg: number | null;
    benchmarkSteamAvg: number | null;
    percentSteam: number | null;
}

export interface ResourceTableData {
    assetName: string;
    assetId: string;
    assetType: string;
    dailyAverage: number;
    totalConsumption: number;
    enPI: number | null;
    benchmarkEnPI: number | null;
    deviation: number | null;
}

export interface EnPIBenchmarkData {
    electricity: AssetEnPIBenchmarkData[] | null;
    water: AssetEnPIBenchmarkData[] | null;
    gas: AssetEnPIBenchmarkData[] | null;
    compressedAir: AssetEnPIBenchmarkData[] | null;
    steam: AssetEnPIBenchmarkData[] | null;
}

export interface AssetEnPIBenchmarkData {
    assetName: string;
    assetType: string;
    value: number;
}

export interface EnPIHistory {
    assetId: string;
    assetName: string;
    organizationId: string;
    enPI: { time: string; consumption: number }[];
    enPIBenchmark: number;
}

export const fetchTotalElectricityConsumptionData = async (
    organizationId: string,
    timezoneId: string,
): Promise<TotalElectricityConsumptionData[]> => {
    const response = await fetch(
        encodeURI(
            `/rc/api/rm/electricityconsumption/plant-graph?organizationId=${organizationId}&timezoneId=${timezoneId}`,
        ),
        {
            method: 'GET',
            headers: await getAuthHeader(),
        },
    );
    if (!response.ok) {
        throw new Error('Something went wrong');
    }
    return response.json();
};

export function useFetchTotalElectricityConsumptionData(
    organizationId: string,
    timezoneId: string,
): UseQueryResult<TotalElectricityConsumptionData[], Error> {
    return useQuery({
        queryKey: ['totalElectricityConsumption', organizationId],
        queryFn: () => fetchTotalElectricityConsumptionData(organizationId, timezoneId),
        enabled: !!organizationId && !!timezoneId,
        refetchInterval: 30000,
    });
}

export const fetchElectricityConsumptionOverviewData = async (
    organizationId: string,
): Promise<ElectricityConsumptionOverviewData[]> => {
    const response = await fetch(
        encodeURI(`/rc/api/rm/electricityconsumption/overview?organizationId=${organizationId}`),
        {
            method: 'GET',
            headers: await getAuthHeader(),
        },
    );
    if (!response.ok) {
        throw new Error('Something went wrong');
    }
    return response.json();
};

export function useFetchElectricityConsumptionOverviewData(
    organizationId: string,
): UseQueryResult<ElectricityConsumptionOverviewData[], Error> {
    return useQuery({
        queryKey: ['electricityConsumptionOverview', organizationId],
        queryFn: () => fetchElectricityConsumptionOverviewData(organizationId),
        enabled: !!organizationId,
        refetchInterval: 30000,
    });
}

export const fetchResourceAverageDailyConsumptionData = async (
    organizationId: string,
    timezoneId: string,
    startTime: number,
    endTime: number,
): Promise<ResourceAverageDailyConsumptionData> => {
    const response = await fetch(
        encodeURI(
            `/rc/api/rm/resource-average-daily-consumption?organizationId=${organizationId}&startTime=${startTime}&endTime=${endTime}&timezoneId=${timezoneId}`,
        ),
        {
            method: 'GET',
            headers: await getAuthHeader(),
        },
    );
    if (!response.ok) {
        throw new Error('Something went wrong');
    }
    return response.json();
};

export function useFetchResourceAverageDailyConsumptionData(
    organizationId: string,
    timezoneId: string,
    startTime: number,
    endTime: number,
): UseQueryResult<ResourceAverageDailyConsumptionData, Error> {
    return useQuery({
        queryKey: ['resourceAverageDailyConsumption', startTime, endTime],
        queryFn: () => fetchResourceAverageDailyConsumptionData(organizationId, timezoneId, startTime, endTime),
        enabled: !!timezoneId,
        refetchInterval: 30000,
    });
}

export const fetchResourceListForPlant = async (plantId: string): Promise<string[]> => {
    const response = await fetch(encodeURI(`/rc/api/organization/${plantId}/resource-manager/resource-list`), {
        headers: await getAuthHeader(),
    });
    if (!response.ok) {
        throw new Error('Something went wrong');
    }
    return response.json();
};

export function useFetchResourceListForPlant(plantId: string): UseQueryResult<string[], Error> {
    return useQuery({
        queryKey: ['resourceListForPlant'],
        queryFn: () => fetchResourceListForPlant(plantId),
        enabled: !!plantId,
    });
}

export const fetchResourceTableData = async (
    organizationId: string,
    timezoneId: string,
    resourceType: string,
    startTime: number,
    endTime: number,
): Promise<ResourceTableData> => {
    const response = await fetch(
        encodeURI(
            `/rc/api/organization/${organizationId}/resource-manager/resource-table?resourceType=${resourceType}&timezoneId=${timezoneId}&startTime=${startTime}&endTime=${endTime}`,
        ),
        {
            method: 'GET',
            headers: await getAuthHeader(),
        },
    );
    if (!response.ok) {
        throw new Error('Something went wrong');
    }
    return response.json();
};

export function useFetchResourceTableData(
    organizationId: string,
    timezoneId: string,
    resourceType: string,
    startTime: number,
    endTime: number,
    //previousYear: boolean,
): UseQueryResult<ResourceTableData, Error> {
    return useQuery({
        queryKey: ['resourceTable', resourceType, startTime, endTime],
        queryFn: () => fetchResourceTableData(organizationId, resourceType, timezoneId, startTime, endTime),
        enabled: !!timezoneId,
        refetchInterval: 30000,
    });
}

export const updateEnPIBenchmark = async ({
    enPIBenchmarkData,
    plantId,
}: {
    enPIBenchmarkData: EnPIBenchmarkData;
    plantId: string;
}): Promise<EnPIBenchmarkData> => {
    const response = await fetch(encodeURI(`/rc/api/organization/${plantId}/resource-manager/enpi-benchmark`), {
        method: 'PUT',
        headers: await getAuthHeader(),
        body: JSON.stringify(enPIBenchmarkData),
    });
    if (!response.ok) {
        throw new Error('Something went wrong');
    }
    return response.json();
};
export const fetchEnPIBenchmarkData = async (plantId: string): Promise<EnPIBenchmarkData> => {
    const response = await fetch(encodeURI(`/rc/api/organization/${plantId}/resource-manager/enpi-benchmark`), {
        method: 'GET',
        headers: await getAuthHeader(),
    });
    if (!response.ok) {
        throw new Error('Something went wrong');
    }
    return response.json();
};

export function useFetchEnPIBenchmarkData(plantId: string): UseQueryResult<EnPIBenchmarkData, Error> {
    return useQuery({
        queryKey: ['enpi-benchmark'],
        queryFn: () => fetchEnPIBenchmarkData(plantId),
    });
}

export const fetchEnPIHistory = async (
    plantId: string,
    timezoneId: string,
    startDate: string,
    endDate: string,
    resourceType: string,
    assetId: string,
): Promise<Response> => {
    return fetch(
        encodeURI(
            `/rc/api/organization/${plantId}/resource-manager/enpi-history?startDate=${startDate}&endDate=${endDate}&resourceType=${resourceType}&timezoneId=${timezoneId}&assetId=${assetId}`,
        ),
        {
            method: 'GET',
            headers: await getAuthHeader(),
        },
    );
};

export function useFetchEnPIHistory(
    plantId: string,
    timezoneId: string,
    startDate: string,
    endDate: string,
    resourceType: string,
    assetId: string,
): UseQueryResult<
    {
        assetId: string;
        assetName: string;
        organizationId: string;
        enPI: { time: number; consumption: number }[];
        enPIBenchmark: number;
    },
    Error
> {
    return useQuery({
        queryKey: ['enPIHistory', assetId, startDate, endDate],
        queryFn: () => fetchEnPIHistory(plantId, timezoneId, startDate, endDate, resourceType, assetId),
        enabled: !!plantId && !!timezoneId && !!assetId,
        refetchInterval: 10000,
    });
}

export const fetchEnergyFlow = async (
    plantId: string,
    timezoneId: string,
    startTime: number,
    endTime: number,
): Promise<Response> => {
    const response = await fetch(
        encodeURI(
            `/rc/api/rm/energyflow/plant?startTime=${startTime}&endTime=${endTime}&timezoneId=${timezoneId}&organizationId=${plantId}`,
        ),
        {
            method: 'GET',
            headers: await getAuthHeader(),
        },
    );
    if (!response.ok) {
        throw new Error('Something went wrong');
    }
    return response.json();
};

export function useFetchEnergyFlow(
    plantId: string,
    timezoneId: string,
    startTime: number,
    endTime: number,
): UseQueryResult<EnergyFlowData, Error> {
    return useQuery({
        queryKey: ['energyFlow', plantId, startTime, endTime],
        queryFn: () => fetchEnergyFlow(plantId, timezoneId, startTime, endTime),
        enabled: !!plantId && !!timezoneId && !!startTime && !!endTime,
        refetchInterval: 10000,
    });
}
