import React from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import { AppState } from '@auth0/auth0-react/dist/auth0-provider';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import 'react-toastify/dist/ReactToastify.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import 'swiper/css';
import 'react-tooltip/dist/react-tooltip.css';

import { CookiesProvider } from 'react-cookie';

import App from './pages/App';
import history from './utils/history';
import './tailwind.generated.css';
import './i18n';
import { QueryCache, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';

// Environment Variables
if (
    process.env.REACT_APP_AUTH0_DOMAIN === undefined ||
    process.env.REACT_APP_AUTH0_CLIENT_ID === undefined ||
    process.env.REACT_APP_REDIRECT_URI === undefined ||
    process.env.REACT_APP_AUDIENCE === undefined
) {
    throw new Error('missing env vars');
}

const onRedirectCallback = (appState: AppState | undefined) => {
    // Use the router's history module to replace the url
    history.replace(appState?.returnTo || window.location.pathname);
};

const theme = createTheme({
    shape: {
        borderRadius: 0,
    },
    palette: {}, // TODO: Add our colors
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            gcTime: 1000 * 60 * 60 * 24, // 24 hours
        },
    },
    queryCache: new QueryCache({
        onError: (error) => {
            console.log(`Something went wrong: ${(error as Error).message}`);
        },
    }),
});

const persister = createSyncStoragePersister({
    storage: window.localStorage,
});

const container = document.getElementById('root');
if (container === null) throw new Error('Root container missing in index.html');
const root = createRoot(container);
root.render(
    <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        authorizationParams={{ redirect_uri: window.location.origin, audience: process.env.REACT_APP_AUDIENCE }}
        onRedirectCallback={onRedirectCallback}
    >
        <PersistQueryClientProvider
            client={queryClient}
            persistOptions={{
                persister,
                // dehydrateOptions: {
                //     shouldDehydrateQuery: (query) => {
                //         const queryIsReadyForPersistence = query.state.status === 'success';
                //         if (queryIsReadyForPersistence) {
                //             const { queryKey } = query;
                //             const excludeFromPersisting = queryKey.includes('overview');
                //             return !excludeFromPersisting;
                //         }
                //         return queryIsReadyForPersistence;
                //     },
                // },
            }}
        >
            <ThemeProvider theme={theme}>
                <CookiesProvider>
                    <App />
                </CookiesProvider>
            </ThemeProvider>
            <ReactQueryDevtools initialIsOpen={false} />
        </PersistQueryClientProvider>
    </Auth0Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
