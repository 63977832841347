import { getAuthHeader } from '../utils/auth0Authentication';
import { useInfiniteQuery, UseInfiniteQueryResult, useQuery, UseQueryResult } from '@tanstack/react-query';
import dayjs from 'dayjs';

export interface NotificationData {
    pages: { notifications: NotificationItemData[] }[];
    nextCursor: string;
}

export interface DeleteNotificationItem {
    notificationId: string;
    createdOn: number;
}

export interface NotificationItemData {
    notificationId: string;
    userId: string;
    feature: string;
    createdOn: number;
    content: { long: string; medium: string; short: string; html: string; type: string };
    action: NotificationActionItem[];
    language: string;
    link: string;
    read: boolean;
    illustration: string;
    severity: number;
    metadata: string[];
    channel: string[];

    actionExecuted: string;
    timezoneId: string;
    organizationId: string;
}

export interface NotificationActionItem {
    apiUrl: string;
    httpMethod: string;
    name: string;
    outcome: string;
    type: string;
}

export interface NotificationReadUnread {
    notificationId: string;
    read: boolean;
}

export interface NotificationCount {
    totalCount: number;
    readCount: number;
    unreadCount: number;
}

export interface UpdateActionItem {
    notificationId: string;
    actionExecuted: string;
}

export const triggerNotificationAction = async ({ action }: { action: NotificationActionItem }): Promise<void> => {
    const response = await fetch(encodeURI(action?.apiUrl), {
        method: action?.httpMethod,
        headers: await getAuthHeader(),
    });
    if (!response.ok) {
        throw new Error('Error in trigger notification action');
    }
    return;
};

export const fetchNotifications = async ({ pageParam = '' }): Promise<NotificationData> => {
    const response = await fetch(
        encodeURI(`/notification/api/notification/list-by-user?limit=20&nextCursor=` + pageParam),
        {
            method: 'GET',
            headers: await getAuthHeader(),
        },
    );
    if (!response.ok) {
        throw new Error('Something went wrong');
    }
    return response.json();
};

export function useFetchNotifications(): UseInfiniteQueryResult<NotificationData, Error> {
    return useInfiniteQuery({
        queryKey: ['notifications'],
        initialPageParam: '0',
        queryFn: ({ pageParam }) => fetchNotifications({ pageParam }),
        getNextPageParam: (lastPage) => {
            if (lastPage.nextCursor !== '') {
                return lastPage.nextCursor;
            }
        },
        refetchInterval: 30000,
    });
}

export const fetchNotificationsLast7 = async ({ pageParam = '' }): Promise<NotificationData> => {
    //epoch - 7 Tage => createdOnFrom
    const createdOnFrom = dayjs(dayjs().subtract(7, 'day')).unix();
    const response = await fetch(
        encodeURI(
            `/notification/api/notification/list-by-user?limit=20&createdOnFrom=${createdOnFrom}&nextCursor=` +
                pageParam,
        ),
        {
            method: 'GET',
            headers: await getAuthHeader(),
        },
    );
    if (!response.ok) {
        throw new Error('Something went wrong');
    }
    return response.json();
};

export function useFetchNotificationsLast7(): UseInfiniteQueryResult<NotificationData, Error> {
    return useInfiniteQuery({
        queryKey: ['notificationsLast7'],
        initialPageParam: '0',
        queryFn: ({ pageParam }) => fetchNotificationsLast7({ pageParam }),
        getNextPageParam: (lastPage) => {
            if (lastPage.nextCursor !== '') {
                return lastPage.nextCursor;
            }
        },
        refetchInterval: 30000,
    });
}

export const setNotificationAsReadUnread = async (
    notificationAsReadUnread: NotificationReadUnread[],
): Promise<string> => {
    const response = await fetch(encodeURI(`/notification/api/notification/set-read`), {
        method: 'PUT',
        headers: await getAuthHeader(),
        body: JSON.stringify(notificationAsReadUnread),
    });
    if (!response.ok) {
        throw new Error('Something went wrong');
    }
    return response.text();
};

export const deleteNotifications = async (DeleteNotificationItem): Promise<string> => {
    const response = await fetch(encodeURI(`/notification/api/notification/delete`), {
        method: 'DELETE',
        headers: await getAuthHeader(),
        body: JSON.stringify(DeleteNotificationItem),
    });
    if (!response.ok) {
        throw new Error('Something went wrong');
    }
    return response.text();
};

export const fetchNotificationCount = async (): Promise<NotificationCount> => {
    const response = await fetch(encodeURI(`/notification/api/notification/count`), {
        method: 'GET',
        headers: await getAuthHeader(),
    });
    if (!response.ok) {
        throw new Error('Something went wrong');
    }
    return response.json();
};

export function useFetchNotificationCount(): UseQueryResult<NotificationCount, Error> {
    return useQuery({
        queryKey: ['notificationCount'],
        queryFn: fetchNotificationCount,
        refetchInterval: 30000,
    });
}

export const updateActionOutcome = async (updateActionItem: UpdateActionItem): Promise<string> => {
    const response = await fetch(encodeURI(`/notification/api/notification/update-action-executed`), {
        method: 'PUT',
        headers: await getAuthHeader(),
        body: JSON.stringify(updateActionItem),
    });
    if (!response.ok) {
        throw new Error('Something went wrong');
    }
    return response.text();
};
