import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHome,
    faUser,
    faMagnifyingGlass,
    faBell,
    faIndustry,
    faQuestionCircle,
} from '@fortawesome/pro-regular-svg-icons';
import {
    faHome as faHomeSolid,
    faMagnifyingGlass as faMagnifyingGlassSolid,
    faBell as faBellSolid,
    faArrowRightToBracket,
} from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useFetchPlantByIdIsAdmin } from '../api/plants';
import { useAuth0 } from '@auth0/auth0-react';
import { useFetchUser } from '../api/user';
import { UserInitials } from '@cipa-gmbh/o6k-component-library';
import { useFetchNotificationCount } from '../api/notifications';

export default function MobileNavBar({
    setIsAdmin,
    setIsPlantMember,
    isPlantMember,
}: {
    setIsAdmin: (arg: boolean) => void;
    setIsPlantMember: (arg: boolean) => void;
    isPlantMember: boolean;
}): JSX.Element {
    const { t } = useTranslation();
    const [isAccountMenuOpen, setIsAccountMenuOpen] = useState<boolean>(false);
    const matchCockpit = useMatch('/cockpit');
    const matchNotifications = useMatch('/notifications');
    const matchHome = useMatch('/');
    const matchSearch = useMatch('/search');
    const matchHelp = useMatch('/help');
    const matchPlant = useMatch('/plant_overview');
    const matchProfile = useMatch('/profile');
    const [, setIsSearch] = useState<boolean>(false);
    const [uuid, setUuid] = useState<string>('');
    const { user: auth0user } = useAuth0();
    const userId = auth0user?.sub;
    const { data: user } = useFetchUser(userId ?? '');
    const { pathname } = useLocation();
    const { data: selectedPlant } = useFetchPlantByIdIsAdmin(uuid);
    const { data: defaultPlant } = useFetchPlantByIdIsAdmin(user?.defaultOrganization ?? '');
    const { logout: auth0Logout } = useAuth0();
    const { data: notificationCount, isLoading: isLoadingNotificationCount } = useFetchNotificationCount();
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();

    useEffect(() => {
        if (!!user?.defaultOrganization && defaultPlant) {
            setIsAdmin(defaultPlant?.isAdmin ?? false);
        }
    }, [user, defaultPlant]);

    useEffect(() => {
        if (pathname.includes('/plant/')) {
            setUuid(getUUID(pathname));
            if (getUUID(pathname) !== '') {
                if (selectedPlant?.isAdmin) {
                    setIsAdmin(true);
                } else {
                    setIsAdmin(false);
                }
            }
        }
    }, [pathname, selectedPlant]);

    useEffect(() => {
        setIsSearch(true);
    }, [query.get('search')]);

    function getUUID(url: string) {
        const substring = url.substr(url.lastIndexOf('/plant/') + 7, 36);
        const uuid = substring.match(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);
        if (uuid && uuid?.length > 0) {
            return uuid[0];
        } else {
            return '';
        }
    }

    return (
        <>
            <div className={`fixed bottom-0 z-[60] block h-17 w-full bg-white lg:hidden`}>
                <ul className="relative z-30 flex w-full items-center justify-center gap-2.5 border-t bg-white px-3 pb-3 pt-2.5 text-sm text-neutral-500 shadow-md">
                    <li className="flex flex-1 items-center justify-center">
                        <NavLink
                            to="/cockpit"
                            className={`${
                                (matchCockpit || matchHome) && !isAccountMenuOpen && 'opensans-semibold text-primary'
                            } group inline-flex h-12 w-full items-center justify-center rounded pt-1 focus:outline-none`}
                            onClick={() => {
                                if (!isPlantMember) {
                                    setIsPlantMember(true);
                                    window.location.href = '/cockpit';
                                }
                                setIsAccountMenuOpen(false);
                            }}
                        >
                            <div className="flex flex-col items-center gap-1">
                                {(matchCockpit || matchHome) && !isAccountMenuOpen ? (
                                    <div>
                                        <FontAwesomeIcon icon={faHomeSolid} size="xl" />
                                    </div>
                                ) : (
                                    <div>
                                        <FontAwesomeIcon icon={faHome} size="xl" />
                                    </div>
                                )}
                                <span className="text-xxs">{t('SIDEBAR.COCKPIT')}</span>
                            </div>
                        </NavLink>
                    </li>
                    <li className="flex flex-1 items-center justify-center">
                        <NavLink
                            to="/notifications"
                            className={`${
                                matchNotifications && !isAccountMenuOpen && 'opensans-semibold text-primary'
                            } group inline-flex h-12 w-full items-center justify-center rounded pt-1 focus:outline-none`}
                            onClick={() => {
                                if (!isPlantMember) {
                                    setIsPlantMember(true);
                                    window.location.href = '/notifications';
                                }
                                setIsAccountMenuOpen(false);
                            }}
                        >
                            <div className="flex flex-col items-center gap-1">
                                {matchNotifications && !isAccountMenuOpen ? (
                                    <div className="relative z-10 focus:outline-none">
                                        <FontAwesomeIcon
                                            icon={faBellSolid}
                                            size="xl"
                                            className="h-5 w-5"
                                            fill="#000000"
                                        />
                                        {!isLoadingNotificationCount &&
                                            notificationCount &&
                                            notificationCount?.unreadCount > 0 && (
                                                <div
                                                    className={`opensans-semibold absolute top-0 -mt-2 ml-2 flex h-4 w-4 flex-col items-center justify-center rounded-full border-2 border-white bg-red-400 p-twoHalf text-xxs text-white`}
                                                >
                                                    {notificationCount?.unreadCount}
                                                </div>
                                            )}
                                    </div>
                                ) : (
                                    <div className="relative z-10 focus:outline-none">
                                        <FontAwesomeIcon icon={faBell} size="xl" className="h-5 w-5" fill="#000000" />
                                        {!isLoadingNotificationCount &&
                                            notificationCount &&
                                            notificationCount?.unreadCount > 0 && (
                                                <div
                                                    className={`opensans-semibold absolute top-0 -mt-2 ml-2 flex h-4 w-4 flex-col items-center justify-center rounded-full border-2 border-white bg-red-400 p-twoHalf text-xxs text-white`}
                                                >
                                                    {notificationCount?.unreadCount}
                                                </div>
                                            )}
                                    </div>
                                )}
                                <span className="text-xxs">{t('SIDEBAR.NOTIFICATIONS')}</span>
                            </div>
                        </NavLink>
                    </li>
                    <li className="flex flex-1 items-center justify-center">
                        <NavLink
                            to="/search"
                            className={`${
                                matchSearch && !isAccountMenuOpen && 'opensans-semibold text-primary'
                            } inline-flex h-12 w-full items-center justify-center rounded pt-1 focus:outline-none`}
                            onClick={() => {
                                setIsAccountMenuOpen(false);
                            }}
                        >
                            <div className="flex flex-col items-center gap-1">
                                {matchSearch && !isAccountMenuOpen ? (
                                    <div>
                                        <FontAwesomeIcon icon={faMagnifyingGlassSolid} size="xl" />
                                    </div>
                                ) : (
                                    <div>
                                        <FontAwesomeIcon icon={faMagnifyingGlass} size="xl" />
                                    </div>
                                )}
                                <span className="text-xxs">{t('SEARCH.SEARCH')}</span>
                            </div>
                        </NavLink>
                    </li>
                    <li className="flex flex-1 items-center justify-center">
                        {user && (
                            <button
                                className={`${
                                    (matchProfile || matchPlant || matchHelp || isAccountMenuOpen) &&
                                    'opensans-semibold text-blue-400'
                                } group inline-flex h-12 w-full items-center justify-center rounded pt-1 focus:outline-none`}
                                onClick={() => setIsAccountMenuOpen(!isAccountMenuOpen)}
                            >
                                <div className="flex flex-col items-center">
                                    <div
                                        className={`${
                                            (matchProfile || matchPlant || matchHelp || isAccountMenuOpen) &&
                                            'ring-2 ring-blue-400'
                                        } flex h-7 w-7 items-center justify-center overflow-hidden rounded-full bg-neutral-900`}
                                    >
                                        {user.hasPic && user.picUrlMedium ? (
                                            <img src={user.picUrlMedium} alt="Profile" />
                                        ) : (
                                            <span>
                                                <UserInitials user={user} />
                                            </span>
                                        )}
                                    </div>
                                    <span className="text-xxs">{t('SIDEBAR.ACCOUNT')}</span>
                                </div>
                            </button>
                        )}
                        {isAccountMenuOpen && (
                            <div className="fixed bottom-[68px] left-0 right-0 z-20 mx-2 my-1 overflow-hidden rounded border border-neutral-100 bg-white">
                                <ul className="py-1">
                                    <li>
                                        <NavLink
                                            to="/profile"
                                            className={`${
                                                matchProfile && 'bg-neutral-200 text-primary shadow'
                                            } inline-flex h-12 w-full items-center rounded pl-4 focus:outline-none`}
                                            onClick={() => {
                                                setIsAccountMenuOpen(false);
                                            }}
                                        >
                                            <div className="inline-flex">
                                                <div className="w-10 text-neutral-500">
                                                    <FontAwesomeIcon icon={faUser} size="lg" />
                                                </div>
                                                <span className="opensans-semibold">{t('HEADER.PROFILE')}</span>
                                            </div>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to="/plant_overview"
                                            className={`${
                                                matchPlant && 'bg-neutral-200 text-primary shadow'
                                            } inline-flex h-12 w-full items-center rounded pl-4 focus:outline-none`}
                                            onClick={() => {
                                                setIsAccountMenuOpen(false);
                                            }}
                                        >
                                            <div className="inline-flex">
                                                <div className="w-10 text-neutral-500">
                                                    <FontAwesomeIcon icon={faIndustry} size="lg" />
                                                </div>
                                                <span className="opensans-semibold">
                                                    {t('PLANTS.OVERVIEW.MY_PLANTS')}
                                                </span>
                                            </div>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to="/help"
                                            className={`${
                                                matchHelp && 'bg-neutral-200 text-primary shadow'
                                            } inline-flex h-12 w-full items-center rounded pl-4 focus:outline-none`}
                                            onClick={() => {
                                                setIsAccountMenuOpen(false);
                                            }}
                                        >
                                            <div className="inline-flex">
                                                <div className="w-10 text-neutral-500">
                                                    <FontAwesomeIcon
                                                        icon={faQuestionCircle}
                                                        size="lg"
                                                        className="ml-0.5"
                                                    />
                                                </div>
                                                <span className="opensans-semibold">{t('SIDEBAR.HELP')}</span>
                                            </div>
                                        </NavLink>
                                    </li>
                                    <li className="border-t">
                                        <button
                                            className={`inline-flex h-12 w-full items-center justify-between rounded bg-transparent pl-4 focus:outline-none`}
                                            onClick={() => {
                                                auth0Logout({
                                                    logoutParams: {
                                                        returnTo:
                                                            process.env.REACT_APP_REDIRECT_URI +
                                                                '?version=login&lang=' +
                                                                user?.defaultLanguage ?? 'en',
                                                    },
                                                });
                                                setIsAccountMenuOpen(false);
                                            }}
                                        >
                                            <div className="inline-flex">
                                                <div className="w-10 text-left text-neutral-500">
                                                    <FontAwesomeIcon icon={faArrowRightToBracket} size="lg" />
                                                </div>
                                                <span className={`opensans-semibold`}>{t('HEADER.LOGOUT')}</span>
                                            </div>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </li>
                </ul>
                {isAccountMenuOpen && (
                    <div
                        onClick={() => {
                            setIsAccountMenuOpen(false);
                        }}
                        tabIndex={-1}
                        className="fixed inset-0 z-10 h-full w-full cursor-default bg-neutral-900 opacity-60 focus:outline-none"
                    />
                )}
            </div>
        </>
    );
}
