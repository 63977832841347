import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../pages/App';
import { AxisRenderer, CategoryAxis, ColumnSeries } from '@amcharts/amcharts5/xy';
import * as am5 from '@amcharts/amcharts5';
import am5locales_de_DE from '@amcharts/amcharts5/locales/de_DE';
import am5locales_en_US from '@amcharts/amcharts5/locales/en_US';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';
import { Tab } from '@headlessui/react';
import { Root } from '@amcharts/amcharts5';
import { DropdownList } from '@cipa-gmbh/o6k-component-library';
import { v4 as uuidv4 } from 'uuid';
import { Plant } from '../../api/plants';
import { TotalElectricityConsumptionData, useFetchTotalElectricityConsumptionData } from '../../api/resourceManager';
import { addMissingMonths, isWithinRange, sortDataByYearMonth, updateColumnNames } from './HelperComponents';

export default function TotalEnergyConsumption({ plant }: { plant: Plant | undefined }): JSX.Element {
    const [selectedTab, setSelectedTab] = useState(0);
    const { t } = useTranslation();
    const [dropdownOptions] = useState<{ label: string; value: string; action: () => void }[]>([
        {
            label: t('RESOURCE_MANAGER.SWITCH_TOTAL'),
            value: '0',
            action: () => setSelectedTab(0),
        },
        {
            label: t('RESOURCE_MANAGER.SWITCH_ENPI'),
            value: '1',
            action: () => setSelectedTab(1),
        },
    ]);
    const [selectedTabDropdown, setSelectedTabDropdown] = useState<{
        label: string;
        value: string;
        action: () => void;
    }>(selectedTab === 0 ? dropdownOptions[0] : dropdownOptions[1]);

    const id = uuidv4();
    const chosenLanguage = useContext(LanguageContext);
    const xAxisRef = useRef<CategoryAxis<AxisRenderer> | null>(null);
    const series1Ref = useRef<ColumnSeries | null>(null);
    const series2Ref = useRef<ColumnSeries | null>(null);
    const rootRef = useRef<Root | null>(null);

    const { data: totalElectricityConsumptionData, refetch: refetchTotalElectricityConsumptionData } =
        useFetchTotalElectricityConsumptionData(plant?.id ?? '', plant?.timezoneId ?? '');

    useEffect(() => {
        refetchTotalElectricityConsumptionData();
    }, [selectedTab]);

    useLayoutEffect(() => {
        let root: Root | null = null;
        am5.addLicense('AM5C446337179');
        if (rootRef.current === null) {
            if (selectedTab === 0) {
                root = am5.Root.new('total-electricity-consumption' + id, {
                    tooltipContainerBounds: {
                        top: 200,
                        right: 0,
                        bottom: 200,
                        left: 0,
                    },
                });
            } else {
                root = am5.Root.new('total-enpi' + id, {
                    tooltipContainerBounds: {
                        top: 200,
                        right: 0,
                        bottom: 200,
                        left: 0,
                    },
                });
            }
        } else {
            root = rootRef.current;
            root.dispose();
            if (selectedTab === 0) {
                root = am5.Root.new('total-electricity-consumption' + id, {
                    tooltipContainerBounds: {
                        top: 200,
                        right: 0,
                        bottom: 200,
                        left: 0,
                    },
                });
            } else {
                root = am5.Root.new('total-enpi' + id, {
                    tooltipContainerBounds: {
                        top: 200,
                        right: 0,
                        bottom: 200,
                        left: 0,
                    },
                });
            }
        }
        root.locale = chosenLanguage === 'de' ? am5locales_de_DE : am5locales_en_US;
        root.setThemes([am5themes_Animated.new(root)]);
        root.numberFormatter.set('numberFormat', '#,###.');
        root.dateFormatter.setAll({
            dateFormat: chosenLanguage === 'en' ? 'MM-dd-YYYY' : 'dd/MM/YYYY',
            dateFields: ['dateStart', 'dateEnd'],
        });

        const chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                paddingLeft: 0,
                layout: root.verticalLayout,
            }),
        );
        chart.set('height', am5.percent(100));
        chart.set('width', am5.percent(100));

        const cursor = chart.set(
            'cursor',
            am5xy.XYCursor.new(root, {
                behavior: 'zoomX',
            }),
        );
        cursor.lineY.set('visible', false);
        cursor.lineX.set('visible', false);

        const xRenderer = am5xy.AxisRendererX.new(root, {
            cellStartLocation: 0.2,
            cellEndLocation: 0.8,
            minGridDistance: 0,
        });

        xRenderer.labels.template.setAll({
            fontSize: 10,
            fill: am5.color('#000000'),
            paddingTop: 8,
        });
        xRenderer.grid.template.set('visible', false);

        const xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                categoryField: 'monthDisplay',
                renderer: xRenderer,
            }),
        );

        xAxis.get('renderer').labels.template.setAll({
            oversizedBehavior: 'truncate',
        });

        const yRenderer = am5xy.AxisRendererY.new(root, {});
        yRenderer.labels.template.setAll({
            visible: false,
        });
        yRenderer.grid.template.set('visible', false);

        const yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: yRenderer,
                extraMax: 0.1,
                min: 0,
                strictMinMax: true,
            }),
        );

        const series1 = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                name: 'Series 1',
                id: 'Series 1' + id,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: selectedTab === 0 ? 'sumActiveElectricity' : 'enPi',
                sequencedInterpolation: true,
                categoryXField: 'monthDisplay',
                fill: am5.color('#0064FA'),
                stroke: am5.color('#0064FA'),
                legendLabelText: t('RESOURCE_MANAGER.CURRENT_PERIOD'),
                tooltip: am5.Tooltip.new(root, {
                    paddingTop: 4,
                    paddingBottom: 4,
                    paddingLeft: 8,
                    paddingRight: 8,
                    pointerOrientation: 'down',
                }),
            }),
        );

        series1.columns.template.setAll({
            width: am5.percent(80),
            cornerRadiusTL: 4,
            cornerRadiusTR: 4,
            strokeOpacity: 0,
            tooltipY: 0,
        });
        series1.columns.template.adapters.add('tooltipText', function (text, target) {
            return `[fontSize: 12px]${t(
                'RESOURCE_MANAGER.MONTHS.' + (target.dataItem?.dataContext as TotalElectricityConsumptionData)['month'],
            )} {year}: {valueY} kWh[/]`;
        });

        let series2;
        if (totalElectricityConsumptionData && totalElectricityConsumptionData.length > 0) {
            series2 = chart.series.push(
                am5xy.ColumnSeries.new(root, {
                    name: 'Series 2',
                    id: 'Series 2' + id,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: selectedTab === 0 ? 'sumActiveElectricity' : 'enPi',
                    sequencedInterpolation: true,
                    categoryXField: 'monthDisplay',
                    fill: am5.color('#CFE0FA'),
                    stroke: am5.color('#CFE0FA'),
                    legendLabelText: t('RESOURCE_MANAGER.PREVIOUS_YEAR'),
                    tooltip: am5.Tooltip.new(root, {
                        paddingTop: 4,
                        paddingBottom: 4,
                        paddingLeft: 8,
                        paddingRight: 8,
                        pointerOrientation: 'down',
                    }),
                }),
            );

            series2.columns.template.setAll({
                width: am5.percent(80),
                cornerRadiusTL: 4,
                cornerRadiusTR: 4,
                strokeOpacity: 0,
                tooltipY: 0,
            });

            series2.columns.template.adapters.add('tooltipText', function (text, target) {
                return `[fontSize: 12px]${t(
                    'RESOURCE_MANAGER.MONTHS.' +
                        (target.dataItem?.dataContext as TotalElectricityConsumptionData)['month'],
                )} {year}: {valueY} kWh[/]`;
            });
        }

        const legend = chart.children.push(
            am5.Legend.new(root, {
                centerX: am5.percent(50),
                x: am5.percent(50),
                marginTop: 14,
            }),
        );
        legend.labels.template.setAll({
            fontSize: 12,
            fontWeight: '400',
            fill: am5.color('#000000'),
        });

        legend.valueLabels.template.set('forceHidden', true);

        // It's important to set legend data after all the events are set on template, otherwise events won't be copied
        legend.data.setAll(chart.series.values);

        legend.markerRectangles.template.setAll({
            dy: 2.5,
            width: 12,
            height: 12,
            cornerRadiusTL: 4,
            cornerRadiusTR: 4,
            cornerRadiusBL: 4,
            cornerRadiusBR: 4,
        });
        legend.labels.template.setAll({
            fontSize: 12,
            fill: am5.color('#000000'),
        });
        legend.valueLabels.template.set('forceHidden', true);

        // It's important to set legend data after all the events are set on template, otherwise events won't be copied
        legend.data.setAll(chart.series.values);

        xAxisRef.current = xAxis;
        series1Ref.current = series1;
        if (series2) {
            series2Ref.current = series2;
        }
        rootRef.current = root;

        return () => {
            if (root) {
                root.dispose();
            }
        };
    }, [selectedTab]);

    useLayoutEffect(() => {
        if (totalElectricityConsumptionData) {
            const checkRightMonth = totalElectricityConsumptionData.filter((dataItem) =>
                isWithinRange(dataItem.month, dataItem.year, 'last12'),
            );
            const fullArray = addMissingMonths(checkRightMonth, 'last12', 'total');
            const updatedColumnNames = updateColumnNames(fullArray, 'total');
            xAxisRef?.current?.data?.setAll(sortDataByYearMonth(updatedColumnNames));
            series1Ref?.current?.data?.setAll(sortDataByYearMonth(updatedColumnNames));
        }
    }, [selectedTab, totalElectricityConsumptionData]);

    useLayoutEffect(() => {
        if (totalElectricityConsumptionData) {
            const checkRightMonth = totalElectricityConsumptionData.filter((dataItem) =>
                isWithinRange(dataItem.month, dataItem.year, 'last13to24'),
            );
            const fullArray = addMissingMonths(checkRightMonth, 'last13to24', 'total');
            const updatedColumnNames = updateColumnNames(fullArray, 'total');
            series2Ref?.current?.data?.setAll(sortDataByYearMonth(updatedColumnNames));
        }
    }, [selectedTab, totalElectricityConsumptionData]);

    return (
        <div className="flex h-80 flex-col bg-white lg:w-1/2 lg:border lg:border-neutral-200">
            <div className="flex items-center justify-between bg-mainBackground px-4 py-2 lg:bg-white lg:px-6">
                <div className="flex flex-col">
                    <span className="opensans-semibold w-auto lg:text-xs lg:text-neutral-500">
                        {t('RESOURCE_MANAGER.TOTAL_ELECTRICITY_CONSUMPTION')}
                    </span>
                    <span className="text-xs text-neutral-400 lg:hidden">{t('RESOURCE_MANAGER.LAST_6_MONTHS')}</span>
                </div>
                <div className="ml-auto hidden lg:block">
                    <Tab.Group
                        defaultIndex={selectedTab}
                        onChange={(index) => {
                            setSelectedTab(index);
                            if (index === 0) {
                                setSelectedTabDropdown(dropdownOptions[0]);
                            } else {
                                setSelectedTabDropdown(dropdownOptions[1]);
                            }
                        }}
                    >
                        <Tab.List className="opensans-semibold flex h-6 w-full items-center rounded bg-neutral-50 p-0.5 text-xxs">
                            {selectedTab === 0 ? (
                                <>
                                    <Tab className="h-5 w-20 rounded bg-blue-400 text-white focus:outline-none">
                                        {t('RESOURCE_MANAGER.SWITCH_TOTAL')}
                                    </Tab>
                                    <Tab className="h-5 w-20 text-neutral-500 focus:outline-none">
                                        {t('RESOURCE_MANAGER.SWITCH_ENPI')}
                                    </Tab>
                                </>
                            ) : (
                                selectedTab === 1 && (
                                    <>
                                        <Tab className="h-5 w-20 text-neutral-500 focus:outline-none">
                                            {t('RESOURCE_MANAGER.SWITCH_TOTAL')}
                                        </Tab>
                                        <Tab className="h-5 w-20 rounded bg-blue-400 text-white focus:outline-none">
                                            {t('RESOURCE_MANAGER.SWITCH_ENPI')}
                                        </Tab>
                                    </>
                                )
                            )}
                        </Tab.List>
                    </Tab.Group>
                </div>
                <div className="w-60 lg:hidden">
                    <DropdownList
                        item_type={'default'}
                        value={selectedTabDropdown}
                        setValue={setSelectedTabDropdown}
                        options={dropdownOptions}
                        no_option_text={t('DROPDOWN.NO_OPTIONS')}
                    />
                </div>
            </div>
            <div
                id={(selectedTab === 0 ? 'total-electricity-consumption' : 'total-enpi') + id}
                className="h-5/6 w-full"
            />
        </div>
    );
}
