import { getAuthHeader } from '../utils/auth0Authentication';
import { DocumentUpdate } from './document';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

export interface DocumentSearchBreadCrump {
    type: 'area' | 'organization' | 'asset';
    name: string;
    id: string;
}

export interface DocumentSearchResult extends DocumentUpdate {
    highlight: { 'fileName.ngram'?: string[]; text: string[]; 'text.edge_ngram': string[] };
    breadcrumb: DocumentSearchBreadCrump[];
    resultType: 'document';
    type: string;
    orgaId: string;
    docId: string;
    areaId: string;
    assetId: string;
    mailId: string;
    createdOn: string;
    text: string;
}

export interface MailSearchResult extends DocumentUpdate {
    breadcrumb: DocumentSearchBreadCrump[];
    resultType: 'mail';
    cc: { name: string; mailAddress: string }[];
    documents: { fileName: string; fileType: string; documentId: string; deleted: boolean }[];
    highlight: { 'fileName.ngram'?: string[]; text: string[]; 'text.edge_ngram': string[] };
    from: { name: string; mailAddress: string }[];
    to: { name: string; mailAddress: string }[];
    subject: string;
    orgaId: string;
    mailId: string;
    sentOnUtc: string;
    type: string;
    createdOn: string;
}

export const searchDocument = async (searchTerm: string): Promise<Response> => {
    return fetch(encodeURI('/search/api/search'), {
        method: 'POST',
        headers: await getAuthHeader(),
        body: JSON.stringify({ searchTerm: searchTerm }),
    });
};

export interface LastNSearches {
    searchTerm: string;
    timestamp: string;
}

export const lastNSearches = async (n: string): Promise<LastNSearches[]> => {
    const response = await fetch(encodeURI('/search/api/lastnsearches'), {
        method: 'POST',
        headers: await getAuthHeader(),
        body: JSON.stringify({ n: n }),
    });
    if (!response.ok) {
        throw new Error(response.statusText);
    }
    return response.json();
};

export function useFetchLastNSearches(n: string): UseQueryResult<LastNSearches[], Error> {
    return useQuery({
        queryKey: ['lastSearches', n],
        queryFn: () => lastNSearches(n),
        initialData: [],
    });
}

export interface MarketplaceSearchResult {
    id: string;
    name: string;
    description: string;
    price: number;
    image?: string;
    productNumber?: string;
    supplier?: string;
    manufacturer?: string;
}

export const searchMarketPlace = async (searchTerm: string): Promise<Response> => {
    return fetch(encodeURI('/search/api/search-marketplace'), {
        method: 'POST',
        headers: await getAuthHeader(),
        body: JSON.stringify({ searchTerm: searchTerm }),
    });
};

export interface AssetSearchResult {
    breadcrumb: DocumentSearchBreadCrump[];
    serialNumber: string;
    yearOfManufacture: number;
    typeOther: boolean;
    displayName: string;
    creatorId: string;
    rootFolderId: string;
    manufacturerOther: boolean;
    machineType: 'asset';
    type: string;
    picUrl?: string;
    createdOn: string;
    manufacturer: string;
    areaId: string;
    deleted: boolean;
    hasPic: boolean;
    id: string;
    mailLocalPart: string;
    organizationName: string;
    organizationId: string;
    areaName: string;
}

export interface AreaSearchResult {
    breadcrumb: DocumentSearchBreadCrump[];
    organizationId: string;
    deleted: boolean;
    hasPic: boolean;
    creatorId: string;
    rootFolderId: string;
    id: string;
    mailLocalPart: string;
    display_name: string;
    machineType: 'area';
    type: string;
    picUrl?: string;
    costCentre: string;
    createdOn: string;
    organizationName: string;
}

export const searchMachines = async (searchTerm: string): Promise<Response> => {
    return fetch(encodeURI('/search/api/machine'), {
        method: 'POST',
        headers: await getAuthHeader(),
        body: JSON.stringify({ searchTerm: searchTerm }),
    });
};

export interface NewsSearchResult {
    id: string;
    type: string;
    title: string;
    description: string;
    newsUrl: string;
    publishedOn: string;
    newsSourceName: string;
    newsSourceFeedUrl: string;
    newsSourceLogoUrl: string;
    newsSourceLanguage: string;
    highlight: {
        'type.ngram'?: string[];
        type?: string[];
        'title.ngram'?: string[];
        title?: string[];
        'description.ngram'?: string[];
        description?: string[];
        'newsSourceName.ngram'?: string[];
        newsSourceName?: string[];
    };
    comments?: string;
    shares?: string;
    likes?: string;
}

export const searchNews = async (searchTerm: string): Promise<Response> => {
    return fetch(encodeURI('/search/api/news'), {
        method: 'POST',
        headers: await getAuthHeader(),
        body: JSON.stringify({ searchTerm: searchTerm }),
    });
};
