import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NoAuthSVG } from '../assets/No_Plant_Access.svg';
import { Button } from '@cipa-gmbh/o6k-component-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/pro-regular-svg-icons';

function NoPlantMemberScreen({ setIsPlantMember }: { setIsPlantMember: (arg: boolean) => void }): JSX.Element {
    const { t } = useTranslation();
    return (
        <div className="flex h-full w-full flex-col items-center justify-center text-center">
            <NoAuthSVG />
            <div className="flex flex-col items-center">
                <span className="opensans-bold mt-10 text-[22px]">{t('NO_PLANT_MEMBER.HEADLINE')}</span>
                <div className="mt-6 w-[483px] text-sm text-neutral-500">
                    <span>{t('NO_PLANT_MEMBER.ONE')}</span>
                    <span className="opensans-bold"> {' ' + t('NO_PLANT_MEMBER.ONE_BOLD')}</span>
                    <span>{' ' + t('NO_PLANT_MEMBER.ONE_END')}</span>
                </div>
                <span className="mt-6 text-sm text-neutral-500">{t('NO_PLANT_MEMBER.TWO')}</span>
            </div>
            <div className="mt-10 flex flex-row items-center gap-4">
                <Button
                    button_type={'primary'}
                    color={'default'}
                    onClick={() => {
                        setIsPlantMember(true);
                        window.location.href = '/cockpit';
                    }}
                >
                    <div className="flex h-full w-full items-center justify-center">
                        <FontAwesomeIcon icon={faHouse} />
                        <span className="ml-3">{t('NO_PLANT_MEMBER.BUTTON_COCKPIT')}</span>
                    </div>
                </Button>
                <Button
                    className="bg-white"
                    button_type={'secondary'}
                    color={'default'}
                    onClick={() => {
                        setIsPlantMember(true);
                        window.history.go(-1);
                    }}
                >
                    <div className="flex h-full w-full items-center justify-center">
                        <span>{t('NO_PLANT_MEMBER.BUTTON_BACK')}</span>
                    </div>
                </Button>
            </div>
        </div>
    );
}

export default NoPlantMemberScreen;
