import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { faChevronRight, faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FormControlLabel, Radio, RadioGroup, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Button, DropdownList } from '@cipa-gmbh/o6k-component-library';
import { faCalendarWeek } from '@fortawesome/pro-regular-svg-icons';
import { enUS, de, pt } from 'date-fns/locale';
import { LanguageContext } from '../../pages/App';

type CustomTimeRange = 'custom' | 'yesterday' | 'last5Days' | 'last30Days';

const DateRangePicker = ({
    startDate,
    endDate,
    setStartDate,
    setEndDate,
}: {
    startDate: string;
    endDate: string;
    setStartDate: (arg: string) => void;
    setEndDate: (arg: string) => void;
}) => {
    dayjs.extend(customParseFormat);
    const chosenLanguage = useContext(LanguageContext);
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const [showPicker, setShowPicker] = useState<boolean>(false);
    const [preselectedPeriod, setPreselectedPeriod] = useState<CustomTimeRange>('yesterday');
    const [customStartDate, setCustomStartDate] = useState<Date | undefined>(
        dayjs(startDate, 'DD.MM.YYYY').subtract(1, 'day').toDate(),
    );
    const [customEndDate, setCustomEndDate] = useState<Date | undefined>(
        dayjs(endDate, 'DD.MM.YYYY').subtract(1, 'day').toDate(),
    );
    const [endDateErrorMessage, setEndDateErrorMessage] = useState<string>('');
    const { t } = useTranslation();

    // Calculate yesterday's date
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement> | CustomTimeRange) => {
        let value: CustomTimeRange;
        if (typeof event === 'string') {
            value = event;
        } else {
            value = (event.target as HTMLInputElement).value as CustomTimeRange;
        }

        setPreselectedPeriod(value);
        if (value === 'yesterday') {
            setCustomStartDate(dayjs().subtract(1, 'day').toDate());
            setCustomEndDate(dayjs().subtract(1, 'day').toDate());
        } else if (value === 'last5Days') {
            setCustomStartDate(dayjs().subtract(5, 'day').toDate());
            setCustomEndDate(dayjs().subtract(1, 'day').toDate());
        } else if (value === 'last30Days') {
            setCustomStartDate(dayjs().subtract(30, 'day').toDate());
            setCustomEndDate(dayjs().subtract(1, 'day').toDate());
        } else if (value === 'custom') {
            setCustomStartDate(dayjs().toDate());
            setCustomEndDate(dayjs().toDate());
        }
    };

    const handleDayClick = (day, modifiers) => {
        if (modifiers.disabled) {
            return;
        }
        if (!customStartDate || (customStartDate && customEndDate) || day < customStartDate) {
            // If no "from" date is selected or both dates are selected, set new "from" date
            setCustomStartDate(day);
            setCustomEndDate(undefined);
        } else {
            // Update "to" date
            setCustomEndDate(day);
        }
        setPreselectedPeriod('custom');
    };

    const BpIcon = styled('span')(() => ({
        borderRadius: '50%',
        width: 20,
        height: 20,
        boxShadow: 'inset 0 0 0 1px rgba(148,148,148,1), inset 0 -1px 0 rgba(148,148,148,1)',
        backgroundColor: '#FFFFFF',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#F7F7F7',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    }));

    const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: '#0064FA',
        boxShadow: 'inset 0 0 0 1px rgba(0,100,250,1), inset 0 -1px 0 rgba(0,100,250,1)',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 20,
            height: 20,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#0064FA',
        },
    });

    // Inspired by blueprintjs
    function BlueRadio(props) {
        return (
            <Radio
                sx={{
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                }}
                disableRipple
                color="default"
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                {...props}
            />
        );
    }

    useEffect(() => {
        applyDateSelection();
    }, []);

    function applyDateSelection() {
        if (!customEndDate) {
            return setEndDateErrorMessage(t('CALENDAR.END_DATE_ERROR'));
        }
        setStartDate(customStartDate ? dayjs(customStartDate).format('DD.MM.YYYY') : dayjs().format('DD.MM.YYYY'));
        setEndDate(
            customEndDate ? dayjs(customEndDate).format('DD.MM.YYYY') : dayjs().subtract(1, 'day').format('DD.MM.YYYY'),
        );
        setCustomEndDate(customEndDate ? customEndDate : dayjs().subtract(1, 'day').toDate());
        setEndDateErrorMessage('');
        setShowPicker(false);
    }
    function cancelDateSelection() {
        setCustomStartDate(dayjs(startDate, 'DD.MM.YYYY').subtract(1, 'day').toDate());
        setCustomEndDate(dayjs(endDate, 'DD.MM.YYYY').subtract(1, 'day').toDate());
        setEndDateErrorMessage('');
    }

    const handleEnterEscape = (e) => {
        if (e.key === 'Enter') {
            buttonRef.current?.click();
        } else if ((e.key === 'Esc' || e.key === 'Escape') && showPicker) {
            setShowPicker(false);
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleEnterEscape, { capture: true });
        return () => {
            window.removeEventListener('keydown', handleEnterEscape);
        };
    }, []);

    return (
        <div className="relative mt-2 flex w-full items-center rounded bg-white lg:mx-4 lg:w-fit">
            <Button
                button_type={'secondary'}
                color={'default'}
                onClick={() => {
                    setShowPicker(!showPicker);
                }}
                className="flex w-full flex-row items-center justify-center gap-3 lg:justify-between"
            >
                <FontAwesomeIcon icon={faCalendarWeek} className="h-4 w-4 text-black" />
                <div className="flex flex-row">
                    <span className="opensans-semibold mr-1 text-xs lg:text-sm">
                        {dayjs(customStartDate).format('DD.MM.YYYY')}
                    </span>{' '}
                    <span className="mx-2 text-xs lg:text-sm">-</span>
                    <span className="opensans-semibold mr-1 text-xs lg:text-sm">
                        {dayjs(customEndDate).format('DD.MM.YYYY')}
                    </span>{' '}
                </div>
            </Button>
            {showPicker && (
                <button
                    onClick={() => {
                        cancelDateSelection();
                        setShowPicker(false);
                    }}
                    tabIndex={-1}
                    className="fixed inset-0 z-50 h-full w-full cursor-default bg-black opacity-25 outline-none focus:outline-none"
                />
            )}
            <div
                className={`${
                    !showPicker ? 'hidden' : ''
                } fixed left-1/2 top-1/2 z-40 mt-10 flex w-95/100 -translate-x-1/2 -translate-y-1/2 flex-col rounded bg-white shadow-2xl sm:mx-0 sm:w-fit`}
                style={{ zIndex: 70 }}
            >
                <div className="flex flex-row rounded">
                    <div className="hidden w-48 border-b border-r border-neutral-200 pl-6 pt-4 sm:block">
                        <span className="opensans-semibold text-sm text-neutral-500">{t('DASHBOARDS.SHIFT.DATE')}</span>
                        <RadioGroup value={preselectedPeriod} onChange={handleChange}>
                            <FormControlLabel
                                value="yesterday"
                                control={<BlueRadio />}
                                label={
                                    <span
                                        className={`opensans text-sm ${
                                            preselectedPeriod === 'yesterday' ? 'text-black' : ''
                                        }`}
                                    >
                                        {t('DASHBOARDS.YESTERDAY')}
                                    </span>
                                }
                            />
                            <FormControlLabel
                                value="last5Days"
                                control={<BlueRadio />}
                                label={
                                    <span
                                        className={`opensans text-sm ${
                                            preselectedPeriod === 'last5Days' ? 'text-black' : ''
                                        }`}
                                    >
                                        {t('DASHBOARDS.LAST_5_DAYS')}
                                    </span>
                                }
                            />
                            <FormControlLabel
                                value="last30Days"
                                control={<BlueRadio />}
                                label={
                                    <span
                                        className={`opensans text-sm ${
                                            preselectedPeriod === 'last30Days' ? 'text-black' : ''
                                        }`}
                                    >
                                        {t('DASHBOARDS.LAST_30_DAYS')}
                                    </span>
                                }
                            />
                            <FormControlLabel
                                value="custom"
                                control={<BlueRadio />}
                                label={
                                    <span
                                        className={`opensans text-sm ${
                                            preselectedPeriod === 'custom' ? 'text-black' : ''
                                        }`}
                                    >
                                        {t('DASHBOARDS.CUSTOM')}
                                    </span>
                                }
                            />
                        </RadioGroup>
                    </div>
                    <div className="w-full border-b border-neutral-200 sm:w-[370px] lg:w-[440px]">
                        <div className="flex w-full flex-col">
                            <div className="flex flex-col">
                                <div className="lg:rounded-tl-0 relative flex w-full flex-row justify-center rounded-t bg-neutral-50 pb-4 lg:rounded-none lg:rounded-tr">
                                    <div className="ml-4 flex w-full flex-col">
                                        <span className="mt-4 text-sm text-xs text-neutral-500">
                                            {t('CALENDAR.FROM')}
                                        </span>
                                        <div
                                            className={`${
                                                customStartDate && customEndDate
                                                    ? 'border-blue-400 text-blue-400'
                                                    : 'border-neutral-100 text-black'
                                            } mt-1 flex h-12 items-center justify-center rounded border bg-white`}
                                        >
                                            <div className="opensans-semibold text-base">
                                                {dayjs(customStartDate).format('DD.MM.YYYY')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mx-3.5 mb-0.5 mt-9 flex flex-col items-center justify-center text-neutral-400">
                                        <FontAwesomeIcon icon={faChevronRight} className="h-4 w-4" />
                                    </div>
                                    <div className="mr-6 flex w-full flex-col">
                                        <span className="mt-4 text-sm text-xs text-neutral-500">
                                            {t('CALENDAR.TO')}
                                        </span>
                                        <div
                                            className={`${
                                                customStartDate && customEndDate
                                                    ? 'opensans-semibold border-neutral-100 text-black'
                                                    : customEndDate
                                                    ? 'opensans-semibold border-neutral-100 '
                                                    : 'border-blue-400 text-neutral-300'
                                            } mt-1 flex h-12 items-center justify-center rounded border border-blue-400 bg-white`}
                                        >
                                            <div className="text-base">
                                                {customEndDate === undefined
                                                    ? 'Datum wählen'
                                                    : dayjs(customEndDate).format('DD.MM.YYYY')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${endDateErrorMessage === '' ? 'hidden' : ''} text-xs text-red-400`}>
                                    <div className="no-wrap flex flex-row items-center justify-start bg-neutral-50 pb-3 pl-6">
                                        <FontAwesomeIcon className="mr-2" icon={faCircleExclamation} />
                                        <span>{t('CALENDAR.END_DATE_ERROR')}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4 flex flex flex-row items-center justify-center px-4 sm:hidden">
                                <DropdownList
                                    className="flex flex-row"
                                    item_type={'default'}
                                    value={{
                                        label:
                                            preselectedPeriod === 'yesterday'
                                                ? t('DASHBOARDS.YESTERDAY')
                                                : preselectedPeriod === 'last5Days'
                                                ? t('DASHBOARDS.LAST_5_DAYS')
                                                : preselectedPeriod === 'last30Days'
                                                ? t('DASHBOARDS.LAST_30_DAYS')
                                                : t('DASHBOARDS.CUSTOM'),
                                        value: preselectedPeriod,
                                    }}
                                    options={[
                                        {
                                            label: t('DASHBOARDS.YESTERDAY'),
                                            value: 'yesterday',
                                            action: () => {
                                                handleChange('yesterday');
                                            },
                                        },
                                        {
                                            label: t('DASHBOARDS.LAST_5_DAYS'),
                                            value: 'last5Days',
                                            action: () => {
                                                handleChange('last5Days');
                                            },
                                        },
                                        {
                                            label: t('DASHBOARDS.LAST_30_DAYS'),
                                            value: 'last30Days',
                                            action: () => {
                                                handleChange('last30Days');
                                            },
                                        },
                                        {
                                            label: t('DASHBOARDS.CUSTOM'),
                                            value: 'custom',
                                            action: () => {
                                                handleChange('custom');
                                            },
                                        },
                                    ]}
                                    no_option_text={t('DROPDOWN.NO_OPTIONS')}
                                    placeholder={t('DASHBOARDS.ERROR.NO_KPI')}
                                    large
                                />
                            </div>
                            <div className="flex w-full flex-col items-center justify-center">
                                <style>
                                    {`
                                     .rdp-head_cell {
                               font-size: 14px;
                               text-transform: capitalize;
                              }
                               .Selectable .rdp-day_range_start {
                              color: #ffffff !important;
                              background-color: #0264FA;
                              }
                              .Selectable .rdp-day_range_end {
                              color: #ffffff !important;
                              background-color: #0264FA;
                              }
                              .Selectable .rdp-day_range_middle {
                                background-color: #ebedf0;
                                color: black;
                              }
                              .Selectable .rdp-caption_label {
                                font-size: 16px;
                               }
                              .Selectable .rdp-cell {
                                font-size: 14px;
                               }
                              `}
                                </style>
                                <DayPicker
                                    mode="range"
                                    className="Selectable"
                                    styles={{ table: { maxWidth: 'none' } }}
                                    numberOfMonths={1}
                                    selected={{
                                        from: customStartDate,
                                        to: customEndDate,
                                    }}
                                    onDayClick={handleDayClick}
                                    disabled={[{ after: yesterday }]}
                                    locale={chosenLanguage === 'de' ? de : chosenLanguage === 'pt' ? pt : enUS}
                                    weekStartsOn={1}
                                    toMonth={new Date(dayjs().year(), dayjs().month())}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row items-center justify-end gap-2 rounded bg-neutral-50">
                    <Button
                        button_type={'secondary'}
                        color={'default'}
                        onClick={() => {
                            cancelDateSelection();
                            setShowPicker(false);
                        }}
                    >
                        <div className="flex h-full w-full items-center justify-center">{t('CALENDAR.CANCEL')}</div>
                    </Button>
                    <Button
                        button_type={'primary'}
                        color={'default'}
                        ref={buttonRef}
                        className="my-3.5 mr-6"
                        onClick={applyDateSelection}
                    >
                        <div className="flex h-full w-full items-center justify-center">{t('CALENDAR.APPLY')}</div>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default function TimeIntervalSelection({
    startDate,
    endDate,
    setStartDate,
    setEndDate,
}: {
    startDate: string;
    endDate: string;
    setStartDate: (arg: string) => void;
    setEndDate: (arg: string) => void;
}): JSX.Element {
    const { t } = useTranslation();

    return (
        <div className="flex w-full flex-col px-4 pt-11 text-xl text-neutral-500 lg:flex-row lg:items-center lg:px-0">
            <span className="whitespace-nowrap">{t('RESOURCE_MANAGER.RESOURCE_CONSUMPTION')}</span>
            <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
            />
        </div>
    );
}
