import { Plant } from '../../api/plants';
import { Dropdown, DropdownItem } from '@cipa-gmbh/o6k-component-library';
import { t } from 'i18next';
import React from 'react';
import { ElectricityConsumptionOverviewData, TotalElectricityConsumptionData } from '../../api/resourceManager';
import i18n from '../../i18n';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

export function CustomSelectPlant({
    selected,
    setSelected,
    options,
}: {
    options: Plant[];
    selected: Plant | null;
    setSelected: (arg: Plant | null) => void;
}): JSX.Element {
    function handleChange(plantId: string) {
        setSelected(options.find((item) => item.id === plantId) ?? null);
    }

    return (
        <Dropdown value={selected?.name}>
            {selected ? (
                options ? (
                    options
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((option, i) => (
                            <DropdownItem key={i} option={option.name} onClick={() => handleChange(option?.id)} />
                        ))
                ) : (
                    <DropdownItem key={1} option={t('DROPDOWN.NO_OPTION')} />
                )
            ) : (
                <DropdownItem key={1} option={''} isLoading disabled />
            )}
        </Dropdown>
    );
}

export function updateColumnNames(
    data: (TotalElectricityConsumptionData | ElectricityConsumptionOverviewData)[],
    type: 'total' | 'overview',
) {
    const helper: (TotalElectricityConsumptionData | ElectricityConsumptionOverviewData)[] = [];
    data.map((item) => {
        if (type === 'total') {
            helper.push({
                ...item,
                monthDisplay: i18n.t(`RESOURCE_MANAGER.MONTHS.${item.month}`),
            });
        } else {
            helper.push({
                ...item,
                monthDisplay: i18n.t(`RESOURCE_MANAGER.MONTHS.${item.month}`),
                power_plant: parseFloat(item['power_plant']),
                power_pv: parseFloat(item['power_pv']),
            });
        }
    });
    return helper;
}

export function isWithinRange(month: string, year: string, range: 'last12' | 'last13to24') {
    const now = dayjs();
    const dateToCheck = dayjs(`${year}-${month}-01`); // Create a date from the month and year
    if (range === 'last12') {
        const twelveMonthsAgo = now.subtract(12, 'month');
        return dateToCheck.isBetween(twelveMonthsAgo, now, 'month', '[]');
    } else if (range === 'last13to24') {
        const twentyFourMonthsAgo = now.subtract(24, 'month');
        const thirteenMonthsAgo = now.subtract(13, 'month');
        return dateToCheck.isBetween(twentyFourMonthsAgo, thirteenMonthsAgo, 'month', '[]');
    }
}

export function addMissingMonths(
    data: (TotalElectricityConsumptionData | ElectricityConsumptionOverviewData)[],
    range: 'last12' | 'last13to24',
    type: 'total' | 'overview',
) {
    const now = dayjs();
    const startMonth = range === 'last12' ? now.subtract(12, 'month') : now.subtract(24, 'month');
    const endMonth = range === 'last12' ? now.subtract(1, 'month') : now.subtract(13, 'month');

    const newData = [...data];
    for (
        let date = startMonth;
        date.isBefore(endMonth) || date.isSame(endMonth, 'month');
        date = date.add(1, 'month')
    ) {
        const month = date.format('MM');
        const year = date.format('YYYY');
        const exists = newData.some((item) => item.month === month && item.year === year);
        if (!exists) {
            if (type === 'total') {
                newData.push({ month, year, enPi: 0, sumActiveElectricity: 0, sumSqm: 0 });
            } else {
                newData.push({ month, year, power_pv: 0, power_plant: 0, pv_to_grid: 0 });
            }
        }
    }

    return newData;
}

export function sortDataByYearMonth(data: (TotalElectricityConsumptionData | ElectricityConsumptionOverviewData)[]) {
    return data.sort((a, b) => {
        if (a.year === b.year) {
            return parseInt(a.month) - parseInt(b.month);
        }
        return parseInt(a.year) - parseInt(b.year);
    });
}
