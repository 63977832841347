import { getAuthHeader } from '../utils/auth0Authentication';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

export interface User {
    auth0Id: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
    hasPic: boolean;
    defaultOrganization: string;
    defaultLanguage?: string;
    joinedOn: string;
    picUploadUrl: string;
    picUrlLarge?: string;
    picUrlMedium?: string;
    picUrlSmall?: string;
}
export interface UserUpdate {
    auth0Id: string;
    emailAddress?: string;
    firstName?: string;
    lastName?: string;
    defaultOrganization?: string;
    defaultLanguage?: string;
}

export const fetchUser = async (userId: string): Promise<User> => {
    const response = await fetch(encodeURI(`/cockpit/api/user/${userId}`), {
        method: 'GET',
        headers: await getAuthHeader({ Accept: 'application/json', 'Content-Type': 'application/json' }),
    });
    if (!response.ok) {
        throw new Error('Something is wrong');
    }
    return response.json();
};
export function useFetchUser(userId: string): UseQueryResult<User, Error> {
    return useQuery({
        queryKey: ['user', userId],
        queryFn: () => fetchUser(userId),
        enabled: !!userId,
    });
}

export const updateUser = async (apiUserUpdate: UserUpdate): Promise<User> => {
    const response = await fetch(`/cockpit/api/user`, {
        method: 'PUT',
        headers: await getAuthHeader(),
        body: JSON.stringify(apiUserUpdate),
    });

    if (!response.ok) {
        throw new Error('Something is wrong');
    }
    return response.json();
};

export const changePassword = async (userId: string): Promise<void> => {
    const response = await fetch(encodeURI(`/cockpit/api/user/${userId}/changepwd`), {
        method: 'POST',
        headers: await getAuthHeader(),
    });
    if (!response.ok) {
        throw new Error('Something is wrong');
    }
    return;
};

export const deleteUserPic = async (): Promise<string> => {
    const response = await fetch(encodeURI(`cockpit/api/user/pic`), {
        method: 'DELETE',
        headers: await getAuthHeader(),
    });

    if (!response.ok) {
        throw new Error('Something went wrong');
    }
    return response.text();
};
