import React from 'react';
import { ReactComponent as SpinnerLoader } from '../assets/Spinner_Circle_White_BG.svg';
import CardboardLoading from '../assets/loading_cardboard.gif';

export default function LoadingAnimation(): JSX.Element {
    return (
        <div className="spartan flex h-screen w-full justify-center bg-white text-black">
            <div className="flex flex-col items-center justify-center">
                <img src={CardboardLoading} alt="loading..." height={'320px'} width={'320px'} />
            </div>
        </div>
    );
}

export const SpinnerRow = ({ isFetching = true }: { isFetching?: boolean }): JSX.Element | null => {
    if (isFetching) {
        return (
            <div className="mb-8 mr-8 flex h-32 w-full items-center justify-center">
                <SpinnerLoader className="h-5 w-5 animate-spin" />
            </div>
        );
    } else {
        return null;
    }
};

export const SmallSpinnerRow = ({ isFetching = true }: { isFetching?: boolean }): JSX.Element | null => {
    if (isFetching) {
        return (
            <div className="flex h-10 items-center justify-center">
                <SpinnerLoader className="h-5 w-5 animate-spin" />
            </div>
        );
    } else {
        return null;
    }
};
