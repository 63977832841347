import React, { createContext, lazy, Suspense, useEffect, useState } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
//import { ReactComponent as MaintenanceScreen } from '../assets/maintenance_screen.svg';
import { globalLanguageKey, setCookie, getMainDomain } from '@cipa-gmbh/o6k-component-library';
import history from '../utils/history';
import { setAuth0ClientGetTokenSilently } from '../utils/auth0Authentication';
import ScrollToTop from '../components/ScrollToTop';
import i18n, { languages } from '../i18n';
import CardboardLoading from '../assets/loading_cardboard.gif';
import Header from '../components/Header/Header';
import Sidebar from '../components/Sidebar/Sidebar';
import LoadingAnimation from '../components/Loading';
import { updateUser, useFetchUser, User, UserUpdate } from '../api/user';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFetchPlantsByUser } from '../api/plants';
import NoPlantMemberScreen from './NoPlantMemberScreen';
import ResourceManager from './ResourceManager';
import MobileNavBar from '../components/MobileNavBar';
import Help from './Help';

const Profile = lazy(() => import('./Profile'));
const PlantsConfig = lazy(() => import('./PlantsConfig'));
const PlantsProfile = lazy(() => import('./PlantsProfile'));
const Notifications = lazy(() => import('./NotificationsFullPage'));
const Live = lazy(() => import('./Live'));
const DashboardsOverview = lazy(() => import('./DashboardsOverview'));
const Dashboard = lazy(() => import('./Dashboard'));
const Documentation = lazy(() => import('./Documentation'));
const Configuration = lazy(() => import('./Configuration'));
const ConditionMonitoring = lazy(() => import('./ConditionMonitoring'));
const Finance = lazy(() => import('./Finance'));
const Search = lazy(() => import('./Search'));
const NotFound = lazy(() => import('./NotFound'));
const Cockpit = lazy(() => import('./Cockpit'));
const ServiceStore = lazy(() => import('./ServiceStore'));
const Historian = lazy(() => import('./Historian'));
const Education = lazy(() => import('./Education'));
const EducationDetails = lazy(() => import('./EducationDetails'));

export const LanguageContext = createContext<string>('en');

export default function App(): JSX.Element {
    const {
        error: loginError,
        getAccessTokenSilently,
        isAuthenticated,
        isLoading,
        loginWithRedirect: auth0loginWithRedirect,
        user: auth0User,
    } = useAuth0();
    const [urlParamsLanguage, setUrlParamsLanguage] = useState<string>('en');
    const [isSidebarOpen, setSidebarOpen] = useState<boolean>(true);
    const [isMobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
    const { t } = useTranslation();
    const [type, setType] = useState<'signUp' | 'login'>('login');
    const [showDataWindow, setShowDataWindow] = React.useState<boolean>(false);
    const [plantId, setPlantId] = useState<string>('');
    const [mode, setMode] = useState<string>('');
    const urlParams = new URLSearchParams(window.location.search);
    const { data: user } = useFetchUser(auth0User?.sub ?? '');
    const [key, setKey] = useState<number>();
    const queryClient = useQueryClient();
    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    const { data: userPlants, isInitialLoading: loadingUserPlants } = useFetchPlantsByUser();
    const [isPlantMember, setIsPlantMember] = useState<boolean>(true);

    const { mutateAsync: updateUserMutation } = useMutation<User, Error, UserUpdate>({
        mutationFn: updateUser,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['user', auth0User?.sub ?? ''],
            });
        },
        onError: (error) => {
            console.log(error.message);
        },
    });

    if (urlParams.has('lang')) {
        setUrlParamsLanguage(urlParams.get('lang') as string);
        if (languages.includes(urlParams.get('lang') as string)) {
            updateUserMutation({ auth0Id: auth0User?.sub ?? '', defaultLanguage: urlParams.get('lang') as string });
        }
        urlParams.delete('lang');
        if (urlParams.toString().length > 0) {
            history.replace(window.location.pathname + '?' + urlParams.toString());
        } else {
            history.replace(window.location.pathname);
        }
    }
    if (urlParams.has('version')) {
        setType(urlParams.has('version') ? (urlParams.get('version') === 'signup' ? 'signUp' : 'login') : 'login');
        urlParams.delete('version');
        if (urlParams.toString().length > 0) {
            history.replace(window.location.pathname + '?' + urlParams.toString());
        } else {
            history.replace(window.location.pathname);
        }
    }

    if (!localStorage.getItem('liveView')) {
        localStorage.setItem('liveView', 'overview');
    }

    useEffect(() => {
        i18n.changeLanguage(user?.defaultLanguage ?? ''.toLowerCase());
        setCookie(globalLanguageKey, user?.defaultLanguage ?? ''.toLowerCase(), getMainDomain());
    }, [user?.defaultLanguage]);

    const loginWithRedirect = (returnTo?: string) => {
        auth0loginWithRedirect({
            appState: {
                returnTo:
                    returnTo ??
                    window.location.pathname + (Array.from(urlParams).length > 0 ? '?' : '') + urlParams.toString(),
            },
            authorizationParams: {
                initialScreen: type,
                language: urlParamsLanguage,
            },
        });
    };
    setAuth0ClientGetTokenSilently(getAccessTokenSilently);

    //check if user is member in plant for no member screen
    useEffect(() => {
        if (plantId && !loadingUserPlants && userPlants && userPlants.length !== 0) {
            if (userPlants?.find((plant) => plant.id === plantId)) {
                setIsPlantMember(true);
            } else {
                setIsPlantMember(false);
            }
        }
    }, [user, userPlants, plantId]);

    if (!loginError && !isLoading && !isAuthenticated) {
        loginWithRedirect();
    }
    if (
        loginError &&
        (loginError.message === 'Please verify your email before logging in.' ||
            urlParams.get('error_description') === 'Please verify your email before logging in.')
    ) {
        return (
            // <div className="relative flex h-screen w-full flex-col items-center justify-center bg-white text-xl">
            //     <MaintenanceScreen className="ml-2 h-56 w-56" />
            //     <span className="opensans-bold mt-8 text-base">Maintenance mode</span>
            //     <span className="openans text-sm">Sorry for the inconvenience</span>
            //     <span className="opensans-bold absolute bottom-0 mb-4">openpack.</span>
            // </div>
            // <div className="spartan flex h-screen flex-grow justify-center bg-white text-black">
            //     <div className="flex flex-col items-center justify-center">
            //         <p className="spartan-bold text-4xl text-opblue">{t('APP.CHECKING_EMAIL_VERIFICATION')}</p>
            //         <button
            //             className="mt-8 w-32 rounded border border-opblue px-4 py-2 text-center text-opblue hover:bg-opblue hover:text-white"
            //             onClick={() => {
            //                 loginWithRedirect('/');
            //             }}
            //         >
            //             Login
            //         </button>
            //     </div>
            // </div>
            <div className="spartan flex h-screen flex-grow justify-center bg-white text-black">
                <div className="flex flex-col items-center justify-center">
                    <p className="spartan-bold text-4xl text-opblue">{t('APP.CHECKING_EMAIL_VERIFICATION')}</p>
                    <button
                        className="mt-8 w-32 rounded border border-opblue px-4 py-2 text-center text-opblue hover:bg-opblue hover:text-white"
                        onClick={() => {
                            loginWithRedirect('/');
                        }}
                    >
                        Login
                    </button>
                </div>
            </div>
        );
    }
    // const [cookies] = useCookies(['showPlatformCookie']);

    if (!isAuthenticated || isLoading) {
        return <LoadingAnimation />;
    }
    // return cookies.hasOwnProperty('showPlatformCookie') && cookies.showPlatformCookie ? (
    return (
        <LanguageContext.Provider value={user?.defaultLanguage ?? 'en'}>
            <BrowserRouter>
                <div className="grid h-screen grid-rows-custom-sidenav-layout overflow-hidden bg-mainBackground">
                    <ScrollToTop />
                    <Header
                        isMobileMenuOpen={isMobileMenuOpen}
                        setMobileMenuOpen={setMobileMenuOpen}
                        plantId={plantId}
                        mode={mode}
                        setShowDataWindow={setShowDataWindow}
                        setKey={setKey}
                    />
                    <div className="grid overflow-y-auto lg:grid-cols-custom-sidenav-layout">
                        <Sidebar
                            setMobileMenuOpen={setMobileMenuOpen}
                            isMobileMenuOpen={isMobileMenuOpen}
                            isSidebarOpen={isSidebarOpen}
                            setSidebarOpen={setSidebarOpen}
                            setShowDataWindow={setShowDataWindow}
                            isAdmin={isAdmin}
                            setIsAdmin={setIsAdmin}
                            setIsPlantMember={setIsPlantMember}
                            isPlantMember={isPlantMember}
                        />

                        <div
                            id="scroller"
                            className="flex flex-row items-start justify-center overflow-y-auto pb-17 lg:block lg:pb-0"
                        >
                            <Suspense
                                fallback={
                                    <div className="flex h-full w-full justify-center bg-white">
                                        <div className="flex flex-col items-center justify-center">
                                            <img
                                                src={CardboardLoading}
                                                alt="loading..."
                                                height={'320px'}
                                                width={'320px'}
                                            />
                                        </div>
                                    </div>
                                }
                            >
                                <Routes>
                                    <Route
                                        path="/"
                                        element={
                                            <Cockpit
                                                setMode={setMode}
                                                setPlantId={setPlantId}
                                                setMobileMenuOpen={setMobileMenuOpen}
                                            />
                                        }
                                    />
                                    <Route path="/profile" element={<Profile />} />
                                    <Route path="/help" element={<Help />} />
                                    <Route path="/plant_overview" element={<PlantsConfig />} />
                                    <Route
                                        path="/plant/:plantId/*"
                                        element={
                                            isPlantMember ? (
                                                <PlantsProfile setPlantId={setPlantId} />
                                            ) : (
                                                <NoPlantMemberScreen setIsPlantMember={setIsPlantMember} />
                                            )
                                        }
                                    />
                                    <Route
                                        path="/plant/:plantId/configuration/*"
                                        element={
                                            isPlantMember ? (
                                                <Configuration setMode={setMode} setPlantId={setPlantId} />
                                            ) : (
                                                <NoPlantMemberScreen setIsPlantMember={setIsPlantMember} />
                                            )
                                        }
                                    />
                                    <Route
                                        path="/plant/:plantId/condition_monitoring/*"
                                        element={
                                            isPlantMember ? (
                                                <ConditionMonitoring setMode={setMode} setPlantId={setPlantId} />
                                            ) : (
                                                <NoPlantMemberScreen setIsPlantMember={setIsPlantMember} />
                                            )
                                        }
                                    />
                                    <Route
                                        path="/plant/:plantId/live/*"
                                        element={
                                            isPlantMember ? (
                                                <Live
                                                    isSidebarOpen={isSidebarOpen}
                                                    showDataWindow={showDataWindow}
                                                    setShowDataWindow={setShowDataWindow}
                                                    setPlantId={setPlantId}
                                                    setMode={setMode}
                                                />
                                            ) : (
                                                <NoPlantMemberScreen setIsPlantMember={setIsPlantMember} />
                                            )
                                        }
                                    />
                                    <Route
                                        path="/plant/:plantId/resource_manager"
                                        element={<ResourceManager setPlantId={setPlantId} />}
                                    />
                                    <Route
                                        path="/plant/:plantId/documentation/*"
                                        element={
                                            isPlantMember ? (
                                                <Documentation key={key} setMode={setMode} setPlantId={setPlantId} />
                                            ) : (
                                                <NoPlantMemberScreen setIsPlantMember={setIsPlantMember} />
                                            )
                                        }
                                    />
                                    <Route
                                        path="/plant/:plantId/dashboards"
                                        element={
                                            isPlantMember ? (
                                                <DashboardsOverview setPlantId={setPlantId} />
                                            ) : (
                                                <NoPlantMemberScreen setIsPlantMember={setIsPlantMember} />
                                            )
                                        }
                                    />
                                    <Route
                                        path="/plant/:plantId/dashboards/:dashboardId"
                                        element={
                                            isPlantMember ? (
                                                <Dashboard setPlantId={setPlantId} />
                                            ) : (
                                                <NoPlantMemberScreen setIsPlantMember={setIsPlantMember} />
                                            )
                                        }
                                    />
                                    <Route path="/historian" element={<Historian />} />
                                    <Route path="/finance" element={<Finance />} />
                                    <Route path="/notifications" element={<Notifications />} />
                                    <Route path="/search" element={<Search setMode={setMode} />} />
                                    {/*<Route path="/mail/:mailId/:type/:typeId" element={<MailDetails />} />*/}
                                    <Route
                                        path="/cockpit"
                                        element={
                                            <Cockpit
                                                setMode={setMode}
                                                setPlantId={setPlantId}
                                                setMobileMenuOpen={setMobileMenuOpen}
                                            />
                                        }
                                    />
                                    <Route path="/service-store" element={<ServiceStore />} />

                                    <Route path="/education" element={<Education />} />
                                    <Route
                                        path="/education/:courseId"
                                        element={<EducationDetails isSidebarOpen={isSidebarOpen} />}
                                    />

                                    <Route path="*" element={<NotFound />} />
                                </Routes>
                            </Suspense>
                        </div>
                    </div>
                    <MobileNavBar
                        setIsAdmin={setIsAdmin}
                        setIsPlantMember={setIsPlantMember}
                        isPlantMember={isPlantMember}
                    />
                </div>
            </BrowserRouter>
        </LanguageContext.Provider>
    );
    // ) : (
    //     <div className="relative flex h-screen w-full flex-col items-center justify-center bg-white text-xl">
    //         <MaintenanceScreen className="ml-2 h-56 w-56" />
    //         <span className="opensans-bold mt-8 text-base">Maintenance mode</span>
    //         <span className="openans text-sm">Sorry for the inconvenience</span>
    //         <span className="opensans-bold absolute bottom-0 mb-4">openpack.</span>
    //     </div>
    // );
}
