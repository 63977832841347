import React, { useCallback, useEffect, useRef, useState } from 'react';
import { faBell } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NotificationItem from '../Notifications/NotificationItem';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ToggleSwitch } from '@cipa-gmbh/o6k-component-library';
import { ReactComponent as NoNotifications } from '../../assets/NoNotifications.svg';
import { ReactComponent as NoNotificationsLast7Days } from '../../assets/NoNotificationsLast7Days.svg';
import {
    NotificationCount,
    NotificationReadUnread,
    setNotificationAsReadUnread,
    useFetchNotificationsLast7,
} from '../../api/notifications';
import { ReactComponent as SpinnerLoader } from '../../assets/Spinner_Circle_White_BG.svg';
import NotificationDropdownItemLoader from '../PulseLoader/NotificationLoader/NotificationDropdownItemLoader';

export default function Notifications({
    menuIsOpen,
    requestedMenu,
    setRequestedMenu,
    notificationCount,
    isFetchingNotificationCount,
}: {
    menuIsOpen: boolean;
    requestedMenu: string;
    setRequestedMenu: (arg: string) => void;
    notificationCount: NotificationCount | undefined;
    isFetchingNotificationCount: boolean;
}): JSX.Element {
    const [isNotificationOpen, setNotificationOpen] = useState(false);
    const [isBellButtonHover, setIsBellButtonHover] = useState(false);
    const [isNestedHover, setIsNestedHover] = useState(false);
    const [isClickedOutside, setIsClickedOutside] = useState(false);
    const [readUnread, setReadUnread] = useState(false);
    const closeNotifications = () => setNotificationOpen(false);
    const { t } = useTranslation();
    const loadMoreRef = useRef(null);
    const {
        data: notification,
        isInitialLoading: isLoadingNotification,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    } = useFetchNotificationsLast7();
    const notificationWindowRef = useRef<HTMLDivElement | null>(null);
    const queryClient = useQueryClient();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                notificationWindowRef.current &&
                !notificationWindowRef.current?.contains(event.target) &&
                isNotificationOpen
            ) {
                setIsClickedOutside(true);
                closeNotifications();
            } else setIsClickedOutside(false);
        };
        document.addEventListener('click', handleClickOutside, { capture: true });
        return () => {
            document.removeEventListener('click', handleClickOutside, { capture: true });
        };
    }, [notificationWindowRef, isNotificationOpen]);

    const toggleNotificationIsOpen = () => {
        setRequestedMenu(isNotificationOpen ? '' : 'notification');
        if (!isClickedOutside) {
            setNotificationOpen(!isNotificationOpen);
        }
        setIsClickedOutside(false);
    };

    const { mutateAsync: setAllNotificationsToReadUnreadMutation } = useMutation<
        string,
        Error,
        NotificationReadUnread[]
    >({
        mutationFn: setNotificationAsReadUnread,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['notifications'],
            });
            queryClient.invalidateQueries({
                queryKey: ['notificationsLast7'],
            });
            queryClient.invalidateQueries({
                queryKey: ['notificationCount'],
            });
        },
        onError: (error) => {
            console.log(error.message);
        },
    });

    const setAllNotificationsToRead = async () => {
        const notificationReadArray = [] as NotificationReadUnread[];
        await setAllNotificationsToReadUnreadMutation(notificationReadArray);
    };

    useEffect(() => {
        if (!hasNextPage) {
            return;
        }
        const observer = new IntersectionObserver(
            (entries) => entries.forEach((entry) => entry.isIntersecting && fetchNextPage()),
            {
                root: null,
            },
        );

        const el = loadMoreRef && loadMoreRef.current;

        if (!el) {
            return;
        }

        observer.observe(el);

        // The rest of our code
    }, [loadMoreRef.current, hasNextPage]);

    useEffect(() => {
        if (!menuIsOpen) {
            closeNotifications();
        }
    }, [menuIsOpen]);

    useEffect(() => {
        if (requestedMenu !== 'notification') {
            closeNotifications();
        }
    }, [requestedMenu]);

    const handleEscape = useCallback(
        (e) => {
            if ((e.key === 'Esc' || e.key === 'Escape') && isNotificationOpen) {
                closeNotifications();
            }
        },
        [isNotificationOpen],
    );
    useEffect(() => {
        window.addEventListener('keydown', handleEscape);
        return () => {
            window.removeEventListener('keydown', handleEscape);
        };
    }, [handleEscape]);

    return (
        <>
            <div
                onClick={toggleNotificationIsOpen}
                role="button"
                className={`${
                    isBellButtonHover && !isNestedHover ? 'hover:bg-neutral-100' : ''
                } group relative z-50 mr-6 hidden rounded px-4 py-3 md:flex`}
                onMouseOver={() => setIsBellButtonHover(true)}
                onMouseLeave={() => setIsBellButtonHover(false)}
            >
                <div className="relative z-10 focus:outline-none">
                    <FontAwesomeIcon icon={faBell} size="lg" className="h-5 w-5" fill="#000000" />
                    {!isFetchingNotificationCount && notificationCount && notificationCount?.unreadCount > 0 && (
                        <div
                            className={`${
                                isNestedHover ? 'group-hover:border-white' : 'group-hover:border-neutral-100'
                            } opensans-semibold absolute top-0 -mt-2 ml-2 flex h-4 w-4 flex-col items-center justify-center rounded-full border-2 border-white bg-red-400 p-twoHalf text-xxs text-white`}
                        >
                            {notificationCount?.unreadCount}
                        </div>
                    )}
                </div>
            </div>
            <div
                ref={notificationWindowRef}
                className={`${
                    isNotificationOpen ? `block` : `hidden`
                } absolute right-32 top-16 overflow-hidden rounded border border-neutral-200 bg-white text-sm text-black shadow-lg`}
                onMouseOver={() => setIsNestedHover(true)}
                onMouseLeave={() => setIsNestedHover(false)}
                style={{ width: '480px' }}
            >
                <div className="flex w-full flex-row items-center justify-between border-b px-4 py-4">
                    <span className="opensans-semibold text-sm text-neutral-500">
                        {t('NOTIFICATIONS.NOTIFICATIONS')}
                    </span>
                    <div className="flex items-center">
                        <span className="mr-3 text-sm text-neutral-500">{t('NOTIFICATIONS.ONLY_SHOW_UNREAD')}</span>
                        <ToggleSwitch enabled={readUnread} onChange={() => setReadUnread(!readUnread)} />
                    </div>
                </div>
                <div className="max-h-115 overflow-y-auto">
                    <div className="flex h-12 w-full flex-row px-4">
                        <button
                            onClick={setAllNotificationsToRead}
                            disabled={isFetchingNotificationCount || notificationCount?.unreadCount === 0}
                        >
                            <span
                                className={`${
                                    isFetchingNotificationCount || notificationCount?.unreadCount === 0
                                        ? 'text-gray-300'
                                        : 'text-blue-400 hover:underline'
                                } text-sm `}
                            >
                                {t('NOTIFICATIONS.SET_ALL_READ')}
                            </span>
                        </button>
                    </div>
                    {notification &&
                        notification?.pages[0]?.notifications?.length > 0 &&
                        notification.pages.map((group) =>
                            group.notifications
                                .filter((filteredNot) => {
                                    return !(readUnread && filteredNot.read);
                                })
                                .map((not, i) => (
                                    <NotificationItem
                                        key={i}
                                        notification={not}
                                        setNotificationOpen={setNotificationOpen}
                                        lastNotification={false}
                                    />
                                )),
                        )}
                    {isLoadingNotification && (
                        <div className="px-2">
                            <NotificationDropdownItemLoader />
                        </div>
                    )}
                    {notification?.pages[0].notifications?.length !== 0 && (
                        <button className="w-full items-center justify-center" ref={loadMoreRef}>
                            <div className="flex flex-row items-center justify-center py-2">
                                {isFetchingNextPage && <SpinnerLoader className="h-5 w-5 animate-spin" />}
                            </div>
                        </button>
                    )}
                    {!isLoadingNotification &&
                        !isFetchingNextPage &&
                        (notificationCount?.totalCount === 0 || notification?.pages[0].notifications?.length === 0 ? (
                            <div className="mx-28 mb-34 mt-34 flex flex-col items-center justify-center">
                                <NoNotifications />
                                <span className="mt-5 text-center text-xs text-neutral-500">
                                    {t('NOTIFICATIONS.ZERO_HINTS')}
                                </span>
                            </div>
                        ) : (
                            <div className="mx-28 mb-5 flex flex-col items-center justify-center">
                                <NoNotificationsLast7Days />
                                <span className="mt-5 text-center text-xs text-neutral-500">
                                    {t('NOTIFICATIONS.LAST_7_DAYS_HINTS')}
                                </span>
                            </div>
                        ))}
                </div>
                <div className="flex flex-row items-center justify-center border-t px-4 py-4 text-left">
                    <NavLink
                        className="cursor-pointer text-blue-400"
                        to={'/notifications'}
                        onClick={() => toggleNotificationIsOpen()}
                    >
                        <span className="hover:underline">{t('NOTIFICATIONS.SHOW_ALL')}</span>
                    </NavLink>
                </div>
            </div>
        </>
    );
}
