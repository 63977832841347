import { useLocation } from 'react-router-dom';
import i18n from 'i18next';

export function getRoutingLocation() {
    const { pathname } = useLocation();
    let location = '';
    if (pathname === '/' || pathname.includes('cockpit')) {
        location = 'Cockpit';
    } else if (pathname.includes('dashboard')) {
        location = 'Dashboards';
    } else if (pathname.includes('documentation')) {
        location = 'Documentation';
    } else if (pathname.includes('configuration')) {
        location = 'Configuration';
    } else if (pathname.includes('live')) {
        location = 'Live View';
    } else if (pathname.includes('search')) {
        location = 'SEARCH.SEARCH';
    } else if (pathname.includes('/condition_monitoring')) {
        location = 'Condition Monitoring';
    } else if (pathname.includes('historian')) {
        location = 'Historian';
    } else if (pathname.includes('finance')) {
        location = 'Finance';
    } else if (pathname.includes('service-store')) {
        location = 'Service Store';
    } else if (pathname.includes('/plant_overview')) {
        location = i18n.t('PLANTS.OVERVIEW.MY_PLANTS');
    } else if (pathname.includes('/profile')) {
        location = i18n.t('HEADER.PROFILE');
    } else if (pathname.includes('/help')) {
        location = i18n.t('SIDEBAR.HELP');
    } else if (pathname.includes('/notifications')) {
        location = i18n.t('NOTIFICATIONS.NOTIFICATIONS');
    } else if (pathname.includes('/education')) {
        location = 'Education';
    }
    return location;
}
