let auth0ClientGetTokenSilently: () => Promise<string>;

export const getAuthHeader = async (additionalHeaders = {}): Promise<HeadersInit> => {
    const token = await auth0ClientGetTokenSilently();
    return { ...additionalHeaders, Authorization: `Bearer ${token}` };
};

export const getAuthToken = async (): Promise<string> => {
    const token = await auth0ClientGetTokenSilently();
    return token;
};

export const setAuth0ClientGetTokenSilently = (func: () => Promise<string>): void => {
    auth0ClientGetTokenSilently = func;
};
