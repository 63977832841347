import React, { useCallback, useEffect, useState } from 'react';
import ProfileSelection from './ProfileSelection';
import SearchBar from './SearchBar';
import Notifications from './NotificationsDropDown';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { updateUser, useFetchUser, User, UserUpdate } from '../../api/user';
import HeaderLoader from '../PulseLoader/HeaderLoader';
import { ReactComponent as HeaderLogo } from '../../assets/openpack_logo_header.svg';
import { getRoutingLocation } from '../../utils/locationHelper';
import { useFetchPlantById, useFetchPlantByIdIsAdmin, useFetchPlantsByUser } from '../../api/plants';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useFetchNotificationCount } from '../../api/notifications';
import { DropdownList } from '@cipa-gmbh/o6k-component-library';
import useBreakpoint from '../../utils/customBreakpointHook';

export default function Header({
    isMobileMenuOpen,
    setMobileMenuOpen,
    plantId,
    mode,
    setShowDataWindow,
    setKey,
}: {
    isMobileMenuOpen: boolean;
    setMobileMenuOpen: (arg: boolean) => void;
    plantId: string;
    mode: string;
    setShowDataWindow: (arg: boolean) => void;
    setKey: (arg: number) => void;
}): JSX.Element {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [requestedMenu, setRequestedMenu] = useState<string>('');
    const closeMenu = () => setMobileMenuOpen(false);
    const { user: auth0user } = useAuth0();
    const userId = auth0user?.sub;
    const { data: user } = useFetchUser(userId ?? '');
    const { isInitialLoading: isLoadingUser } = useFetchUser(userId ?? '');
    const { data: notificationCount, isFetching: isFetchingNotificationCount } = useFetchNotificationCount();
    const { data: plants, isInitialLoading: loadingPlants } = useFetchPlantsByUser();
    const { data: defaultPlant, isLoading: isLoadingDefaultPlant } = useFetchPlantByIdIsAdmin(
        user?.defaultOrganization ?? '',
    );
    const [dropdownOptions, setDropdownOptions] = useState<{ label: string; value: string; action: () => void }[]>([]);
    const queryClient = useQueryClient();
    const { data: plant } = useFetchPlantById((mode === 'cockpit' ? defaultPlant?.id : plantId) ?? '');
    const breakpoint = useBreakpoint();

    const { mutateAsync: updateUserMutation } = useMutation<User, Error, UserUpdate>({
        mutationFn: updateUser,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['user', userId],
            });
        },
        onError: (error) => {
            console.log(error.message);
        },
    });

    const updateDefaultPlant = (plantId) => {
        updateUserMutation({ defaultOrganization: plantId, auth0Id: userId ?? '' });
    };

    const handleEscape = useCallback(
        (e) => {
            if (e.key === 'Esc' || e.key === 'Escape') {
                setMobileMenuOpen(false);
            }
        },
        [isMobileMenuOpen],
    );

    useEffect(() => {
        window.addEventListener('keydown', handleEscape);
        return () => {
            window.removeEventListener('keydown', handleEscape);
        };
    }, [handleEscape]);

    const [selectedDropdownItem, setSelectedDropdownItem] = useState<{
        label: string;
        value: string;
        action: () => void;
    }>();

    useEffect(() => {
        const newOptions = plants
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((data) => ({
                label: data.name,
                value: data.id,
                action: () => {
                    setShowDataWindow(false);
                    setKey(uuidv4());
                    updateDefaultPlant(data.id);
                    if (mode === 'live') {
                        navigate(`/plant/${data.id}/live/${localStorage.getItem('liveView') || 'overview'}`);
                    } else if (mode !== 'cockpit') {
                        navigate(`/plant/${data.id}/${mode}`);
                    }
                },
            }));
        setDropdownOptions(newOptions ? newOptions : []);

        setSelectedDropdownItem(newOptions?.find((data) => data.value === plant?.id));
    }, [plants, mode]);

    return (
        <header className="z-50 lg:z-[70]">
            <div className="flex h-full items-center justify-between border-b bg-white px-3 shadow-sm lg:h-18 lg:pl-16 lg:pr-7 2xl:pr-[68px]">
                <div className="hidden w-1/3 lg:block">
                    <HeaderLogo className="-ml-4 hover:cursor-pointer" onClick={() => navigate('/')} />
                </div>
                <div className="flex w-full flex-col items-center lg:w-3/5 lg:w-fit">
                    {mode !== 'search' && (
                        <span className="spartan-extrabold py-6 text-sm lg:hidden">
                            {getRoutingLocation() === 'SEARCH.SEARCH'
                                ? t(getRoutingLocation())
                                : getRoutingLocation() === 'Configuration'
                                ? t('HEADER.CONFIGURATION')
                                : getRoutingLocation()}
                        </span>
                    )}
                    {/*dropdown for plant selection is in header in mobile view*/}
                    {(mode === 'cockpit' ||
                        mode === 'live' ||
                        mode === 'documentation' ||
                        mode === 'configuration') && (
                        <div
                            id="standardDropdownPrefilled"
                            className="flex w-full flex-row justify-center pb-2 lg:hidden"
                        >
                            <div className="flex w-full flex-row">
                                <DropdownList
                                    className="flex flex-row"
                                    item_type={'default'}
                                    value={selectedDropdownItem}
                                    setValue={setSelectedDropdownItem}
                                    options={dropdownOptions}
                                    loading={isLoadingDefaultPlant || loadingPlants}
                                    large
                                    no_option_text={t('DROPDOWN.NO_OPTIONS')}
                                />
                            </div>
                        </div>
                    )}
                    {((breakpoint !== 'xs' && breakpoint !== 'sm' && breakpoint !== 'md') || mode === 'search') && (
                        <SearchBar />
                    )}
                </div>
                <div className="hidden w-1/3 items-center justify-end bg-white text-black lg:flex">
                    {!isLoadingUser ? (
                        <>
                            <Notifications
                                menuIsOpen={isMobileMenuOpen}
                                requestedMenu={requestedMenu}
                                setRequestedMenu={setRequestedMenu}
                                notificationCount={notificationCount}
                                isFetchingNotificationCount={isFetchingNotificationCount}
                            />
                            <ProfileSelection
                                closeMenu={closeMenu}
                                menuIsOpen={isMobileMenuOpen}
                                requestedMenu={requestedMenu}
                                setRequestedMenu={setRequestedMenu}
                            />
                        </>
                    ) : (
                        <HeaderLoader />
                    )}
                </div>
            </div>
        </header>
    );
}
