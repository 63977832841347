import React from 'react';

export default function HeaderLoader(): JSX.Element {
    return (
        <>
            <div className="skeleton-box mr-6 h-12 w-44 rounded" />
            <div className="skeleton-box mr-6 h-12 w-14 rounded" />
            <div className="skeleton-box h-12 w-18 rounded rounded-l-3xl" />
        </>
    );
}
