import React, { ReactElement, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRight } from '@fortawesome/pro-regular-svg-icons';
import { LanguageContext } from './App';
import { useTranslation } from 'react-i18next';

export default function Help(): ReactElement {
    const { t } = useTranslation();
    const chosenLanguage = useContext(LanguageContext);
    return (
        <div className="flex flex-col">
            <div className="opensans-bold hidden whitespace-nowrap px-8 pt-8 text-lg text-black lg:block">
                {t('SIDEBAR.HELP')}
            </div>
            <ul className="flex w-screen flex-col gap-2 p-4">
                <li>
                    <a
                        className="flex h-12 items-center rounded"
                        href={`${
                            chosenLanguage === 'de'
                                ? 'https://openpack.com/nutzungsbedingungen'
                                : 'https://www.openpack.com/en/nutzungsbedingungen'
                        }`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FontAwesomeIcon icon={faArrowUpRight} className="p-4" size="sm" />
                        <span className="p-4">{t('SIDEBAR.HELP_MENU.TERMS_OF_USE')}</span>
                    </a>
                </li>
                <li>
                    <a
                        className="flex h-12 items-center rounded"
                        href={`${
                            chosenLanguage === 'de'
                                ? 'https://openpack.com/kontakt'
                                : 'https://openpack.com/en/contact-us'
                        }`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FontAwesomeIcon icon={faArrowUpRight} className="p-4" size="sm" />
                        <span className="p-4">{t('SIDEBAR.HELP_MENU.CONTACT')}</span>
                    </a>
                </li>
                <li>
                    <a
                        className="flex h-12 items-center rounded"
                        href={`${
                            chosenLanguage === 'de'
                                ? 'https://openpack.com/datenschutz'
                                : 'https://openpack.com/en/datenschutz'
                        }`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FontAwesomeIcon icon={faArrowUpRight} className="p-4" size="sm" />
                        <span className="p-4">{t('SIDEBAR.HELP_MENU.PRIVACY')}</span>
                    </a>
                </li>
                <li>
                    <a
                        className="flex h-12 items-center rounded"
                        href={`${
                            chosenLanguage === 'de'
                                ? 'https://openpack.com/impressum'
                                : 'https://openpack.com/en/impressum-2'
                        }`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FontAwesomeIcon icon={faArrowUpRight} className="p-4" size="sm" />
                        <span className="p-4">{t('SIDEBAR.HELP_MENU.IMPRINT')}</span>
                    </a>
                </li>
            </ul>
        </div>
    );
}
