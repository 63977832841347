import { getAuthHeader } from '../utils/auth0Authentication';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

export interface NewsFeed {
    id: string;
    description: string;
    newsUrl: string;
    publishedOn: string;
    title: string;
    newsSource: {
        id: string;
        feedUrl: string;
        language: string;
        logoUrl: string;
        name: string;
    };
}

export interface MyAppsData {
    image: string;
    imageAlt: string;
    appName: string;
    navURL: string;
    clickHandler?: (arg: boolean) => void;
    dfMenuHandler?: (arg: boolean) => void;
    newTab?: boolean;
}

export interface ServiceData {
    logoImage: string;
    logoImageAlt: string;
    bodyImage: string;
    bodyImageAlt: string;
    title: string;
    description: string;
    categories: string[];
    URL: string;
    contactEmail: string;
}

export interface BannerData {
    bannerImage: string;
    alt: string;
    is_service_store?: boolean;
}

export interface SolutionListItem {
    solutionId: string;
    name: string;
    solutionType: string;
    iiotDataSink: boolean;
    iiotDataSource: boolean;
    iconUrl: string;
    packages: [{ packageId: string; name: string; packageType: string }];
}

export interface PackageListItem {
    packageId: string;
    name: string;
    packageType: string;
    includedSolutions: [{ solutionId: string; name: string }];
    bookedByInputOrganization: boolean;
    availableOrganizations: [{ id: string; name: string; bookedByOrganization: boolean }];
}
export interface SolutionList {
    mostlyUsed: SolutionListItem[];
    further: SolutionListItem[];
    partner: SolutionListItem[];
    packages: PackageListItem[];
}

export const fetchSolutionList = async (organizationId: string): Promise<SolutionList> => {
    const response = await fetch(encodeURI(`/cockpit/api/organization/${organizationId}/solution-list`), {
        method: 'GET',
        headers: await getAuthHeader(),
    });
    if (!response.ok) {
        throw new Error('Something went wrong');
    }
    return response.json();
};

export function useFetchSolutionList(plantId: string): UseQueryResult<SolutionList, Error> {
    return useQuery({
        queryKey: ['solutionList', plantId],
        queryFn: () => fetchSolutionList(plantId),
        enabled: !!plantId,
    });
}

export const fetchNews = async ({ language, refresh }: { language: string; refresh: boolean }): Promise<Response> => {
    return await fetch(encodeURI(`/cockpit/api/news/language/${language}${refresh ? '?refresh=true' : ''}`), {
        method: 'GET',
        headers: await getAuthHeader(),
    });
};
