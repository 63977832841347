import React, { useEffect, useState } from 'react';
import { DropdownList } from '@cipa-gmbh/o6k-component-library';
import { Plant, useFetchPlantById, useFetchPlantsByUser } from '../api/plants';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateUser, useFetchUser, User, UserUpdate } from '../api/user';
import { useAuth0 } from '@auth0/auth0-react';
import EnergyFlow from '../components/ResourceManager/EnergyFlow';
import TotalEnergyConsumption from '../components/ResourceManager/TotalEnergyConsumption';
import ElectricityConsumptionOverview from '../components/ResourceManager/ElectricityConsumptionOverview';
import ConsumptionPerResourceDailyAverage from '../components/ResourceManager/ConsumptionPerResourceDailyAverage';
import ResourceTable from '../components/ResourceManager/ResourceTable';
import TimeIntervalSelection from '../components/ResourceManager/TimeIntervalSelection';
import dayjs from 'dayjs';

export default function ResourceManager({ setPlantId }: { setPlantId: (arg: string) => void }): JSX.Element {
    const { plantId } = useParams();
    const queryClient = useQueryClient();
    const [dropdownOptions, setDropdownOptions] = useState<{ label: string; value: string; action: () => void }[]>([]);
    const [selectedDropdownItem, setSelectedDropdownItem] = useState<{
        label: string;
        value: string;
        action: () => void;
    }>();
    const { data: plants, isInitialLoading: loadingPlants } = useFetchPlantsByUser();
    const navigate = useNavigate();
    const { data: plant } = useFetchPlantById(plantId ?? '');
    const { t } = useTranslation();
    const { user: auth0user } = useAuth0();
    const userId = auth0user?.sub;
    const { data: user } = useFetchUser(userId ?? '');
    const [startDate, setStartDate] = useState<string>(dayjs().format('DD.MM.YYYY'));
    const [endDate, setEndDate] = useState<string>(dayjs().format('DD.MM.YYYY'));

    useEffect(() => {
        if (plantId) {
            setPlantId(plantId);
        }
    }, [plantId]);

    const { mutateAsync: updateUserMutation } = useMutation<User, Error, UserUpdate>({
        mutationFn: updateUser,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['user', user?.auth0Id],
            });
        },
        onError: (error) => {
            console.log(error.message);
        },
    });

    const updateDefaultPlant = (plantId) => {
        updateUserMutation({ defaultOrganization: plantId, auth0Id: user?.auth0Id ?? '' });
    };

    useEffect(() => {
        const newOptions = plants
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((data) => ({
                label: data.name,
                value: data.id,
                action: () => {
                    updateDefaultPlant(data.id);
                    navigate(`/plant/${data.id}/resource_manager`);
                },
            }));
        setDropdownOptions(newOptions ? newOptions : []);

        setSelectedDropdownItem(newOptions?.find((data) => data.value === plant?.id));
    }, [plants]);

    return (
        <div className="flex w-full flex-col">
            <div className="sticky top-0 z-20 flex h-16 w-full flex-row items-center justify-between gap-6 border-b border-neutral-200 bg-white">
                <div className="opensans-semibold ml-6 hidden text-lg lg:block">{t('RESOURCE_MANAGER.HEADLINE')}</div>
                <div className="flex w-full flex-row gap-4 lg:w-fit">
                    <div
                        id="standardDropdownPrefilled"
                        className="m-3 flex w-full flex-row justify-end lg:ml-0 lg:mr-6 lg:w-64"
                    >
                        <DropdownList
                            className="flex flex-row"
                            item_type={'default'}
                            value={selectedDropdownItem}
                            setValue={setSelectedDropdownItem}
                            options={dropdownOptions}
                            loading={loadingPlants}
                            no_option_text={t('DROPDOWN.NO_OPTIONS')}
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-x-4 bg-neutral-50 shadow-md lg:gap-y-6 lg:border-b lg:px-6 lg:py-6">
                <div className="px-4 pt-6 text-xl text-neutral-500 lg:px-0 lg:pt-0 ">
                    {t('RESOURCE_MANAGER.OVERVIEW')}
                </div>
                <div className="flex w-full flex-col lg:flex-row lg:gap-4">
                    <TotalEnergyConsumption plant={plant} />
                    <ElectricityConsumptionOverview plant={plant} />
                </div>
                <EnergyFlow
                    startTime={dayjs(startDate, 'DD.MM.YYYY').startOf('day').unix()}
                    endTime={dayjs(endDate, 'DD.MM.YYYY').endOf('day').unix()}
                    plantId={plantId ?? ''}
                    timezoneId={plant?.timezoneId ?? ''}
                />
                <TimeIntervalSelection
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                />
                <ConsumptionPerResourceDailyAverage
                    startTime={dayjs(startDate, 'DD.MM.YYYY').startOf('day').unix()}
                    endTime={dayjs(endDate, 'DD.MM.YYYY').endOf('day').unix()}
                    plant={plant ?? ({} as Plant)}
                />
                <ResourceTable startDate={startDate} endDate={endDate} plant={plant ?? ({} as Plant)} />
            </div>
        </div>
    );
}
