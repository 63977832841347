import { getAuthHeader } from '../utils/auth0Authentication';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

export interface PlantUser {
    userId: string;
    isAdmin: boolean;
    lastName: string;
    firstName: string;
    emailAddress: string;
}

export interface PlantInvitationUser {
    auth0Id: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    hasPic: boolean;
    joinedOn: string;
    checked?: boolean;
}

export interface Invitation {
    id: string;
    organizationId: string;
    organizationName: string;
    accepted: boolean;
    declined: boolean;
    emailAddress: string;
    userId?: string;
    deleted: boolean;
    createdOn: string;
    creator: PlantInvitationUser;
    user: PlantUser | null;
}

export interface Plant {
    id: string;
    name: string;
    companyId?: string;
    company?: Company;
    taxId: string;
    hasPic: boolean;
    isApplicationProvider: boolean;
    isDataProvider: boolean;
    isProductProvider: boolean;
    timezoneId: string;
    picUrlLarge: string;
    picUrlMedium: string;
    picUrlSmall: string;
    organizationUsers: PlantUser[];
    organizationInvitations: Invitation[];
    address: {
        street: string;
        houseNumber: string;
        postalCode: string;
        city: string;
        country: string;
        longitude: string;
        latitude: string;
    };
    isActive: boolean;
    isMember: boolean;
    picUploadUrl: string;
    svg?: string;
    config?: string;
    hasSvg?: boolean;
    plantPic?: string;
}

export interface Company {
    id: string;
    createdOn: string;
    name: string;
    address: {
        id: string;
        createdOn: string;
        street: string;
        houseNumber: string;
        postalCode: string;
        city: string;
        country: string;
    };
    organizations: Plant[];
}

export interface Group {
    id: string;
    createdOn: string;
    name: string;
    companies: Company[];
}

export interface PaywallOption {
    id: string;
    name: string;
    createdOn: string;
    deleted: boolean;
}

export const fetchPlantPaywallOptions = async (organizationId: string): Promise<PaywallOption[]> => {
    const response = await fetch(encodeURI(`/mmm/api/organization/${organizationId}/option-list`), {
        headers: await getAuthHeader(),
    });

    if (!response.ok) {
        throw new Error('Something is wrong');
    }
    return response.json();
};

export function useFetchPlantPaywallOptions(organizationId: string): UseQueryResult<PaywallOption[], Error> {
    return useQuery({
        queryKey: ['paywallOptions', organizationId],
        queryFn: () => fetchPlantPaywallOptions(organizationId),
        enabled: !!organizationId,
        initialData: [],
    });
}

export const fetchPlantsByUserWithCompanyGroup = async (): Promise<Company[]> => {
    const response = await fetch(encodeURI(`/cockpit/api/organization/user`), {
        headers: await getAuthHeader(),
    });

    if (!response.ok) {
        throw new Error('Something is wrong');
    }
    return response.json();
};

export function useFetchPlantsByUserWithCompanyGroup(): UseQueryResult<Plant[], Error> {
    return useQuery({
        queryKey: ['plantWithCompanyGroup'],
        queryFn: fetchPlantsByUserWithCompanyGroup,
        select: extractPlantsFromCompaniesHelper,
        initialData: [],
    });
}

export const extractPlantsFromCompaniesHelper = (companies: Company[]): Plant[] => {
    const plants: Plant[] = [];
    companies.forEach((company) => {
        company.organizations?.forEach((plant) => {
            plant.companyId = company.id;
            plants.push(plant);
        });
    });
    return plants;
};

export function useFetchCompaniesFromUser(): UseQueryResult<Company[], Error> {
    return useQuery({
        queryKey: ['company'],
        queryFn: () => fetchPlantsByUserWithCompanyGroup(),
        initialData: [],
    });
}

export const fetchOnlyPlantsByUser = async (): Promise<Company[]> => {
    const response = await fetch(encodeURI(`/cockpit/api/organizations/user`), {
        headers: await getAuthHeader(),
    });

    if (!response.ok) {
        throw new Error('Something is wrong');
    }
    return response.json();
};

export function useFetchPlantsByUser(): UseQueryResult<Plant[], Error> {
    return useQuery({
        queryKey: ['plant'],
        queryFn: fetchOnlyPlantsByUser,
        initialData: [],
    });
}

export const fetchPlantsIsAdminByUser = async (): Promise<{ id: string; name: string; isAdmin: boolean }[]> => {
    const response = await fetch(encodeURI(`/cockpit/api/organization/is-admin`), {
        headers: await getAuthHeader(),
    });
    if (!response.ok) {
        throw new Error('Something is wrong');
    }
    return response.json();
};

export function useFetchPlantsIsAdminByUser(): UseQueryResult<{ id: string; name: string; isAdmin: boolean }[], Error> {
    return useQuery({
        queryKey: ['plantIsAdmin'],
        queryFn: () => fetchPlantsIsAdminByUser(),
    });
}

export const fetchPlantById = async (plantId: string): Promise<Plant | string> => {
    const response = await fetch(encodeURI(`/cockpit/api/organization/${plantId}`), {
        headers: await getAuthHeader(),
    });
    if (!response.ok) {
        throw new Error('Something is wrong!');
    }
    if (
        response.headers.get('content-type') &&
        response.headers.get('content-type')?.indexOf('application/json') !== -1
    ) {
        return response.json();
    } else {
        return response.text();
    }
};

export function useFetchPlantById(plantId: string): UseQueryResult<Plant, Error> {
    return useQuery({
        queryKey: ['plant', plantId],
        queryFn: () => fetchPlantById(plantId),
        enabled: !!plantId,
    });
}

export const fetchPlantByIdIsAdmin = async (plantId: string): Promise<{ isAdmin: boolean; id: string }> => {
    const response = await fetch(encodeURI(`/cockpit/api/organization/${plantId}/is-admin`), {
        headers: await getAuthHeader(),
    });
    if (!response.ok) {
        throw new Error('Something went wrong');
    }
    return response.json();
};

export function useFetchPlantByIdIsAdmin(
    plantId: string,
): UseQueryResult<{ isAdmin: boolean; id: string } | null, Error> {
    return useQuery({
        queryKey: ['plantIsAdmin', plantId],
        queryFn: () => fetchPlantByIdIsAdmin(plantId),
        enabled: !!plantId,
    });
}

export const fetchPlantForOverview = async (
    plantId: string,
): Promise<{ plantPic?: string; svg?: string; config?: string; hasSvg: boolean }> => {
    const response = await fetch(encodeURI(`/mmm/api/organization/${plantId}/overview/`), {
        headers: await getAuthHeader(),
    });
    if (!response.ok) {
        throw new Error('Something is wrong');
    }
    return response.json();
};

const fetchArcherDataAndStoreInCache = async (
    plantId: string,
): Promise<{ svgData: string | null; hasSvg: boolean; configData: JSON | null }> => {
    const plant = await fetchPlantForOverview(plantId);
    let svgData: string | null = null;
    let configData = null;
    if (plant.hasSvg) {
        const responseSvg = await fetch(plant.svg ?? '');
        if (!responseSvg.ok) {
            throw new Error('Something is wrong');
        }
        svgData = await responseSvg.text();
        const responseConfig = await fetch(plant.config ?? '');
        if (!responseConfig.ok) {
            throw new Error('Something is wrong');
        }
        configData = await responseConfig.json();
    }

    return { svgData: svgData, hasSvg: plant?.hasSvg, configData: configData };
};

export function useFetchArcherData(
    plantId: string,
    plant: Plant | string,
    plants: Plant[] | string,
): UseQueryResult<{ svgData: string; hasSvg: boolean; configData: JSON }, Error> {
    return useQuery({
        queryKey: ['plantOverview', plantId],
        queryFn: () => fetchArcherDataAndStoreInCache(plantId),
        enabled: !!plantId && !!plant && !!plants,
    });
}

export const addUserToPlant = async ({
    plantId,
    users,
}: {
    plantId: string;
    users: string[];
}): Promise<Invitation[]> => {
    const response = await fetch(encodeURI(`/cockpit/api/organization/${plantId}/invite`), {
        method: 'POST',
        headers: await getAuthHeader(),
        body: JSON.stringify(users),
    });

    if (!response.ok) {
        throw new Error('Something is wrong');
    }
    return response.json();
};

export const deleteInvitationFromPlantPlant = async ({
    plantId,
    inviteId,
}: {
    plantId: string;
    inviteId: string;
}): Promise<string> => {
    const response = await fetch(encodeURI(`/cockpit/api/organization/${plantId}/invite/${inviteId}`), {
        method: 'DELETE',
        headers: await getAuthHeader(),
    });
    if (!response.ok) {
        throw new Error('Something is wrong');
    }
    return response.text();
};

export const removeUserFromPlant = async ({
    plantId,
    userId,
}: {
    plantId: string;
    userId: string;
}): Promise<string> => {
    const response = await fetch(encodeURI(`/cockpit/api/organization/${plantId}/user`), {
        method: 'DELETE',
        headers: await getAuthHeader(),
        body: JSON.stringify([userId]),
    });

    if (!response.ok) {
        throw new Error('Something is wrong');
    }
    return response.text();
};

export const updateUserRole = async ({
    plantId,
    userId,
    isAdmin,
}: {
    plantId: string;
    userId: string;
    isAdmin: boolean;
}): Promise<string> => {
    const response = await fetch(encodeURI(`/cockpit/api/organization/${plantId}/user/${userId}`), {
        method: 'PUT',
        headers: await getAuthHeader(),
        body: JSON.stringify({ isAdmin: isAdmin }),
    });
    if (!response.ok) {
        throw new Error('Something is wrong');
    }
    return response.text();
};

export const deletePlantPic = async (plantId: string): Promise<void> => {
    const response = await fetch(encodeURI(`/cockpit/api/organization/${plantId}/pic`), {
        method: 'DELETE',
        headers: await getAuthHeader(),
    });

    if (!response.ok) {
        throw new Error('Something went wrong');
    }
    return;
};
